(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .constant('DevelopmentConstant', (function() {
            return {
                availability: {
                    LIST: ['AVAILABLE','PENDING','WITHDRAWN','SOLD','SOLDBYOTHERAGENT','LEASED'],
                    AVAILABLE: 'AVAILABLE',
                    PENDING: 'PENDING',
                    WITHDRAWN: 'WITHDRAWN',
                    SOLD: 'SOLD',
                    SOLD_BY_OTHER_AGENT: 'SOLDBYOTHERAGENT',
                    LEASED: 'LEASED',
                },
                transactionType: {
                    LIST: ['SELL','RENT'],
                    SELL: 'SELL',
                    RENT: 'RENT'
                },
                propertyType: {
                    LIST: ['APARTMENT','TOWNHOUSE','VILLA','PENTHOUSE','DUPLEX','SHOPHOUSE','OFFICETEL','CONDOTEL','LAND'],
                    APARTMENT: 'APARTMENT',
                    TOWNHOUSE: 'TOWNHOUSE',
                    VILLA: 'VILLA',
                    PENTHOUSE: 'PENTHOUSE',
                    DUPLEX: 'DUPLEX',
                    SHOPHOUSE: 'SHOPHOUSE',
                    OFFICETEL: 'OFFICETEL',
                    CONDOTEL: 'CONDOTEL',
                    LAND: 'LAND'
                },
                paymentCycle: {
                    LIST: ['ANNUALLY', 'MONTHLY', 'WEEKLY', 'DAILY'],
                    ANNUALLY: 'ANNUALLY',
                    MONTHLY: 'MONTHLY',
                    WEEKLY: 'WEEKLY',
                    DAILY: 'DAILY'
                },
                propertyUnitType: {
                	LIST: ['SQ_METER', 'SQ_FEET'],
                	SQ_METER: 'SQ_METER',
                	SQ_FEET: 'SQ_FEET'
                },
                lotUnitType: {
                	LIST: ['SQ_METER', 'SQ_KILOMETER', 'HECTARE', 'SQ_FEET', 'SQ_MILE', 'ACRE'],
                	SQ_METER: 'SQ_METER',
                	SQ_KILOMETER: 'SQ_KILOMETER',
                	HECTARE: 'HECTARE',
                	SQ_FEET: 'SQ_FEET',
                	SQ_MILE: 'SQ_MILE',
                	ACRE: 'ACRE'
                },
                roadUnitType: {
                	LIST: ['METER', 'FEET'],
                	METER: 'METER',
                	FEET: 'FEET'
                },
                constructionStatus: {
                	LIST: ['OFF_PLAN', 'UNDER_CONSTRUCTION', 'COMPLETED', 'RESALE'],
                	OFF_PLAN: 'OFF_PLAN',
                	UNDER_CONSTRUCTION: 'UNDER_CONSTRUCTION',
                	COMPLETED: 'COMPLETED',
                	RESALE: 'RESALE'
                },
                feeTerm: {
                    LIST: ['MONTHLY', 'QUARTERLY', 'BIANNUAL', 'ANNUAL'],
                    MONTHLY: 'MONTHLY',
                    QUARTERLY: 'QUARTERLY',
                    BIANNUAL: 'BIANNUAL',
                    ANNUAL: 'ANNUAL'
                },
                price: {
                	MAX_SEARCH_PRICE : 10000000,
                	MAX_SEARCH_LEASE_PRICE : 15000
                },
                documentType: {
                	LIST: ['LAND_USE_RIGHT_CERTIFICATE', 'BUSINESS_LICENSE', 'INVESTMENT_CERTIFICATE', 
                		'PROJECT_APPROVAL', 'MASTER_PLAN', 'FOUNDATION_REVISION_MINUTES'],
                		LAND_USE_RIGHT_CERTIFICATE: 'LAND_USE_RIGHT_CERTIFICATE',
                		BUSINESS_LICENSE: 'BUSINESS_LICENSE',
                		INVESTMENT_CERTIFICATE: 'INVESTMENT_CERTIFICATE',
                		PROJECT_APPROVAL: 'PROJECT_APPROVAL',
                		MASTER_PLAN: 'MASTER_PLAN',
                		FOUNDATION_REVISION_MINUTES: 'FOUNDATION_REVISION_MINUTES',
                },
            }
        })());
})();
