(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'AuthorityConstant'];

    function stateConfig($stateProvider, AuthorityConstant) {
        $stateProvider
        .state('reports', {
            parent: 'app',
            url: '/reports',
            data: {
            	authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_ADMIN , AuthorityConstant.ROLE_MANAGER , AuthorityConstant.ROLE_AGENT],
            	pageTitle: 'indochinaApp.report.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/reports/reports.html',
                    controller: 'ReportsController',
                    controllerAs: 'vm'
                }
            },
            params: {
            	reportType: null,
    	        agentsSelected: [],
    	        stagesSelected: [],
    	        developmentsSelected: [],
    	        propertiesSelected: [],
    	        officeSelected: [],
    	        languagePreferenceSelected: null,
    	        fromDate: null,
    	        toDate: null,
    	        hasError: false
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('report');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
