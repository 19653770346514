(function () {
    'use strict';

    angular
        .module('indochinaApp')
        .constant('CurrencyConstant', (function() {
			return {
				LIST : [
					'USD',
					'VND',
					'AED',
					'AUD',
					'CAD',
					'CHF',
					'CNY',
					'EUR',
					'GBP',
					'HKD',
					'IDR',
					'INR',
					'JPY',
					'KRW',
					'MYR',
					'RUB',
					'SGD',
					'THB',
					'TWD'        
				],
				DES : {
					USD : '$',
					VND : '₫',
					AED : 'د.إ',
					AUD : '$',
					CAD : '$',
					CHF : 'CHF',
					CNY : '¥',
					EUR : '€',
					GBP : '£',
					HKD : 'HK$',
					IDR : 'Rp',
					INR : '₹',
					JPY : '¥',
					KRW : '₩',
					MYR : 'RM',
					RUB : '₽',
					SGD : 'S$',
					THB : '฿',
					TWD : 'NT$'
				}
			}
		})()
    );
})();
