(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('DotProperty', DotProperty);

    DotProperty.$inject = ['$rootScope', '$scope', '$state', '$http', 'FileSaver','$window'];

    function DotProperty ($rootScope, $scope, $state, $http, FileSaver,$window) {
        var vm = this;
        init();

        function init() {
        	downloadXml();
        }
        
        function downloadXml(){
	       	 var url = "api/cms/dotproperty/get-all";
	       	 $http.get(url).then(function(result) {
	   		 		var response = new Blob([result.data], {type : 'text/xml;charset=utf-8;'})
	   		 		//FileSaver.saveAs(response, 'DotProperty.xml');
	   		 		var fileURL = URL.createObjectURL(response);
		   		 	$window.open(fileURL, '_self');//'_blank'
		    	 }, function(response){
		    		 if(response.status == 401){
		    			
	       		 	 }
		    	 });
        }
    }
})();


