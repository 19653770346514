(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('PhotoDialogControllerRe', PhotoDialogControllerRe);

    PhotoDialogControllerRe.$inject = ['$rootScope', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'photoEntity', 'photoDescriptionLanguages', 'PropertyService', 'PhotoService', '$translate', '$state',
                                     '$uibModal', 'photoProperties'];

    function PhotoDialogControllerRe ($rootScope, $timeout, $scope, $stateParams, $uibModalInstance, photoEntity, photoDescriptionLanguages, PropertyService, PhotoService, $translate, $state,
    								$uibModal, photoProperties) {
        var vm = this;
        var languages = [];
        $scope.$on('modal.closing', function(event, reason, closed) {
        	if($scope.editForm.$dirty){
                event.preventDefault();

                $uibModal.open({
                    templateUrl: 'app/components/confirmation-dialog/leave/confirm-dialog.html',
                    controller: 'LeaveConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                        entity: function(){
                        	return null;
                        },
                        translateKeys: function(){
                        	return {
                        		keys: 'indochinaApp.property.confirmLeavePage'
                        	};
                        }
                    }
                }).result.then(function() {
                	$scope.editForm.$dirty = false;
                	vm.clear();
                }, function() {
                    event.preventDefault();
                });
            }

        });

        // session is time out so we must close all modals
        $rootScope.$on('modal:close', function(event, result) {
            clear();
        });

     // default language
        vm.defaultLanguage = ['EN','VN'];

        var propertyId = $stateParams.id;
        vm.photoId = photoEntity ? photoEntity.id : 0;

        vm.isNotHavePhoto = (vm.photoId === 0) ? true : false;

        vm.image = photoEntity.relativePath+'/'+photoEntity.fileOnServer;
        vm.imageCropped = null;
        vm.aspectRatio = photoProperties.imageSize.width/photoProperties.imageSize.height;
        vm.resultImageSize = {w:photoProperties.imageSize.width, h:photoProperties.imageSize.height};
        vm.cropAreaStyle = {height:photoProperties.imageSize.height+'px'}
        vm.cropject={cropImageWidth:null, cropImageHeight:null};
        vm.blockingObject = {block: true};
        vm.callTestFuntion = function () {
            vm.blockingObject.render(function (dataURL) {
                vm.image = dataURL;
            });
        };
        vm.blockingObject.callback = function (dataURL) {
            vm.image = dataURL;
        };
        vm.resetImage = function() {
            vm.image = photoEntity.relativePath+'/'+photoEntity.fileOnServer;
            vm.imageCropped = null;
        }
        var img = new Image();
        img.src = vm.image;
        img.onload = function() {
            //vm.cropAreaStyle = {width:this.width+'px', height:this.height+'px'}
            vm.originalDimensions = {imageWidth:this.width, imageHeight:this.height};
        };


        vm.photoDescriptionLanguageEmpty = {id:null, langKey:null, description:null, photoId:vm.photoId, created: null, modified: null};
        vm.photoDescriptionLanguages = photoDescriptionLanguages;
        if(photoDescriptionLanguages.length ==0)
            addPhotoDescriptionLanguage();

        vm.addPhotoDescriptionLanguage = addPhotoDescriptionLanguage;
        vm.removePhotoDescriptionLanguage = removePhotoDescriptionLanguage;
        vm.hasRowEmpty = hasRowEmpty;

        vm.getLanguages = getLanguages;
        vm.clear = clear;
        vm.save = save;
        vm.onTxtAreaChange = onTxtAreaChange;

        vm.isOneRow = isOneRow;

        PropertyService.getAllLanguage().then(function (reponse) {
            languages = reponse.data;
        });

        function clear () {
            $rootScope.$broadcast('app:resetTimeSession', true);
            $uibModalInstance.dismiss('cancel');
        }

        function onTxtAreaChange() {
        	if (vm.photoId && vm.photoId !== 0) {
        		angular.element("#btnSubmit").attr('disabled',null);
        	}
        }

        function dataURIToBlobInfo(dataURI) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }


            var blob = new Blob([ia], {type:mimeString});
            var ext = mimeString.split('/')[1];
            var fileName = photoEntity.fileName.substr(0, photoEntity.fileName.lastIndexOf('.'));
            //var file = new File([blob], (fileName+'.'+ext), {type:mimeString});
            return {fileName:fileName+'.'+ext, blob:blob};
        }
        function save () {
            $rootScope.$broadcast('app:resetTimeSession', true);
        	$scope.editForm.$dirty = false;
            vm.isSaving = true;
            //remove description if don't have description
        	checkingPhotoDescriptionLanguages();

            if(vm.imageCropped) {
                PhotoService.uploadPhoto({id:vm.photoId}, {propertyId:propertyId, blob:dataURIToBlobInfo(vm.imageCropped).blob, fileName:dataURIToBlobInfo(vm.imageCropped).fileName}, onSaveSuccess, onSaveError);
            } else {
                if(vm.photoId > 0) {
                    PhotoService.savePhotoDescriptionLanguages({id:vm.photoId}, vm.photoDescriptionLanguages, function(result) {

                        $uibModalInstance.close(result);
                        vm.isSaving = false;
                        $scope.editForm.$dirty = false;
                    }, function() {
                        $uibModalInstance.close();
                        vm.isSaving = false;
                    });
                }
            }
        }

        function onSaveSuccess (photo) {
            PhotoService.savePhotoDescriptionLanguages({id:photo.id}, vm.photoDescriptionLanguages, function(result) {
                $uibModalInstance.close(photo);
                vm.isSaving = false;
                $rootScope.$broadcast('property:editImage', true);
                //$scope.editForm.$dirty = false;
                //$state.isEdit = true;
            	//$state.reload($state.current.name);
            }, function() {
                $uibModalInstance.close(photo);
                vm.isSaving = false;
            });
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function removePhotoDescriptionLanguage(index) {
        	$scope.editForm.$dirty = true;
            vm.photoDescriptionLanguages.splice(index, 1);
            if(photoDescriptionLanguages.length ==0)
                addPhotoDescriptionLanguage();
        }

        function addPhotoDescriptionLanguage() {
        	//$scope.editForm.$dirty = true;
        	if (!vm.photoDescriptionLanguages.length) {
        		//default language EN && VN
                var photoDescriptionLanguageEN = {id:null, langKey:'EN', description:null, photoId:vm.photoId, created: null, modified: null};
                var photoDescriptionLanguageVN = {id:null, langKey:'VN', description:null, photoId:vm.photoId, created: null, modified: null};
                vm.photoDescriptionLanguages.push(photoDescriptionLanguageEN);
                vm.photoDescriptionLanguages.push(photoDescriptionLanguageVN);
        	} else {
        		vm.photoDescriptionLanguages.push(angular.copy(vm.photoDescriptionLanguageEmpty));
                vm.photoDescriptionLanguageEmpty.langKey = null;
                vm.photoDescriptionLanguageEmpty.description = null;
        	}
        }

        function getLanguages(me) {
            if(vm.photoDescriptionLanguages.length === 0)
                return languages;

            var langs = [];
            for(var i = 0; i < languages.length; i++) {
                if(languages[i] === me || !isExist(languages[i]))
                    langs.push(languages[i]);
            }
            return langs;
        };

        function isExist(code) {
            for(var i = 0; i < vm.photoDescriptionLanguages.length; i++) {
                if(vm.photoDescriptionLanguages[i].langKey === code)
                    return true;
            }
            return false;
        }

        function hasRowEmpty() {
            if(vm.photoDescriptionLanguages.length < languages.length) {
                return true;
            }
            return false;
        }

        function isOneRow(){
        	if(vm.photoDescriptionLanguages.length === 1) {
        		return true;
        	}
        	return false;
        }

        function checkingPhotoDescriptionLanguages(){
        	var arrayDes = [];
        	for(var i = 0; i < vm.photoDescriptionLanguages.length; i++) {
                if(vm.photoDescriptionLanguages[i].description !== null && vm.photoDescriptionLanguages[i].description !== "") {
                	arrayDes.push(vm.photoDescriptionLanguages[i]);
                }
            }
        	vm.photoDescriptionLanguages = arrayDes;
        }
    }
})();
