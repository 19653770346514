(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('PropertyExposeController', PropertyExposeController);

    PropertyExposeController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'previousState', 'entity', 'PhotoService', 'PropertyService', 'CurrencyConstant', 'PropertyExpose', 'FileSaver'];

    function PropertyExposeController($scope, $state, $rootScope, $stateParams, previousState, entity, PhotoService, PropertyService, CurrencyConstant, PropertyExpose, FileSaver) {

        var vm = this;

        vm.cancel = cancel;
        vm.save = save;
        vm.property = entity;
        vm.selectNumber = selectNumber;
        //vm.getNumber = getNumber;

        init();

        function init() {

            vm.exposeObject = {
                id: vm.property.id,
                photos: []
            }

            // init number array for select images
            //vm.numbersMaster = [1,2,3,4,5,6,7];
            vm.numbersMaster = [];
            vm.numbers = [];

            // init list photo of this property
            PhotoService.query({propertyId: vm.property.id}, function(result) {
                vm.photos = result;
                var i;
                for(i = 1; i < vm.photos.length + 1; i++) {
                    if(i < 8) {
                        vm.numbersMaster.push(i)
                    }
                }

                angular.forEach(vm.photos, function(value, key) {
                    vm.numbers.push(angular.copy(vm.numbersMaster));
                });
            });

            // init language list
            PropertyService.getAllLanguage().then(function(reponse){
                vm.languages = reponse.data;
                // set default english
                vm.exposeObject.language = vm.languages[0];
            });

            // init currency list and set default is 'USD'
            vm.currencyUnits = CurrencyConstant.LIST;
            vm.exposeObject.currency = vm.currencyUnits[0];

            // init measurement and set default is 'Metric'
            vm.measurements = [{code:0, description: 'Metric'}, {code:1, description: 'Imperial'}]
            vm.measurementSelect = vm.measurements[0];
        }

        function selectNumber(index, photoId, number) {
            // get the number of last pick
            var lastPickNumber = null;
            angular.forEach(vm.exposeObject.photos, function(value, key) {
                if(value.photoId === photoId) {
                    lastPickNumber = value.order;
                    // remove image to avoid one image have many order
                    vm.exposeObject.photos.splice(key, 1);
                }
            });

            angular.forEach(vm.numbers, function(value, key) {
                if(key !== index) {
                    angular.forEach(vm.numbers[key], function(item, i) {
                        // remove number in all selects of image
                        if(item === number) {
                            vm.numbers[key].splice(i, 1);
                        }

                    });
                    // push number if have into all selects of image
                    if(lastPickNumber) vm.numbers[key].push(lastPickNumber);
                }

                vm.numbers[key].sort();
            });

            // push object of photo order into expose object if order number has chosen
            if(number) vm.exposeObject.photos.push({photoId: photoId, order: number});
        }

        function cancel() {
            $state.go('property-detail.detail-tab', {id:vm.property.id});
            $rootScope.$broadcast('property:backFromExpose', true);
        }

        function save () {
            vm.exposeObject.measurement = vm.measurementSelect.code;
            PropertyExpose.expose(vm.exposeObject, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            var fileName = vm.property.propertyId + '.pdf';
            FileSaver.saveAs(result.response, fileName);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
