(function() {
    'use strict';

    angular
        .module('indochinaApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            'rzModule',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'ui.select',
            'ngSanitize',
            'uiCropper',
            'ui.tree',
            'dibari.angular-ellipsis',
            'ngFileSaver',
            'ngIdle',
            'ui.sortable'
            
        ]).run(run);

    run.$inject = ['$rootScope', 'stateHandler', 'translationHandler', 'JhiCurrencyService', 'Idle','$state', '$window', '$localStorage', '$uibModal'];

    function run($rootScope, stateHandler, translationHandler, JhiCurrencyService, Idle, $state, $window, $localStorage, $uibModal) {
        $rootScope.$on('IdleTimeout', function() {
        	if($state.current.name != 'login') {
        		//$rootScope.$broadcast('app:sesstionIsTimeOut', true);
                $rootScope.isSessionTimeOut = true;
				$rootScope.gotoLogout();
			}
        });

    	Idle.watch();
        stateHandler.initialize();
        translationHandler.initialize();

        JhiCurrencyService.initialize();

        $rootScope.downloadFile = downloadFile;
        $rootScope.scrollTop = scrollTop;
        $rootScope.gotoLogout = gotoLogout;

        function downloadFile(fileId){
        	var href = window.location.href;
        	var newHref = href.substring(0,href.indexOf('#')) + 'api/files/download/'+fileId;
        	window.location.href = newHref;
        }

        function scrollTop(){
        	try{
        		$('html, body').animate({
        			scrollTop: 0
        		}, 'slow');
        	}
        	catch(e){
        		// I don't want to show anything.
        	}
        }

		function gotoLogout(){
			$state.go('logout');

			// broadcast to all modal is opening to close
			$rootScope.$broadcast('modal:close', true);

			$uibModal.open({
		        templateUrl: 'app/components/confirmation-dialog/inform/session-timeout-dialog.html',
		        controller: 'SessionTimeoutInformDialogController',
		        controllerAs: 'vm',
		        size: 'sm',
		        windowClass : 'absolute-center',
		        resolve: {
		            entity: null,
		            translateKeys: function(){
		            	return {
		            		keys: 'global.messages.alertTimeOutSession'
		            	};
		            }
		        }
		    }).result.then(function() {
                // reset variable session time out
                if($rootScope.isSessionTimeOut) {
                    $rootScope.isSessionTimeOut = false;
                }
		    }, function() {
		        //$state.go('^');
		    });
		}
    }
})();
