(function() {
    'use strict';
    angular
        .module('indochinaApp')
        .factory('ReportService', ReportService);

    ReportService.$inject = ['$resource', 'DateUtils'];

    function ReportService($resource, DateUtils) {
        var resourceUrl = 'api/report';

        return $resource(resourceUrl, {}, {
            'exportPipelineCSV': {
                method: 'POST',
                url: resourceUrl + '/export/pipeline-csv',
           	 	headers : {accept : 'text/csv'},
                cache : false,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.fromDate = DateUtils.convertLocalDateToServer(copy.fromDate);
                    copy.toDate = DateUtils.convertLocalDateToServer(copy.toDate);
                    copy.listAgentId = copy.agentsSelected.map(function(val, key, array){
                   	 return val.id;
                    });
                    return angular.toJson(copy);
                },
                transformResponse : function(data) {
                    return {
                        response : new Blob([data], {type : 'text/csv;charset=utf-8;'})
                    };
                }
             },
             'exportSalesByOfficeCSV': {
                 method: 'POST',
                 url: resourceUrl + '/export/sales-by-office-csv',
            	 	headers : {accept : 'text/csv'},
                 cache : false,
                 transformRequest: function (data) {
                     var copy = angular.copy(data);
                     copy.fromDate = DateUtils.convertLocalDateToServer(copy.fromDate);
                     copy.toDate = DateUtils.convertLocalDateToServer(copy.toDate);
                     copy.listAgentId = copy.agentsSelected.map(function(val, key, array){
                       	 return val.id;
                        });
                     return angular.toJson(copy);
                 },
                 transformResponse : function(data) {
                     return {
                         response : new Blob([data], {type : 'text/csv;charset=utf-8;'})
                     };
                 }
              },
              'exportLeasingCSV': {
                  method: 'POST',
                  url: resourceUrl + '/export/leasing-csv',
             	 	headers : {accept : 'text/csv'},
                  cache : false,
                  transformRequest: function (data) {
                      var copy = angular.copy(data);
                      copy.fromDate = DateUtils.convertLocalDateToServer(copy.fromDate);
                      copy.toDate = DateUtils.convertLocalDateToServer(copy.toDate);
                      copy.listAgentId = copy.agentsSelected.map(function(val, key, array){
                        	 return val.id;
                         });
                      return angular.toJson(copy);
                  },
                  transformResponse : function(data) {
                      return {
                          response : new Blob([data], {type : 'text/csv;charset=utf-8;'})
                      };
                  }
               },
             'exportQuotaCSV': {
                 method: 'POST',
                 url: resourceUrl + '/export/quota-csv',
            	 headers : {accept : 'text/csv'},
                 cache : false,
                 transformRequest: function (data) {
                     var copy = angular.copy(data);
                     copy.fromDate = DateUtils.convertLocalDateToServer(copy.fromDate);
                     copy.toDate = DateUtils.convertLocalDateToServer(copy.toDate);
                     copy.listAgentId = copy.agentsSelected.map(function(val, key, array){
                    	 return val.id;
                     });
                     return angular.toJson(copy);
                 },
                 transformResponse : function(data) {
                     return {
                         response : new Blob([data], {type : 'text/csv;charset=utf-8;'})
                     };
                 }
              },
            'marketingReportCSV': {
                method: 'POST',
                url: resourceUrl + '/export/marketing-report-csv',
                headers : {accept : 'text/csv'},
                cache : false,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.fromDate = DateUtils.convertLocalDateToServer(copy.fromDate);
                    copy.toDate = DateUtils.convertLocalDateToServer(copy.toDate);
                    copy.listAgentId = copy.agentsSelected.map(function(val, key, array){
                        return val.id;
                    });
                    copy.listPropertyId = copy.propertiesSelected.map(function(val, key, array){
                        return val.id;
                    });
                    copy.listDevelopmentId = copy.developmentsSelected.map(function(val, key, array){
                        return val.id;
                    });
                    return angular.toJson(copy);
                },
                transformResponse : function(data) {
                    return {
                        response : new Blob([data], {type : 'text/csv;charset=utf-8;'})
                    };
                }
            },
            'exportSalesSummaryCSV': {
                method: 'POST',
                url: resourceUrl + '/export/sales-summary-csv',
           	 headers : {accept : 'text/csv'},
                cache : false,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.fromDate = DateUtils.convertLocalDateToServer(copy.fromDate);
                    copy.toDate = DateUtils.convertLocalDateToServer(copy.toDate);
                    copy.listAgentId = copy.agentsSelected.map(function(val, key, array){
                   	 return val.id;
                    });
                    return angular.toJson(copy);
                },
                transformResponse : function(data) {
                    return {
                        response : new Blob([data], {type : 'text/csv;charset=utf-8;'})
                    };
                }
             },
             'exportPortalsCSV': {
                 method: 'POST',
                 url: resourceUrl + '/export/portals-csv',
            	 headers : {accept : 'text/csv'},
                 cache : false,
                 transformRequest: function (data) {
                     var copy = angular.copy(data);
                     copy.fromDate = DateUtils.convertLocalDateToServer(copy.fromDate);
                     copy.toDate = DateUtils.convertLocalDateToServer(copy.toDate);
                     copy.listAgentId = null;
                     return angular.toJson(copy);
                 },
                 transformResponse : function(data) {
                     return {
                         response : new Blob([data], {type : 'text/csv;charset=utf-8;'})
                     };
                 }
              },
             'exportLeadCSV': {
                 method: 'POST',
                 url: resourceUrl + '/export/lead-csv',
            	 headers : {accept : 'text/csv'},
                 cache : false,
                 transformRequest: function (data) {
                     var copy = angular.copy(data);
                     copy.fromDate = DateUtils.convertLocalDateToServer(copy.fromDate);
                     copy.toDate = DateUtils.convertLocalDateToServer(copy.toDate);
                     copy.listAgentId = copy.agentsSelected.map(function(val, key, array){
                    	 return val.id;
                     });
                     return angular.toJson(copy);
                 },
                 transformResponse : function(data) {
                     return {
                         response : new Blob([data], {type : 'text/csv;charset=utf-8;'})
                     };
                 }
              },
        });
    }
})();
