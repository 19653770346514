(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('OpportunityDetailController', OpportunityDetailController);

    OpportunityDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'MeasurementConversionService', 'Opportunity', 'Contact', 'Person',
                                           'PropertyService', 'JhiCurrencyService', 'CurrencyConstant', 'OpportunityConstant', 'AuthorityConstant', 'Principal',
                                           'login'];

    function OpportunityDetailController($scope, $rootScope, $stateParams, previousState, entity, MCService, Opportunity, Contact, Person,
    										PropertyService, JhiCurrencyService, CurrencyConstant, OpportunityConstant, AuthorityConstant, Principal,
    										login) {
        var vm = this;
        
        /******************************************************************************************
         * define all variables
         */

        vm.opportunity = entity;
        vm.previousState = previousState.name;
        
        vm.currencyCode = JhiCurrencyService.currencyCode;
        vm.currencyRate = JhiCurrencyService.rate;
        vm.currenciesDes = CurrencyConstant.DES;
        
        /******************************************************************************************
         * all default action
         */
        $rootScope.scrollTop();
        
        vm.isShowEditButton = false;
        vm.isShowDeleteButton = false;
        
        if(login.authorities[0] === AuthorityConstant.ROLE_AGENT){
			vm.isShowEditButton = true;
        }
        else if(login.authorities[0] === AuthorityConstant.ROLE_MANAGER){
        	vm.isShowEditButton = true;
        }
        else if(login.authorities[0] === AuthorityConstant.ROLE_SUPER_USER){
        	vm.isShowEditButton = true;
        	vm.isShowDeleteButton = true;
        }
        
        if(vm.opportunity.propertyId != null){        	
        	PropertyService.getPropertyForOpportunity({id: vm.opportunity.propertyId}).$promise.then(function (reponse) {
        		setProperty(reponse);
        	});
        }
        
        if(vm.opportunity.contactId != null){
        	Contact.getContactForOpportunity({id:vm.opportunity.contactId}).$promise.then(function (reponse) {
        		vm.contact = reponse;
        	});
        }
        
        if(vm.opportunity.person != null && vm.opportunity.person.id != null){
        	Person.get({id:vm.opportunity.person.id}).$promise.then(function (reponse) {
        		vm.person = reponse;
        	});
        }
        
        /******************************************************************************************
         * define functions
         */
        function setProperty(property){
        	vm.property = property;
            vm.property.sizeConverter = MCService.init(vm.property.sizeUnit);
        }
    }
})();
