(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'AuthorityConstant'];

    function stateConfig($stateProvider, AuthorityConstant) {
        $stateProvider
        .state('property-detail.publish-tab', {
        	parent: 'property-detail',
        	url: '/publish-tab',
            data: {
            	authorities: [AuthorityConstant.ROLE_SUPER_USER]
            },
        	templateUrl: 'app/entities/property/property-detail/publish-tab/publish-tab.html',
            controller: 'PublishTabController',
            controllerAs: 'vm',
            resolve: {
                entity: ['$stateParams', 'PropertyService', function($stateParams, PropertyService) {
                    return PropertyService.get({id : $stateParams.id}).$promise;
                }],
                photos: ['entity', 'PhotoService', function(entity, PhotoService) {
                    return PhotoService.query({propertyId: entity.id}).$promise;
                }]
            }
        });
    }

})();
