(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('ActivitiesTabSection1Controller', ActivitiesTabSection1Controller);

    ActivitiesTabSection1Controller.$inject = ['$scope', '$state', '$parse', '$rootScope', '$stateParams', 'previousState', 'entity', 'Activity', 'activityTypeResource', '$uibModal', 'login'];

    function ActivitiesTabSection1Controller($scope, $state, $parse, $rootScope, $stateParams, previousState, entity, Activity, activityTypeResource, $uibModal, login) {

        var vm = this;
        vm.contact = entity;

        var pagingParams = $state.pagingParams;

        vm.loadPage = loadPage;
        //vm.createNewActivity = createNewActivity;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 5;

        vm.activityClick = activityClick;
        if($state.isEdit) {
        	vm.isEdit = $state.isEdit;
        }
        loadAll();

        $rootScope.$on('indochinaApp:activityUpdate', function(event, result) {
        	transition();
        });

        $rootScope.$on('contact:changeMode', function(event, result) {
            vm.isEdit = result;
        });

        $rootScope.$on('activity-contact:search', function(event, fromDate, toDate) {
            vm.fromDate = fromDate;
            vm.toDate = toDate;
            vm.page = 0;
            transition();
        });

        function loadAll () {
        	Activity.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                contactId: vm.contact.id,
                fromDate: vm.fromDate,
                toDate:vm.toDate
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if(vm.predicate === 'person') {
                	result = [];
                	result.push('primanyPerson.firstName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                	result.push('primanyPerson.lastName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                }
                if(vm.predicate === 'owner') {
                	result = [];
                	result.push('owner.firstName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                	result.push('owner.lastName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                }
                if (vm.predicate !== 'id') {
                    result.push('id' + ',' + (vm.reverse ? 'asc' : 'desc'));
                }
                return result;
            }
            function onSuccess(data, headers) {
                //vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.activities = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                //AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
        	$state.pagingParams.page = vm.page;
        	$state.pagingParams.predicate = vm.predicate;
        	$state.pagingParams.ascending = vm.reverse;
        	$state.pagingParams.sort = vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc');
        	loadAll();
        }

        function activityClick (activity) {
        	var templateUrl = null;
        	var controller = null;
        	/*if(activity.isContactCreate) {
        		templateUrl = 'app/entities/activity/activity-dialog.html';
        		controller = 'ActivityDialogController';
        	} else {
        		templateUrl = 'app/entities/activity/activity-property-dialog.html';
            	controller = 'ActivityPropertyDialogController';
        	}*/
        	$uibModal.open({
                templateUrl: 'app/entities/activity/activity-dialog.html',
                controller: 'ActivityDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    development: function () {
                        return null;
                    },
                	login: function () {
                	    return login;
                    },
                	isFollowUpActivity: function () {
                		return false;
                	},
                	contact: ['Contact', function (Contact) {
                		return null;
                	}],
                    property: function () {
                        return null;
                    },
                    entity: function () {
                        return Activity.get({id : activity.id}, function (result){
                        }).$promise;
                    },
                    propertyList: function () {
                        return null;
                    },
                    propertyTemplate: function () {
                	    return null;
                    },
                    activityTypeResource: function () {
                        return activityTypeResource;
                    }
                }
            }).result.then(function() {
                $state.go('activity', null, { reload: 'activity' });
            }, function() {

            });
        }
    }
})();
