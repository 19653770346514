(function() {
    'use strict';
    angular
        .module('indochinaApp')
        .factory('LeadSourceManagementService', LeadSourceManagementService);

    LeadSourceManagementService.$inject = ['$resource'];

    function LeadSourceManagementService ($resource) {
        var resourceUrl =  'api/lead-sources/:id';

        return $resource(resourceUrl, {}, {
        	'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                    	console.log(data);
                    }
                    return data;
                }
            },
            'save': { method:'POST' },
            'getAllDelete': { 
            	url: 'api/lead-sources-delete',
            	method: 'GET',
            	isArray: true
            	},
        });
    }
})();

