(function() {
    'use strict';
    angular
        .module('indochinaApp')
        .factory('PropertyExpose', PropertyExpose);

    PropertyExpose.$inject = ['$resource'];

    function PropertyExpose ($resource) {
        var resourceUrl =  'api/expose-property';

        var service = $resource('api/:id', {}, {

            'expose' : {
                method : 'post',
                url : resourceUrl,
                responseType: 'arraybuffer',
                cache : false,
                transformResponse : function(data) {
                    return {
                        response : new Blob([data], {type : 'application/pdf'})
                    };
                }
            }
        });

        return service;

    }
})();
