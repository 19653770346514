(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('ActivitiesTabController', Controller);

    Controller.$inject = ['$scope', '$state', '$rootScope', 'entity', 'FileSaver', 'searchActivity', 'Activity', '$uibModal', 'login', 'listSearchAgent', 'AuthorityConstant'
                         , 'activityTypeResource', 'DateUtils', 'ActivityConstant'];

    function Controller($scope, $state, $rootScope, entity, FileSaver, searchActivity, Activity, $uibModal, login, listSearchAgent, AuthorityConstant, activityTypeResource
                        , DateUtils, ActivityConstant) {

        var vm = this;


        // define action
        vm.search = search;
        vm.clear = clear;
        vm.transition = transition;
        vm.activityClick = activityClick;
        vm.onAgentSelect = onAgentSelect;
        vm.onRemoveAgentSelect = onRemoveAgentSelect;
        vm.createNewActivity = createNewActivity;
        vm.exportCSV = exportCSV;

        // paging variable
        var pagingParams = $state.pagingParams;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = 5;

        // data picker
        vm.openFromCalendar = openFromCalendar;
        vm.openToCalendar = openToCalendar;
        vm.validateDatepicker = validateDatepicker;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.fromDate = false;
        vm.datePickerOpenStatus.toDate = false;

        vm.delete = deleteActivity;

        vm.datePickerOption = {
            showWeeks: false,
            startingDay: 1,
            readonlyInput: true,
            dateformat: 'MM/dd/yyyy'
        }

        vm.isHavePermissionToDelete = isHavePermissionToDelete;

        vm.isShowExportButton = (login.authorities[0] === AuthorityConstant.ROLE_MANAGER || login.authorities[0] === AuthorityConstant.ROLE_SUPER_USER);

        init ();

        function init () {

            $rootScope.scrollTop();
            vm.isEdit = true;

            // check activity tab open from contact, property or development
            if (isCreateFromDevelopment()) {
                $rootScope.$broadcast('development:activeTab', 'ACTIVITY');
                vm.development = entity;
            }
            if (isCreateFromContact()) {
                $rootScope.$broadcast('contact:activeTab', 'ACTIVITY');
                vm.contact = entity;
            }
            if (isCreateFromProperty()) {
                $rootScope.$broadcast('property:activeTab', 'ACTIVITY');
                vm.property = entity;
            }


            // checking login user is role agent or not
            if(login.authorities[0] === AuthorityConstant.ROLE_AGENT) {
                vm.isAgent = true;
            }

            // init agent Resource
            vm.listAgents = listSearchAgent;

            // load search condition
            vm.searchObj = searchActivity;
            vm.searchObj.agentObjectList = [];

            if (isCreateFromDevelopment()) {
                vm.searchObj.developmentId = vm.development.id;
            }

            if (isCreateFromContact()) {
                vm.searchObj.contactId = vm.contact.id;
            }

            if (isCreateFromProperty()) {
                vm.searchObj.propertyId = vm.property.id;
            }

            // load default agent for search
            loadDefaultAgent ();

        }

        function loadDefaultAgent () {
            // add login to default search agent
            angular.forEach(vm.listAgents, function(agent, key) {
                if(login.id === agent.id) {
                    onAgentSelect (agent);
                    vm.searchObj.agentObjectList.push(agent);
                }
            });
        }



        function search () {
            vm.page = 0;
            transition ();
        }

        function clear () {

            //reset all search condition
            vm.searchObj.searchQuery =  null;
            vm.searchObj.status = ActivityConstant.status.OPEN;
            vm.searchObj.agentIds = [];
            vm.searchObj.agentObjectList = [];

            vm.fromDate = null;
            vm.toDate = null;
            vm.invalidDatepicker = false;

            // load default agent for search
            loadDefaultAgent ();

            // search again
            search ();
        }


        /*if($state.isEdit) {
            vm.isEdit = $state.isEdit;
        }*/

        loadAll();

        $rootScope.$on('indochinaApp:activityUpdate', function(event, result) {
            transition();
        });

        /*$rootScope.$on('property:changeMode', function(event, result) {
            vm.isEdit = result;
        });*/

        function loadAll () {

            vm.searchObj.fromDate = DateUtils.convertLocalDateToServer(vm.fromDate);
            vm.searchObj.toDate = DateUtils.convertLocalDateToServer(vm.toDate);

            var apiParam = angular.copy(vm.searchObj);
            apiParam.page = pagingParams.page - 1;
            apiParam.size = vm.itemsPerPage;
            apiParam.sort = sort();

			Activity.searchActivity(apiParam, onSuccess, onError);

        }

        function sort() {
        	var result = [];

        	if (vm.predicate === 'person'){
        		result.push('ps.firstName' + ',' + (vm.reverse ? 'asc' : 'desc'));
        		result.push('ps.lastName' + ',' + (vm.reverse ? 'asc' : 'desc'));
        	}
        	else if (vm.predicate === 'phone'){
        		result.push('ph.phone' + ',' + (vm.reverse ? 'asc' : 'desc'));
        	}
        	else if (vm.predicate === 'email'){
        		result.push('e.email' + ',' + (vm.reverse ? 'asc' : 'desc'));
        	}
        	else{
        		result.push('a.' + vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'));
        	}

    		if (vm.predicate !== 'id') {
                result.push('a.id,' + (vm.reverse ? 'asc' : 'desc'));
            }
            return result;
        }
        function onSuccess(data, headers) {
            //vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.activities = data;
            vm.page = pagingParams.page;

            angular.forEach(vm.activities, function(activity, key) {
                if ( activity.status === ActivityConstant.status.OPEN ) {
                    activity.statusText = ActivityConstant.statusText.OPEN;
                } else {
                    activity.statusText = ActivityConstant.statusText.COMPLETE;
                }
            });


        }
        function onError(error) {
            //AlertService.error(error.data.message);
        }

        function transition() {
            $state.pagingParams.page = vm.page;
            $state.pagingParams.predicate = vm.predicate;
            $state.pagingParams.ascending = vm.reverse;
            $state.pagingParams.sort = vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc');
            loadAll();
        }

        function activityClick (activity) {
            if (activity.status === ActivityConstant.status.OPEN) {
                $uibModal.open({
                    templateUrl: 'app/entities/activity/activity-dialog.html',
                    controller: 'ActivityDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        development: ['DevelopmentService', function (DevelopmentService) {
                            if (isCreateFromDevelopment()) {
                                return DevelopmentService.get({id : vm.development.id}).$promise;
                            }
                            return null;
                        }],
                        login: function () {
                            return login;
                        },
                        isFollowUpActivity: function () {
                            return false;
                        },
                        contact: function () {
                            return null;
                        },
                        property: function () {
                            return null;
                        },
                        entity: function () {
                            return Activity.get({id : activity.id}).$promise;
                        },
                        propertyTemplate: ['PropertyService', function (PropertyService) {
                            if(isCreateFromDevelopment()) {
                                return PropertyService.get({id:vm.development.propertyTemplate.id}).$promise;
                            }
                            return null;
                        }],
                        activityTypeResource: function () {
                            return activityTypeResource;
                        },
                        marketingCampaignResource: ['MarketingCampaign', function(MarketingCampaign) {
                            return MarketingCampaign.query().$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('activity', null, { reload: 'activity' });
                }, function() {

                });
            } else {
                $uibModal.open({
                    templateUrl: 'app/entities/activity/activity-dialog-view.html',
                    controller: 'ActivityDialogViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return Activity.get({id : activity.id}).$promise;
                        },
                        development: ['DevelopmentService', function (DevelopmentService) {
                            if (isCreateFromDevelopment()) {
                                return DevelopmentService.get({id : vm.development.id}).$promise;
                            }
                            return null;
                        }],
                        pagingParams: ['PaginationUtil', function (PaginationUtil) {
                            var sort = 'id';
                            return {
                                page: PaginationUtil.parsePage(1),
                                sort: sort,
                                predicate: PaginationUtil.parsePredicate(sort),
                                ascending: PaginationUtil.parseAscending(sort),
                                search: null
                            };
                        }]
                    }
                }).result.then(function() {
                    $state.go('activity', null, { reload: 'activity' });
                }, function() {

                });
            }
        }


        function onAgentSelect (item) {

            // check contact type to init contact types list display in UI
            vm.searchObj.agentIds.push(item.id);
        }

        function onRemoveAgentSelect (item) {
            vm.searchObj.agentIds = [];
            angular.forEach(vm.searchObj.agentObjectList, function(value, key) {
                vm.searchObj.agentIds.push(value.id);
            });
        }

        function createNewActivity () {
            $uibModal.open({
                templateUrl: 'app/entities/activity/activity-dialog.html',
                controller: 'ActivityDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    development: ['DevelopmentService', function (DevelopmentService) {
                        if (isCreateFromDevelopment()) {
                            return DevelopmentService.get({id : vm.development.id}).$promise;
                        }
                        return null;
                    }],
                    login: function () {
                        return login;
                    },
                    isFollowUpActivity: function () {
                        return false;
                    },
                    property:['PropertyService', function (PropertyService) {
                        if(isCreateFromProperty()) {
                            return PropertyService.get({id : vm.property.id}).$promise;
                        }
                        return null;
                    }],
                    contact:['Contact', function (Contact) {
                        if(isCreateFromContact()) {
                            return Contact.get({id : vm.contact.id}).$promise;
                        }
                        return null;
                    }],
                    entity: function () {
                        return createEntityActivity ();
                    },
                    propertyTemplate: ['PropertyService', function (PropertyService) {
                        if(isCreateFromDevelopment()) {
                            return PropertyService.get({id:vm.development.propertyTemplate.id}).$promise;
                        }
                        return null;
                    }],
                    activityTypeResource: function () {
                        return activityTypeResource;
                    },
                    marketingCampaignResource: ['MarketingCampaign', function(MarketingCampaign) {
                        return MarketingCampaign.query().$promise;
                    }]
                }
            }).result.then(function() {
                $state.go('activity', null, { reload: 'activity' });
            }, function() {

            });
        }

        function createEntityActivity () {

            var activityEntity = {
                id: null,
                activityType: null,
                title: null,
                status: ActivityConstant.status.OPEN,
                notes: null,
                activated: true,
                createdDate: null,
                modifiedDate: null,
                dueDate: new Date(),
                opportunity: null,
                contactActivities: [],
                propertyActivities: []

            };

            if (isCreateFromDevelopment()) {
                activityEntity.from = 1;
            }

            if (isCreateFromContact()) {
                activityEntity.from = 2;
            }

            if (isCreateFromProperty()) {
                activityEntity.from = 3;
            }


            return activityEntity;


        }

        function exportCSV () {
            var apiParam = angular.copy(vm.searchObj);
            apiParam.page = 0;
            apiParam.size = Number.MAX_SAFE_INTEGER;
            apiParam.sort = sort();
            Activity.exportCsvActivity(apiParam, function (result){
                FileSaver.saveAs(result.response, 'ActivityPages.csv');
            });
        }

        function openFromCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function openToCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function validateDatepicker(){
            vm.invalidDatepicker = false;
            if(vm.fromDate != null && vm.toDate != null){
                if(vm.fromDate > vm.toDate){
                    vm.invalidDatepicker = true;
                }
            }
        }

        function isCreateFromDevelopment () {
            if ($state.current.name === 'development-detail.activities-tab.section') {
                return true;
            }
            return false;
        }

        function isCreateFromProperty () {
            if ($state.current.name === 'property-detail.activities-tab.section') {
               return true;
            }
            return false;
        }

        function isCreateFromContact () {
            if ($state.current.name === 'contact-detail.activities-tab.section') {
                return true;
            }
            return false;
        }

        function isHavePermissionToDelete () {
            return login.authorities[0] === AuthorityConstant.ROLE_SUPER_USER;
        }

        function deleteActivity (activity) {
            $uibModal.open({
                templateUrl: 'app/components/confirmation-dialog/delete/confirm-dialog.html',
                controller: 'DeleteConfirmDialogController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass : 'absolute-center',
                resolve: {
                    entity: function(){
                        return {
                            title: activity.title
                        };
                    },
                    translateKeys: function(){
                        return {
                            keys: 'indochinaApp.activity.deleteConfirm'
                        };
                    }
                }
            }).result.then(function() {
                Activity.delete({id: activity.id}, onSaveSuccess, onSaveError);
            }, function() {
            });


        }

        function onSaveSuccess(result) {
            $rootScope.$broadcast('indochinaApp:activityUpdate', result);
        }

        function onSaveError() {
            // do something
        }
    }
})();
