(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('DashboardSectionActivityController', Controller);

    Controller.$inject = ['$rootScope', '$scope', 'Principal', '$state', 'Activity', 'ParseLinks', 'AlertService', 'activityTypeResource', '$uibModal', 'login'];

    function Controller ($rootScope, $scope, Principal, $state, Activity, ParseLinks, AlertService, activityTypeResource, $uibModal, login) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.createNewActivity = createNewActivity;

        vm.predicate = 'dueDate';
        vm.reverse = 0;
        vm.itemsPerPage = 10;
        vm.page = 1;
        vm.activities  = [];

        $rootScope.$on('indochinaApp:activityUpdate', function(event, result) {
        	vm.page = 1;
        	vm.activities = [];
        	loadAll();
        });

        // action
        //vm.edit = edit;
        vm.loadMore = loadMore;
        vm.checkDueDate = checkDueDate;
        vm.click = click;
        loadAll();

        function loadAll () {
            Activity.getActivityByUser({
            	page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                result.push('id' + ',' + (vm.reverse ? 'asc' : 'desc'));
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');

                vm.queryCount = vm.totalItems;
                //vm.activities = data;
                vm.activities.push.apply(vm.activities, data)
//                vm.page = vm.page + 1;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
        	loadAll();
        }



        function openFromContact (activity) {
        	$uibModal.open({
                templateUrl: 'app/entities/activity/activity-dialog.html',
                controller: 'ActivityDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    development: function () {
                        return null;
                    },
                	login: function () {
                        return login;
                    },
                	isFollowUpActivity: function () {
                		return false;
                	},
                	contact: function () {
                		return null;
                	},
                    property: function () {
                        return null;
                    },
                    entity: function () {
                        return Activity.get({id : activity.id}).$promise;
                    },
                    propertyTemplate: function () {
                        return null;
                    },
                    activityTypeResource: function () {
                        return activityTypeResource;
                    },
                    marketingCampaignResource: ['MarketingCampaign', function(MarketingCampaign) {
                        return MarketingCampaign.query().$promise;
                    }]
                }
            }).result.then(function() {
               // $state.go('activity', null, { reload: 'activity' });
            }, function() {

            });
        }

        function view (id) {
        	$uibModal.open({
                templateUrl: 'app/entities/activity/activity-dialog-view.html',
                controller: 'ActivityDialogViewController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return Activity.get({id : id}).$promise;
                    }
                }
            }).result.then(function() {
                $state.go('activity', null, { reload: 'activity' });
            }, function() {

            });
        }
        function loadMore() {
        	vm.page = vm.page + 1;
        	loadAll();
        }
        function checkDueDate (activity) {
        	var now = new Date();
        	now.setMinutes(0);
        	now.setHours(0);
        	now.setSeconds(0);
        	now.setMilliseconds(0);
        	var later = new Date(activity.dueDate);
        	var count = Math.round((now - later) / (1000 * 60 * 60 * 24));
        	if(count <= 0) return "";
        	if(count > 7) return "past-more"
        	return "past"
        }

        function click(activity){
        	if(activity.status === 'OPEN') {
                openFromContact(activity);
        		/*if(activity.isContactCreate) {
        			openFromContact(activity);
        		} else {
        			openFromProperty(activity);
        		}*/
        	} else {
        		view(activity.id);
        	}
        }

        function createNewActivity () {
            $uibModal.open({
                templateUrl: 'app/entities/activity/activity-dialog.html',
                controller: 'ActivityDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    development: function () {
                        return null;
                    },
                    login: function () {
                        return login;
                    },
                    isFollowUpActivity: function () {
                        return false;
                    },
                    property: function () {
                        return null;
                    },
                    contact: function () {
                        return null;
                    },
                    entity: function () {
                        return {
                            title: null,
                            type: null,
                            status: 'OPEN',
                            notes: null,
                            activated: true,
                            createdDate: null,
                            modifiedDate: null,
                            dueDate: new Date(),
                            id: null,
                            opportunity: null,
                            from: 0,
                            contactActivities: [],
                            propertyActivities: []
                        };
                    },
                    propertyTemplate: function () {
                        return null;
                    },
                    activityTypeResource: function () {
                        return activityTypeResource;
                    },
                    marketingCampaignResource: ['MarketingCampaign', function(MarketingCampaign) {
                        return MarketingCampaign.query().$promise;
                    }]
                }
            }).result.then(function() {
                $state.go('activity', null, { reload: 'activity' });
            }, function() {

            });
        }


    }
})();
