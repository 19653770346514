(function() {
    'use strict';
    angular
        .module('indochinaApp')
        .factory('CoAgentCompanyMasterData', CoAgentCompanyMasterData);

    CoAgentCompanyMasterData.$inject = ['$resource'];

    function CoAgentCompanyMasterData ($resource) {
        var resourceUrl =  'api/co-agent-company-master-data/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            // parameter: name
            'searchByName' : {
 				method : 'GET',
 				url : 'api/co-agent-company-master-data/search-by-name',
 				isArray : true
 			},
        });
    }
})();
