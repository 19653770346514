(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('PersonalDetailsTabController', PersonalDetailsTabController);

    PersonalDetailsTabController.$inject = ['$scope', '$state', '$parse', '$rootScope', '$stateParams', 'previousState', 'entity', 'Contact', 'PersonalDetails', 'DateUtils', '$uibModal'];

    function PersonalDetailsTabController($scope, $state, $parse, $rootScope, $stateParams, previousState, entity, Contact, PersonalDetails, DateUtils, $uibModal) {

        var vm = this;
        vm.contact = entity;
        if($state.isEdit) {
        	vm.isEdit = $state.isEdit;
        }

        $rootScope.scrollTop();
        $rootScope.$broadcast('contact:activeTab', 'PERSONAL');
        $scope.$on('$stateChangeStart', onStateChangeStart);

        vm.save = save;
        vm.cancel = cancel;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.openChildCalendar = openChildCalendar;
        vm.addChildSection = addChildSection;
        vm.removeChildSection = removeChildSection;
        vm.isOneRow = isOneRow;

        $rootScope.$on('contact:changeMode', function(event, result) {
        	vm.isEdit = result;
        });

        $rootScope.$on('contact:activated', function(event, result) {
        	vm.contact.activated = result;
        });

        vm.datePickerOption = {
        		showWeeks: false,
                startingDay: 1,
                readonlyInput: true,
                dateformat: 'MM/dd/yyyy'
        }

        init();

        function init () {

    		if(vm.contact.person.personalDetails === null) {
            	vm.personalDetails = {
            		childs: [],
        			birthday: null,
                    partnerName: null,
                    partnerBirthday: null,
                    notes: null,
                    id: null
            	}
            } else {
            	vm.personalDetails = vm.contact.person.personalDetails;
            }
    		if(vm.personalDetails.childs !== null && vm.personalDetails.childs.length === 0) {
        		var child = addChildInfo();
        		vm.personalDetails.childs.push(child);
        	}

    		vm.personalDetails.childs.forEach(myFunction);
    		function myFunction (item, $index) {
    			vm.datePickerOpenStatus[$index] = false;
    		}
        }

        function save () {
        	$scope.editForm.$dirty = false;
        	// convert date
        	/*var personBirthday = vm.personalDetails.birthday;
        	vm.personalDetails.birthday = DateUtils.convertLocalDateToServer(personBirthday);
        	var partnerBirthday = vm.personalDetails.partnerBirthday;
        	vm.personalDetails.partnerBirthday = DateUtils.convertLocalDateToServer(partnerBirthday);
        	angular.forEach(vm.personalDetails.childs, function(value, key) {
        		var birthday = value.birthday;
        		value.birthday = DateUtils.convertLocalDateToServer(birthday);
    		})*/
            vm.isSaving = true;
            if (vm.personalDetails.id !== null) {
                PersonalDetails.update(vm.personalDetails, onSaveSuccess, onSaveError);
            } else {
            	vm.contact.person.personalDetails = vm.personalDetails;
            	Contact.update(vm.contact, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            /*vm.isSaving = false;
            $state.go('contact-detail.personal-detail-tab', {id: $stateParams.id}, { reload: true });*/
        	cancel();
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function cancel () {

            if($scope.editForm.$dirty) {

                // if have any change we must reload page to reset entity
                $state.reload($state.current.name);

                return;
            }

            // change mode edit in state
            $state.isEdit = false;

            // change mode on current state
            vm.isEdit = false;

            // broadcast event to appear button edit
            $rootScope.$broadcast('contact:changeMode', false);

            $scope.editForm.$setPristine();

            /*$state.isEdit = false;
            $state.reload();*/
        	/*$state.isEdit = false;
    		$rootScope.$broadcast('contact:changeMode', false);
    		//$state.reload($state.current.name);*/

            $state.reload($state.current.name);
        }

        vm.datePickerOpenStatus.birthday = false;
        vm.datePickerOpenStatus.partnerBirthday = false;


        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function openChildCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function addChildInfo() {
        	return {
        		name: null,
                birthday: null,
                id: null
        	};
        }

        function isOneRow(){
        	if(vm.personalDetails.childs.length === 1) {
        		return true;
        	}
        	return false;
        }

        function addChildSection() {
        	var child = addChildInfo();
        	vm.personalDetails.childs.push(child);
        }

        function removeChildSection(index) {
        	confirmDeleteChild(index);
        	//vm.personalDetails.childs.splice(index, 1);
        }

        function confirmDeleteChild(index){
	        if(vm.personalDetails.childs[index].id && (vm.personalDetails.childs[index].name || vm.personalDetails.childs[index].birthday)){        	
	        	$uibModal.open({
	        		templateUrl: 'app/components/confirmation-dialog/delete/confirm-dialog.html',
	        		controller: 'DeleteConfirmDialogController',
	        		controllerAs: 'vm',
	        		size: 'sm',
	        		windowClass : 'absolute-center',
	        		resolve: {
	        			entity: function(){
	        				return null;
	        			},
	        			translateKeys: function(){
	        				return {
	        					keys: 'indochinaApp.property.confirmDeleteSales'
	        				};
	        			}
	        		}
	        	}).result.then(function() {
	        		vm.personalDetails.childs.splice(index, 1);
	        		$scope.editForm.$dirty = true;
	        	}, function() {
	        	});
	        }
	        else{
        		vm.personalDetails.childs.splice(index, 1);
        		$scope.editForm.$dirty = true;
	        }
        }


        /*$rootScope.$on('app:sesstionIsTimeOut', function(event, result) {
        	$scope.editForm.$dirty = false;
        });*/

        function onStateChangeStart(event, toState, toParams, fromState, fromParams) {
            if($scope.editForm.$dirty && !$rootScope.isSessionTimeOut){
            	event.preventDefault();

                $uibModal.open({
                    templateUrl: 'app/components/confirmation-dialog/leave/confirm-dialog.html',
                    controller: 'LeaveConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                        entity: function(){
                        	return null;
                        },
                        translateKeys: function(){
                        	return {
                        		keys: 'indochinaApp.property.confirmLeavePage'
                        	};
                        }
                    }
                }).result.then(function() {

                    // change mode edit in state
                    $state.isEdit = false;

                    // avoid loop onStateChange function
                    if($scope.editForm) {
                        $scope.editForm.$dirty = false;
                    }

                    if(toState.name == 'contact-detail.personal-detail-tab'){

                        // reload parent to reset entity
                        $state.reload('contact-detail');

                        // broadcast event to appear button edit
                        $rootScope.$broadcast('contact:changeMode', false);
                    }
                    else{
                        $state.go(toState, toParams, { reload: true });
                    }

                }, function() {
//                    $state.go('^');
                });
            }
        }
    }
})();
