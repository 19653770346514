(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'AuthorityConstant'];

    function stateConfig($stateProvider, AuthorityConstant) {
        $stateProvider
        .state('property-detail.legal-tab', {
        	parent: 'property-detail',
        	url: '/legal-tab',
        	data: {
            	authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_ADMIN, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT]
            },
        	templateUrl: 'app/entities/property/property-detail/legal-tab/legal-tab.html',
            controller: 'PropertyLegalTabController',
            controllerAs: 'vm',
            resolve: {
                entity: ['$stateParams', 'PropertyService', function($stateParams, PropertyService) {
                    return PropertyService.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        ;
    }

})();
