(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('ContactPreferLocationDetailController', ContactPreferLocationDetailController);

    ContactPreferLocationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ContactPreferLocation'];

    function ContactPreferLocationDetailController($scope, $rootScope, $stateParams, previousState, entity, ContactPreferLocation) {
        var vm = this;

        vm.contactPreferLocation = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('indochinaApp:contactPreferLocationUpdate', function(event, result) {
            vm.contactPreferLocation = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
