(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('DevelopmentDetailController', DevelopmentDetailController);

    DevelopmentDetailController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'previousState', 'entity' , 'User', '$uibModal',
                                        'Principal', 'PaginationUtil', 'pagingParamsForActivity',
                                        'pagingParamsForOpportunity', 'login', 'listSearchAgent', 'DevelopmentService'];

    function DevelopmentDetailController($scope, $state, $rootScope, $stateParams, previousState, entity, User, $uibModal,
    									Principal, PaginationUtil, pagingParamsForActivity,
    									pagingParamsForOpportunity, login, listSearchAgent, DevelopmentService) {

        var vm = this;

        // get development entity
        vm.development = entity;

        // function define
        vm.isCheckPermission = isCheckPermission;

        if($state.isEdit === true) {
        	$state.isEdit = false;
        }

        // update entity when update in child state
        var unsubscribe = $rootScope.$on('indochinaApp:developmentUpdate', function(event, result) {
            vm.development = result;
        });
        $scope.$on('$destroy', unsubscribe);

        $state.pagingParams = {
        		page: PaginationUtil.parsePage(pagingParamsForActivity.page),
                sort: pagingParamsForActivity.sort,
                predicate: PaginationUtil.parsePredicate(pagingParamsForActivity.sort),
                ascending: PaginationUtil.parseAscending(pagingParamsForActivity.sort)
        }
        /*$state.pagingParamsHistorical = {
        		page: PaginationUtil.parsePage(pagingParamsForActivityHistorical.page),
                sort: pagingParamsForActivityHistorical.sort,
                predicate: PaginationUtil.parsePredicate(pagingParamsForActivityHistorical.sort),
                ascending: PaginationUtil.parseAscending(pagingParamsForActivityHistorical.sort)
        }*/

        $state.pagingParamsForOpportunity = angular.copy(pagingParamsForOpportunity);

        vm.edit = edit;
        vm.back = back;
        vm.duplicate = duplicate;

        function edit (){
        	vm.isEdit = true;
        	$state.isEdit = true;
        	$rootScope.$broadcast('development:changeMode', true);
        }

        function back () {
        	$state.go('development');
        }


        function isCheckPermission() {

        	if(login.authorities[0] === 'ROLE_ADMIN' || login.authorities[0] === 'ROLE_SUPER_USER') {
        		return false;
        	}

            if(login.authorities[0] === 'ROLE_MANAGER') {
                vm.allAgentOfManager = listSearchAgent;
                if(vm.allAgentOfManager) {
                    for(var i = 0; i < vm.allAgentOfManager.length; i++) {
                        if(vm.allAgentOfManager[i].id === vm.development.agentId) {
                            return false;
                        }
                    }
                }
            }

            if(login.id === vm.development.agentId) {
                return false;
            }

        	return true;
        }

        $rootScope.$on('development:changeMode', function(event, result) {
            vm.isEdit = result;
        });

        $rootScope.$on('development:activeTab', function(event, result) {
            switch(result){
                case 'DETAIL':
                    vm.isShowExposeButton = true;
                    vm.isShowDuplicateButton = true;
                    vm.isActOrOpp = false;
                    break;
                case 'PHOTO':
                    vm.isShowExposeButton = true;
                    vm.isShowDuplicateButton = false;
                    vm.isActOrOpp = false;
                    break;
                case 'PUBLISH':
                case 'LEGAL':
                case 'SALES':
                    vm.isShowExposeButton = false;
                    vm.isShowDuplicateButton = false;
                    vm.isActOrOpp = false;
                    break;
                case 'ACTIVITY':
                case 'OPPORTUNITY':
                    vm.isShowExposeButton = false;
                    vm.isShowDuplicateButton = false;
                    vm.isActOrOpp = true;
                    break;
            }
        });

        function duplicate () {
            $uibModal.open({
                templateUrl: 'app/components/confirmation-dialog/duplicate/duplicate-dialog.html',
                controller: 'DuplicateDialogControllerRe',
                controllerAs: 'vm',
                size: 'sm',
                windowClass : 'absolute-center',
                resolve: {
                    translateKeys: function(){
                        return {
                            keys: 'indochinaApp.development'
                        };
                    }
                }
            }).result.then(function() {
                $state.go('development.duplicate.detail-tab', {id: $stateParams.id});
            }, function() {
                //$state.go('^');
            });
        }

        /*$scope.$on("curreny:change", function(event, item){
        	var price = null;
        	if(propertySearch.transactionType === PropertyConstant.transactionType.RENT) {
        		price = Math.ceil(item.valueOfUsdExchangeRate * PropertyConstant.price.MAX_SEARCH_LEASE_PRICE);
        	} else {
        		price = Math.ceil(item.valueOfUsdExchangeRate * PropertyConstant.price.MAX_SEARCH_PRICE);
        	}

        	propertySearch.valueOfUsdExchangeRate = item.valueOfUsdExchangeRate;

        	propertySearch.maxPrice = price;

        	propertySearch.stepRange = item.valueOfUsdExchangeRate * 100;

        	propertySearch.destinationCode = item.destinationCode;
        });*/

        /*function goExpose () {
            $state.go("property-detail.expose");
            vm.isExposeState =  true;
        }*/
    }
})();
