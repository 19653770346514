(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('UserManagementDetailController', UserManagementDetailController);

    UserManagementDetailController.$inject = ['$stateParams', '$state', '$uibModal', 'User', 'AuthorityConstant', 'ManagerService'];

    function UserManagementDetailController ($stateParams, $state, $uibModal, User, AuthorityConstant, ManagerService) {
        var vm = this;

        vm.load = load;
        vm.reactivate = reactivate;
        
        vm.user = {};
        vm.managers = null;

        vm.load($stateParams.login);

        function load (login) {
            User.get({login: login}, function(result) {
            	if(result == null || result.id == null || result.activated == false){
        	  		$state.go('accessdenied');
        	  	}
            	
                vm.user = result;
                
                if(vm.user != null){
                	if(vm.user.authorities[0] == AuthorityConstant.ROLE_AGENT || vm.user.authorities[0] == AuthorityConstant.ROLE_MANAGER){
                		ManagerService.getManagersById({id: vm.user.id}, function(result) {
                			vm.managers = result;
                        });                		
                	}
                	
                	vm.isCoAgentRole = (vm.user.authorities[0] == AuthorityConstant.ROLE_CO_AGENT);
                }
            });
            
        }
        
        function reactivate(){
        	User.reactivate({id: vm.user.id}, function(result){
        		vm.user.isExpiredResetKey = result.isExpiredResetKey;
        		
        		$uibModal.open({
                    templateUrl: 'app/components/confirmation-dialog/inform/confirm-dialog.html',
                    controller: 'InformConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                        entity: null,
                        translateKeys: function(){
                        	return {
                        		keys: 'userManagement.informAfterReactivateSuccessfully'
                        	};
                        }
                    }
                }).result.then(function() {
//                	$uibModalInstance.dismiss('stayHere');
//                	$window.open($state.href('logout'),'_self');
                }, function() {
//                	$uibModalInstance.dismiss('stayHere');
//                	$window.open($state.href('logout'),'_self');
                });
        		
        	});
        }
    }
})();
