(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('OpportunityDeleteController',OpportunityDeleteController);

    OpportunityDeleteController.$inject = ['$rootScope', '$state', '$uibModalInstance', 'entity', 'Opportunity', 'OpportunityConstant', 'AuthorityConstant', 'Principal'];

    function OpportunityDeleteController($rootScope, $state, $uibModalInstance, entity, Opportunity, OpportunityConstant, AuthorityConstant, Principal) {
        var vm = this;

        vm.opportunity = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        // session is time out so we must close all modals
        $rootScope.$on('modal:close', function(event, result) {
            clear();
        });

        // SUPER_USER/MANAGER can edit
        // ADMIN can not edit
        // AGENT can edit open stage
        vm.isCanEdit = Principal.hasAnyAuthority([AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_MANAGER]);
        if(!vm.isCanEdit){
        	Principal.hasAuthority(AuthorityConstant.ROLE_AGENT).then(function (result) {
        		if (result) {
        			if(vm.opportunity.stage == OpportunityConstant.STAGE.DEPOSIT || vm.opportunity.stage == OpportunityConstant.STAGE.NEGOTIATION){
        				vm.isCanEdit = true;
        			}
        		}
        		if(!vm.isCanEdit){
        			clear ();
        			$state.go('accessdenied');
                }
        	});
        }

        function clear () {
            $rootScope.$broadcast('app:resetTimeSession', true);
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Opportunity.delete({id: id},
                function () {
                    $rootScope.$broadcast('app:resetTimeSession', true);
                    $uibModalInstance.close(true);
                });
        }
    }
})();
