(function() {
    'use strict';
    angular
        .module('indochinaApp')
        .factory('Activity', Activity);

    Activity.$inject = ['$resource', 'DateUtils'];

    function Activity ($resource, DateUtils) {
        var resourceUrl =  'api/activities/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                        data.dueDate = DateUtils.convertLocalDateFromServer(data.dueDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dueDate = DateUtils.convertLocalDateToServer(copy.dueDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dueDate = DateUtils.convertLocalDateToServer(copy.dueDate);
                    return angular.toJson(copy);
                }
            },
            'getAllActivityTypes' : {
				method : 'GET',
				isArray: true,
				url : 'api/activities/types'
			},
			'exportCsvActivity' : {
                method : 'GET',
                url : 'api/activities/export-csv',
                headers : {accept : 'text/csv'},
                cache : false,
                transformResponse : function(data) {
                    return {
                        response : new Blob([data], {type : 'text/csv;charset=utf-8;'})
                    };
                }
            },
			'getActivityCompleteStatus': {
				method: 'GET',
				isArray: true,
				url : 'api/activities/complete-status'
			},
			'getActivityByProperty': {
				method: 'GET',
				isArray: true,
				url : 'api/activities/get-by-property'
			},
//            'getActivityByDevelopment': {
//                method: 'GET',
//                isArray: true,
//                url : 'api/activities/get-by-development'
//            },
//            'getActivityByContact': {
//                method: 'GET',
//                isArray: true,
//                url : 'api/activities/get-by-contact'
//            },
			'getActivityByUser': {
				method: 'GET',
				isArray: true,
				url : 'api/activities/by-user'
			},
			'searchActivity': {
				method: 'GET',
				isArray: true,
				url : 'api/activities/search-activity'
			},
			'getForDashboardActivitySummary': {
				method: 'GET',
				isArray: true,
				url : 'api/activities/dashboard-activity-summary'
			},
			'getForDashboardTeamActivitySummary': {
				method: 'GET',
				url : 'api/activities/dashboard-team-activity-summary'
			}
        });
    }
})();
