(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'AuthorityConstant'];

    function stateConfig($stateProvider, AuthorityConstant) {
        $stateProvider
        .state('user-management', {
            parent: 'admin',
            url: '/user-management?page&sort&search',
            data: {
                authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_ADMIN, AuthorityConstant.ROLE_MANAGER],
                pageTitle: 'userManagement.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/user-management/user-management.html',
                    controller: 'UserManagementController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: {
                	value: '',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('user-management');
                    return $translate.refresh();
                }]

            }        })
        .state('user-management.new', {
            url: '/new',
            data: {
            	authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_ADMIN, AuthorityConstant.ROLE_MANAGER]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/user-management/user-management-dialog.html',
                    controller: 'UserManagementDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                id: null, login: null, firstName: null, lastName: null, email: null,
                                activated: true, langKey: null, createdBy: null, createdDate: null,
                                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                                resetKey: null, authorities: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('user-management', null, { reload: true });
                }, function() {
                    $state.go('user-management');
                });
            }]
        })
        .state('user-management.edit', {
            url: '/{login}/edit',
            data: {
            	authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_ADMIN, AuthorityConstant.ROLE_MANAGER]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', '$rootScope', 'User', function($stateParams, $state, $uibModal, $rootScope, User) {
            	User.get({login : $stateParams.login}, function(result){
                	if(result == null || result.id == null || result.activated == false){
            	  		$state.go('accessdenied');
            	  	}
                	else{
                		$uibModal.open({
                            templateUrl: 'app/admin/user-management/user-management-dialog.html',
                            controller: 'UserManagementDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'md',
                            resolve: {
                                entity: result
                            }
                        }).result.then(function() {
                            $state.go('user-management', null, { reload: true });
                        }, function(parameter) {
                            // Chau Cao coding
                        	if('stayHere' == parameter){
                        		return;
                        	}

                            // if session is time out, go to logout
                            if(!$rootScope.isSessionTimeOut) {
                                $state.go('^');
                            }
                        });
                	}
                });
            }]
        })
        .state('user-management-detail', {
            parent: 'user-management',
            url: '/user/{login}',
            data: {
            	authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_ADMIN, AuthorityConstant.ROLE_MANAGER],
                pageTitle: 'user-management.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/user-management/user-management-detail.html',
                    controller: 'UserManagementDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('user-management');
                    return $translate.refresh();
                }]
            }
        })
        .state('user-management.delete', {
            url: '/{login}/delete',
            data: {
            	authorities: [AuthorityConstant.ROLE_SUPER_USER]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', '$rootScope', 'User', function($stateParams, $state, $uibModal, $rootScope, User) {
            	return User.get({login : $stateParams.login}, function(result){
                	if(result == null || result.id == null || result.activated == false){console.log("dasfdsf");
            	  		$state.go('accessdenied');
            	  	}
                	else{
                		$uibModal.open({
                            templateUrl: 'app/admin/user-management/user-management-delete-dialog.html',
                            controller: 'UserManagementDeleteController',
                            controllerAs: 'vm',
                            size: 'sm',
                            windowClass : 'absolute-center',
                            resolve: {
                                entity: result
                            }
                        }).result.then(function() {
                            $state.go('user-management', null, { reload: true });
                        }, function() {
                            // if session is time out, go to logout
                            if(!$rootScope.isSessionTimeOut) {
                                $state.go('^');
                            }
                        });
                	}
                });
            }]
        });
    }
})();
