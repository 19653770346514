(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('SessionTimeoutInformDialogController',SessionTimeoutInformDialogController);

    SessionTimeoutInformDialogController.$inject = ['$uibModalInstance', 'entity', 'translateKeys', '$timeout'];

    function SessionTimeoutInformDialogController($uibModalInstance, entity, translateKeys, $timeout) {
        var vm = this;

        vm.enity = entity;
        vm.translateKeys = translateKeys.keys;
        
        vm.clear = clear;
        vm.confirm = confirm;

        $(window).on("mouseover focus", function(e) {
        	callAtTimeout();
        })
        
        function callAtTimeout() {
        	$timeout(function(){
        		$uibModalInstance.close(true);        		
        	}, 2000);
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        
        function confirm () {
        	$uibModalInstance.close(true);
        }
    }
})();
