(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('ContactDeleteController',ContactDeleteController);

    ContactDeleteController.$inject = ['$uibModalInstance', 'entity', 'Contact', '$rootScope'];

    function ContactDeleteController($uibModalInstance, entity, Contact, $rootScope) {
        var vm = this;

        vm.contact = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        // session is time out so we must close all modals
        $rootScope.$on('modal:close', function(event, result) {
            clear();
        });

        function clear () {
            $rootScope.$broadcast('app:resetTimeSession', true);
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Contact.delete({id: id},
                function () {
                    $rootScope.$broadcast('app:resetTimeSession', true);
                    $uibModalInstance.close(true);
                });
        }
    }
})();
