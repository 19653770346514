(function() {
    'use strict';
    angular
        .module('indochinaApp')
        .factory('Company', Company);

    Company.$inject = ['$resource', 'DateUtils'];

    function Company ($resource, DateUtils) {
        var resourceUrl =  'api/companies/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.modifiedDate = DateUtils.convertDateTimeFromServer(data.modifiedDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getByName': {
            	url: 'api/companies/companies-by-name',
            	method: 'GET', 
            	isArray: true
            }
        });
    }
})();
