(function() {
    'use strict';
    angular
        .module('indochinaApp')
        .factory('DevelopmentService', DevelopmentService);

    DevelopmentService.$inject = ['$resource', 'DateUtils', '$http', '$q'];

    function DevelopmentService ($resource, DateUtils, $http, $q) {
    	var resourceUrl =  'api/developments/:id';

        var service = $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        /*data.created = DateUtils.convertDateTimeFromServer(data.created);
                        data.modified = DateUtils.convertDateTimeFromServer(data.modified);
                        data.availabilityPeriod = DateUtils.convertDateTimeFromServer(data.availabilityPeriod);*/
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'delete': { method:'DELETE' },
            'getAllByDevelopmentNameForAutoComplete' : {
 				method : 'GET',
 				url : 'api/developments/get-all-by-development-name-for-auto-complete',
 				isArray : true
 			 }
        });

        return service;
    }
})();
