(function() {
	'use strict';

	angular
		.module('indochinaApp')
		.constant('AuthorityConstant', (function() {
			return {
				// we should to remove a 'ROLE_USER' here
				ALL: ['ROLE_SUPER_USER', 'ROLE_MANAGER', 'ROLE_AGENT', 'ROLE_CO_AGENT', 'ROLE_ADMIN', 'ROLE_USER'],
				ROLE_LIST: ['ROLE_SUPER_USER', 'ROLE_MANAGER', 'ROLE_AGENT', 'ROLE_CO_AGENT', 'ROLE_ADMIN'],
				ROLE_SUPER_USER : 'ROLE_SUPER_USER',
				ROLE_MANAGER : 'ROLE_MANAGER',
				ROLE_AGENT : 'ROLE_AGENT',
				ROLE_CO_AGENT : 'ROLE_CO_AGENT',
				ROLE_ADMIN : 'ROLE_ADMIN'
			}
		})());
})();
