(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('LinkAnotherProperty',LinkAnotherProperty);

    LinkAnotherProperty.$inject = ['$uibModalInstance', 'entity', 'translateKeys'];

    function LinkAnotherProperty($uibModalInstance, entity, translateKeys) {
        var vm = this;
        
        vm.opportunity = entity;
        vm.translateKeys = translateKeys.keys;
        
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
        	$uibModalInstance.close(true);
        }
    }
})();
