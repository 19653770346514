(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .constant('LeadSourceConstant', (function() {
            return {
                types: {
                    INTRODUCTION: 'Introduction',
                    PORTAL: 'Portals',
                    PORTAL_DOT_PROPERTY: 'DotProperty',
                    PORTAL_JUWAI: 'Juwai',
                    PORTAL_SQUARE_FOOT: 'Squarefoot',
                    PORTAL_TRANIO: 'Tranio',
                    INDOCHINA_WEBSITE: 'Indochina Website',
                    CoBROKER: 'Co-Broker',
                    ICC_STAFF:'ICC Staff',
                    ICC_DIVISION:'ICC Division',
                    EXISTING_CONTACT:'Existing Contact',
                    PERSONAL_NETWORK:'Personal Network',
                    DATABASE:'Database'
                }
            }
        })());
})();
