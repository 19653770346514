(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('development-detail.detail-tab', {
        	parent: 'development-detail',
        	url: '/detail-tab',
        	templateUrl: 'app/entities/development/development-detail/detail-tab/detail-tab.html',
            controller: 'DevelopmentDetailTabController',
            controllerAs: 'vm'
        })
        .state('development.new.detail-tab', {
        	parent: 'development.new',
        	url: '/new-detail-tab',
            templateUrl: 'app/entities/development/development-detail/detail-tab/detail-tab.html',
            controller: 'DevelopmentDetailTabController',
            controllerAs: 'vm'
        })
        .state('development.duplicate.detail-tab', {
            parent: 'development.duplicate',
            url: '/duplicate-detail-tab',
            templateUrl: 'app/entities/development/development-detail/detail-tab/detail-tab.html',
            controller: 'DevelopmentDetailTabController',
            controllerAs: 'vm'
        });
    }

})();
