(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('SuperUserDashboardController', SuperUserDashboardController);

    SuperUserDashboardController.$inject = ['$scope', 'Principal', '$state'];

    function SuperUserDashboardController ($scope, Principal, $state) {
        var vm = this;

    }
})();
