(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'AuthorityConstant'];

    function stateConfig($stateProvider, AuthorityConstant) {
        $stateProvider
        .state('development-detail.photo-video-tab', {
        	parent: 'development-detail',
        	url: '/photo-video-tab',
        	templateUrl: 'app/entities/development/development-detail/photo-video-tab/photo-video-tab.html',
            controller: 'DevelopmentPhotoVideoTabController',
            controllerAs: 'vm'
        });
    }

})();
