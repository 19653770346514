(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('BulkUploadDetailController', BulkUploadDetailController);

    BulkUploadDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'BulkUpload', 'User'];

    function BulkUploadDetailController($scope, $rootScope, $stateParams, previousState, entity, BulkUpload, User) {
        var vm = this;

        vm.bulkUpload = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('indochinaApp:bulkUploadUpdate', function(event, result) {
            vm.bulkUpload = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
