(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('PropertyController', PropertyController);

    PropertyController.$inject = [ '$rootScope', '$state', 'PropertyService', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'PropertyConstant',
                                  'Country', 'MeasurementConversionService', 'JhiCurrencyService', 'previousState', '$scope', 'propertySearch', 'CurrencyConstant',
                                  'District','FileSaver', 'provinceResource', 'cityResource', 'Principal', 'AuthorityConstant'];

    function PropertyController($rootScope, $state, PropertyService, ParseLinks, AlertService, paginationConstants, pagingParams, PropertyConstant,
    								Country, MCService, JhiCurrencyService, previousState, $scope, propertySearch, CurrencyConstant,
    								District, FileSaver, provinceResource, cityResource, Principal, AuthorityConstant) {

        var vm = this;
        var destinationCode = null;
        var valueOfUsdExchangeRate = propertySearch.valueOfUsdExchangeRate;
        vm.isSearched = false;
        vm.isCoAgentRole = Principal.hasAnyAuthority([AuthorityConstant.ROLE_CO_AGENT]);

        // function on grid
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 10;
        vm.previousState = previousState;
        vm.previousStateParams = previousState.params;
        
        // search functions
        vm.search = search;
        // export csv
        vm.exportCSV = exportCSV;
        //vm.getMaxRoom = getMaxRoom; 
        vm.clearSearchingValue = clearSearchingValue;
        vm.changeTransactionType = changeTransactionType;
        vm.changeAvailability = changeAvailability;
        vm.changeBathRoom = changeBathRoom;
        vm.onSelectCountry = onSelectCountry;
        vm.onSelectCity = onSelectCity;
        vm.onKeyUpCountry = onKeyUpCountry;
        vm.onKeyUpCity = onKeyUpCity;
        
        vm.currencyCode = JhiCurrencyService.currencyCode;
        vm.currencyRate = JhiCurrencyService.rate;

        vm.cityList = [];
        vm.cityMasterData = cityResource;
        vm.provinceList = [];
        vm.provinceMasterData = provinceResource;
        vm.countryList = [];
        vm.transactionTypeList = PropertyConstant.transactionType.LIST;
        vm.availabilityList = PropertyConstant.availability.LIST;
        
        vm.currenciesDes = CurrencyConstant.DES;
        
        //TODO : init value for currency with currency code
        JhiCurrencyService.initialize(vm.currencyCode.value);
        var currencyDecCode = propertySearch.codeDescription;
        
        
        vm.changeMinBedRoom = changeMinBedRoom;
        vm.changeMaxBedRoom = changeMaxBedRoom;
        vm.changeMinBathRoom = changeMinBathRoom;
        vm.changeMaxBathRoom = changeMaxBathRoom;
        
        vm.validateSizeOfUnit = validateSizeOfUnit;
        vm.selectFromToValidateBedRoom = selectFromToValidateBedRoom;
        vm.selectFromToValidateBathRoom = selectFromToValidateBathRoom;
        
        // default value for search
        vm.searchObject = {};
        /*changeMinBedRoom('');
        changeMaxBedRoom('');
        changeMinBathRoom('');
        changeMaxBathRoom('');*/
        vm.getBathRoom = getBathRoom;
        vm.maxRoom = 5;
        vm.slider = {
		    minValue: 0,
		    maxValue: Math.ceil(vm.currencyRate[vm.currencyCode.value] * PropertyConstant.price.MAX_SEARCH_PRICE),
		    options: {
		        floor: 0,
		        ceil: Math.ceil(vm.currencyRate[vm.currencyCode.value] * PropertyConstant.price.MAX_SEARCH_PRICE),
		        //ceil: 10000,
		        step: propertySearch.stepRange,
		        minRange: Math.ceil(vm.currencyRate[vm.currencyCode.value] * 100),
		        noSwitching: true,
				pushRange : true,
				translate: function(value, sliderId, label) {
					JhiCurrencyService.currencyCode.value
					switch (label) {
						case 'model':
						    if(vm.currencyCode.value !== 'USD')
						        vm.slider.minValueForUSD =  Math.ceil(value / vm.searchObject.valueOfUsdExchangeRate);
						    else {
						    	vm.slider.minValueForUSD = value;
						    }
						    if(value !== vm.slider.options.floor && destinationCode === 'VND' && vm.searchObject.transactionType === PropertyConstant.transactionType.SELL) {
								return currencyDecCode + (value/1000000000).toFixed(3) + ' B VND';
							}
							return currencyDecCode + numberWithCommas(value);
						case 'ceil':
							if(destinationCode === 'VND' && vm.searchObject.transactionType === PropertyConstant.transactionType.SELL) {
								return currencyDecCode + (value/1000000000).toFixed(3) + ' B VND';
							}
							return currencyDecCode + numberWithCommas(value);
						case 'high':
						    if(vm.currencyCode.value !== 'USD')
						        vm.slider.maxValueForUSD =  Math.ceil(value / vm.searchObject.valueOfUsdExchangeRate);
						    else 
						        vm.slider.maxValueForUSD = value
						        if(value == vm.slider.options.ceil){
						        	if(destinationCode === 'VND' && vm.searchObject.transactionType === PropertyConstant.transactionType.SELL) {
										return currencyDecCode + (value/1000000000).toFixed(3) + ' B VND' + '+';
									}
						        } else {
						        	if(destinationCode === 'VND' && vm.searchObject.transactionType === PropertyConstant.transactionType.SELL) {
										return currencyDecCode + (value/1000000000).toFixed(3) + ' B VND';
									}
						        }
						        
							if(value == vm.slider.options.ceil){
								return currencyDecCode + numberWithCommas(value) + '+';
							}
							/*if(destinationCode === 'VND' && vm.searchObject.transactionType === PropertyConstant.transactionType.SELL) {
								return currencyDecCode + value/1000000000 + ' B VND' + '+';
							}*/
							return currencyDecCode + numberWithCommas(value);
						default:
							return currencyDecCode + numberWithCommas(value)
					}
				}
		    }
		};

        init();
        
        function init(){
         // search DTO
            //var valueOfUsdExchangeRate = JhiCurrencyService.rate[JhiCurrencyService.currencyCode.value];
            //propertySearch.maxPrice = valueOfUsdExchangeRate * propertySearch.maxPrice;
            propertySearch.valueOfUsdExchangeRate = valueOfUsdExchangeRate;
            propertySearch.destinationCode = JhiCurrencyService.currencyCode.value;
            
            vm.searchObject = angular.copy(propertySearch);
            
            District.getAllByCityCode({cityCode: 'HCM'}, function(data){
        		vm.districtHCMMasterList = data;
        		
        		if(vm.searchObject.city == 'Ho Chi Minh City'){
        			vm.districtList = vm.districtHCMMasterList;
        			vm.districtTypeaheadEditable = false;
        		}
        	});
            
            District.getAllByCityCode({cityCode: 'HAN'}, function(data){
        		vm.districtHANMasterList = data;
        		
        		if(vm.searchObject.city == 'Ha Noi'){
        			vm.districtList = vm.districtHANMasterList;
        			vm.districtTypeaheadEditable = false;
        		}
        	});
            
            District.getAllByCityCode({cityCode: 'DNG'}, function(data){
        		vm.districtDNGMasterList = data;
        		
        		if(vm.searchObject.city == 'Da Nang'){
        			vm.districtList = vm.districtDNGMasterList;
        			vm.districtTypeaheadEditable = false;
        		}
        	});
            
            if(vm.searchObject.country == null || vm.searchObject.country == ''){
            	vm.provinceList = vm.provinceMasterData;
                vm.provinceTypeaheadEditable = true;
        		
                vm.cityList = vm.cityMasterData;
                vm.cityTypeaheadEditable = true;
            }
            else if(vm.searchObject.country.toUpperCase() == 'vietnam'.toUpperCase()){
        		vm.provinceList = vm.provinceMasterData;
                vm.provinceTypeaheadEditable = false;
        		
                vm.cityList = vm.cityMasterData;
                vm.cityTypeaheadEditable = false;
                //vm.searchObject.city = '';
            }
            else{
            	vm.provinceList = [];
                vm.provinceTypeaheadEditable = true;
            	
            	vm.cityList = [];
            	vm.cityTypeaheadEditable = true;
            }
        	
        	Country.query({
                page: 0,
                size: 0,
                sort: 'name,asc'
            }, function(data){
            	vm.countryList = data; 
            }, function(error){
            	AlertService.error(error.data.message);
            });
        	
        	changeTransactionType(vm.searchObject.transactionType);
        	changeAvailability(vm.searchObject.availability);
        	changeBedRoom(vm.searchObject.bedRoom);
        	changeBathRoom(vm.searchObject.bathRoom);
            
        	updatePropertySearch();
        	loadAll();
        }
        
        function loadAll (isSearch) {
            
            var apiParam = angular.copy(propertySearch);
            apiParam.page = pagingParams.page - 1;
            apiParam.size = vm.itemsPerPage;
            apiParam.sort = sort();
        	PropertyService.query(apiParam, onSuccess, onError);
        	
            function sort() {
                var result = [];
                if (vm.predicate == 'developmentName'){
                	result.push('d.name,' + (vm.reverse ? 'asc' : 'desc'));
                }
                else if (vm.predicate == 'owner'){
                	result.push('o.name,' + (vm.reverse ? 'asc' : 'desc'));
                }
                else if (vm.predicate == 'address'){
                	result.push('street_number,' + (vm.reverse ? 'asc' : 'desc'));
                	result.push('street_name,' + (vm.reverse ? 'asc' : 'desc'));
                	result.push('district,' + (vm.reverse ? 'asc' : 'desc'));
                	result.push('city,' + (vm.reverse ? 'asc' : 'desc'));
                	result.push('province,' + (vm.reverse ? 'asc' : 'desc'));
                	result.push('country,' + (vm.reverse ? 'asc' : 'desc'));
                }
                else if(vm.predicate == 'priceUSD'){
                	result.push('t.priceUSD,' + (vm.reverse ? 'asc' : 'desc'));
                }
                else{
                	result.push(vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'));
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.properties = data;
                vm.page = pagingParams.page;
             // for slider
                if(!isSearch) {
                	if(vm.searchObject.maxPriceSearch !== null) {
                        vm.slider.maxValue = vm.searchObject.maxPriceSearch * vm.searchObject.valueOfUsdExchangeRate;
                    } else {
                        vm.slider.maxValue = vm.searchObject.maxPrice;
                    }
                    if(vm.searchObject.minPriceSearch !== null) {
                        vm.slider.minValue = vm.searchObject.minPriceSearch * vm.searchObject.valueOfUsdExchangeRate;
                    } else {
                        vm.slider.minValue = vm.searchObject.minPrice;
                    }
                }
                
                addConverter();
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
            
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
        	propertySearch.minPrice = vm.slider.options.floor;
            propertySearch.maxPrice = vm.slider.options.ceil;
            
            pagingParams.page = vm.page;
            loadAll();
            
//        	$state.transitionTo($state.$current, {
//                page: vm.page,
//                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
//                propertySearch:propertySearch
//            });
        }
        function addConverter(){
        	vm.properties.forEach(function(p){
        		if(p.size != null){
        			p.sizeConverter = MCService.init(p.sizeUnit);
        		}
        	});
        }
        function updatePropertySearch() {
        	vm.searchObject.minPrice = vm.slider.minValue;
    		vm.searchObject.maxPrice = vm.slider.maxValue;
            //Convert currency
			vm.searchObject.minPriceSearch =  Math.floor(vm.searchObject.minPrice / vm.searchObject.valueOfUsdExchangeRate);
			vm.searchObject.maxPriceSearch = Math.floor(vm.searchObject.maxPrice / vm.searchObject.valueOfUsdExchangeRate);
            
            propertySearch.searchQuery = vm.searchObject.searchQuery;
            propertySearch.district = vm.searchObject.district;
            propertySearch.city = vm.searchObject.city;
            propertySearch.province = vm.searchObject.province;
            propertySearch.country = vm.searchObject.country;
            propertySearch.minPrice = vm.searchObject.minPrice;
            propertySearch.maxPrice = vm.searchObject.maxPrice;
            propertySearch.transactionType = vm.searchObject.transactionType;
            propertySearch.availability = vm.searchObject.availability;
            propertySearch.minSizeOfUnit = vm.searchObject.minSizeOfUnit;
            propertySearch.maxSizeOfUnit = vm.searchObject.maxSizeOfUnit;
            propertySearch.unit = vm.searchObject.unit;
            propertySearch.minBedRoom = vm.searchObject.minBedRoom;
            propertySearch.maxBedRoom = vm.searchObject.maxBedRoom;
            propertySearch.minBathRoom = vm.searchObject.minBathRoom;
            propertySearch.maxBathRoom = vm.searchObject.maxBathRoom;
            propertySearch.minPriceSearch = vm.searchObject.minPriceSearch;
            propertySearch.maxPriceSearch = vm.searchObject.maxPriceSearch;
            propertySearch.valueOfUsdExchangeRate = vm.searchObject.valueOfUsdExchangeRate;
        }
        function search(){
        	vm.isSearched = true;
        	
        	pagingParams.page = 1;
        	
        	updatePropertySearch();
        	loadAll(true);
        }
        
        function exportCSV(){
        	var apiParam = angular.copy(vm.searchObject);
        	
        	apiParam.minPrice = vm.slider.minValue;
        	apiParam.maxPrice = vm.slider.maxValue;
            //Convert currency
        	apiParam.minPriceSearch =  Math.floor(vm.searchObject.minPrice / vm.searchObject.valueOfUsdExchangeRate);
        	apiParam.maxPriceSearch = Math.floor(vm.searchObject.maxPrice / vm.searchObject.valueOfUsdExchangeRate);
			// sort
			var sort = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate == 'city'){
            	sort.push('province,' + (vm.reverse ? 'asc' : 'desc'));
            	sort.push('country,' + (vm.reverse ? 'asc' : 'desc'));
            }
            apiParam.sort = sort;
            //export csv
            apiParam.measurementCode = MCService.currentMeasurement.value;
            apiParam.currencyCode = vm.currencyCode.value;
            PropertyService.exportCSVProperties(apiParam, function (result){
                FileSaver.saveAs(result.response, 'PropertyPages.csv');
            });
        }
        
        function clearSearchingValue(){
        	vm.searchObject = {};
        	vm.searchObject.country = 'Vietnam';
        	vm.searchObject.unit= 'SQ_METER';
        	vm.searchObject.destinationCode = destinationCode;
        	vm.searchObject.valueOfUsdExchangeRate = valueOfUsdExchangeRate;
        	changeTransactionType('');
        	changeAvailability('');
        	changeBedRoom('');
        	changeBathRoom('');
        	
        	// reset cityList
        	vm.cityList = vm.cityMasterData;
            vm.cityTypeaheadEditable = false;
        	
        	// clear slider here
        	vm.slider.options.ceil = vm.slider.maxValue =  Math.ceil(valueOfUsdExchangeRate * PropertyConstant.price.MAX_SEARCH_PRICE);
        	vm.slider.options.minRange =  Math.ceil(valueOfUsdExchangeRate * 100);
        	vm.slider.minValue = 0;
        	$state.params.propertySearchObj = null;
        	$state.params.propertySearchObjPaging = null;
        	
        	vm.links = null;
            vm.predicate = 'title';
            vm.reverse = true;
            pagingParams.page = 1;
            updatePropertySearch();
            // refresh data on grid
            loadAll();
        }
        
        function changeTransactionType(transactiontype){
        	if(transactiontype == ''){
        		vm.transactionTypeText = 'all';
        	}
        	else{
        		vm.transactionTypeText = transactiontype;
        	}
        	
        	vm.searchObject.transactionType = transactiontype;
        	
        	if(vm.slider.options && transactiontype === PropertyConstant.transactionType.RENT) {
        		vm.slider.options.ceil = PropertyConstant.price.MAX_SEARCH_LEASE_PRICE * valueOfUsdExchangeRate;
        	} else {
        		vm.slider.options.ceil = PropertyConstant.price.MAX_SEARCH_PRICE * valueOfUsdExchangeRate;
        	}
        	
    		vm.slider.options.floor = 0;
    		vm.slider.maxValue = vm.slider.options.ceil;
    		vm.slider.minValue = 0;
        	$rootScope.$broadcast('rzSliderForceRender');
        	
        }
        
        function changeAvailability(a){
        	if(a == ''){
        		vm.availabilityText = 'all';
        	}
        	else{
        		vm.availabilityText = a;
        	}
        	
        	vm.searchObject.availability = a;
        }
        
        function changeBedRoom(i){
        	if(i == ''){
        		vm.bedRoomText = 'Bedrooms';
        	}
        	else{
        		vm.bedRoomText = i + '+';
        	}
        	
        	vm.searchObject.bedRoom = i;
        }
        
        function changeBathRoom(i){
        	if(i == ''){
        		vm.bathRoomText = 'Bathrooms';
        	}
        	else{
        		vm.bathRoomText = i + '+';
        	}
        	
        	vm.searchObject.bathRoom = i;
        }
        
        function getBathRoom (){
        	return [0.5,1,1.5,2,2.5,3,3.5,4,4.5,5];
        }
        
        function onSelectCountry($item, $model, $label) {
            if($model.toUpperCase() == 'vietnam'.toUpperCase()){
            	vm.provinceList = vm.provinceMasterData;
                vm.provinceTypeaheadEditable = false;
            	
                vm.cityList = vm.cityMasterData;
                vm.cityTypeaheadEditable = false;
            }
            else{
            	vm.provinceList = [];
                vm.provinceTypeaheadEditable = true;
            	
            	vm.cityList = [];
            	vm.cityTypeaheadEditable = true;
            }
            vm.searchObject.province = '';
            vm.searchObject.city = '';
            vm.searchObject.district = '';
            
            vm.districtList = [];
			vm.districtTypeaheadEditable = true;
        }
        
        function onSelectCity($item, $model, $label) {
        	if($item.code == 'HCM'){
        		vm.districtList = vm.districtHCMMasterList;
    			vm.districtTypeaheadEditable = false;
        	}
        	else if($item.code == 'HAN'){
        		vm.districtList = vm.districtHANMasterList;
    			vm.districtTypeaheadEditable = false;
        	}
        	else if($item.code == 'DNG'){
        		vm.districtList = vm.districtDNGMasterList;
    			vm.districtTypeaheadEditable = false;
        	}
        	else{
        		vm.districtList = [];
    			vm.districtTypeaheadEditable = true;
        	}
        	vm.searchObject.district = '';
        }
        
        function onKeyUpCountry(){
        	if(vm.searchObject.country == null || vm.searchObject.country == ''){
        		vm.provinceList = vm.provinceMasterData;
                vm.provinceTypeaheadEditable = true;
        		
        		vm.cityList = vm.cityMasterData;
        		vm.cityTypeaheadEditable = true;
        	}
        }
        
        function onKeyUpCity(){
        	if(vm.searchObject.city == null || vm.searchObject.city == ''){
        		vm.districtList = [];
        		vm.districtTypeaheadEditable = true;
        	}
        }
        
        // receive broadcast change currency
        $scope.$on("curreny:change", function(event, item){
        	var price = null;
        	if(vm.slider.options && vm.searchObject.transactionType === PropertyConstant.transactionType.RENT) {
        		price = Math.ceil(item.valueOfUsdExchangeRate * PropertyConstant.price.MAX_SEARCH_LEASE_PRICE);
        		/*vm.slider.options.ceil = price;
            	vm.slider.options.step = item.valueOfUsdExchangeRate * 100;
            	vm.slider.maxValue = vm.slider.options.ceil;
            	vm.slider.minValue = 0;
            	//vm.slider.options.minRange = 0;
*/        		
        	} else {
        		price = Math.ceil(item.valueOfUsdExchangeRate * PropertyConstant.price.MAX_SEARCH_PRICE);
            	/*vm.slider.options.ceil = price;
            	vm.slider.options.step = item.valueOfUsdExchangeRate * 100;
            	vm.slider.options.minRange =  Math.ceil(vm.searchObject.valueOfUsdExchangeRate * 100);
            	if(vm.slider.maxValueForUSD) {
                    vm.slider.maxValue =  Math.ceil(vm.slider.maxValueForUSD * vm.searchObject.valueOfUsdExchangeRate);
                } else {
                    vm.slider.maxValue = price;
                }
                if(vm.slider.minValueForUSD) {
                    vm.slider.minValue =  Math.ceil(vm.slider.minValueForUSD * vm.searchObject.valueOfUsdExchangeRate);
                } else {
                    vm.slider.minValue = 0;
                }*/
        	}
        	vm.slider.options.ceil = price;
        	vm.slider.options.step = item.valueOfUsdExchangeRate * 100;
        	vm.slider.maxValue = vm.slider.options.ceil;
        	vm.slider.minValue = 0;
        	// update local variables
        	valueOfUsdExchangeRate = item.valueOfUsdExchangeRate;
        	destinationCode = item.destinationCode;
        	currencyDecCode = item.codeDescription;
        	
        	//update current property search
        	vm.searchObject.valueOfUsdExchangeRate = item.valueOfUsdExchangeRate;
        	vm.searchObject.destinationCode = item.destinationCode;
        	
        	// update object property search
        	propertySearch.valueOfUsdExchangeRate = item.valueOfUsdExchangeRate;
        	propertySearch.maxPrice = price;
        	propertySearch.destinationCode = item.destinationCode;
        	
        	// update slider
        	/*vm.slider.options.ceil = price;
        	vm.slider.options.step = item.valueOfUsdExchangeRate * 100;
        	vm.slider.options.minRange =  Math.ceil(vm.searchObject.valueOfUsdExchangeRate * 100);
        	if(vm.slider.maxValueForUSD) {
                vm.slider.maxValue =  Math.ceil(vm.slider.maxValueForUSD * vm.searchObject.valueOfUsdExchangeRate);
            } else {
                vm.slider.maxValue = price;
            }
            if(vm.slider.minValueForUSD) {
                vm.slider.minValue =  Math.ceil(vm.slider.minValueForUSD * vm.searchObject.valueOfUsdExchangeRate);
            } else {
                vm.slider.minValue = 0;
            }*/
            
        });
        
        function numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        
        function changeMinBedRoom(value) {
        	vm.searchObject.minBedRoom = value;
        }
        
        function changeMaxBedRoom(value) {
        	vm.searchObject.maxBedRoom = value;
        }
        
        function changeMinBathRoom(value) {
        	vm.searchObject.minBathRoom = value;
        }
        
        function changeMaxBathRoom(value) {
        	vm.searchObject.maxBathRoom = value;
        }
        
        function validateSizeOfUnit(){
        	if(vm.searchObject.minSizeOfUnit == null || vm.searchObject.maxSizeOfUnit == null){
        		return false;
        	}
        	if(parseFloat(vm.searchObject.minSizeOfUnit) > parseFloat(vm.searchObject.maxSizeOfUnit)){
        		return true;
        	}
        	return false;
        }
        
        function selectFromToValidateBedRoom(){
        	if(vm.searchObject.maxBedRoom == null) return false;
        	return vm.searchObject.minBedRoom > vm.searchObject.maxBedRoom;
        }
        
        function selectFromToValidateBathRoom(){
        	if(vm.searchObject.maxBathRoom === '') return false;
        	return vm.searchObject.minBathRoom > vm.searchObject.maxBathRoom;
        }
        
    }
})();
