(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .constant('MarketingCampaignConstant', (function() {
            return {
                types: {
                    MARKETING_CAMPAIGN: 'Marketing Campaign'
                }
            }
        })());
})();
