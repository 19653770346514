(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('LeaveConfirmDialogController',LeaveConfirmDialogController);

    LeaveConfirmDialogController.$inject = ['$uibModalInstance', 'entity', 'translateKeys', '$rootScope'];

    function LeaveConfirmDialogController($uibModalInstance, entity, translateKeys, $rootScope) {
        var vm = this;

        vm.property = entity;
        vm.translateKeys = translateKeys.keys;

        vm.clear = clear;
        vm.confirm = confirm;

        $rootScope.$on('modal:close', function(event, result) {
        	clear();
        });

        function clear () {
            $rootScope.$broadcast('app:resetTimeSession', true);
            $uibModalInstance.dismiss('cancel');
        }

        function confirm () {
            $rootScope.$broadcast('app:resetTimeSession', true);
        	$uibModalInstance.close(true);
        }
    }
})();
