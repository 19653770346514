(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('PhotosController', PhotosController);

    PhotosController.$inject = ['$rootScope','$state', '$timeout', '$scope', '$stateParams', 'PhotoService', 'previousState', '$uibModal', 'Upload', 'AlertService'];

    function PhotosController($rootScope, $state, $timeout, $scope, $stateParams, PhotoService, previousState, $uibModal, Upload, AlertService) {
        $scope.$emit('indochinaApp:tabActiveUpdate', 'photos');
        
        var vm = this;
        
        vm.isCreateNew = $state.current.name === 'property.new.photo';
        vm.isDulication = $state.current.name === 'property-detail.duplicate.photo';
        vm.isEdited = $state.current.name === 'property-detail.edit.publish' || $state.current.name === 'property-detail.edit.photo' || $state.current.name === 'property-detail.edit.photos';
        
        vm.id = $stateParams.id;
        
        vm.cancel = cancel;
        vm.viewPhoto = viewPhoto;
        
        loadAll();
        
        function loadAll() {
            PhotoService.query({propertyId: $stateParams.id ? $stateParams.id: null}, function(result) {
                vm.photos = result;
                vm.searchQuery = null;
            });
        }
        
        function cancel() {
        	if(vm.isCreateNew){
        		if($rootScope.previousState != null && $rootScope.previousState.name == 'property'){
        			$state.go('property', $rootScope.previousParams);
        		}
        		else{
        			$state.go('property', {});
        		}
        	}
        	else{
        		$state.go('property-detail', {id: vm.id, activeTab: 'photo'});
        	}
        }
        
        function viewPhoto(photo) {
        	if (photo.id) {
        		$uibModal.open({
                    templateUrl: 'app/entities/property/property-editor/photo/partial/photo-view-dialog.html',
                    controller: 'PhotoViewController',
                    controllerAs: 'vm',
                    windowClass : 'absolute-center',
                    resolve: {
                    	photoView: ['$stateParams', function($stateParams) {
                            return photo;
                        }]
                    }
                }).result.then(function() {
                    //$state.go('property', null);
                }, function() {
                    //$state.go('^');
                });
        	}
        }
        
        vm.uploadFiles = function(files, errFiles) {
            vm.files = files;
            vm.errFiles = errFiles;
            
            var isShowedErrorMsg = false;
            angular.forEach(files, function(file) {
            	
            	// 2097152bytes = 2MB
            	if(file.size > 2097152){
            		
            		// we will show when the first image have size larger 2MB.
            		// if we wait system uploaded all images, It is very slow. 
            		if(!isShowedErrorMsg){
            			isShowedErrorMsg = true;
            			AlertService.error("File must be less than 2MB");
            		}
            	}
            	else{            		
            		file.upload = Upload.upload({
            			url: 'api/photos/upload',
            			data: {propertyId:$stateParams.id, file: file}
            		});
            		
            		file.upload.then(function (response) {
            			$timeout(function () {
            				file.result = response.data;
            				vm.photos.push(response.data);
            			});
            		}, function (response) {
            			if (response.status > 0)
            				$scope.errorMsg = response.status + ': ' + response.data;
            		}, function (evt) {
            			file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            		});
            	}
            });
        }
    }
})();
