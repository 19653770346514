(function () {
    'use strict';

    angular
        .module('indochinaApp')
        .factory('ManagerService', ManagerService);

    ManagerService.$inject = ['$resource', '$http'];

    function ManagerService ($resource, $http) {
    	
    	var resourceUrl =  'api/users/';
    	return $resource(resourceUrl, {}, {
			'getManagersById' : {
				method : 'GET',
				url : resourceUrl + 'getManagersById',
				isArray : true
			},
    		'getAllManagerByOfficeId' : {
				method : 'GET',
				url : resourceUrl + 'get-managers-by-office',
				isArray : true
			},
			'getListManagerSuperUser' : {
				method : 'GET',
				url : resourceUrl + 'get-list-manager-and-super-user',
				isArray : true
			}
        });
    }
})();