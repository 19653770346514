(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(sessionIdleConfig);

    sessionIdleConfig.$inject = ['KeepaliveProvider', 'IdleProvider'];

    function sessionIdleConfig(KeepaliveProvider, IdleProvider) {
		IdleProvider.idle(60*59);
		IdleProvider.timeout(1);
		KeepaliveProvider.interval(10);
	};
})();
