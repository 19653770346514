(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('development-detail.sales-tab', {
        	parent: 'development-detail',
        	url: '/sales-tab',
        	templateUrl: 'app/entities/development/development-detail/sales-tab/sales-tab.html',
            controller: 'DevelopmentSalesTabController',
            controllerAs: 'vm',
            resolve: {
                entity: ['$stateParams', 'DevelopmentService', function($stateParams, DevelopmentService) {
                    return DevelopmentService.get({id : $stateParams.id}).$promise;
                }]
            }
        });
    }

})();
