(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('PublishTabController', PublishTabController);

    PublishTabController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'previousState', 'entity', 'PublishService', 'Principal', '$uibModal', 'PropertyService', '$translate', 'photos', 'publishSiteResource'];

    function PublishTabController($scope, $state, $rootScope, $stateParams, previousState, entity, PublishService, Principal, $uibModal, PropertyService, $translate, photos, publishSiteResource) {
        var vm = this;
        vm.property = entity;

        if($state.isEdit) {
        	vm.isEdit = $state.isEdit;
        }

        $rootScope.scrollTop();
        $rootScope.$broadcast('property:activeTab', 'PUBLISH');
        $rootScope.$on('property:changeMode', function(event, result) {
        	vm.isEdit = result;
        });

        $scope.$on('$stateChangeStart', onStateChangeStart);

        vm.save = save;
        vm.cancel = cancel;
        
        
        vm.photos = photos;

        // define for checkbox
        vm.selectedSites = [];


        init();

        function init () {
            // load site resource
            vm.sites = publishSiteResource;

            // load value for nearby facility checkbox
            if(vm.property.sites.length > 0) {
                angular.forEach(vm.property.sites, function(value, key) {
                    vm.selectedSites[value.id] = true;
                });
            }
        }

        function save () {

            $scope.editForm.$dirty = false;

            PropertyService.update(vm.property, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.editForm.$setPristine();
            // change variable isSaving
            vm.isSaving = false;

            // update entity
            $scope.$emit('indochinaApp:propertyUpdate', result);

            // change mode edit in state
            $state.isEdit = false;

            // change mode on current state
            vm.isEdit = false;

            // broadcast event to appear button edit
            $rootScope.$broadcast('property:changeMode', false);
        }

        function onSaveError (response) {
            console.log(response);
        }

        vm.publishSiteClick = publishSiteClick;
        function publishSiteClick (value, site) {
            if(vm.photos.length === 0) {
                vm.selectedSites[site.id] = false;
                angular.forEach(vm.property.sites, function(value, key) {
                    if(site.id === value.id) {
                        vm.property.sites.splice(i, 1);
                    }
                    i++;
                });
            } else {
                if(value === true) {
                    vm.property.sites.push(site);
                } else {
                    var i = 0;
                    angular.forEach(vm.property.sites, function(value, key) {
                        if(site.id === value.id) {
                            vm.property.sites.splice(i, 1);
                        }
                        i++;
                    });
                }
            }
        }

        vm.tooltipEnable = tooltipEnable;
        function tooltipEnable (site) {
            //////////////////////////////////////////////////////////////////////////////////
            // tooltip display if site is dot property or ( site is tranio or square foot and
            // property don't have photos
            /////////////////////////////////////////////////////////////////////////////////
            if(site.id === 2 || vm.photos.length === 0) {
                return true;
            }
            return false;
        }

        vm.getTooltipMessage = getTooltipMessage;
        function getTooltipMessage (site) {
            //////////////////////////////////////////////////////////////////////////////////
            // message is warning if property don't have photos
            // message is display for dot property if site is dot property
            /////////////////////////////////////////////////////////////////////////////////
            if(vm.photos.length === 0) {
                return $translate.instant('indochinaApp.property.publishSite.noImagesTooltip');
            }
            return $translate.instant('indochinaApp.property.publishSite.dotPropertyTooltip');
        }

        function cancel() {

            if($scope.editForm.$dirty) {

                // if have any change we must reload page to reset entity
                $state.reload($state.current.name);

                return;
            }

            // change mode edit in state
            $state.isEdit = false;

            // change mode on current state
            vm.isEdit = false;

            // broadcast event to appear button edit
            $rootScope.$broadcast('property:changeMode', false);

            //scroll to top page
            $rootScope.scrollTop();

        }

        function onStateChangeStart(event, toState, toParams, fromState, fromParams) {
            if($scope.editForm.$dirty && !$rootScope.isSessionTimeOut){
            	event.preventDefault();

                $uibModal.open({
                    templateUrl: 'app/components/confirmation-dialog/leave/confirm-dialog.html',
                    controller: 'LeaveConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                        entity: function(){
                        	return null;
                        },
                        translateKeys: function(){
                        	return {
                        		keys: 'indochinaApp.property.confirmLeavePage'
                        	};
                        }
                    }
                }).result.then(function() {


                    // change mode edit in state
                    $state.isEdit = false;

                    // avoid loop onStateChange function
                    if($scope.editForm) {
                        $scope.editForm.$dirty = false;
                    }

                    if(toState.name == 'property-detail.publish-tab'){

                        // reload parent to reset entity
                        $state.reload('property-detail');

                        // broadcast event to appear button edit
                        $rootScope.$broadcast('property:changeMode', false);
                    }
                    else{
                        //$state.isEdit = true;
                        $state.go(toState, toParams, { reload: true });
                    }
                }, function() {
//                    $state.go('^');
                });
            }
        }

    }
})();
