(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('LeadSourceDeleteController',LeadSourceDeleteController);

    LeadSourceDeleteController.$inject = ['$uibModalInstance', 'entity', '$rootScope'];

    function LeadSourceDeleteController($uibModalInstance, entity, $rootScope) {
        var vm = this;
        vm.leadSource = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        // session is time out so we must close all modals
        $rootScope.$on('modal:close', function(event, result) {
            clear();
        });

        function clear () {
            $rootScope.$broadcast('app:resetTimeSession', true);
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
            $rootScope.$broadcast('app:resetTimeSession', true);
        	$uibModalInstance.close(true);
        }
    }
})();
