(function() {
    'use strict';
    angular
        .module('indochinaApp')
        .factory('MeasurementConversionService', MeasurementConversionService);

    MeasurementConversionService.$inject = ['$localStorage', 'Principal', 'User', '$rootScope'];

    function MeasurementConversionService ($localStorage, Principal, User, $rootScope) {
    	var feetToMeter = 0.3048;
    	var meterToFeet = 3.28084;
    	var sqFeetToSqMeter = 0.092903;
    	var sqMeterToSqFeet = 10.7639;
    	var sqMileToSqKilometer = 2.58999;
    	var sqKilometerToSqMile = 0.386102;
    	var acreToHectare = 0.404686;
    	var hectareToAcre = 2.47105;
    	
    	
    	var measurementConstant = {
    		LIST: ['METRIC','IMPERIAL'],
    		METRIC: 'METRIC',
    		IMPERIAL: 'IMPERIAL'
    	}
    	var unitConstant = {
    		LIST: [],
    		SQ_METER: 'SQ_METER',
        	SQ_KILOMETER: 'SQ_KILOMETER',
        	HECTARE: 'HECTARE',
        	SQ_FEET: 'SQ_FEET',
        	SQ_MILE: 'SQ_MILE',
        	ACRE: 'ACRE',
        	METER: 'METER',
        	FEET: 'FEET'
    	}
    	
//    	var currentMeasurement = getMeasurement();
    	var array = [];
    	
    	var scope = {
    		unitConstant: unitConstant,
    		measurementConstant: measurementConstant,
    		currentMeasurement: {
    			// auto 2 way binding on navbar.html
    			value: getMeasurement()
    		},
    		init: init,
    		change: change
    	};
    	
    	$rootScope.$on('authenticationSuccess', function(event, data){
    		User.getCurrentUser().then(function(reponse){
				if(reponse.data != null){
					change(reponse.data.preferredUnit);
				}
			});
    	});
    	
    	return scope;
    	
    	
    	function init(unit){
    		var object = {
    			unitSource: unit,
    			destination: {
    				rate: 1,
    	    		unit: unit
    			}
    		}
    		
    		array.push(object);
    		
    		update(object);
    		
    		return object;
    	}
    	
    	function change(measurement){
    		scope.currentMeasurement.value = measurement;
    		
    		setMeasurement(measurement);
    		updateList();
    	}
    	
    	function updateList(){
    		array.forEach(update);
    	} 
    	
    	function update(entry){
			if(scope.currentMeasurement.value == measurementConstant.IMPERIAL){
    			switch(entry.unitSource){
    			case unitConstant.METER:
    				entry.destination.rate = meterToFeet;
    				entry.destination.unit = unitConstant.FEET;
    				break;
    			case unitConstant.SQ_METER:
    				entry.destination.rate = sqMeterToSqFeet;
    				entry.destination.unit = unitConstant.SQ_FEET;
    				break;
    			case unitConstant.SQ_KILOMETER:
    				entry.destination.rate = sqKilometerToSqMile;
    				entry.destination.unit = unitConstant.SQ_MILE;
    				break;
    			case unitConstant.HECTARE:
    				entry.destination.rate = hectareToAcre;
    				entry.destination.unit = unitConstant.ACRE;
    				break;
    			default:
    				entry.destination.rate = 1;
    				entry.destination.unit = entry.unitSource;
    			}
    		}
    		else{
    			switch(entry.unitSource){
    			case unitConstant.FEET:
    				entry.destination.rate = feetToMeter;
    				entry.destination.unit = unitConstant.METER;
    				break;
    			case unitConstant.SQ_FEET:
    				entry.destination.rate = sqFeetToSqMeter;
    				entry.destination.unit = unitConstant.SQ_METER;
    				break;
    			case unitConstant.SQ_MILE:
    				entry.destination.rate = sqMileToSqKilometer;
    				entry.destination.unit = unitConstant.SQ_KILOMETER;
    				break;
    			case unitConstant.ACRE:
    				entry.destination.rate = acreToHectare;
    				entry.destination.unit = unitConstant.HECTARE;
    				break;
    			default:
    				entry.destination.rate = 1;
    				entry.destination.unit = entry.unitSource;
    			}
    		}
    	} 
    	
    	function getMeasurement(){
    		var measurement = measurementConstant.METRIC;
    		
        	if($localStorage.measurement != null){
        		measurement = $localStorage.measurement
        	}
        	else{
        		if(Principal.isAuthenticated()){
        			User.getCurrentUser().then(function(reponse){
        			if(reponse.data != null){
        				measurement = reponse.data.preferredUnit;
        				setMeasurement(measurement)
        			}
        			});
        		}
        	}
        	
        	return measurement;
    	}
    	
    	function setMeasurement(measurement){
    		$localStorage.measurement = measurement;
    	}
    }
})();
