(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('contact-detail.personal-detail-tab', {
        	parent: 'contact-detail',
        	url: '/personal-detail-tab',
        	templateUrl: 'app/entities/contact/contact-detail/personal-details-tab/personal-details-tab.html',
            controller: 'PersonalDetailsTabController',
            controllerAs: 'vm'
        });
    }

})();
