(function() {
    'use strict';
    
    angular
    .module('indochinaApp')
    .directive('currency', ['$filter', function ($filter) {
        return {
            require: '?ngModel',
            link: function (scope, elem, attrs, ctrl) {
            	var min = attrs.currencyMin || 0.01;
            	var max = attrs.currencyMax || 9999999999999.99;
//            	var format = attrs.currencyFormat || 'number';
            	var required = attrs.currencyRequired || false;
            	
                if (!ctrl) return;
                
                elem.on("keypress", function(e) {
                	if(!isAcceptKey(e)){
                		e.preventDefault();                		
                	}
                	
//                	var plainValue = null;
//                	if(elem[0].value != null){
//                		plainValue = elem[0].value.replace(/[^.\d]/g, '');
//                	}
//                	
//                	if(Number(plainValue) >= Number(max) && !isSelecting(elem)){
//               			e.preventDefault();
//                	}
                });

                // $formatters is called when data is changed by controller
                ctrl.$formatters.unshift(function (modelValue) {
                	ctrl.$setValidity('required', true);
                	
                	if((modelValue == null || modelValue == '') && required){
                		ctrl.$setValidity('required', false);
                	}

                    return formatNumber(ctrl.$modelValue);
                });
                
                // $parsers is called when data is changed by view
                ctrl.$parsers.unshift(function (viewValue) {
                	var plainNumber = null;
                	var caretPos = elem[0].selectionStart;
                	
                	ctrl.$setValidity('required', true);
                	ctrl.$setValidity('currencyMin', true);
                	ctrl.$setValidity('currencyMax', true);
                	
                	if(viewValue != null){
                		plainNumber = viewValue.replace(/[^.\d]/g, '');
                	}
                	
                    if(viewValue == null || plainNumber == ''){
                    	ctrl.$setViewValue(null); // view value
                    	ctrl.$render(); // call controller render again
                    	
                    	if(required){
                    		ctrl.$setValidity('required', false);
                    	}
                    	
//                    	elem.val(null); // view value
                    	return null;// model value
                    }
                    else{
                    	if(Number(plainNumber) < Number(min)){
                    		ctrl.$setValidity('currencyMin', false);
                    	}
                    	if(Number(plainNumber) > Number(max)){
                    		ctrl.$setValidity('currencyMax', false);
                    	}
                    	
                    	var finalValue = formatNumber(plainNumber);
                    	var movePos = finalValue.length - viewValue.length;
                    	
//                    	elem.val(finalValue);
                    	ctrl.$setViewValue(finalValue);
                    	setCaretPosition(elem[0], caretPos + movePos);
                    	
                    	ctrl.$render();
                    	return plainNumber;
                    }
                });
                
                function isAcceptKey(e){
                	// [0-9]
            		if(e.which >= 48 && e.which <= 67){
                		return true;
                	}
            		
            		// [.]
            		if(e.which == 46){
            			return true;
            		}
            		
            		// [a-z]
            		if(e.which >= 97 && e.which <= 122){
                		return false;
                	}
            		
            		// [ !"#$%&'()*+,-./]
            		if(e.which >= 32 && e.which <= 37){
                		return false;
                	}
            		
            		if(e.which >= 91 && e.which <= 96){
                		return false;
                	}
            		
            		if(e.which == 123 || e.which == 125 || e.which == 126){
                		return false;
                	}
            		
            		// we should change here
            		// it have some error on firefox
            		return true;
                }
                
                function isSelecting(elem){
                	return elem[0].selectionStart != elem[0].selectionEnd;
                }
                
                function setCaretPosition(elem, caretPos) {
                    if (elem !== null) {
                    	if (elem.createTextRange) {
                            var range = elem.createTextRange();
                            range.move('character', caretPos);
                            range.select();
                        } else {
                            if (elem.setSelectionRange) {
                                elem.focus();
                                elem.setSelectionRange(caretPos, caretPos);
                            } else
                                elem.focus();
                        }
                    }
                }
                
                function formatNumber(number){
                	if(number == null){
                		return null;
                	} 
                	
                	var plainNumber = number.toString().replace(/[^.\d]/g, '');
                	
                	var fraction = '';
                	var interger = plainNumber;
                	var dotIndex = plainNumber.indexOf('.');
                	
                	if(dotIndex != -1){
                		interger = plainNumber.substr(0,dotIndex);
                		fraction = plainNumber.substr(dotIndex);
                	}
                	
                	interger = interger.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                	
                	return interger + fraction;
                }
            }
        };
    }]);
})();
