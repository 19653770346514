(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('DashboardSectionOpportunityController', Controller);

    Controller.$inject = ['$scope', 'Principal', '$state', 'ParseLinks', 'AlertService', 'Opportunity', 'CurrencyConstant'];

    function Controller($scope, Principal, $state, ParseLinks, AlertService, Opportunity, CurrencyConstant) {
        var vm = this;

        vm.currenciesDes = CurrencyConstant.DES;
        
        vm.predicate = 'closeDate';
        vm.reverse = 1;
        vm.itemsPerPage = 10;
        vm.page = 1;
        vm.opportunities  = [];
        
        vm.loadMore = loadMore;

        loadAll();
        
        function loadAll () {
        	var apiParams = {};
        	apiParams.page = vm.page - 1;
        	apiParams.size = vm.itemsPerPage;
        	apiParams.sort = sort();
        	
            Opportunity.getAllOpportunitiesForDashboard(apiParams, onSuccess, onError);
            
            function sort() {
                var result = [];
                
                result.push('o.closeDate,desc');
            	result.push('o.id,asc');
                
                return result;
            }
            function onSuccess(data, headers) {
//                vm.links = ParseLinks.parse(headers('link'));
            	vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.opportunities = vm.opportunities.concat(data);
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadMore() {
        	vm.page = vm.page + 1;
        	loadAll();
        }
    }
})();
