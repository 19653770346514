(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .filter('findCurrencyFromKey', findCurrencyFromKey);

    function findCurrencyFromKey() {
        return findCurrencyFromKeyFilter;

        function findCurrencyFromKeyFilter(currency) {
            return {
            'USD':'USD - US Dollar',
            'VND':'VND - Vietnamese Dong',
            'AED':'AED - UAE Dirham',
            'THB':'THB - Thai Baht',
            'RUB':'RUB - Russian Ruble',
            'SGD':'SGD - Singapore Dollar',
            'HKD':'HKD - Hong Kong Dollar',
            'EUR':'EUR - Euro',
            'GBP':'GBP - British Pound Sterling',
            'MYR':'MYR - Malaysian Ringgit',
            'KRW':'KRW - South Korean Won',
            'JPY':'JPY - Japanese Yen',
            'CNY':'CNY - Chinese Yuan',
            'AUD':'AUD - Australian Dollar',
            'TWD':'TWD - New Taiwan Dollar',
            'INR':'INR - Indian Rupee',
            'IDR':'IDR - Indonesian Rupiah',
            'CAD':'CAD - Canadian Dollar',
            'CHF':'CHF - Swiss Franc'
            }[currency];
        }
    }
})();
