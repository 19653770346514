(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('ActivityDialogViewController', ActivityDialogViewController);

    ActivityDialogViewController.$inject = ['$filter', '$state', '$uibModal', '$scope', 'ContactActivity', '$uibModalInstance', 'entity', 'pagingParams', 'development', '$rootScope', 'Activity', 'ActivityConstant'];

    function ActivityDialogViewController ($filter, $state, $uibModal, $scope, ContactActivity, $uibModalInstance, entity, pagingParams, development, $rootScope, Activity, ActivityConstant) {
        var vm = this;
        vm.activity = entity;

        vm.save = save;


        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 5;
        vm.isMarketing = isMarketing;

        if(development) {
            vm.activity.developmentName = development.name;
            vm.haveDevelopment = true;
        }

        vm.contactActivities = vm.activity.contactActivities;

        vm.clear = clear;

        // session is time out so we must close all modals

        // session is time out so we must close all modals
        $rootScope.$on( 'modal:close', function( event, result ) {
            $uibModalInstance.dismiss( 'cancel' );
        } );

        function clear () {
            $rootScope.$broadcast( 'app:resetTimeSession', true );
            onStateChangeStart();
        }

        init ();

        function init () {
            loadAll ();
        }

        function loadAll () {

            vm.searchObject = {
                activityId: vm.activity.id
            };

            var apiParam = angular.copy(vm.searchObject);
            apiParam.page = pagingParams.page - 1;
            apiParam.size = vm.itemsPerPage;
            apiParam.sort = sort();


            ContactActivity.query(apiParam, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if(vm.predicate === 'person') {
                    result = [];
                    result.push('person.firstName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                    result.push('person.lastName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                }
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.contactActivities = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                //AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            pagingParams.page = vm.page;
            pagingParams.predicate = vm.predicate;
            pagingParams.ascending = vm.reverse;
            pagingParams.sort = vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc');
            loadAll();
        }

        function save () {
            $rootScope.$broadcast( 'app:resetTimeSession', true );

            vm.isSaving = true;

            Activity.update( vm.activity, onSaveSuccess, onSaveError );
        }

        function onSaveSuccess ( result ) {
            $scope.$emit( 'indochinaApp:activityUpdate', result );
            $scope.editForm.$dirty = false;
            clear();
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function onStateChangeStart() {
            if( $scope.editForm.$dirty && !$rootScope.isSessionTimeOut ){
                $uibModal.open( {
                    templateUrl: 'app/components/confirmation-dialog/leave/confirm-dialog.html',
                    controller: 'LeaveConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                        entity: function(){
                            return null;
                        },
                        translateKeys: function(){
                            return {
                                keys: 'indochinaApp.property.confirmLeavePage'
                            };
                        }
                    }
                } ).result.then( function() {
                    $scope.editForm.$dirty = false;
                    $uibModalInstance.dismiss( 'cancel' );
                }, function() {
//                    $state.go( '^' );
                } );
            } else {
                $uibModalInstance.dismiss( 'cancel' );
            }
        }

        function isMarketing () {
             return vm.activity.activityType.name === ActivityConstant.type.MARKETING_CAMPAIGN;
        }

    }
})();
