(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('PropertyImportController',PropertyImportController);

    PropertyImportController.$inject = ['$scope', 'PropertyService', 'AlertService'];

    function PropertyImportController($scope, PropertyService, AlertService) {
        var vm = this;
        
        vm.file = null;
        vm.isImporting = false;
        vm.bulkUpload = {numberFail:-1};
        
        vm.getTemplate = getTemplate;
        vm.importProperties = importProperties;

        function importProperties() {
            if(vm.file) {
                vm.isImporting = true;
                PropertyService.importProperties({file:vm.file}, onImportSuccess, onImportError);
            }
        }
        
        function onImportSuccess(data, headers) {
           vm.bulkUpload = data;
           vm.file = null;
           vm.isImporting = false;
           AlertService.success("Import data successfully!");
        }
        function onImportError(error) {
            AlertService.error("Has internal server error!");
            vm.isImporting = false;
        }
        
        function getTemplate(){
        	var href = window.location.href;
        	var newHref = href.substring(0,href.indexOf('#')) + 'api/properties/xlstemplate'; 
        	window.location.href = newHref;
        }
    }
})();
