(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('MaterialsDetailController', MaterialsDetailController);

    MaterialsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Materials'];

    function MaterialsDetailController($scope, $rootScope, $stateParams, previousState, entity, Materials) {
        var vm = this;

        vm.materials = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('indochinaApp:materialsUpdate', function(event, result) {
            vm.materials = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
