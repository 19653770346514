(function () {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('ActivityDialogController', ActivityDialogController);

    ActivityDialogController.$inject = ['$rootScope', '$filter', '$state', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Activity', 'Opportunity', 'PropertyService', 'Contact',
        '$uibModal', 'isFollowUpActivity', '$window', 'login', 'activityTypeResource', 'propertyTemplate', 'contact', 'property', 'development', 'ActivityConstant', 'marketingCampaignResource',
        'PropertyConstant'];

    function ActivityDialogController($rootScope, $filter, $state, $timeout, $scope, $stateParams, $uibModalInstance, entity, Activity, Opportunity, PropertyService, Contact,
                                      $uibModal, isFollowUpActivity, $window, login, activityTypeResource, propertyTemplate, contact, property, development, ActivityConstant, marketingCampaignResource, 
                                      PropertyConstant) {
        var vm = this;

        /*due date config*/
        var today = new Date();
        today.setDate(today.getDate() - 2);
        vm.currentDate = $filter('date')(today, 'MM/dd/yyyy');
        vm.datePickerOpenStatus = {};

        vm.datePickerOption = {
            showWeeks: false,
            readonlyInput: true,
            dateformat: 'MM/dd/yyyy'
            //minDate: vm.currentDate
        }

        /*variable block*/
        vm.activity = entity;


        vm.isFollowUpActivity = isFollowUpActivity;
        //vm.contact =  vm.activity.primaryContact;
        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.modifiedDate = false;
        vm.datePickerOpenStatus.dueDate = false;
        //vm.isCompleted = false;
        vm.persons = [];
        vm.secondaryPersons = [];
        vm.phones = [];
        vm.secondaryPhones = [];


        /*method block*/
        vm.clear = clear;
        vm.complete = complete;
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.typeSelected = typeSelected;
        vm.opportunities = opportunities;
        vm.opportunitiySelect = opportunitySelect;
        vm.properties = properties;
        vm.propertySelect = propertySelect;
        vm.personSelected = personSelected;
        vm.phoneSelected = phoneSelected;
        vm.emailSelected = emailSelected;
        vm.contacts = contacts;
        vm.opportunityChange = opportunityChange;
        vm.onChangeProperty = onChangeProperty;
        vm.onChangeContact = onChangeContact;
        vm.marketingChange = marketingChange;
        vm.marketingParentChange = marketingParentChange;

        vm.addContactActivity = addContactActivity;
        vm.onRemoveContactActivity = onRemoveContactActivity;
        vm.contactSelect = contactSelect;

        vm.addPropertyActivity = addPropertyActivity;
        vm.onRemovePropertyActivity = onRemovePropertyActivity;

        // function checking
        vm.checkingPropertyNotBelongToDevelopment = checkingPropertyNotBelongToDevelopment;
        vm.checkingIsPrimaryProperty = checkingIsPrimaryProperty;
        vm.checkingContactRequire = checkingContactRequire;
        vm.checkingDisableRemoveContact = checkingDisableRemoveContact;
        vm.isNoHaveProperty = isNoHaveProperty;
        vm.isDisableOpportunity = isDisableOpportunity;
        vm.isHaveOpportunity = isHaveOpportunity;
        vm.isCreateFromDashBoard = isCreateFromDashBoard;
        vm.isCreateFromDevelopment = isCreateFromDevelopment;
        vm.isCreateFromContact = isCreateFromContact;
        vm.isCreateFromProperty = isCreateFromProperty;
        vm.isMarketingType = isMarketingType;
        vm.isMarketingRemove = isMarketingRemove;

        // session is time out so we must close all modals
        $rootScope.$on('modal:close', function (event, result) {
            $uibModalInstance.dismiss('cancel');
        });

        init();

        function init() {

            // define primary property
            vm.primaryProperty = property;

            // define primary contact
            vm.primaryContact = contact;


            // get activity type
            vm.allActivityTypes = activityTypeResource;
            if (vm.activity.id) {
                angular.forEach(vm.allActivityTypes, function (value, key) {
                    if (value.id === vm.activity.activityType.id) {
                        vm.typeSelectedValue = value.description;
                    }
                });
            }

            // get marketing campaign child
            vm.marketingCampaignParents = marketingCampaignResource;

            angular.forEach(vm.marketingCampaignParents, function (marketingCampaign, key) {
                if (vm.activity.marketingCampaignParentId === marketingCampaign.id) {
                    vm.activity.isMarketingCampaignParentActive = marketingCampaign.activated;
                    vm.marketingCampaignChilds = marketingCampaign.childs;
                }
            });

            /*
            * detect activity create from:
            * 0: dashboard
            * 1: development
            * 2: contact
            * 3: property
            * */


            var contactObject = null;
            var propertyObject = null;

            if (isCreateFromContact() && !vm.activity.id) {
                contactObject = contact;
            }

            if (isCreateFromProperty() && !vm.activity.id) {
                propertyObject = property;
            }

            if (( isCreateFromDevelopment() || isCreateFromDashBoard() ) && !vm.activity.id) {
                // do something
            }

            if (!vm.activity.id) {
                createAndPushPrimaryContact(contactObject);
                createAndPushPrimaryProperty(propertyObject);

            } else {
                if (vm.activity.propertyActivities && isNoHaveProperty()) {
                    createAndPushPrimaryProperty(propertyObject);
                }
            }

            // define development
            if (development) {
                vm.activity.developmentId = development.id;
                vm.activity.developmentName = development.name;
                vm.haveDevelopment = true;
            }


            if (vm.activity.id) {

                vm.activity.from = 2;

                angular.forEach(vm.activity.contactActivities, function (contactActivity, key) {
                    contactActivity.persons = [];

                    // define primary contact
                    if (contactActivity.isPrimary) {
                        vm.primaryContact = {
                            id: null
                        };
                        vm.primaryContact.id = contactActivity.contactId;
                    }

                    // get full info of contact to get person list
                    Contact.get({id: contactActivity.contactId}, function (data) {

                        if (data.isCompany) {
                            angular.forEach(data.company.persons, function (person, key) {
                                contactActivity.persons.push(person);
                            });
                        } else {
                            contactActivity.persons.push(data.person);
                        }
                        personSelected(contactActivity);
                    });
                });

                // set property when no have property in property list
                if (!vm.activity.propertyActivities || isNoHaveProperty()) {
                    createAndPushPrimaryProperty(null);
                }
            }


        }

        function createAndPushPrimaryProperty(prop) {

            // define primary property activity
            vm.primaryPropertyActivity = addPropertyActivityInformation();

            if (prop) {

                vm.primaryPropertyActivity.propertyId = prop.id;
                //vm.primaryPropertyActivity.propertyIdText = property.name;
                vm.primaryPropertyActivity.isPrimary = true;
                vm.primaryPropertyActivity.propertyIdText = prop.propertyId;
                vm.primaryPropertyActivity.developmentId = prop.developmentId;
                vm.primaryPropertyActivity.isClosewon = (prop.availability === PropertyConstant.availability.CLOSED_WON || 
                    prop.availability === PropertyConstant.availability.SOLD_BY_OTHER) ? true : false;

            }

            vm.activity.propertyActivities.unshift(vm.primaryPropertyActivity);
        }

        function createAndPushPrimaryContact(contact) {



            // define primary contact activity
            vm.primaryContactActivity = addContactActivityInformation();

            if (contact) {
                vm.primaryContactActivity.contactId = contact.id;
                vm.primaryContactActivity.contactName = contact.name;
                vm.primaryContactActivity.isPrimary = true;
                vm.primaryContactActivity.contactObject = contact;
                if (contact.isCompany) {
                    vm.primaryContactActivity.persons = contact.company.persons;
                    vm.primaryContactActivity.persons.push()
                } else {
                    vm.primaryContactActivity.persons.push(contact.person);
                }
            }

            vm.activity.contactActivities.unshift(vm.primaryContactActivity);

            updatePrimaryContact();
        }

        function addContactActivity() {
            var contactActivity = addContactActivityInformation();
            vm.activity.contactActivities.push(contactActivity);

            checkForLoadOpportunity(1);
        }

        function onRemoveContactActivity(index) {

            confirmRemove (index, true);

        }

        function removeContactActivity(index) {

            $scope.editForm.$dirty = true;

            vm.activity.contactActivities.splice(index, 1);

            if (index === 0) {

                if (vm.activity.opportunity) {
                    opportunityChange(index);
                }

                // reset and update primary contact when remove primary contact
                vm.primaryContact = vm.activity.contactActivities[index].contactObject;
            }

            checkForLoadOpportunity(1);

        }


        function confirmRemove(index, isContact){
        	if((isContact && vm.activity.contactActivities[index].id) || (!isContact && vm.activity.propertyActivities[index].id)){        		
        		$uibModal.open({
        			templateUrl: 'app/components/confirmation-dialog/delete/confirm-dialog.html',
        			controller: 'DeleteConfirmDialogController',
        			controllerAs: 'vm',
        			size: 'sm',
        			windowClass : 'absolute-center',
        			resolve: {
        				entity: function(){
        					return null;
        				},
        				translateKeys: function(){
        					return {
        						keys: 'indochinaApp.property.confirmDeleteSales'
        					};
        				}
        			}
        		}).result.then(function() {
        			if (isContact) {
        				removeContactActivity (index);
        			} else {
        				removePropertyActivity (index);
        			}
        		}, function() {
        		});
        	}
        	else{
        		if (isContact) {
    				removeContactActivity (index);
    			} else {
    				removePropertyActivity (index);
    			}
        	}
        }

        function addPropertyActivity() {
            var propertyActivity = addPropertyActivityInformation();
            vm.activity.propertyActivities.push(propertyActivity);

            checkForLoadOpportunity(2);
        }

        function onRemovePropertyActivity(index) {
            confirmRemove (index, false);
        }

        function removePropertyActivity(index) {
            $scope.editForm.$dirty = true;
            vm.activity.propertyActivities.splice(index, 1);

            if (index === 0) {
                vm.primaryProperty = vm.activity.propertyActivities[0].propertyObject;
            }

            checkForLoadOpportunity(2);

        }

        function addContactActivityInformation() {
            return {
                contactId: null,
                contactName: null,
                personId: null,
                personName: null,
                phoneId: null,
                phoneValue: null,
                emailId: null,
                emailValue: null,
                isPrimary: false,
                persons: [],
                mobiles: [],
                emails: [],
                contactObject: null
            }
        }

        function addPropertyActivityInformation() {
            return {
                id: null,
                propertyId: null,
                propertyIdText: null,
                propertyName: null,
                isPrimary: null,
                activityId: null,
                propertyObject: null,
                isTemplate: null,
                isClosewon: null
            }
        }


        function personSelected(contactActivity) {

            contactActivity.mobiles = [];
            contactActivity.emails = [];

            angular.forEach(contactActivity.persons, function (person, key) {
                if (person.id === contactActivity.personId) {

                    angular.forEach(person.emails, function (email, key) {
                        contactActivity.emails.push(email);
                    });

                    angular.forEach(person.mobiles, function (mobile, key) {
                        if (mobile && mobile.phone) {
                            contactActivity.mobiles.push(mobile);
                        }
                    });

                    // add office phone into list phone
                    if (person.officePhone != null && person.officePhone.phone) {
                        contactActivity.mobiles.push(person.officePhone);
                    }
                }
            });
        }

        function phoneSelected(contactActivity) {
            // do something
        }

        function emailSelected(contactActivity) {
            // do something
        }

        function contactSelect(contactActivity, contactId, index) {

            if (isCreateFromDashBoard()) {
                checkForLoadOpportunity(1);
            }

            // contactId for contact activity
            contactActivity.contactId = contactId;

            // clear persons list
            contactActivity.persons = [];

            // clear person, phone, email selected
            clearPerson(contactActivity);
            clearPhone(contactActivity);
            clearEmail(contactActivity);

            // get full info of contact to get person list
            Contact.get({id: contactActivity.contactId}, function (data) {

                if (isCreateFromDashBoard() && index === 0) {
                    vm.primaryContact = data;
                }

                if (data.isCompany) {
                    angular.forEach(data.company.persons, function (person, key) {
                        contactActivity.persons.push(person);
                    });
                } else {
                    contactActivity.persons.push(data.person);
                }

                contactActivity.contactObject = data;

            });

            updatePrimaryContact();

            //personSelected( contactActivity );
        }

        function clearPerson(contactActivity) {
            contactActivity.personId = null;
            contactActivity.personName = null;
        }

        function clearPhone(contactActivity) {
            contactActivity.phoneId = null;
            contactActivity.phoneValue = null;
        }

        function clearEmail(contactActivity) {
            contactActivity.emailId = null;
            contactActivity.emailValue = null;
        }

        function updatePrimaryContact() {
            // set primary contact always is the first contact
            if (!isCreateFromContact()) {
                angular.forEach(vm.activity.contactActivities, function (contactActivity, key) {
                    if (key === 0) {
                        contactActivity.isPrimary = true;
                    } else {
                        contactActivity.isPrimary = false;
                    }
                });
            }
        }

        function save() {
            $rootScope.$broadcast('app:resetTimeSession', true);

            
            if (isCannotSaveOrCompleteActivity ()) {
                openErrorPopup ();
                return ;
            }

            clearContactGarbage();

            clearPropertyGarbage();

            if (vm.activity.activityType.name !== ActivityConstant.type.MARKETING_CAMPAIGN) {
                // clear marketing resource
                vm.activity.marketingCampaignChildId = null;
                vm.activity.marketingCampaignChildName = null;
                vm.activity.isMarketingCampaignChildActive = null;
                vm.activity.cost = null;
            }

            if (vm.activity.id !== null) {
                Activity.update(vm.activity, onSaveSuccess, onSaveError);
            } else {
                vm.activity.owner = login;
                Activity.save(vm.activity, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('indochinaApp:activityUpdate', result);
            $scope.editForm.$dirty = false;
            clear();
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


        function clearContactGarbage() {
            var contacts = [];
            angular.forEach(vm.activity.contactActivities, function (contactActivity, key) {
                if (contactActivity.contactId) {
                    contacts.push(contactActivity);
                }
            });

            vm.activity.contactActivities = contacts;

            updatePrimaryContact();
        }

        function clearPropertyGarbage() {
            var properties = [];
            angular.forEach(vm.activity.propertyActivities, function (propertyActivity, key) {
                if (propertyActivity.propertyId) {

                    // clear template property if have another property
                    if (!propertyActivity.isTemplate) {
                        properties.push(propertyActivity);
                    } else {
                        if (vm.activity.propertyActivities == 1) {
                            properties.push(propertyActivity);
                        }
                    }

                }
            });

            vm.activity.propertyActivities = properties;

            // set default property if development don't have any property or user didn't choice a property
            if (vm.haveDevelopment && isNoHaveProperty() && !vm.activity.opportunity) {
                createAndPushPrimaryProperty(propertyTemplate);
            }


        }

        function checkForLoadOpportunity(by) {



            // by is 1 is contact, 2 is property

            if (isCreateFromDashBoard()) {

                if (vm.activity.opportunity && vm.activity.opportunity.id) {
                    return;
                }

                var checkingContact = false;
                var checkingProperty = false;

                angular.forEach(vm.activity.propertyActivities, function (propertyActivity, key) {
                    if (propertyActivity.propertyId) {
                        checkingProperty = true;
                    }

                });


                angular.forEach(vm.activity.contactActivities, function (contactActivity, key) {
                    if (contactActivity.contactId) {
                        checkingContact = true;
                    }

                });

                if (by === 1) {

                    if (!checkingContact && checkingProperty) {
                        vm.contactFirst = false;
                    } else if (!checkingContact && !checkingProperty) {
                        vm.contactFirst = true;
                    } else if (checkingContact && !checkingProperty) {
                        vm.contactFirst = true;
                    } else if (vm.contactFirst && checkingContact && checkingProperty) {
                        vm.contactFirst = true;
                    }

                }


                if (by === 2) {


                    if (!checkingContact && checkingProperty) {
                        vm.contactFirst = false;
                    } else if (!checkingContact && !checkingProperty) {
                        vm.contactFirst = false;
                    } else if (checkingContact && !checkingProperty) {
                        vm.contactFirst = true;
                    } else if (!vm.contactFirst && checkingContact && checkingProperty) {
                        vm.contactFirst = false;
                    }
                }
            }
        }

        function opportunities(name) {

            if (isCreateFromContact() || vm.contactFirst) {
                if (vm.primaryContact && vm.primaryContact.id) {
                    return Opportunity.getByName({name: name, contactId: vm.primaryContact.id}).$promise;
                }

            }

            if (isCreateFromDevelopment() || isCreateFromProperty() || !vm.contactFirst) {
                if (vm.primaryProperty && vm.primaryProperty.id) {
                    return Opportunity.getByPropertyAndAgentInContact({
                        name: name,
                        propertyId: vm.primaryProperty.id
                    }).$promise;
                }
            }
        }

        function propertySelect(propertyActivity, property, index) {

            checkForLoadOpportunity(2);

            propertyActivity.propertyId = property.id;
            propertyActivity.propertyIdText = property.propertyId;
            propertyActivity.propertyName = property.title;
            propertyActivity.developmentId = property.developmentId;
            propertyActivity.isTemplate = property.isTemplate;
            propertyActivity.isClosewon = property.isClosewon;


            propertyActivity.propertyObject = property;

            if (( isCreateFromDevelopment() || isCreateFromDashBoard()  ) && index === 0) {
                vm.primaryProperty = property;
            }
        }

        function opportunitySelect(item) {

            vm.activity.opportunity = item;
            vm.activity.propertyActivities = [];

            // push contact of opp as primary contact
            if (isCreateFromProperty() || ( !vm.contactFirst && !isCreateFromContact() )) {

                vm.activity.contactActivities = [];

                Contact.get({id: item.contactId}, function (data) {
                    createAndPushPrimaryContact(data);
                });


            }


        }

        function opportunityChange(index) {

            // clear and add default property in property list
            if (( isCreateFromProperty() || ( !isCreateFromContact() && !vm.contactFirst ) ) && vm.activity.opportunity.id) {
                // clear property list
                vm.activity.propertyActivities = [];

                // remove first contact primary and if index === 0 so we remove primary contact already
                if (index !== 0) {
                    vm.activity.contactActivities.shift();
                }

                // if contact list empty add primary contact into list
                if (vm.activity.contactActivities.length === 0) {
                    createAndPushPrimaryContact(null);
                }

                // add default primary contact
                createAndPushPrimaryProperty(vm.primaryProperty);

                // update primary contact for the first
                updatePrimaryContact();

            }

            if (isNoHaveProperty()) {
                var property = addPropertyActivityInformation();
                vm.activity.propertyActivities.push(property);

            }

            vm.activity.opportunity = null;

        }

        function contacts(name) {

            var ids = [];

            angular.forEach(vm.activity.contactActivities, function (contactActivity, key) {
                if (contactActivity.contactId) {
                    ids.push(contactActivity.contactId);
                }

            });

            return Contact.getAllContactByName({name: name, ids: ids}).$promise;
        }

        function properties(searchQuery) {

            var ids = [];

            angular.forEach(vm.activity.propertyActivities, function (propertyActivity, key) {
                if (propertyActivity.propertyId) {
                    ids.push(propertyActivity.propertyId);
                }

            });

            var developmentId = null;

            if (vm.haveDevelopment) {
                developmentId = vm.activity.developmentId;
            }

            return PropertyService.getAllPropertiesByNameAndWithoutListIdsForAutoComplete({
                developmentId: developmentId,
                searchQuery: searchQuery,
                ids: ids
            }).$promise;


        }


        function clear() {
            $rootScope.$broadcast('app:resetTimeSession', true);
            onStateChangeStart();
        }

        function complete() {
            $rootScope.$broadcast('app:resetTimeSession', true);


            if (isCannotSaveOrCompleteActivity ()) {
                openErrorPopup ();
                return ;
            }

            var now = getToday();
            $scope.editForm.$dirty = false;
            var isPastActivity = false;
            if (vm.activity.dueDate.getTime() < now.getTime()) {
                vm.activity.closeDate = vm.activity.dueDate;
                isPastActivity = true;
            } else {
                vm.activity.closeDate = new Date();
            }
            vm.activity.status = 'COMPLETE';


            // set development code
            //setDevelopmentCode();


            if (vm.activity.id !== null) {
                Activity.update(vm.activity, function onSaveSuccess(result) {
                    checkingAndCreateFollowUpActivity(result, isPastActivity);
                }, onSaveError);
            } else {
                vm.activity.owner = login;
                Activity.save(vm.activity, function onSaveSuccess(result) {
                    checkingAndCreateFollowUpActivity(result, isPastActivity);
                }, onSaveError);
            }
            clear();

            // now.setDate(now.getDate() - 2);
            // //if( now.getTime() > vm.activity.dueDate.getTime() ) {
            // if (false) { // feedback 21092017 comment out code
            //     // open pop-up
            //     //openErrorPopup();
            // } else {
            //     now = getToday();
            //     $scope.editForm.$dirty = false;
            //     var isPastActivity = false;
            //     if (vm.activity.dueDate.getTime() < now.getTime()) {
            //         vm.activity.closeDate = vm.activity.dueDate;
            //         isPastActivity = true;
            //     } else {
            //         vm.activity.closeDate = new Date();
            //     }
            //     vm.activity.status = 'COMPLETE';


            //     // set development code
            //     //setDevelopmentCode();


            //     if (vm.activity.id !== null) {
            //         Activity.update(vm.activity, function onSaveSuccess(result) {
            //             checkingAndCreateFollowUpActivity(result, isPastActivity);
            //         }, onSaveError);
            //     } else {
            //         vm.activity.owner = login;
            //         Activity.save(vm.activity, function onSaveSuccess(result) {
            //             checkingAndCreateFollowUpActivity(result, isPastActivity);
            //         }, onSaveError);
            //     }
            //     clear();
            // }
        }


        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function typeSelected() { // clear opportunity if activity type is offer or deposit
            if (( vm.activity.activityType.name === ActivityConstant.type.OFFER || vm.activity.activityType.name === ActivityConstant.type.DEPOSIT ) && vm.activity.opportunity) {
                opportunityChange();
            }

            if (vm.activity.activityType.name !== ActivityConstant.type.MARKETING_CAMPAIGN && vm.activity.marketingCampaignChildId) {


                clearMarketingValue ();
            }
        }

        function onStateChangeStart() {
            if ($scope.editForm.$dirty && !$rootScope.isSessionTimeOut) {
                $uibModal.open({
                    templateUrl: 'app/components/confirmation-dialog/leave/confirm-dialog.html',
                    controller: 'LeaveConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass: 'absolute-center',
                    resolve: {
                        entity: function () {
                            return null;
                        },
                        translateKeys: function () {
                            return {
                                keys: 'indochinaApp.property.confirmLeavePage'
                            };
                        }
                    }
                }).result.then(function () {
                    $scope.editForm.$dirty = false;
                    $uibModalInstance.dismiss('cancel');
                }, function () {
//                    $state.go( '^' );
                });
            } else {
                $uibModalInstance.dismiss('cancel');
            }
        }


        function checkingAndCreateFollowUpActivity(result, isPastActivity) {

            var type = result.activityType.name;

            var propertyId = null;
            var contactId = null;

            // reset id for contact activity list
            angular.forEach(result.contactActivities, function (contactActivity, key) {
                contactActivity.id = null;

                // get activity primary to create opportunity when type is offer
                if (key === 0) {
                    contactId = contactActivity.contactId;
                }
            });

            // reset id for property list
            angular.forEach(result.propertyActivities, function (propertyActivity, key) {
                propertyActivity.id = null;

                // get first property to create opportunity when type is offer
                if (key === 0 && !propertyActivity.isTemplate) {
                    propertyId = propertyActivity.propertyId;
                }

            });

            if (isTypeToCreateFollowUpActivity(type)) {
                createFlowUpActivity(result, isPastActivity);
            } else if (isTypeToCreateOpportunity(type)) {
                createOpportunity(propertyId, contactId, result);
            } else {
                $rootScope.$broadcast('indochinaApp:activityUpdate', result);
            }
        }

        function createOpportunity(propertyId, contactId, result) {

            $rootScope.$broadcast('indochinaApp:activityUpdate', result);
            $window.open($state.href('opportunity.new-from-contact-activity', {
                contactId: contactId,
                activityId: result.id,
                propertyId: propertyId
            }), '_blank');
        }

        function createFlowUpActivity(result, isPastActivity) {

            // define due date
            var dueDate = null;
            if (isPastActivity) {
                dueDate = new Date(result.dueDate);
            } else {
                dueDate = new Date();
            }
            dueDate.setDate(dueDate.getDate() + 3);


            var flowUpActivity = {
                title: result.title,
                activityType: vm.allActivityTypes[1],
                owner: login,
                status: 'OPEN',
                isFollowUp: true,
                notes: result.notes,
                activated: result.activated,
                //dueDate: new Date( vm.activity.dueDate.setDate( vm.activity.dueDate.getDate() + 3 ) ),
                dueDate: dueDate,
                closeDate: null,
                id: null,
                opportunity: result.opportunity,
                contactActivities: result.contactActivities,
                propertyActivities: result.propertyActivities
            };

            Activity.update(flowUpActivity, function onSaveSuccess(result) {
                createNewActivity(result);
            }, onSaveError);
        }

        function createNewActivity(activity) {
            var modal = $uibModal.open({
                templateUrl: 'app/entities/activity/activity-dialog.html',
                controller: 'ActivityDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    login: function () {
                        return login;
                    },
                    development: function () {
                        if (vm.haveDevelopment) {
                            return development;
                        }
                        return null;
                    },
                    isFollowUpActivity: function () {
                        return true;
                    },
                    contact: ['Contact', function (Contact) {
                        return Contact.get({id: activity.contactActivities[0].contactId}).$promise;
                    }],
                    property: function () {
                        return null;
                    },
                    entity: function () {
                        return Activity.get({id: activity.id}).$promise;
                    },
                    propertyTemplate: function () {
                        return null;
                    },
                    activityTypeResource: function () {
                        return activityTypeResource;
                    },
                    marketingCampaignResource: ['MarketingCampaign', function (MarketingCampaign) {
                        return MarketingCampaign.query().$promise;
                    }]
                }
            });

            modal.result.then(function () {
                $state.go('activity', null, {reload: 'activity'});
            }, function () {

            });
        }

        function openErrorPopup() {
            $uibModal.open({
                templateUrl: 'app/entities/activity/error-dialog.html',
                controller: 'ErrorDialogController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center',
                resolve: {
                    entity: function () {
                        return null;
                    },
                    translateKeys: function () {
                        return {
                            keys: 'indochinaApp.activity.errorSaveOrComplete'
                        };
                    }
                }
            }).result.then(function () {

            }, function () {
//                $state.go( '^' );
            });
        }

        function getToday() {
            var date = new Date();
            date.setHours(0);
            date.setMilliseconds(0);
            date.setMinutes(0);
            date.setSeconds(0);
            return date;

        }


        // clear text property name
        function onChangeProperty(propertyActivity) {

            propertyActivity.propertyId = null;
            checkForLoadOpportunity(2);
        }

        function onChangeContact(contactActivity, index) {
            contactActivity.contactId = null;
            checkForLoadOpportunity(1);


            if (index === 0) {
                if (vm.activity.opportunity) {
                    opportunityChange();
                }

                vm.primaryContact = null;

            }

        }

        function marketingChange() {
            angular.forEach(vm.marketingCampaignChilds, function (marketingCampaign, key) {
                if (vm.activity.marketingCampaignChildId === marketingCampaign.id) {
                    vm.activity.isMarketingCampaignChildActive = marketingCampaign.activated;
                }
            });
        }

        function marketingParentChange () {
            angular.forEach(vm.marketingCampaignParents, function (marketingCampaign, key) {
                
                if (vm.activity.marketingCampaignParentId === marketingCampaign.id) {
                    vm.activity.isMarketingCampaignParentActive = marketingCampaign.activated;
                    vm.marketingCampaignChilds = marketingCampaign.childs;
                }
            });

            vm.activity.marketingCampaignChildId = null;
            vm.activity.marketingCampaignChildName = null;
            vm.activity.isMarketingCampaignChildActive = null;

            
        }

        function checkingPropertyNotBelongToDevelopment(propertyActivity) {
            if (vm.haveDevelopment) {

                if (!propertyActivity.id) {
                    return false;
                } else {
                    if (propertyActivity.developmentId && propertyActivity.developmentId !== vm.activity.developmentId) {
                        return true;
                    }
                    return false;
                }
            }
            return false;

        }

        function checkingIsPrimaryProperty(index) {
            if (isCreateFromProperty() && index === 0) {
                return true;
            }
            return false;
        }

        function checkingContactRequire() {
            var i = 0;
            angular.forEach(vm.activity.contactActivities, function (contactActivity, key) {
                if (contactActivity.contactId) {
                    i++;
                }
            });
            if (i >= 1) {
                return false;
            }
            return true;
        }


        function checkingDisableRemoveContact(index, contactActivity) {
            if (( vm.activity.id && index === 0 ) || ( contactActivity.isPrimary && isCreateFromContact() )
                || ( index === 0 && vm.activity.contactActivities.length === 1 )
                || ( isCreateFromDashBoard() && vm.activity.opportunity && !vm.contactFirst && contactActivity.isPrimary )
                || ( isCreateFromDevelopment() && vm.activity.opportunity && contactActivity.isPrimary )) {
                return true;
            }
            return false;
        }

        function isNoHaveProperty() {
            if (vm.activity.propertyActivities.length === 0) {
                return true;
            }
            return false;
        }

        function isDisableOpportunity() {
            if (( vm.activity.activityType && ( vm.activity.activityType.name === ActivityConstant.type.OFFER
                    || vm.activity.activityType.name === ActivityConstant.type.DEPOSIT )) || isManyContact() || isManyProperty()) {
                return true;
            }
            return false;
        }

        function isManyContact() {
            if (vm.activity.contactActivities.length > 1) {
                return true;
            }
            return false;
        }

        function isManyProperty() {
            if (vm.activity.propertyActivities.length > 1) {
                return true;
            }
        }

        function isHaveOpportunity() {
            if (vm.activity.opportunity) {
                return true;
            }
            return false;
        }

        function isCreateFromDashBoard() {
            if (vm.activity.from === ActivityConstant.from.DASHBOARD) {
                return true;
            }
            return false;
        }

        function isCreateFromDevelopment() {
            if (vm.activity.from === ActivityConstant.from.DEVELOPMENT) {
                return true;
            }
            return false;
        }

        function isCreateFromContact() {
            if (vm.activity.from === ActivityConstant.from.CONTACT) {
                return true;
            }
            return false;
        }

        function isCreateFromProperty() {
            if (vm.activity.from === ActivityConstant.from.PROPERTY) {
                return true;
            }
            return false;
        }

        function isMarketingType() {
            if (vm.activity.activityType && vm.activity.activityType.name === ActivityConstant.type.MARKETING_CAMPAIGN) {
                return true;
            }
            return false;
        }

        function isMarketingRemove() {
            if (!vm.activity.marketingCampaignChildId) {
                return false;
            } else {
                if (vm.activity.isMarketingCampaignChildActive) {
                    return false;
                }
                return true;
            }
        }

        function isTypeToCreateFollowUpActivity(type) {
            if (type === ActivityConstant.type.EMAIL || type === ActivityConstant.type.TELEPHONE_CALL || type === ActivityConstant.type.PROPERTY_INFORMATION
                || type === ActivityConstant.type.VIEWING || type === ActivityConstant.type.MEETING || type === ActivityConstant.type.INTRODUCTION) {
                return true;
            }
            return false;
        }

        function isTypeToCreateOpportunity(type) {
            if (type === ActivityConstant.type.OFFER || type === ActivityConstant.type.DEPOSIT) {
                return true;
            }
            return false;
        }


        /* if activity have property with stage is close_won or sold_by_other we can't save or complete (An additional request from Client on Q424)*/
        function isCannotSaveOrCompleteActivity () {
            if(vm.activity.activityType && isTypeToCreateOpportunity(vm.activity.activityType.name)) {
                if(!_.isEmpty(vm.activity.propertyActivities) && !vm.activity.propertyActivities[0].propertyId) return false;
                return vm.activity.propertyActivities[0].isClosewon;
            }
            return false;
        }

        function clearMarketingValue () {
            // clear child marketing resource
            vm.activity.marketingCampaignParentId = null;
            vm.activity.marketingCampaignParentName = null;
            vm.activity.isMarketingCampaignParentActive = null;

            // clear child marketing resource
            vm.activity.marketingCampaignChildId = null;
            vm.activity.marketingCampaignChildName = null;
            vm.activity.isMarketingCampaignChildActive = null;
            vm.activity.cost = null;

            vm.marketingCampaignChilds = [];
        }

        $timeout(function () {
            angular.element('.form-group:eq( 1 )>input').focus();
        });
    }
})();
