(function() {
    'use strict';
    
    angular
    .module('indochinaApp')
    .directive('currencyNoFraction', ['$filter', function ($filter) {
        return {
            require: '?ngModel',
            link: function (scope, elem, attrs, ctrl) {
            	var min = attrs.currencyMin || 1;
            	var max = attrs.currencyMax || 9999999999999;
            	var format = attrs.currencyFormat || 'number';
            	var required = attrs.currencyRequired || false;
            	
                if (!ctrl) return;
                
                elem.on("keypress", function(e) {
                	if(!isAcceptKey(e)){
                		e.preventDefault();                		
                	}
                	
                	var plainValue = null;
                	if(elem[0].value != null){
                		plainValue = elem[0].value.replace(/[^\d]/g, '');
                	}
                	
                	if(plainValue.length >= max.toString().length && !isSelecting(elem)){
               			e.preventDefault();
                	}
                });

                // $formatters is called when data is changed by controller
                ctrl.$formatters.unshift(function (modelValue) {
                	ctrl.$setValidity('required', true);
                	
                	if((modelValue == null || modelValue == '') && required){
                		ctrl.$setValidity('required', false);
                	}
                	
                    return $filter(format)(ctrl.$modelValue)
                });
                
                // $parsers is called when data is changed by view
                ctrl.$parsers.unshift(function (viewValue) {
                	var plainNumber = null;                	
                	
                	ctrl.$setValidity('required', true);
                	ctrl.$setValidity('currencyMin', true);
                	ctrl.$setValidity('currencyMax', true);
                	
                	if(viewValue != null){
                		plainNumber = viewValue.replace(/[^\d]/g, '');
                	}
                    
                    if(viewValue == null || plainNumber == ''){
                    	ctrl.$setViewValue(null); // view value
                    	ctrl.$render(); // call controller render again
                    	
                    	if(required){
                    		ctrl.$setValidity('required', false);
                    	}
                    	
//                    	elem.val(null); // view value
                    	return null;// model value
                    }
                    else{
                    	if(plainNumber < min){
                    		ctrl.$setValidity('currencyMin', false);
                    	}
                    	if(plainNumber > max){
                    		ctrl.$setValidity('currencyMax', false);
                    	}
                    	
                    	elem.val($filter(format)(plainNumber));
                    	return plainNumber;
                    }
                });
                
                function isAcceptKey(e){
                	// [0-9]
            		if(e.which >= 48 && e.which <= 67){
                		return true;
                	}
            		
            		// [a-z]
            		if(e.which >= 97 && e.which <= 122){
                		return false;
                	}
            		
            		// [ !"#$%&'()*+,-./]
            		if(e.which >= 32 && e.which <= 37){
                		return false;
                	}
            		
            		if(e.which >= 91 && e.which <= 96){
                		return false;
                	}
            		
            		if(e.which == 123 || e.which == 125 || e.which == 126){
                		return false;
                	}
            		
            		// we should change here
            		// it have some error on firefox
            		return true;
                }
                
                function isSelecting(elem){
                	return elem[0].selectionStart != elem[0].selectionEnd;
                }
            }
        };
    }]);
})();
