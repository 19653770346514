(function() {
	'use strict';

	angular
		.module('indochinaApp')
		.constant('ReportConstants', (function() {
			return {
				DefaultReportTypes : [
					{type: 0, description: 'quotaReport', state: 'reports.quota-report'},
		            {type: 2, description: 'pipelineReport', state: 'reports.pipeline-report'},
		            {type: 9, description: 'portalsReport', state: 'reports.portals-report'},
		            {type: 10, description: 'leadReport', state: 'reports.lead-report'},
				],
				SuperUserReportTypes : [
					{type: 5, description: 'marketingReport', state: 'reports.marketing-report'},
		            {type: 6, description: 'salesSummary', state: 'reports.sales-summary'},
		            {type: 7, description: 'salesByOffice', state: 'reports.sales-by-office'},
		            {type: 8, description: 'leasingReport', state: 'reports.leasing-report'}
				],
				ReportTypes : {
					QuotaReport: 0,
					PipelineReport: 2,
					MarketingReport: 5,
					SalesSummary: 6,
					SalesByOffice: 7,
					LeasingReport: 8,
					PortalsReport: 9,
					LeadReport: 10
				}
			}
		})());
})();
