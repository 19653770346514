(function() {
    'use strict';
    angular
        .module('indochinaApp')
        .factory('ExchangeRates', ExchangeRates);

    ExchangeRates.$inject = ['$resource'];

    function ExchangeRates ($resource) {
        var resourceUrl =  'api/exchange-rates/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getByUSD': {
            	url: 'api/exchange-rates/get-by-usd',
            	method: 'GET', 
            	isArray: true
            }
        });
    }
})();
