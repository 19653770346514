(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('DevelopmentPropertyTypeDeleteController',DevelopmentPropertyTypeDeleteController);

    DevelopmentPropertyTypeDeleteController.$inject = ['$uibModalInstance', 'entity', 'DevelopmentPropertyType'];

    function DevelopmentPropertyTypeDeleteController($uibModalInstance, entity, DevelopmentPropertyType) {
        var vm = this;

        vm.developmentPropertyType = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            DevelopmentPropertyType.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
