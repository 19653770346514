(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'AuthorityConstant'];

    function stateConfig($stateProvider, AuthorityConstant) {
        $stateProvider
        .state('marketing-campaign-management', {
            parent: 'admin',
            url: '/marketing-campaign-management',
            data: {
                authorities: [AuthorityConstant.ROLE_SUPER_USER],
                pageTitle: 'indochinaApp.marketingCampaign.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/marketing-campaign/marketing-campaign-management.html',
                    controller: 'MarketingCampaignManagementController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('lead-source-management');
                    $translatePartialLoader.addPart('marketingCampaign');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
