(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('dotProperty', {
        	//parent: 'account',
            url: '/dotProperty',
            data: {
            	authorities: [],
            },
        	views: {
                'content@': {
                    templateUrl: 'app/portal/dot-property.html',
                    controller: 'DotProperty',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
