(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('DashboardSectionTeamActivitySummaryController', Controller);

    Controller.$inject = ['$scope', '$timeout', 'Principal', '$state', 'Activity', 'ParseLinks', 'AlertService', 'paginationConstants', '$uibModal', 'DateUtils'];

    function Controller ($scope, $timeout, Principal, $state, Activity, ParseLinks, AlertService, paginationConstants, $uibModal, DateUtils) {
        var vm = this;
        
        vm.itemsPerPage = 10;
        vm.page = 1;
        
        vm.results = [];
        
        vm.search = search;
        vm.loadMore = loadMore;
        vm.isShowLoadMoreButton = isShowLoadMoreButton;
        vm.openFromCalendar = openFromCalendar;
        vm.openToCalendar = openToCalendar;
        vm.validateDatepicker = validateDatepicker;
        
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.fromDate = false;
        vm.datePickerOpenStatus.toDate = false
        
        vm.datePickerOption = {
    		showWeeks: false,
            startingDay: 1,
            readonlyInput: true,
            dateformat: 'MM/dd/yyyy'
        }
        
        initData();

        function initData() {
        	var now = new Date();
        	var quarter = Math.floor(now.getMonth() / 3) + 1;
			var fromDate = null;
			var toDate = null;
			if(quarter === 1) {
				fromDate = new Date(now.getFullYear(), 0, 1);
				toDate = new Date(now.getFullYear(), 2, 31);
			} else if (quarter === 2) {
				fromDate = new Date(now.getFullYear(), 3, 1);
				toDate = new Date(now.getFullYear(), 5, 31);
			} else if (quarter === 3) {
				fromDate = new Date(now.getFullYear(), 6, 1);
				toDate = new Date(now.getFullYear(), 8, 30);
			} else {
				fromDate = new Date(now.getFullYear(), 9, 1);
				toDate = new Date(now.getFullYear(), 11, 31);
			}
			vm.fromDate = fromDate;
			vm.toDate = toDate;
			
	        loadAll();
        }

        function loadAll () {
        	var fromDate = DateUtils.convertLocalDateToServer(vm.fromDate);
        	var toDate = DateUtils.convertLocalDateToServer(vm.toDate);
        	Activity.getForDashboardTeamActivitySummary({
        		fromDate: fromDate,
        		toDate: toDate,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
        	}, onSaveSuccess, onSaveError);
        	
        	function sort() {
                return ['id'];
            }
        	
        	function onSaveSuccess(data, headers) {
        		customizeUI();
        		
        		if(data.tree == null){
        			vm.results = [];
        			vm.totalRow = null;
        			return;
        		}
        		
        		vm.numberRowMax = vm.page * vm.itemsPerPage;
        		vm.numberRowCurrent = vm.results.length;
        		vm.numberRowIndex = 0;
        		
	        	vm.totalItems = data.totalEmployees;
	            vm.queryCount = vm.totalItems;
	            
        		formatData(data.tree.employees, data.list, 0);
        		
        		// get total row
        		if(data.total == null || data.total.length <= 0){
        			vm.totalRow = null;
        		}
        		else{
                	vm.totalRow = {};
                	
                	data.total.forEach(function(i){
	                	vm.totalRow[i.type] = i.numRow;
                	});
                }
            }
            
            function onSaveError() {
            	
            }

            function formatData(listEmployee, listDTO, padding){
            	listEmployee.forEach(function(e){
            		var obj = {
            			id: e.manager.id,
            			fullName: e.manager.fullName,
            			padding: padding
            		};
            		
            		listDTO.forEach(function(dto){
            			if(e.manager.id == dto.agentId){
            				obj[dto.type] = dto.numRow;
            			}
            		});
            		
            		if(vm.numberRowIndex < vm.numberRowMax){
            			if(vm.numberRowIndex >= vm.numberRowCurrent){            				
            				vm.results.push(obj);
            			}
            			vm.numberRowIndex += 1;
            			formatData(e.employees, listDTO, padding + 20);
            		}
            		
            	});
            }
        }

        function search(){
            vm.page = 1;
            vm.results = [];
        	loadAll();
        }
        
        function loadMore(){
        	vm.page = vm.page + 1;
        	loadAll();
        }
        
        function isShowLoadMoreButton(){
        	return parseFloat(vm.totalItems) > parseFloat(vm.results.length);
        }
        
        function openFromCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        
        function openToCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        
        function validateDatepicker(){
        	vm.invalidDatepicker = false;
        	if(vm.fromDate != null && vm.toDate != null){
        		if(vm.fromDate > vm.toDate){
        			vm.invalidDatepicker = true;
        		}
        	}
        }
        
        function customizeUI(){
        	customize();
        	
        	$timeout(function (){
        		customize();
        	}, 1);
        	$timeout(function (){
        		customize();
        	}, 3);
        	$timeout(function (){
        		customize();
        	}, 5);
        	
        	function customize(){        		
        		var jsScrollBar = $('.jsScrollBar');
        		
        		// don't show scroll bar
        		if(parseFloat(jsScrollBar.get(0).scrollHeight) <= parseFloat(jsScrollBar.get(0).clientHeight)){
        			$('.jsTableHead').attr('style','margin-bottom: 0; border-radius: 4px 4px 0 0;');
        			$('.jsTableBody').attr('style','width: 77px;');
        		}
        		else{
        			$('.jsTableHead').attr('style','padding-right: 17px; margin-bottom: 0; margin-right: 0; border-radius: 4px 4px 0 0;');
        			$('.jsTableBody').attr('style','width: 65px;');
        		}
        	}
        }
    }
})();
