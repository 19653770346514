(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$timeout', 'Auth', 'Principal', '$localStorage','Idle'];

    function LoginController ($rootScope, $state, $timeout, Auth, Principal, $localStorage, Idle) {
        var vm = this;

        vm.authenticationError = false;
//        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.register = register;

        vm.requestResetPassword = requestResetPassword;

        if($localStorage.username) {
        	vm.username = $localStorage.username;
        } else {
        	vm.username = null;
        }

        if($localStorage.password) {
        	vm.password = $localStorage.password;
        } else {
        	vm.password = null;
        }
        if($localStorage.rememberMe == true) {
        	vm.rememberMe = $localStorage.rememberMe
        } else {
        	vm.rememberMe = false;
        }
        $timeout(function (){angular.element('#username').focus();});

//        function cancel () {
//            vm.credentials = {
//                username: null,
//                password: null,
//                rememberMe: true
//            };
//            vm.authenticationError = false;
//            $uibModalInstance.dismiss('cancel');
//        }

        function login (event) {
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                if(vm.rememberMe) {
                	$localStorage.username = vm.username;
                	$localStorage.password = vm.password;
                	$localStorage.rememberMe = true;
                } else {
                	$localStorage.username = null;
                	$localStorage.password = null;
                	$localStorage.rememberMe = false;
                }
                Idle.watch();
//                $uibModalInstance.close();
//                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
//                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
//                    $state.go('home');
//                }

                $rootScope.$broadcast('authenticationSuccess');
            	Principal.identity().then(function(account) {
                    if(account !== null){
                    	if (Auth.getPreviousState()) {
            				Auth.resetPreviousState();
            			}

                        // redirect to opportunity link if user click from email
                    	if($rootScope.toOppState) {
                            $state.go($rootScope.toOppState.name, {id: $rootScope.toOppStateParams.id});
                            return;
                        }

                    	switch(account.authorities[0]){
                    		case 'ROLE_SUPER_USER':
//                    			$state.go('super-user-dashboard');
//                    			break;
                    		case 'ROLE_MANAGER':
                    		case 'ROLE_AGENT':
                    			$state.go('dashboard.home');
                    			break;
                    		case 'ROLE_CO_AGENT':
                    			$state.go('property');
                    			break;
                    		case 'ROLE_ADMIN':
                    			$state.go('user-management');
                    			break;
                			default:
                				$state.go('home');
                    	}
                    }
                });


                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is successful, go to stored previousState and clear previousState
//                if (Auth.getPreviousState()) {
//                    var previousState = Auth.getPreviousState();
//                    Auth.resetPreviousState();
//                    $state.go(previousState.name, previousState.params);
//                }
            }).catch(function () {
                vm.authenticationError = true;
            });
        }

        function register () {
//            $uibModalInstance.dismiss('cancel');
            $state.go('register');
        }

        function requestResetPassword () {
//            $uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }
    }
})();
