(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .constant('ContactConstant', (function() {
            return {
            	type: {
                    LIST: ['SELLER', 'BUYER', 'OTHER'],
                    SELLER: 'SELLER',
                    BUYER: 'BUYER',
                    OTHER: 'OTHER'
                },
            	status: {
                    LIST: ['COLD', 'WARM', 'HOT', 'SPAM'],
                    COLD: 'COLD',
                    WARM: 'WARM',
                    HOT: 'HOT',
                    SPAM: 'SPAM'
                },
                documentType: {
                	LIST: ['EOI', 'DEPOSIT_AGREEMENT', 'PURCHASE_AGREEMENT', 'HANDOVER_MINUTES', 
                		'LOAN_AGREEMENT', 'OWNERSHIP_CERTIFICATE', 'UNSPECIFIED'],
                	EOI: 'EOI',
                	DEPOSIT_AGREEMENT: 'DEPOSIT_AGREEMENT',
                	PURCHASE_AGREEMENT: 'PURCHASE_AGREEMENT',
                	HANDOVER_MINUTES: 'HANDOVER_MINUTES',
                	LOAN_AGREEMENT: 'LOAN_AGREEMENT',
                	OWNERSHIP_CERTIFICATE: 'OWNERSHIP_CERTIFICATE',
                	UNSPECIFIED: 'UNSPECIFIED'                		
                }
            }
        })());
})();
