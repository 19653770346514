(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['$rootScope', '$state', '$stateParams', '$uibModal', '$uibModalInstance', '$window', 'entity', 'User', 'JhiLanguageService', 'ManagerService', 'AuthorityConstant', 'CurrencyConstant',
                                              'Principal', 'CoAgentTypeConstant', '$timeout', '$scope', 'CoAgentCompanyMasterData', 'Office', 'Auth', 'CompanyNameMasterData'];

    function UserManagementDialogController ($rootScope, $state, $stateParams, $uibModal, $uibModalInstance, $window, entity, User, JhiLanguageService, ManagerService, AuthorityConstant, CurrencyConstant,
    										Principal, CoAgentTypeConstant, $timeout, $scope, CoAgentCompanyMasterData, Office, Auth, CompanyNameMasterData) {
        var vm = this;

        vm.user = entity;

        vm.role = null;
        vm.languages = null;
        vm.isShowManagerField = false;
        vm.authorities = AuthorityConstant.ROLE_LIST;
        vm.coAgentTypes = CoAgentTypeConstant.LIST;
        vm.currencies = CurrencyConstant.LIST;
        vm.preferredUnits = ['IMPERIAL', 'METRIC'];

        vm.listManagerAndSuperUser = [];
        vm.managers = {selected: [], list: []};

        vm.clear = clear;
        vm.save = save;
        vm.changeRole = changeRole;
        vm.changeOffice = changeOffice;
        vm.onSelectManager = onSelectManager;
        vm.onRemoveManager = onRemoveManager;
        vm.isCoAgentTypeChange = isCoAgentTypeChange;
        vm.onSelectCompanyCorporate = onSelectCompanyCorporate;
        vm.loadMasterDataForCoAgentCompanyCorporate = loadMasterDataForCoAgentCompanyCorporate;
        vm.onSelectCompanyIndividual = onSelectCompanyIndividual;
        vm.loadMasterDataForCoAgentCompanyIndividual = loadMasterDataForCoAgentCompanyIndividual;

        // session is time out so we must close all modals
        $rootScope.$on('modal:close', function(event, result) {
            clear();
        });

        // only super user role can change role of user
        vm.disableChangeRole = false;
        if(vm.user.id != null){
            // only super user role can change role of user
        	Principal.hasAuthority(AuthorityConstant.ROLE_SUPER_USER).then(function (result) {
        		if (!result) {
        			vm.disableChangeRole = true;
        		}
        	});
        }
        else{
        	// admin can not create new Super User account
        	Principal.hasAuthority(AuthorityConstant.ROLE_ADMIN).then(function (result) {
        		if (result) {
        			vm.authorities = [AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT, AuthorityConstant.ROLE_CO_AGENT, AuthorityConstant.ROLE_ADMIN];
        		}
        	});

        	// manager only create a agent account
        	Principal.hasAuthority(AuthorityConstant.ROLE_MANAGER).then(function (result) {
        		if (result) {
        			vm.authorities = [AuthorityConstant.ROLE_AGENT, AuthorityConstant.ROLE_CO_AGENT];
        		}
        	});
        }

        initData();

        function initData(){
        	ManagerService.getListManagerSuperUser({},function(result){
        		vm.listManagerAndSuperUser = result;
        		refreshManagerField(false);
        	});
        	
        	JhiLanguageService.getAll().then(function (languages) {
                vm.languages = languages;
            });

        	// get office
        	Office.query({}, function(data){
        		vm.offices = data;
        	});

        	// re-set role
            if(entity != null && entity.authorities != null){
            	vm.role = entity.authorities[0];
            }

        	if ((vm.role === AuthorityConstant.ROLE_MANAGER || vm.role === AuthorityConstant.ROLE_AGENT) && entity.id) {
        		ManagerService.getManagersById({id: entity.id}, function(result) {
        			vm.managers.selected = result;
        			refreshManagerField(false);
                });
        	}
        	else{
        		refreshManagerField(false);
        	}

        	// set default value for CoAgentType when create new co-agent account
        	if(vm.user.coAgentType == null || vm.user.coAgentType == ''){
        		vm.user.coAgentType = CoAgentTypeConstant.INDIVIDUAL;
        	}

        	changeRole();
        }

        function changeRole(){
        	vm.isCoAgentRole = (vm.role == AuthorityConstant.ROLE_CO_AGENT);
        	vm.isShowOfficeField = (vm.role != AuthorityConstant.ROLE_CO_AGENT && vm.role != AuthorityConstant.ROLE_ADMIN)

        	vm.isShowManagerField = false;
        	if(vm.role == AuthorityConstant.ROLE_AGENT || vm.role === AuthorityConstant.ROLE_MANAGER){
        		vm.isShowManagerField = true;
        		
        		refreshManagerField(true);
        		enhanceUISelect();
        	}
        }

        function changeOffice(){
        	if(vm.role == AuthorityConstant.ROLE_AGENT){        		
        		refreshManagerField(true);
        	}
        	else{
        		refreshManagerField(false);
        	}
        }

        function onSelectManager($item, $model){
        	if(vm.user.office == null && vm.role == AuthorityConstant.ROLE_AGENT){
        		vm.user.office = $item.office;
        		refreshManagerField(false);
        	}
        }

        function onRemoveManager($item, $model){
        	refreshManagerField(false);
        }
        
        function refreshManagerField(isRemoveManager){
        	if(isRemoveManager){
        		vm.managers.selected = [];
        	}
        	
        	if(vm.role == AuthorityConstant.ROLE_AGENT){
        		var officeId = null;
            	if(vm.user != null && vm.user.office != null && vm.user.office.id != null){
            		officeId = vm.user.office.id;
            	}
        		
            	ManagerService.getAllManagerByOfficeId({officeId: officeId}, function(result) {
            		if(vm.managers.selected == []){
            			vm.managers.list = result;
            		}
            		else{
            			vm.managers.list = [];
            			result.forEach(function(user){
            				if(!isContent(vm.managers.selected, user)){
            					vm.managers.list.push(user);
            				}
            			});
            		}
            	});
        	}
        	else if(vm.role === AuthorityConstant.ROLE_MANAGER){
        		if(vm.managers.selected == []){
        			vm.managers.list = angular.copy(vm.listManagerAndSuperUser);
        		}
        		else{
        			vm.managers.list = [];
        			vm.listManagerAndSuperUser.forEach(function(user){
        				if(!isContent(vm.managers.selected, user)){
        					vm.managers.list.push(user);
        				}
        			});
        		}
        	}
        }
        
        function isContent(listManagerSelected, user){
    		if(vm.user != null && vm.user.id != null && vm.user.id == user.id){
    			// don't add current user
    			return true;
    		}
    		
    		if(listManagerSelected == null || listManagerSelected == []){
    			return false;
    		}
    		
    		return listManagerSelected.some(function(selected){
    			if(selected.managerId != null){
    				return selected.managerId == user.id;
    			}
    			else{
    				return selected.id == user.id;
    			}
    		});
    	}

        function clear () {
            $rootScope.$broadcast('app:resetTimeSession', true);
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
//            $uibModalInstance.close(result);
            Principal.identity(true).then(function(account) {
            	if(account == null || account == ''){
            		Auth.logout();

            		$uibModal.open({
                        templateUrl: 'app/components/confirmation-dialog/inform/confirm-dialog.html',
                        controller: 'InformConfirmDialogController',
                        controllerAs: 'vm',
                        size: 'sm',
                        windowClass : 'absolute-center',
                        resolve: {
                            entity: null,
                            translateKeys: function(){
                            	return {
                            		keys: 'userManagement.confirmAfterChangeEmail'
                            	};
                            }
                        }
                    }).result.then(function() {
                    	$uibModalInstance.dismiss('stayHere');
                    	$window.open($state.href('logout'),'_self');
                    }, function() {
                    	$uibModalInstance.dismiss('stayHere');
                    	$window.open($state.href('logout'),'_self');
                    });
            	}
            	else{
            		$uibModalInstance.close(result);
            	}

            });
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {

            $rootScope.$broadcast('app:resetTimeSession', true);

        	vm.isSaving = true;

        	vm.user.authorities = [vm.role];

        	if (vm.role === AuthorityConstant.ROLE_AGENT || vm.role === AuthorityConstant.ROLE_MANAGER) {
        		vm.user.managers = vm.managers.selected
        	}

            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }

//            if(vm.user.companyName == null || vm.user.companyName == '') {
//            	vm.user.companyId = null;
//            }
        }
        
        function loadMasterDataForCoAgentCompanyCorporate(name){
        	vm.user.companyCorporateId = null;
        	return CompanyNameMasterData.searchByNameForAutoComplete({name:name}).$promise;
        }

        function onSelectCompanyCorporate(item, model, label){
        	vm.user.companyCorporateId = item.id;
        }

        function loadMasterDataForCoAgentCompanyIndividual(name){
        	vm.user.companyIndividualId = null;
        	return CoAgentCompanyMasterData.searchByName({name:name}).$promise;
        }

        function onSelectCompanyIndividual(item, model, label){
        	vm.user.companyIndividualId = item.id;
        }

        function enhanceUISelect(){
        	$timeout(function (){
	        	$('#manager div:first').on('click', function(e) {
					if (e.target === this){
						$('#manager div:first input').click();
					}
	    		});
        	}, 1000);
        }

        function isCoAgentTypeChange(currCoAgentType) {
        	vm.user.companyId = null;
        	vm.user.companyName = null;
        }
    }
})();
