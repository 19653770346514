(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'AuthorityConstant'];

    function stateConfig($stateProvider, AuthorityConstant) {
        $stateProvider
        .state('lead-source-management', {
            parent: 'admin',
            url: '/lead-source-management',
            data: {
                authorities: [AuthorityConstant.ROLE_SUPER_USER],
                pageTitle: 'leadSourceManagement.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/lead-source-management/lead-source-management.html',
                    controller: 'LeadSourceManagementController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('lead-source-management');
                    return $translate.refresh();
                }]
            }
        }).state('lead-source-management.new', {
            url: '/new',
            data: {
            	authorities: [AuthorityConstant.ROLE_SUPER_USER]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/lead-source-management/lead-source-management-dialog.html',
                    controller: 'LeadSourceManagementDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                id: null, name: null, activated: true, createdDate: null,
                                lastModifiedBy: null, parentId: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('lead-source-management', null, { reload: true });
                }, function() {
                    $state.go('lead-source-management');
                });
            }]
        }).state('lead-source-management.edit', {
            url: '/{id}/edit',
            data: {
            	authorities: [AuthorityConstant.ROLE_SUPER_USER]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/lead-source-management/lead-source-management-dialog.html',
                    controller: 'LeadSourceManagementDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: ['LeadSourceManagementService', function(LeadSourceManagementService) {
                            return LeadSourceManagementService.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('lead-source-management', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
