(function() {
    'use strict';
    angular
        .module('indochinaApp')
        .factory('Opportunity', Opportunity);

    Opportunity.$inject = ['$resource', 'DateUtils'];

    function Opportunity ($resource, DateUtils) {
        var resourceUrl =  'api/opportunities/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.closeDate = DateUtils.convertLocalDateFromServer(data.closeDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.closeDate = DateUtils.convertLocalDateToServer(copy.closeDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.closeDate = DateUtils.convertLocalDateToServer(copy.closeDate);
                    return angular.toJson(copy);
                }
            },
            'exportCSVOpportunities': {
                method:'GET',
                url:'api/opportunities/export-csv',
           	 	headers : {accept : 'text/csv'},
                cache : false,
                transformResponse : function(data) {
                    return {
                        response : new Blob([data], {type : 'text/csv;charset=utf-8;'})
                    };
                }
             },
            'getByName': {
            	url: 'api/opportunities/getByName/:name/:contactId',
            	method: 'GET', 
            	isArray: true
            },
            'getByPersonId': {
            	url: 'api/opportunities/choice/:id',
                method: 'GET',
                transformResponse: function (data) {
                	if (data) {
                        data = angular.fromJson(data);
                    }
                    return angular.toJson(data);
                }
            },
            'deletePersonWhenCompanyChange': {
            	url: 'api/opportunities/update-when-contact-change/:id',
                method: 'GET'
            },
            'findOneByIdAndActivatedTrue': {
            	url: 'api/opportunities/find-one-by-id-and-activated-true',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.closeDate = DateUtils.convertLocalDateFromServer(data.closeDate);
                    }
                    return data;
                }
            },
            'getAllOpenOpportunitiesByContactAndProperty': {
            	url: 'api/opportunities/get-all-open-opportunities-by-contact-and-property',
            	method: 'GET', 
            	isArray: true
            },
            'getAllOpportunitiesForDashboard': {
            	url: 'api/opportunities/get-all-opportunity-for-dashboard',
            	method: 'GET', 
            	isArray: true
            },
            'getByPropertyAndAgentInContact': {
            	url: 'api/opportunities/get-all-opportunity-for-property-agent-contact',
            	method: 'GET', 
            	isArray: true
            }
        });
    }
})();
