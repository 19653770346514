(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('ActivitiesTabSection2PropertyController', ActivitiesTabSection2PropertyController);

    ActivitiesTabSection2PropertyController.$inject = ['$scope', '$state', '$parse', '$rootScope', '$stateParams', 'previousState', 'entity', 'Activity', 'ParseLinks', '$uibModal'];

    function ActivitiesTabSection2PropertyController($scope, $state, $parse, $rootScope, $stateParams, previousState, entity, Activity, ParseLinks, $uibModal) {

        var vm = this;
        vm.property = entity;

        var pagingParams = $state.pagingParamsHistorical;

        vm.loadPage = loadPage;
        //vm.createNewActivity = createNewActivity;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 5;

        vm.activityClick = activityClick;
        if($state.isEdit) {
        	vm.isEdit = $state.isEdit;
        }
        loadAll();

        $rootScope.$on('indochinaApp:activityUpdate', function(event, result) {
        	transition();
        });

        $rootScope.$on('contact:changeMode', function(event, result) {
            vm.isEdit = result;
        });

        $rootScope.$on('activity-property:search', function(event, fromDate, toDate) {
            vm.fromDate = fromDate;
            vm.toDate = toDate;
            vm.page = 0;
            transition();
        });

        function loadAll () {
        	Activity.getActivityByProperty({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                status: 'COMPLETE',
                propertyId: vm.property.id,
                fromDate: vm.fromDate,
                toDate:vm.toDate
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if(vm.predicate === 'dueDate') {
                	result.push('closeDate' + ',' + (vm.reverse ? 'asc' : 'desc'));
                }
                if(vm.predicate === 'person') {
                	result = [];
                	result.push('primanyPerson.firstName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                	result.push('primanyPerson.lastName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                }
                if(vm.predicate === 'owner') {
                	result = [];
                	result.push('owner.firstName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                	result.push('owner.lastName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                }
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                //vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.activities = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                //AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
        	$state.pagingParamsHistorical.page = vm.page;
        	$state.pagingParamsHistorical.predicate = vm.predicate;
        	$state.pagingParamsHistorical.ascending = vm.reverse;
        	$state.pagingParamsHistorical.sort = vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc');
        	loadAll();
        }

        function activityClick (activity) {
        	$uibModal.open({
        		templateUrl: 'app/entities/activity/activity-dialog-view.html',
                controller: 'ActivityDialogViewController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return Activity.get({id : activity.id}).$promise;
                    },
                    development: function () {
                        return null;
                    },
                    pagingParams: ['PaginationUtil', function (PaginationUtil) {
                        var sort = 'id';
                        return {
                            page: PaginationUtil.parsePage(1),
                            sort: sort,
                            predicate: PaginationUtil.parsePredicate(sort),
                            ascending: PaginationUtil.parseAscending(sort),
                            search: null
                        };
                    }]
                }
            }).result.then(function() {
                $state.go('activity', null, { reload: 'activity' });
            }, function() {

            });
        }
    }
})();
