(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('ReportsController', ReportsController);

    ReportsController.$inject = [ '$scope', '$filter', '$state', '$rootScope', 'Principal', 'AuthorityConstant', 'User', 'OpportunityConstant',
			'Office', 'PropertyService', 'ReportConstants', '$stateParams', 'DevelopmentService', 'ReportService', 'FileSaver' ];

    function ReportsController($scope, $filter, $state, $rootScope, Principal, AuthorityConstant, User, OpportunityConstant, Office,
			PropertyService, ReportConstants, $stateParams, DevelopmentService, ReportService, FileSaver) {
        var vm = this;
        vm.editForm = {};

        // Date configuration
        vm.fromDatePickerOption = {
    		showWeeks: false,
    		startingDay: 1,
            readonlyInput: true,
            dateFormat: 'MM/dd/yyyy',
            maxDate: null
        }
        vm.toDatePickerOption = {
            showWeeks: false,
            startingDay: 1,
            readonlyInput: true,
            dateFormat: 'MM/dd/yyyy',
            minDate: null
        }
        vm.reportSelector = {
        		reportType: null,
    	        agentsSelected: [],
    	        stagesSelected: [],
    	        developmentsSelected: [],
    	        propertiesSelected: [],
    	        officeSelected: [],
    	        fromDate: null,
    	        toDate: null,
    	        hasError: false
        };
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.fromDate = false;
        vm.openFromCalendar = openFromCalendar;
        vm.reportTypeChange = reportTypeChange;
        vm.validateDatepicker = validateDatepicker;
        vm.invalidMinMaxDate = false;
        
        // data sources
        vm.agents = [];
        vm.isDisabledAgentField = false;
		vm.isDisabledFromDateField = false;
		vm.isDisabledToDateField = false;
		vm.isRequiredFromDateField = true;
		vm.isRequiredToDateField = true;
        vm.currentAccount = null;
        vm.stages = OpportunityConstant.STAGE_MULTI_SELECTOR;
        vm.developments = [];
        vm.properties = [];
        vm.offices = [];
        
        // toggle selector
        vm.isDisplayDevelopment = isDisplayDevelopment;
        vm.isDisplayProperty = isDisplayProperty;
        vm.isDisplayOffice = isDisplayOffice;
        
        vm.exportReport = exportReport;
        
        init();

        function init() {
        	// init default report types
            vm.reportTypes = ReportConstants.DefaultReportTypes;
            
            Principal.identity().then(function(account) {
            	vm.currentAccount = account;
            	vm.currentAccount.fullName = vm.currentAccount.firstName + ' ' + vm.currentAccount.lastName;
            	
            	if (account.authorities[0] === AuthorityConstant.ROLE_AGENT) {
            		vm.reportSelector.agentsSelected.push(vm.currentAccount);
                	vm.isDisabledAgentField = true;
                } else if (account.authorities[0] === AuthorityConstant.ROLE_SUPER_USER) {
                	vm.reportTypes = vm.reportTypes.concat(ReportConstants.SuperUserReportTypes);
                }
            	
            	initSelectorDataSource();
            });
        }

        function openFromCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function reportTypeChange() {
        	initSelectorDataSource();
        }
        
        function isDisplayDevelopment() {
        	return vm.reportSelector.reportType && vm.reportSelector.reportType.type === 5; // Marketing Report
        }
        
        function isDisplayProperty() {
        	return vm.reportSelector.reportType && vm.reportSelector.reportType.type === 5; // Marketing Report
        }
        
        function isDisplayOffice() {
        	return vm.reportSelector.reportType && vm.reportSelector.reportType.type === 7; // Sales by Office report
        }
        
        function initAgents() {
        	if (vm.currentAccount && vm.agents.length <= 0) {
        		// return current user if login role is agent
                if (vm.currentAccount.authorities[0] === AuthorityConstant.ROLE_AGENT) {
                	vm.agents.push(vm.currentAccount);
                }
                
                // return list agent and current user if login role is MANAGER
                if (vm.currentAccount.authorities[0] === AuthorityConstant.ROLE_MANAGER) {
                    return User.getAgentListByManagerId({id: vm.currentAccount.id}, function (result) {
                    	vm.agents = result;
                    	vm.agents.push(vm.currentAccount);
                    });
                }
                // return list all users if login role is ADMIN or SUPER USER account
                if (vm.currentAccount.authorities[0] === AuthorityConstant.ROLE_SUPER_USER 
                		|| vm.currentAccount.authorities[0] === AuthorityConstant.ROLE_ADMIN) {
                	vm.agents = User.getAllAgentManagerAdminSuperUserList();
                }
        	}
        }
        
        function initOffices() {
        	if (vm.offices.length <= 0) {
        		Office.query({}, function(offices) {
            		vm.offices = offices;
            	});
        	}
        }
        
        function initDevelopments() {
        	if (vm.developments.length <= 0) {
        		DevelopmentService.getAllByDevelopmentNameForAutoComplete(function(developments) {
        			vm.developments = developments;
        		});
        	}
        }
        
        function initProperties() {
        	if (vm.properties.length <= 0) {
        		PropertyService.getAllForReportSelector(function (properties) {
            		vm.properties = properties;
            	});
        	}
        }
        
        function initSelectorDataSource() {
            vm.isDisabledAgentField = false;
    		vm.isDisabledFromDateField = false;
    		vm.isDisabledToDateField = false;
    		vm.isRequiredFromDateField = true;
    		vm.isRequiredToDateField = true;

        	if (vm.currentAccount.authorities[0] === AuthorityConstant.ROLE_AGENT) {
            	vm.isDisabledAgentField = true;
            }
        	
        	initAgents();
        	if (vm.isDisplayDevelopment()) initDevelopments();
        	if (vm.isDisplayProperty()) initProperties();
        	if (vm.isDisplayOffice()) initOffices();
        	
        	if (vm.reportSelector.reportType){
        		if (vm.reportSelector.reportType.type === ReportConstants.ReportTypes.PortalsReport){
        			vm.isDisabledAgentField = true;
        			vm.isDisabledFromDateField = true;
        			vm.isDisabledToDateField = true;
        			vm.isRequiredFromDateField = false;
        			vm.isRequiredToDateField = false;
        		}
        	}
        	
        }
        
        function validateDatepicker() {
        	if (!vm.reportSelector.fromDate || !vm.reportSelector.toDate) {
        		vm.editForm.from_date.$setValidity("minMax", true);
        		vm.reportSelector.hasError = true;
        		return;
        	}
        	
        	
        	var invalidMinMaxDate = false;
        	if (vm.reportSelector.fromDate && vm.reportSelector.toDate) {
        		invalidMinMaxDate = vm.reportSelector.fromDate > vm.reportSelector.toDate
        	}
        	vm.editForm.from_date.$setValidity("minMax", !invalidMinMaxDate);
        }
        
        function exportReport() {
        	if (vm.reportSelector && vm.reportSelector.reportType) {
    			// prepare selector's params to export
        		vm.reportSelector.listAgentId = [];
        		if (vm.reportSelector.agentsSelected && vm.reportSelector.agentsSelected.length > 0) {
        			vm.reportSelector.listAgentId = vm.reportSelector.agentsSelected.map(function(agent) {
        				return agent.id;
        			});
        		} else {
        			// if agent are not selected, then get all agents of logged-in user
        			if (vm.agents) {
        				vm.reportSelector.listAgentId = vm.agents.map(function(agent) {
            				return agent.id;
            			});
        			}
        		}
        		
        		vm.reportSelector.officeIds = [];
        		if (vm.reportSelector.officeSelected) {
        			vm.reportSelector.officeIds = vm.reportSelector.officeSelected.map(function(office) {
        				return office.id;
        			});
        		}
    			
    			if (vm.reportSelector.reportType.type === 0) { // quotaReport
    	        	ReportService.exportQuotaCSV(vm.reportSelector, function (result) {
    	                FileSaver.saveAs(result.response, buildFileName('QuotaReport'));
    	        	}, function (err) {
    	        		
    	        	});
    			} else if (vm.reportSelector.reportType.type === 2) { // pipelineReport
            		ReportService.exportPipelineCSV(vm.reportSelector, function (result) {
                        FileSaver.saveAs(result.response, buildFileName('PipelineReport'));
                	}, function (err) {
                		
                	});
    			} else if (vm.reportSelector.reportType.type === 5) { // marketingReport
                    ReportService.marketingReportCSV(vm.reportSelector, function (result) {
                        FileSaver.saveAs(result.response, buildFileName('MarketingReport'));
                    }, function (err) {
                        // do something
                    });
    			} else if (vm.reportSelector.reportType.type === 6) { // salesSummary
    	        	ReportService.exportSalesSummaryCSV(vm.reportSelector, function (result) {
    	                FileSaver.saveAs(result.response, buildFileName('SalesSummaryReport'));
    	        	}, function (err) {
    	        		
    	        	});
    			} else if (vm.reportSelector.reportType.type === 7) { // salesByOffice
            		ReportService.exportSalesByOfficeCSV(vm.reportSelector, function (result) {
                        FileSaver.saveAs(result.response, buildFileName('SalesByOfficeReport'));
                	}, function (err) {
                		
                	});
    			} else if (vm.reportSelector.reportType.type === 8) { // leasingReport
    				ReportService.exportLeasingCSV(vm.reportSelector, function (result) {
                        FileSaver.saveAs(result.response, buildFileName('LeasingReport'));
                	}, function (err) {
                		
                	});
    			} else if (vm.reportSelector.reportType.type === ReportConstants.ReportTypes.PortalsReport) {
    				var copy = angular.copy(vm.reportSelector);
    				copy.fromDate = new Date();
    				copy.toDate = null;
    				var filename = 'PortalsReport_' + $filter('date')(copy.fromDate, 'MMddyyyy') + '.csv';
    				
    				ReportService.exportPortalsCSV(copy, function (result) {
                        FileSaver.saveAs(result.response, filename);
                	}, function (err) {
                		
                	});
    			} else if (vm.reportSelector.reportType.type === ReportConstants.ReportTypes.LeadReport) {
    				ReportService.exportLeadCSV(vm.reportSelector, function (result) {
                        FileSaver.saveAs(result.response, buildFileName('LeadReport'));
                	}, function (err) {
                		
                	});
    			}
        	}
        }
        
        function buildFileName(reportName){
    		var datedTitle = "";
    		if (vm.reportSelector.fromDate) {
    			datedTitle = $filter('date')(vm.reportSelector.fromDate, 'MMddyyyy');
            }
    		if (vm.reportSelector.fromDate && vm.reportSelector.toDate) {
    			datedTitle = datedTitle + "-";
    		}
    		if (vm.reportSelector.toDate) {
    			datedTitle = datedTitle + $filter('date')(vm.reportSelector.toDate, 'MMddyyyy');
            }
    		if(datedTitle != ""){
    			datedTitle = "_" + datedTitle;
    		}
    		
    		return reportName + datedTitle + ".csv";
        }
    }
})();
