(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'AuthorityConstant'];

    function stateConfig($stateProvider, AuthorityConstant) {
        $stateProvider
        .state('contact', {
            parent: 'entity',
            url: '/contact?page&sort&search',
            data: {
                authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_ADMIN, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT],
                pageTitle: 'indochinaApp.contact.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contact/contacts.html',
                    controller: 'ContactController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'name,asc',
                    squash: true
                },
                search: {
//                	value: '{"activated":1,"agent":-1}',
                	value: 'U2FsdGVkX1+sYhCt/1KXIb9DxJF58njQirysnbT5/5DaEHcqNMUB9hea4W+s+cFa',
                    squash: true
                },
                pageForActivity: {
                    value: '1',
                    squash: true
                },
                sortForActivity: {
                    value: 'dueDate,desc',
                    squash: true
                },
                pageForActivityHistorical: {
                    value: '1',
                    squash: true
                },
                sortForActivityHistorical: {
                    value: 'dueDate,desc',
                    squash: true
                },
//                searchForOpportunity: {
//                	value: {},
//                    squash: true
//                },
//                pageForOpportunityActive: {
//                    value: '1',
//                    squash: true
//                },
//                sortForOpportunityActive: {
//                    value: 'closeDate,desc',
//                    squash: true
//                },
//                pageForOpportunityHistorical: {
//                    value: '1',
//                    squash: true
//                },
//                sortForOpportunityHistorical: {
//                    value: 'closeDate,desc',
//                    squash: true
//                }
                searchForOpportunity: {
                    value: {agent: -1, status: 'active'},
                    squash: true
                },
                pageForOpportunity: {
                    value: '1',
                    squash: true
                },
                sortForOpportunity: {
                    value: 'name,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('contactPreferences');
                    $translatePartialLoader.addPart('contact');
                    $translatePartialLoader.addPart('company');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('person');
                    $translatePartialLoader.addPart('bankAccount');
                    $translatePartialLoader.addPart('property');
                    $translatePartialLoader.addPart('personalDetails');
                    $translatePartialLoader.addPart('child');
                    $translatePartialLoader.addPart('activity');
                    $translatePartialLoader.addPart('otherContactType');
                    $translatePartialLoader.addPart('constructionStatus');
                    $translatePartialLoader.addPart('unitType');
                    $translatePartialLoader.addPart('contactLegal');
                    return $translate.refresh();
                }]
            }
        })
        .state('contact-detail', {
            parent: 'contact',
            url: '/{id}',
            data: {
                authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_ADMIN, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT],
                pageTitle: 'indochinaApp.contact.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contact/contact-detail/contact-detail.html',
                    controller: 'ContactDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                searchActivity: [function (){
                    return {
                        searchQuery: null,
                        status: 'OPEN',
                        propertyIds: [],
                        agentIds: [],
                        agentObjectList: [],
                        contactId: null
                    }
                }],
//                searchOpportunity: [function (){
//                    return {
//                        searchQuery: null,
//                        status: 1,
//                        agents: []
//                    }
//                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('contact');
                    return $translate.refresh();
                }],
                entity: ['$q', '$state', '$stateParams', 'Contact', function($q, $state, $stateParams, Contact) {
//                    return Contact.get({id : $stateParams.id}).$promise;
                	var deferred = $q.defer();
                	Contact.getContactByEditingRole({id : $stateParams.id}, function(entity){
                		deferred.resolve(entity);
                	}, function(){
                		$state.go('accessdenied');
                	});

                	return deferred.promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'contact',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }],
                pagingParamsForActivity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.pageForActivity),
                        sort: $stateParams.sortForActivity,
                        predicate: PaginationUtil.parsePredicate($stateParams.sortForActivity),
                        ascending: PaginationUtil.parseAscending($stateParams.sortForActivity),
                        search: $stateParams.search
                    };
                }],
                pagingParamsForActivityHistorical: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.pageForActivityHistorical),
                        sort: $stateParams.sortForActivityHistorical,
                        predicate: PaginationUtil.parsePredicate($stateParams.sortForActivityHistorical),
                        ascending: PaginationUtil.parseAscending($stateParams.sortForActivityHistorical)
                    };
                }],
//                pagingParamsForOpportunityActive: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
//                    return {
//                        page: PaginationUtil.parsePage($stateParams.pageForOpportunityActive),
//                        sort: $stateParams.sortForOpportunityActive,
//                        predicate: PaginationUtil.parsePredicate($stateParams.sortForOpportunityActive),
//                        ascending: PaginationUtil.parseAscending($stateParams.sortForOpportunityActive),
//                        search: $stateParams.searchForOpportunity
//                    };
//                }],
//                pagingParamsForOpportunityHistorical: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
//                    return {
//                        page: PaginationUtil.parsePage($stateParams.pageForOpportunityHistorical),
//                        sort: $stateParams.sortForOpportunityHistorical,
//                        predicate: PaginationUtil.parsePredicate($stateParams.sortForOpportunityHistorical),
//                        ascending: PaginationUtil.parseAscending($stateParams.sortForOpportunityHistorical)
//                    };
//                }]
	            pagingParamsForOpportunity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
	                return {
	                    page: PaginationUtil.parsePage($stateParams.pageForOpportunity),
	                    sort: $stateParams.sortForOpportunity,
	                    predicate: PaginationUtil.parsePredicate($stateParams.sortForOpportunity),
	                    ascending: PaginationUtil.parseAscending($stateParams.sortForOpportunity),
	                    search: $stateParams.searchForOpportunity
	                };
	            }]
            }
        })
        .state('contact-detail.edit', {
            parent: 'contact-detail',
            url: '/detail/edit',
            data: {
                authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_ADMIN, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contact/contact-dialog.html',
                    controller: 'ContactDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Contact', function(Contact) {
                            return Contact.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('contact.new', {
        	parent: 'contact',
            url: '/new',
            data: {
                authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_ADMIN, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT]
            },
        	views: {
                'content@': {
                    templateUrl: 'app/entities/contact/contact-detail/contact-detail.html',
                    controller: 'ContactDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            	pagingParamsForActivity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.pageForActivity),
                        sort: $stateParams.sortForActivity,
                        predicate: PaginationUtil.parsePredicate($stateParams.sortForActivity),
                        ascending: PaginationUtil.parseAscending($stateParams.sortForActivity),
                        search: $stateParams.search
                    };
                }],
                pagingParamsForActivityHistorical: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.pageForActivityHistorical),
                        sort: $stateParams.sortForActivityHistorical,
                        predicate: PaginationUtil.parsePredicate($stateParams.sortForActivityHistorical),
                        ascending: PaginationUtil.parseAscending($stateParams.sortForActivityHistorical)
                    };
                }],
//                pagingParamsForOpportunityActive: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
//                    return {
//                        page: PaginationUtil.parsePage($stateParams.pageForOpportunityActive),
//                        sort: $stateParams.sortForOpportunityActive,
//                        predicate: PaginationUtil.parsePredicate($stateParams.sortForOpportunityActive),
//                        ascending: PaginationUtil.parseAscending($stateParams.sortForOpportunityActive),
//                        search: $stateParams.searchForOpportunity
//                    };
//                }],
//                pagingParamsForOpportunityHistorical: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
//                    return {
//                        page: PaginationUtil.parsePage($stateParams.pageForOpportunityHistorical),
//                        sort: $stateParams.sortForOpportunityHistorical,
//                        predicate: PaginationUtil.parsePredicate($stateParams.sortForOpportunityHistorical),
//                        ascending: PaginationUtil.parseAscending($stateParams.sortForOpportunityHistorical)
//                    };
//                }],
                pagingParamsForOpportunity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.pageForOpportunity),
                        sort: $stateParams.sortForOpportunity,
                        predicate: PaginationUtil.parsePredicate($stateParams.sortForOpportunity),
                        ascending: PaginationUtil.parseAscending($stateParams.sortForOpportunity),
                        search: $stateParams.searchForOpportunity
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('contact');
                    return $translate.refresh();
                }],
                entity: function () {
                    return {
                        parentSource: null,
                        childSource: null,
                        person: {
                            personalDetails: null,
                            salutation: null,
                            firstName: null,
                            lastName: null,
                            company: null,
                            otherPosition: null,
                            position: 'none-select',
                            email: null,
                            mobile: null,
                            officePhone: null,
                            nationality: null,
                            vietKieu: null,
                            id: null
                        },
                        company: {
                            bankAccount: null,
                            persons: [],
                            name: null,
                            website: null,
                            phone: null,
                            streetNumber: null,
                            streetName: null,
                            city: null,
                            province: null,
                            country: null,
                            activated: null,
                            createDate: null,
                            modifiedDate: null,
                            taxCode: null,
                            registryNumber: null,
                            id: null
                        },
                        contactPreferences: [],
                        /*contactPreferences: {
                            currency: null,
                            minimumPrice: null,
                            maximumPrice: null,
                            bedRoomNumber: null,
                            bathRoomNumber: null,
                            propertyTranscations: [],
                            countries: [],
                            propertytypes: [],
                            propertyLocations: [],
                            internalRooms: [],
                            outsideAreas: [],
                            facilitiesOffereds: [],
                            nearbyFacilities: [],
                            parkingFacilities: [],
                            locations:[]
                        },*/
                        contactBies: null,
                        agent: null,
                        contactTypes: [],
                        isCompany: false,
                        contactBy: null,
                        status: 'none-select',
                        vip: false,
                        preferredContactMethod: null,
                        chatUsernameLifechat: null,
                        chatUsernameFacebook: null,
                        chatUsernameWechat: null,
                        chatUsernameWhatapp: null,
                        chatUsernameViber: null,
                        chatUsernameSkype: null,
                        mailingStreet: null,
                        mailingCity: null,
                        mailingCountry: null,
                        mailingProvince:null,
                        mailingStreetName: null,
                        mailingStreetNumber:null,
                        notes: null,
                        createdDate: null,
                        lastModifiedDate: null,
                        lastModifiedBy: null,
                        activated: true,
                        id: null
                    };
                },
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'contact',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]

            }

        })
        .state('contact.edit', {
            parent: 'contact',
            url: '/{id}/edit',
            data: {
                authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_ADMIN, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contact/contact-dialog.html',
                    controller: 'ContactDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Contact', function(Contact) {
                            return Contact.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('contact', null, { reload: 'contact' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('contact.delete', {
            parent: 'contact',
            url: '/{id}/delete',
            data: {
                authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_ADMIN, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contact/contact-delete-dialog.html',
                    controller: 'ContactDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Contact', function(Contact) {
                            return Contact.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('contact', null, { reload: 'contact' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
