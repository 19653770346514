(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('ActivitiesTabSection1DevelopmentController', ActivitiesTabSection1DevelopmentController);

    ActivitiesTabSection1DevelopmentController.$inject = ['$scope', '$state', '$parse', '$rootScope', '$stateParams', 'previousState', 'entity', 'Activity', 'ParseLinks', '$uibModal', 'login'];

    function ActivitiesTabSection1DevelopmentController($scope, $state, $parse, $rootScope, $stateParams, previousState, entity, Activity, ParseLinks, $uibModal, login) {

        var vm = this;
        /*vm.property = entity;

        var pagingParams = $state.pagingParams;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 5;

        vm.activityClick = activityClick;
        if($state.isEdit) {
        	vm.isEdit = $state.isEdit;
        }
        loadAll();

        $rootScope.$on('indochinaApp:activityUpdate', function(event, result) {
        	transition();
        });

        $rootScope.$on('property:changeMode', function(event, result) {
            vm.isEdit = result;
        });

        function loadAll () {
        	Activity.getActivityByProperty({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                status: 'OPEN',
                propertyId: vm.property.id
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if(vm.predicate === 'person') {
                	result = [];
                	result.push('primanyPerson.firstName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                	result.push('primanyPerson.lastName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                }
                if(vm.predicate === 'owner') {
                	result = [];
                	result.push('owner.firstName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                	result.push('owner.lastName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                }
                if (vm.predicate !== 'id') {
                    result.push('id' + ',' + (vm.reverse ? 'asc' : 'desc'));
                }
                return result;
            }
            function onSuccess(data, headers) {
                //vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.activities = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                //AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
        	$state.pagingParams.page = vm.page;
        	$state.pagingParams.predicate = vm.predicate;
        	$state.pagingParams.ascending = vm.reverse;
        	$state.pagingParams.sort = vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc');
        	loadAll();
        }

        function activityClick (activity) {
        	var templateUrl = null;
        	var controller = null;
        	$uibModal.open({
                templateUrl: 'app/entities/activity/activity-dialog.html',
                controller: 'ActivityDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                	login: login,
                	isFollowUpActivity: function () {
                		return false;
                	},
                	contact: function () {
                		return vm.contact;
                	},
                    entity: function () {
                        return Activity.get({id : activity.id}).$promise;
                    }
                }
            }).result.then(function() {
                $state.go('activity', null, { reload: 'activity' });
            }, function() {

            });
        }*/
    }
})();
