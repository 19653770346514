(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('PropertyDetailController', PropertyDetailController);

    PropertyDetailController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'previousState', 'entity' , 'MeasurementConversionService',
                                        'PropertyLocationService', 'InternalRoomsService', 'OutsideAreaService', 'FacilitiesOfferedService', 'AccessRoadService',
                                        'ParkingFacilitiesService', 'PhotoService', 'JhiCurrencyService', 'User', 'PublishService', 'NearbyFacilitiesService', '$uibModal',
                                        'Principal','propertySearch','PropertyConstant', 'PropertyLegal', 'PropertySales'];

    function PropertyDetailController($scope, $state, $rootScope, $stateParams, previousState, entity, MCService,
            PropertyLocationService, InternalRoomsService, OutsideAreaService, FacilitiesOfferedService, AccessRoadService,
            ParkingFacilitiesService, PhotoService, JhiCurrencyService, User, PublishService, NearbyFacilitiesService, $uibModal,
            Principal, propertySearch, PropertyConstant, PropertyLegal, PropertySales) {
        var vm = this;

        // show and hidden tabs
        vm.variableTab = true;
        vm.photoTab = false;
        vm.publishTab = false;
        vm.legalTab = false;
        vm.salesTab = false;
        vm.activeTab = activeTab;

        vm.property = entity;
        vm.previousState = previousState.name;
        vm.previousStateParams = previousState.params;
        vm.previousPropertySearchObj = previousState.propertySearchObj;
        vm.previousPropertySearchObj.page = vm.previousPropertySearchObj.page + 1;
        
        vm.selectedPropertyLocations = [];
        vm.selectedInternalRooms = [];
        vm.selectedOutsideAreas = [];
        vm.selectedFacilitiesOffereds = [];
        vm.selectedAccessRoads = [];
        vm.selectedParkingFacilities = [];
        vm.selectedNearbyFacilities = [];
        
        vm.currencyCode = JhiCurrencyService.currencyCode;
        vm.currencyRate = JhiCurrencyService.rate;
        vm.deleteProperty = deleteProperty;
        vm.viewPhoto = viewPhoto;
        
        
        loadAll();
        
        // for publish tab
        vm.sites = null;
        PublishService.query(vm.searchObject, onSuccess, onError);
        
        function onSuccess(data, headers) {
        	vm.sites = data;
        }
        
        function onError(error) {
            //AlertService.error(error.data.message);
        }
        
        $scope.checkHaveInList = function (site){
        	for(var i = 0; i < vm.property.sites.length; i++) {
        		var s = vm.property.sites[i];
        		if(s.id == site.id) {
        			return true;
        		}
        	}
        	return false;
        }
        
        // for checking permission for edit
        var login = null;
        vm.isCheckPermission = isCheckPermission;
        Principal.identity().then(function(account) {
        	
        	login = account;
        	
        	if(account.authorities[0] === 'ROLE_MANAGER') {
        		User.getAgentListByManagerId({id: account.id}, function(result) {
        			vm.allAgentOfManager = result;
                });
        	}
        });
        
        
        function loadAll(){
        	User.getUserById(vm.property.modifiedBy).then(function(result){
        		vm.modifiedUser = result.data;
        	});
        	
        	PhotoService.query({propertyId: vm.property.id}, function(result) {
                vm.photos = result;
            });
        	
            PropertyLocationService.query(function(result) {
                if(result != null){
                    _.each(result, function(propertyLocation) {
                        _.each(vm.property.currentPropertyLocations, function(currentPropertyLocation) {
                            if(currentPropertyLocation.value == 1 && propertyLocation.id == currentPropertyLocation.propertyLocationId){
                                propertyLocation.checked = true;
                            }
                        });
                    });
                    vm.selectedPropertyLocations = result;
                }
            });
            
            InternalRoomsService.query(function(result) {
                if(result != null){
                    _.each(result, function(internalRoom) {
                        _.each(vm.property.currentInternalRooms, function(currentInternalRoom) {
                            if(currentInternalRoom.value == 1 && internalRoom.id == currentInternalRoom.internalRoomsId){
                            	internalRoom.checked = true;
                            }
                        });
                    });
                    
                    vm.selectedInternalRooms = result;
                }
            });
            
            OutsideAreaService.query(function(result) {
                if(result != null){
                    _.each(result, function(outsideArea) {
                        _.each(vm.property.currentOutsideAreas, function(currentOutsideArea) {
                            if(currentOutsideArea.value == 1 && outsideArea.id == currentOutsideArea.outsideAreaId){
                                outsideArea.checked = true;
                            }
                        });
                    });
                    vm.selectedOutsideAreas = result;
                }
            });
            
            FacilitiesOfferedService.query(function(result) {
                if(result != null){
                    _.each(result, function(facilitiesOffered) {
                        _.each(vm.property.currentFacilitiesOffereds, function(currentFacilitiesOffered) {
                            if(currentFacilitiesOffered.value == 1 && facilitiesOffered.id == currentFacilitiesOffered.facilitiesOfferedId){
                                facilitiesOffered.checked = true;
                            }
                        });
                    });
                    vm.selectedFacilitiesOffereds = result;
                }
            });
            
            AccessRoadService.query(function(result) {
                if(result != null){
                    _.each(result, function(accessRoad) {
                        _.each(vm.property.currentAccessRoads, function(currentAccessRoad) {
                            if(currentAccessRoad.value == 1 && accessRoad.id == currentAccessRoad.accessRoadId){
                            	accessRoad.checked = true;
                            }
                          });
                      });
                    vm.selectedAccessRoads = result;
                }
            });
            
            ParkingFacilitiesService.query(function(result) {
                if(result != null){
                    _.each(result, function(parkingFacility) {
                        _.each(vm.property.currentParkingFacilities, function(currentParkingFacility) {
                            if(parkingFacility.id == currentParkingFacility.parkingFacilitiesId){
                                parkingFacility.checked = true;
                            }
                        });
                    });
                    vm.selectedParkingFacilities = result;
                }
            });
            
            NearbyFacilitiesService.query(function(result) {
                if(result != null){
                    _.each(result, function(nearbyFacilities) {
                        _.each(vm.property.currentNearbyFacilities, function(currentNearbyFacility) {
                            if(currentNearbyFacility.value == 1 && nearbyFacilities.id == currentNearbyFacility.nearbyFacilitiesId){
                            	nearbyFacilities.checked = true;
                            }
                        });
                    });
                    vm.selectedNearbyFacilities = result;
                }
            });
            
            // converter
            if(vm.property.size != null){
            	vm.sizeConverter = MCService.init(vm.property.sizeUnit);
            }
            if(vm.property.lotSize != null){
            	vm.lotConverter = MCService.init(vm.property.lotSizeUnit);
            }
            if(vm.property.accessRoadWidth != null){
            	vm.roadConverter = MCService.init(vm.property.accessRoadWidthUnit);
            }
            
            if($stateParams.activeTab != null && $stateParams.activeTab == 'photo'){
            	activeTab('p');
            }
            
            if($stateParams.activeTab != null && $stateParams.activeTab == 'publish') {
            	activeTab('pl');
            }
            
            if($stateParams.activeTab != null && $stateParams.activeTab == 'legal') {
            	activeTab('l');
            }
            
            if($stateParams.activeTab != null && $stateParams.activeTab == 'sales') {
            	activeTab('s');
            }
        }
        
        function activeTab(tab){
        	vm.variableTab = false;
            vm.photoTab = false;
            vm.publishTab = false;
            vm.legalTab = false;
            vm.salesTab = false;
            
            if(tab == 'v'){
            	vm.variableTab = true;
            }
            else if(tab == 'p'){
            	vm.photoTab = true;
            }
            else if(tab == 'pl'){
            	vm.publishTab = true;
            }
            else if(tab == 'l'){
            	vm.legalTab = true;
            }
            else if(tab == 's'){
            	vm.salesTab = true;
            }
        }
        
        var unsubscribe = $rootScope.$on('indochinaApp:propertyUpdate', function(event, result) {
            vm.property = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
        function deleteProperty(id) {
        	if (id) {
        		$uibModal.open({
                    templateUrl: 'app/entities/property/property-delete-dialog.html',
                    controller: 'PropertyDeleteController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                    	entity: ['$stateParams', 'PropertyService', function($stateParams, PropertyService) {
                            return PropertyService.get({id : id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('property', null);
                }, function() {
                    //$state.go('^');
                });
        	}
        }
        
        function viewPhoto(photo) {
        	if (photo.id) {
        		$uibModal.open({
                    templateUrl: 'app/entities/property/property-editor/photo/partial/photo-view-dialog.html',
                    controller: 'PhotoViewController',
                    controllerAs: 'vm',
                    windowClass : 'absolute-center',
                    resolve: {
                    	photoView: ['$stateParams', function($stateParams) {
                            return photo;
                        }]
                    }
                }).result.then(function() {
                    //$state.go('property', null);
                }, function() {
                    //$state.go('^');
                });
        	}
        }
        
        function isCheckPermission() {
        	
        	if(login.authorities[0] === 'ROLE_ADMIN' || login.authorities[0] === 'ROLE_SUPER_USER') {
        		return false;
        	}
        	
        	if(vm.allAgentOfManager) {
        		for(var i = 0; i < vm.allAgentOfManager.length; i++) {
        			if(vm.property.userDTO && vm.allAgentOfManager[i].id === vm.property.userDTO.id) {
        				return false;
        			}
        		}
        	}
        	
        	if(vm.property.userDTO && login.id === vm.property.userDTO.id) {
        		return false;
        	}
        	
        	return true;
        }
        
        $scope.$on("curreny:change", function(event, item){
        	var price = Math.ceil(item.valueOfUsdExchangeRate * PropertyConstant.price.MAX_SEARCH_PRICE);
        	
        	propertySearch.valueOfUsdExchangeRate = item.valueOfUsdExchangeRate;
        	
        	propertySearch.maxPrice = price;
        	
        	propertySearch.stepRange = item.valueOfUsdExchangeRate * 100;
        	
        	propertySearch.destinationCode = item.destinationCode;
        });
        
        /******************************************************************************************
         * 
         *     Legal tab
         * 
         ******************************************************************************************/
        // for both Legal and Sales tab
        vm.downloadFile = $rootScope.downloadFile;
        
        vm.legal = {
    		predicate: 'fileName',
    		reverse: 'asc',
    		page: 1,
    		itemsPerPage: 20,      
        	loadPage: loadPageLegal,
        	transition: transitionLegal
        }
        
        loadAllLegal ();
        
        function loadAllLegal () {
            PropertyLegal.query({
            	propertyId: vm.property.id,
                page: vm.legal.page - 1,
                size: vm.legal.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [];
                
                if(vm.legal.predicate == 'createdBy'){
                	result.push('u.firstName,' + (vm.legal.reverse ? 'asc' : 'desc'));
                	result.push('u.lastName,' + (vm.legal.reverse ? 'asc' : 'desc'));
                }
                else if(vm.legal.predicate == 'fileName'){
                	result.push('f.filename,' + (vm.legal.reverse ? 'asc' : 'desc'));
                }
                else if(vm.legal.predicate == 'createdDate'){
                	result.push('f.created,' + (vm.legal.reverse ? 'asc' : 'desc'));
                }
                
                if (vm.legal.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
//                vm.legal.links = ParseLinks.parse(headers('link'));
                vm.legal.totalItems = headers('X-Total-Count');
                vm.legal.queryCount = vm.legal.totalItems;
                vm.propertyLegals = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        
        function loadPageLegal(page) {
            vm.legal.page = page;
            transitionLegal();
        }
        
        function transitionLegal(){
        	loadAllLegal();
        }
        
        /******************************************************************************************
         * 
         *     Sales tab
         * 
         ******************************************************************************************/
		vm.sales = {
			predicate: 'fileName',
			reverse: 'asc',
			page: 1,
			itemsPerPage: 20,      
			loadPage: loadPageSales,
			transition: transitionSales
		}
		
		loadAllSales ();
            
        function loadAllSales () {
            PropertySales.query({
            	propertyId: vm.property.id,
                page: vm.sales.page - 1,
                size: vm.sales.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [];
                
                if(vm.sales.predicate == 'createdBy'){
                	result.push('u.firstName,' + (vm.sales.reverse ? 'asc' : 'desc'));
                	result.push('u.lastName,' + (vm.sales.reverse ? 'asc' : 'desc'));
                }
                else if(vm.sales.predicate == 'fileName'){
                	result.push('f.filename,' + (vm.sales.reverse ? 'asc' : 'desc'));
                }
                else if(vm.sales.predicate == 'createdDate'){
                	result.push('f.created,' + (vm.sales.reverse ? 'asc' : 'desc'));
                }
                
                if (vm.sales.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
//                    vm.sales.links = ParseLinks.parse(headers('link'));
                vm.sales.totalItems = headers('X-Total-Count');
                vm.sales.queryCount = vm.sales.totalItems;
                vm.propertySales = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
            
        function loadPageSales(page) {
            vm.sales.page = page;
            transitionSales();
        }
        
        function transitionSales(){
        	loadAllSales();
        }
    }
})();
