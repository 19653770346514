(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('DashboardSectionActivitySummaryController', Controller);

    Controller.$inject = ['$scope', 'Principal', '$state', 'Activity', 'ParseLinks', 'AlertService', 'paginationConstants', '$uibModal', 'DateUtils'];

    function Controller ($scope, Principal, $state, Activity, ParseLinks, AlertService, paginationConstants, $uibModal, DateUtils) {
        var vm = this;
        
        vm.search = search;
        vm.openFromCalendar = openFromCalendar;
        vm.openToCalendar = openToCalendar;
        vm.validateDatepicker = validateDatepicker;
        
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.fromDate = false;
        vm.datePickerOpenStatus.toDate = false
        
        vm.datePickerOption = {
        		showWeeks: false,
                startingDay: 1,
                readonlyInput: true,
                dateformat: 'MM/dd/yyyy'
        }
        
        initData();
        
        loadAll();
        
        function initData(){
        	var now = new Date();
        	var quarter = Math.floor(now.getMonth() / 3) + 1;
			var fromDate = null;
			var toDate = null;
			if(quarter === 1) {
				fromDate = new Date(now.getFullYear(), 0, 1);
				toDate = new Date(now.getFullYear(), 2, 31);
			} else if (quarter === 2) {
				fromDate = new Date(now.getFullYear(), 3, 1);
				toDate = new Date(now.getFullYear(), 5, 31);
			} else if (quarter === 3) {
				fromDate = new Date(now.getFullYear(), 6, 1);
				toDate = new Date(now.getFullYear(), 8, 30);
			} else {
				fromDate = new Date(now.getFullYear(), 9, 1);
				toDate = new Date(now.getFullYear(), 11, 31);
			}
			vm.fromDate = fromDate;
			vm.toDate = toDate;
        }

        function loadAll () {
        	var fromDate = DateUtils.convertLocalDateToServer(vm.fromDate);
        	var toDate = DateUtils.convertLocalDateToServer(vm.toDate);
        	Activity.getForDashboardActivitySummary({fromDate: fromDate, toDate: toDate}, onSaveSuccess, onSaveError);
        	
        	function onSaveSuccess(data) {
        		if(data == null || data.length <= 0){
        			vm.result = null;
        		}
        		else {	
                	vm.result = angular.copy(data[0]);
                	
                	data.forEach(function(i){
                		vm.result[i.type] = i.numRow;
                	});
                }
            }

            function onSaveError() {
            	
            }
        }
        
        function search(){
        	loadAll();
        }
        
        function openFromCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function openToCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        
        function validateDatepicker(){
        	vm.invalidDatepicker = false;
        	if(vm.fromDate != null && vm.toDate != null){
        		if(vm.fromDate > vm.toDate){
        			vm.invalidDatepicker = true;
        		}
        	}
        }
    }
})();
