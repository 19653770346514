(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('QuotaManagementController', QuotaManagementController);

    QuotaManagementController.$inject = ['$state', 'QuotaManagement', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$scope', '$rootScope', '$uibModal'];

    function QuotaManagementController($state, QuotaManagement, ParseLinks, AlertService, paginationConstants, pagingParams, $scope, $rootScope, $uibModal) {

        var vm = this;
        var yearBuilded = 2017;
        
        $scope.$on('$stateChangeStart', onStateChangeStart);

        vm.edit = edit;
        vm.cancel = cancel;
        vm.save = save;
        vm.search = search;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.tempQuotaManagement = {};

        
        init();
        
        function init(){
        	var currentDate = new Date();
        	var currentYear = currentDate.getFullYear();
        	vm.year = angular.copy(currentYear);
        	vm.yearList = [];
        	for(var y = yearBuilded; y <= currentYear; y++){
        		vm.yearList.push(y);
        	}
        	
        	loadAll();
        }

        function loadAll () {
            QuotaManagement.query({
            	year: vm.year,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
            	var result = [];

            	if(vm.predicate == 'agent'){
            		result.push('u.firstName,' + (vm.reverse ? 'asc' : 'desc'));
            		result.push('u.lastName,' + (vm.reverse ? 'asc' : 'desc'));
            	}
            	else{
            		result.push(vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'));
            	}
                if (vm.predicate !== 'id') {
                    result.push('q.id');
                }
                return result;
            }
            function onSuccess(data, headers) {
//                vm.links = ParseLinks.parse(headers('link'));
//                vm.totalItems = headers('X-Total-Count');
//                vm.queryCount = vm.totalItems;
                vm.quotaManagements = data;
                vm.page = pagingParams.page;

                calculateTotal();
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        
        function search(){
        	loadAll ();
        }

        function calculateTotal(){
        	vm.totalQuota1 = 0;
    		vm.totalQuota2 = 0;
    		vm.totalQuota3 = 0;
    		vm.totalQuota4 = 0;

        	vm.quotaManagements.forEach(function(q){
        		vm.totalQuota1 = vm.totalQuota1 + q.quota1;
        		vm.totalQuota2 = vm.totalQuota2 + q.quota2;
        		vm.totalQuota3 = vm.totalQuota3 + q.quota3;
        		vm.totalQuota4 = vm.totalQuota4 + q.quota4;
        	});
        }

        function edit(quotaManagement){
        	quotaManagement.isEditing = true;
        	vm.tempQuotaManagement[quotaManagement.id] = angular.copy(quotaManagement);
        }

        function cancel(quotaManagement){
        	if(isDirty(quotaManagement)){
        		$uibModal.open({
                    templateUrl: 'app/components/confirmation-dialog/leave/confirm-dialog.html',
                    controller: 'LeaveConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                        entity: function(){
                        	return null;
                        },
                        translateKeys: function(){
                        	return {
                        		keys: 'indochinaApp.quotaManagement.confirmLeavePage'
                        	};
                        }
                    }
                }).result.then(function() {
                	quotaManagement.isEditing = false;
                }, function() {
                });
        	}
        	else{
        		quotaManagement.isEditing = false;
        	}
        }

        function save(quotaManagement){
        	QuotaManagement.update(vm.tempQuotaManagement[quotaManagement.id], onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
        	vm.quotaManagements.some(function(q){
        		if(q.id == result.id){
        			q.isEditing = false;

        			// todo: It is not auto binding when I use "q = result;"
        			q.quota1 = result.quota1;
        			q.quota2 = result.quota2;
        			q.quota3 = result.quota3;
        			q.quota4 = result.quota4;

        			return true;
        		}
        		return false;
        	});

        	calculateTotal();
        }

        function onSaveError(error) {
        	AlertService.error(error.data.message);
        	quotaManagement.isEditing = false;
        }

        function isDirty(quotaManagement){
        	if(quotaManagement.isEditing){
        		var tempObject = vm.tempQuotaManagement[quotaManagement.id];

        		return $scope.editForm[quotaManagement.id+'_quota1'].$invalid || $scope.editForm[quotaManagement.id+'_quota2'].$invalid
        				|| $scope.editForm[quotaManagement.id+'_quota3'].$invalid || $scope.editForm[quotaManagement.id+'_quota4'].$invalid
        				|| quotaManagement.quota1 != tempObject.quota1 || quotaManagement.quota2 != tempObject.quota2
        				|| quotaManagement.quota3 != tempObject.quota3 || quotaManagement.quota4 != tempObject.quota4
        	}

        	return false;
        }


        /*$rootScope.$on('app:sesstionIsTimeOut', function(event, result) {
        	vm.isLeaveConfirmDialog = true;
        });*/
        function onStateChangeStart(event, toState, toParams, fromState, fromParams) {
            if($rootScope.isSessionTimeOut) {
                return;
            }
            if(!vm.isLeaveConfirmDialog && vm.quotaManagements.some(function(q){return isDirty(q);})){
            	event.preventDefault();

                $uibModal.open({
                    templateUrl: 'app/components/confirmation-dialog/leave/confirm-dialog.html',
                    controller: 'LeaveConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                        entity: function(){
                        	return null;
                        },
                        translateKeys: function(){
                        	return {
                        		keys: 'global.messages.confirmLeavePage'
                        	};
                        }
                    }
                }).result.then(function() {
                	vm.isLeaveConfirmDialog = true;;
                	$state.go(toState, toParams, { reload: false });
                }, function() {
//                    $state.go('^');
                });
            }
        }
    }
})();
