(function() {
    'use strict';
    angular
        .module('indochinaApp')
        .factory('PhotoService', PhotoService);

    PhotoService.$inject = ['$resource', 'DateUtils'];

    function PhotoService ($resource, DateUtils) {
        var resourceUrl =  'api/photos/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.created = DateUtils.convertDateTimeFromServer(data.created);
                        data.modified = DateUtils.convertDateTimeFromServer(data.modified);
                    }
                    return data;
                }
            },
            'getPhotoDescriptionLanguages': {
                url:'api/photos/:id/photo-description-languages',
                method:'GET', 
                isArray: true 
            },
            'update': { method:'PUT' },
            'updateOrder': {
            	url:'api/photos/order',
                method:'PUT', 
                isArray: true 
            },
            'uploadPhoto': { 
                method:'POST',
                url:'api/photos/:id/upload-photo',
                transformRequest: function(data) {
                    var formData = new FormData();
                    formData.append('propertyId', data.propertyId);
                    formData.append('blob', data.blob);
                    formData.append('fileName', data.fileName);
                    return formData;
                },
                headers: { 'Content-Type': undefined },
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.created = DateUtils.convertDateTimeFromServer(data.created);
                        data.modified = DateUtils.convertDateTimeFromServer(data.modified);
                    }
                    return data;
                }
             },
             'savePhotoDescriptionLanguages': { 
                 method:'POST',
                 url:'api/photos/:id/save-photo-description-languages',
                 isArray: true
              }
        });
    }
})();
