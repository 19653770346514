(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('ContactMethodDetailController', ContactMethodDetailController);

    ContactMethodDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ContactMethod'];

    function ContactMethodDetailController($scope, $rootScope, $stateParams, previousState, entity, ContactMethod) {
        var vm = this;

        vm.contactMethod = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('indochinaApp:contactMethodUpdate', function(event, result) {
            vm.contactMethod = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
