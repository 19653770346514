(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .directive('updateHeightContainer', updateHeightContainer);
    updateHeightContainer.$inject = ['$window'];

    function updateHeightContainer($window) {
        var directive = {
            restrict: 'A',
            scope: { 
                
            },
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            var config = {
                adjustHeight:0
            };
            
            try {
                var configCustom = eval('('+attrs.updateHeightContainer+')');
                config = angular.extend(config, configCustom);
            } catch (e) {
                console.log(e);
            }
            
            var win = angular.element($window);
            function update() {
            	if(win.innerWidth() > 767) {
            		var containerRect = element[0].getBoundingClientRect();
                    element.css({maxHeight:win.innerHeight() - containerRect.top - config.adjustHeight});
                } else {
                	element.css({maxHeight:'none'});
                }
            }
            
            angular.element(document).ready(function() {
                update();
            });
            win.resize(function() {
                //console.log('win.resize: ',element[0].offsetWidth + " " + element[0].offsetHeight);
            	update();
            });
            
            scope.$watch(
                function () {
                    return [element[0].clientWidth, element[0].clientHeight].join('x');
                },
                function (nval, oval) {
                    //console.log('directive got resized:', nval.split('x'));
                    update();
                }
            )
        }
    }
})();
