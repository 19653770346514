(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'AuthorityConstant'];

    function stateConfig($stateProvider, AuthorityConstant) {
        $stateProvider
        .state('development-detail.activities-tab', {
        	parent: 'development-detail',
        	data: {
            	authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_ADMIN, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT]
            },
        	templateUrl: 'app/entities/activity/activity-tab/activity-tab.html',
            controller: 'ActivitiesTabController',
            controllerAs: 'vm',
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('activity');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('development-detail.activities-tab.section', {
            parent: 'development-detail.activities-tab',
            url: '/activities-tab',
            views: {
                'section1': {
                    templateUrl: 'app/entities/development/development-detail/activity-tab/activity-tab-section-1.html',
                    controller: 'ActivitiesTabSection1DevelopmentController',
                    controllerAs: 'vm'
                },
                'section2': {
                	templateUrl: 'app/entities/development/development-detail/activity-tab/activity-tab-section-2.html',
                    controller: 'ActivitiesTabSection2DevelopmentController',
                    controllerAs: 'vm'
                }
            }
        });
    }

})();
