(function() {
	'use strict';

	angular
		.module('indochinaApp')
		.constant('CoAgentTypeConstant', (function() {
			return {
				LIST: ['INDIVIDUAL', 'CORPORATE'],
				INDIVIDUAL : 'INDIVIDUAL',
				CORPORATE : 'CORPORATE'
			}
		})());
})();
