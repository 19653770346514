(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('PhotoViewControllerRe', PhotoViewControllerRe);

    PhotoViewControllerRe.$inject = ['$uibModalInstance', 'photoView', '$rootScope'];

    function PhotoViewControllerRe($uibModalInstance, photoView, $rootScope) {
        var vm = this;
        vm.photoView = photoView;
        vm.clear = clear;

        // session is time out so we must close all modals
        $rootScope.$on('modal:close', function(event, result) {
            clear();
        });

        function clear () {
            $rootScope.$broadcast('app:resetTimeSession', true);
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
