(function() {
    'use strict';
    angular
        .module('indochinaApp')
        .factory('DevelopmentLegal', DevelopmentLegal);

    DevelopmentLegal.$inject = ['$resource', 'DateUtils'];

    function DevelopmentLegal ($resource, DateUtils) {
        var resourceUrl =  'api/development-legals/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.issueDate = DateUtils.convertDateTimeFromServer(data.issueDate);
                        data.expiryDate = DateUtils.convertDateTimeFromServer(data.expiryDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.issueDate = DateUtils.convertLocalDateToServer(copy.issueDate);
                    copy.expiryDate = DateUtils.convertLocalDateToServer(copy.expiryDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                	var formData = new FormData();
                    var copy = angular.copy(data.legal);
                    copy.issueDate = DateUtils.convertLocalDateToServer(copy.issueDate);
                    copy.expiryDate = DateUtils.convertLocalDateToServer(copy.expiryDate);
                    copy.createdDate = DateUtils.convertLocalDateToServer(copy.createdDate);
                    formData.append("jsondata", JSON.stringify(copy));
                    if(data.file){
                    	formData.append("file", data.file);
                    }
                    return formData;
                },
                headers: {'Content-Type': undefined}
            }
        });
    }
})();
