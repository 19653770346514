(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('OpportunityEditorController', OpportunityEditorController);

    OpportunityEditorController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', 'entity', 'Opportunity', 'PropertyService', 'LeadSourceManagementService', 'User',
                                           'JhiCurrencyService', 'CurrencyConstant', 'OpportunityConstant', 'PropertyConstant', 'MeasurementConversionService', 'AlertService', '$translate', '$uibModal',
                                           '$state', '$filter', 'previousState', 'Contact', 'AuthorityConstant', 'Principal'];

    function OpportunityEditorController ($timeout, $scope, $rootScope, $stateParams, entity, Opportunity, PropertyService, LeadSource, User,
								    		JhiCurrencyService, CurrencyConstant, OpportunityConstant, PropertyConstant, MCService, AlertService, $translate, $uibModal,
								    		$state, $filter, previousState, Contact, AuthorityConstant, Principal) {
        var vm = this;

        /******************************************************************************************
         * define all variables
         */

        vm.opportunity = entity;
        vm.property = {};
		vm.contact = {};
		vm.person = {};
		vm.referringParty2 = {};
		vm.currenciesDes = CurrencyConstant.DES;
		vm.currencyUnits = CurrencyConstant.LIST;
        vm.currencyCode = JhiCurrencyService.currencyCode;
        vm.currencyRate = JhiCurrencyService.rate;
        vm.stages = OpportunityConstant.STAGE.LIST;
        vm.coAgents = [];
        vm.properties = [];
        vm.leadsources = [];
        vm.openedProbabilities = [50,60,70,80,90];
        vm.closedProbabilities = [0,100];

        vm.save = save;
        vm.cancel = cancel;
        vm.openCalendar = openCalendar;
        vm.loadProperty = loadProperty;
        vm.onSelectProperty = onSelectProperty;
        vm.loadContact = loadContact;
        vm.onSelectContact = onSelectContact;
        vm.onSelectPerson = onSelectPerson;
        vm.loadReferringParty = loadReferringParty;
        vm.onSelectReferringParty1 = onSelectReferringParty1;
        vm.onSelectReferringParty1Person = onSelectReferringParty1Person;
        vm.onKeyUpCommissionSplit1 = onKeyUpCommissionSplit1;
        vm.onKeyUpcommissionOnPropertyValue1 = onKeyUpcommissionOnPropertyValue1;
        vm.onSelectReferringParty2 = onSelectReferringParty2;
        vm.onSelectReferringParty2Person = onSelectReferringParty2Person;
        vm.onKeyUpCommissionSplit2 = onKeyUpCommissionSplit2;
        vm.onKeyUpcommissionOnPropertyValue2 = onKeyUpcommissionOnPropertyValue2;
        vm.isDuplicateReferringPartyContact = isDuplicateReferringPartyContact;
        vm.isDuplicateReferringPartyPerson = isDuplicateReferringPartyPerson;
        vm.isLargerTotalCommission = isLargerTotalCommission;

        vm.isEdit = $state.current.name === 'opportunity.edit';
        vm.isCreateNew = !vm.isEdit;
        vm.isCreateNewFromContact = $state.current.name === 'opportunity.new-from-contact' || $state.current.name === 'opportunity.new-from-contact-activity';
        vm.isCreateNewFromProperty = $state.current.name === 'opportunity.new-from-property' || $state.current.name === 'opportunity.new-from-property-activity';

        vm.isDisableContact = vm.isCreateNewFromContact || (vm.isCreateNewFromProperty && vm.opportunity.contactId != null);
        vm.isDisableProperty = vm.isCreateNewFromProperty || (vm.isCreateNewFromContact && vm.opportunity.propertyId != null);

        /******************************************************************************************
         * all default action
         */
        $rootScope.scrollTop();

        if(vm.isEdit){
        	// SUPER_USER/MANAGER/AGENT can edit
        	// ADMIN can not edit
        	if(!Principal.hasAnyAuthority([AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT])){
        		$state.go('accessdenied');
        	}
        }

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        /******************************************************************************************
         * define all listener action
         */

        $scope.$on('$stateChangeStart', onStateChangeStart);

        $scope.$watch('vm.opportunity.stage', function(newValue, oldValue) {
        	if(vm.property != null && vm.property.availability == PropertyConstant.availability.CLOSED_WON
        			&& vm.property.closedWonOpportunityId != null && vm.property.closedWonOpportunityId != vm.opportunity.id
        			&& oldValue != null && oldValue != ''
        			&& newValue == OpportunityConstant.STAGE.CLOSED_WON){

        		$uibModal.open({
                    templateUrl: 'app/components/confirmation-dialog/inform/confirm-dialog.html',
                    controller: 'InformConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                        entity: null,
                        translateKeys: function(){
                        	return {
                        		keys: 'indochinaApp.opportunity.informWhenUserChooseClosedWon'
                        	};
                        }
                    }
                }).result.then(function() {
                	vm.opportunity.stage = OpportunityConstant.STAGE.CLOSED_LOST;
                }, function() {
                	vm.opportunity.stage = OpportunityConstant.STAGE.CLOSED_LOST;
                });
        		
        		return;
        	}

        	// update Probability
        	if(OpportunityConstant.STAGE.CLOSED_WON == newValue){
        		vm.opportunity.probability = 100;
        	}
        	else if(OpportunityConstant.STAGE.CLOSED_LOST == newValue){
        		vm.opportunity.probability = 0;
        	}
        	else if(OpportunityConstant.STAGE.HISTORICAL_LIST.indexOf(oldValue) != -1){
        		vm.opportunity.probability = null;
        	}
        	
        	// update Invoiced Value
        	if(OpportunityConstant.STAGE.CLOSED_WON == oldValue && OpportunityConstant.STAGE.CLOSED_WON != newValue){
        		vm.opportunity.invoicedValue = null;
        	}
        	else if(OpportunityConstant.STAGE.CLOSED_WON != oldValue && OpportunityConstant.STAGE.CLOSED_WON == newValue){
        		vm.opportunity.invoicedValue = vm.opportunity.transactionPrice;
        	}
        	
        	// update Closed Date
        	if(OpportunityConstant.STAGE.CLOSED_WON != oldValue && OpportunityConstant.STAGE.CLOSED_WON == newValue){
        		vm.opportunity.closeDate = new Date();
        	}

        },true); // true is deep watch

        $scope.$watch('vm.property.propertyId', function(newValue, oldValue) {
        	if(oldValue != null && oldValue != '' && (newValue == null || newValue == '')){
        		vm.property = {};
        	}
        });

        $scope.$watch('vm.contact.contactName', function() {
        	if(!$.isEmptyObject(vm.contact) && (vm.contact.contactName == null || vm.contact.contactName == '')){
        		vm.contact = {};
        		vm.person = {};

        		vm.opportunity.referringParty1Name = '';
        	}
        });

        $scope.$watch('vm.opportunity.referringParty1Name', function() {
        	if(!$.isEmptyObject(vm.referringParty1) && (vm.opportunity.referringParty1Name == null || vm.opportunity.referringParty1Name == '')){
        		vm.referringParty1 = {};
        		vm.opportunity.referringParty1Person = null;
        		vm.opportunity.commissionSplit1 = null;
        		vm.opportunity.commissionOnPropertyValue1 = null;
        	}
        });

        $scope.$watch('vm.opportunity.referringParty2Name', function() {
        	if(!$.isEmptyObject(vm.referringParty2) && (vm.opportunity.referringParty2Name == null || vm.opportunity.referringParty2Name == '')){
        		vm.referringParty2 = {};
        		vm.opportunity.referringParty2Person = null;
        		vm.opportunity.commissionSplit2 = null;
        		vm.opportunity.commissionOnPropertyValue2 = null;
        	}
        });

        /******************************************************************************************
         * load data from server
         */
        if(vm.opportunity.propertyId != null){
        	PropertyService.getPropertyForOpportunity({id: vm.opportunity.propertyId}).$promise.then(function (property) {
        		// can not create new opportunity from closed-won property
        		if(vm.isCreateNew && property != null && property.availability == PropertyConstant.availability.CLOSED_WON){
        			$state.go('accessdenied');
        		}
        		
        		setProperty(property);

                if(vm.isCreateNew){
        			vm.opportunity.propertyCommission = vm.property.commission;
        		}
        	});
        }

        if(vm.opportunity.contactId != null){
        	Contact.getContactForOpportunity({id:vm.opportunity.contactId}).$promise.then(function (reponse) {
        		vm.contact = reponse;

        		// set person
        		if(vm.opportunity.person != null && vm.opportunity.person.id != null){
        			vm.contact.person.some(function(c){
        				if(vm.opportunity.person.id == c.id){
        					vm.person = angular.copy(c);
        					onSelectPerson(false);
        					return true;
        				}
        				return false;
        			});
        		}
        		else if(vm.isCreateNewFromContact || vm.isCreateNewFromProperty){
        			if(vm.contact.person.length >= 1){
        				vm.person = angular.copy(vm.contact.person[0]);
        				onSelectPerson(false);
        			}

        			updateReferringParty1WhenUpdateContact();
        		}
        	});
        }

        if(vm.opportunity.referringParty1Id != null){
        	Contact.getContactForOpportunity({id:vm.opportunity.referringParty1Id}).$promise.then(function (reponse) {
        		vm.referringParty1 = reponse;
        	});
        }

        if(vm.opportunity.referringParty2Id != null){
        	Contact.getContactForOpportunity({id:vm.opportunity.referringParty2Id}).$promise.then(function (reponse) {
        		vm.referringParty2 = reponse;
        	});
        }

        init();

        /******************************************************************************************
         * define functions
         */
        function init(){
        	var fiveDateInPast = new Date();
        	fiveDateInPast.setDate(fiveDateInPast.getDate()-5);

        	vm.validDate = $filter('date')(fiveDateInPast, 'MM/dd/yyyy');
	        vm.datePickerOption = {
	        		showWeeks: false,
	        		closeDate: false,
	        		dateformat: 'MM/dd/yyyy'//,
//	        		minDate: vm.validDate
	        }
        }

        function cancel(){
        	$state.go(previousState.name, previousState.params, { reload: false });
        }

        function save () {
            vm.isSaving = true;

            vm.opportunity.propertyId = vm.property.id;
        	vm.opportunity.contactId = vm.contact.id;

        	if(vm.person != null && vm.person.id != null){
        		vm.opportunity.person = {};
            	vm.opportunity.person.id = vm.person.id;
        	}

        	if(vm.referringParty1 != null && vm.referringParty1 != ''){
        		vm.opportunity.referringParty1Id = vm.referringParty1.id;
        	}

        	if(vm.referringParty2 != null && vm.referringParty2 != ''){
        		vm.opportunity.referringParty2Id = vm.referringParty2.id;
        	}

            if (vm.opportunity.id !== null) {
                Opportunity.update(vm.opportunity, onSaveSuccess, onSaveError);
            } else {
                Opportunity.save(vm.opportunity, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
        	if (vm.opportunity.id !== null) {
        		AlertService.success($translate.instant('indochinaApp.opportunity.updated', { param: result.name }));
            } else {
            	vm.opportunity.id = result.id;
                AlertService.success($translate.instant('indochinaApp.opportunity.created', { param: result.name }));
            }

            vm.isSaving = false;
            $scope.editForm.$dirty = false;
            $state.go('opportunity-detail',{id: result.id});

        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function openCalendar (date) {
            vm.datePickerOption[date] = true;
        }

        function loadProperty(searchQuery){
        	return PropertyService.getAllPropertiesForOpportunity({searchQuery: searchQuery}).$promise;
        }

        function onSelectProperty($item, $model, $label) {
            setProperty($item);

            if($.isEmptyObject(vm.property)){
    			vm.opportunity.propertyCommission = null;
    		}
    		else{
    			vm.opportunity.propertyCommission = vm.property.commission;
    		}

        	vm.opportunity.commissionSplit1 = null;
        	vm.opportunity.commissionOnPropertyValue1 = null;
        	vm.opportunity.commissionSplit2 = null;
        	vm.opportunity.commissionOnPropertyValue2 = null;
        };

        function setProperty(property){
        	vm.property = property;
            vm.property.sizeConverter = MCService.init(vm.property.sizeUnit);
        }

        function loadContact(searchQuery){
        	return Contact.getAllContactsForOpportunity({searchQuery: searchQuery}).$promise;
        }

        function onSelectContact($item, $model, $label) {
            setContact($item, true);
        };

        function setContact(contact, isReset){
        	vm.contact = contact;

        	if(isReset && vm.contact.person != null){
        		vm.person = vm.contact.person[0];
        	}

        	onSelectPerson(isReset);

        	// refresh referring party 1
        	updateReferringParty1WhenUpdateContact();
        }

        function onSelectPerson(isReset) {

            vm.person.phoneList = [];
            vm.person.emailList = [];

            if(isReset){
        		vm.opportunity.personPhone = null;
        		vm.opportunity.personEmail = null;
        	}

        	if(vm.person.mobiles != null){
        		vm.person.mobiles.forEach(function(m){
        		    if(m.phone) {
                        vm.person.phoneList.push(m);
                    }
        		});
        	}
        	if(vm.person.officePhone != null && vm.person.officePhone.phone){
        		vm.person.phoneList.push(vm.person.officePhone);
        	}
        	if(vm.person.emails !=null){
        		vm.person.emails.forEach(function(e){
        			vm.person.emailList.push(e);
        			if(e.default){
        				vm.opportunity.personEmail = e;
        			}
                 });
        	}
        };

        function loadReferringParty(searchQuery){
        	return Contact.getAllContactForReferringPartyOnOpportunity({searchQuery: searchQuery}).$promise;
        }

        function updateReferringParty1WhenUpdateContact(){
        	if(vm.contact.leadSourceContact != null){
            	Contact.getContactForOpportunity({id:vm.contact.leadSourceContact.id}).$promise.then(function (reponse) {
            		vm.referringParty1 = reponse;

            		vm.opportunity.referringParty1Name = reponse.contactName;

            		// introduction contact
            		if(vm.contact.leadSourceContactPerson != null){
            			vm.opportunity.referringParty1Person = vm.contact.leadSourceContactPerson;
            		}
            		// co-broker contact
            		else{
            			vm.opportunity.referringParty1Person = vm.referringParty1.person[0];
            		}

            		// set default value if person is a co-broker
            		onSelectReferringParty1Person();
            	});
        	}
        	else{
        		vm.opportunity.referringParty1Name = '';
        	}
        }

        function onSelectReferringParty1($item, $model, $label){
        	vm.referringParty1 = $item;

        	vm.opportunity.referringParty1Person = vm.referringParty1.person[0];

    		// set default value if person is a co-broker
        	onSelectReferringParty1Person();
        }

        function onSelectReferringParty1Person(){
        	if(vm.opportunity.referringParty1Person.linkedUser != null){
        		if(vm.opportunity.referringParty1Person.linkedUserRole == AuthorityConstant.ROLE_CO_AGENT){
        			vm.opportunity.commissionSplit1 = vm.opportunity.referringParty1Person.linkedUserCommission;

        			onKeyUpCommissionSplit1();
        		}
        	}
        }

        function onKeyUpCommissionSplit1(){
        	if(vm.opportunity.propertyCommission != null && vm.opportunity.propertyCommission != ''
        			&& vm.opportunity.commissionSplit1 != null && vm.opportunity.commissionSplit1 != ''){

        		var tempValue = vm.opportunity.propertyCommission * vm.opportunity.commissionSplit1 / 100;

        		vm.opportunity.commissionOnPropertyValue1 = roundTo(tempValue);
        	}
        }

        function onKeyUpcommissionOnPropertyValue1(){
        	if(vm.opportunity.propertyCommission != null && vm.opportunity.propertyCommission != ''
        			&& vm.opportunity.commissionOnPropertyValue1 != null && vm.opportunity.commissionOnPropertyValue1 != ''){

        		var tempValue = vm.opportunity.commissionOnPropertyValue1 / vm.opportunity.propertyCommission * 100;

        		vm.opportunity.commissionSplit1 = roundTo(tempValue);
        	}
        }

        function onSelectReferringParty2($item, $model, $label){
        	vm.referringParty2 = $item;

        	vm.opportunity.referringParty2Person = vm.referringParty2.person[0];

        	onSelectReferringParty2Person();
        }

        function onSelectReferringParty2Person(){
        	if(vm.opportunity.referringParty2Person.linkedUser != null){
        		if(vm.opportunity.referringParty2Person.linkedUserRole == AuthorityConstant.ROLE_CO_AGENT){
        			vm.opportunity.commissionSplit2 = vm.opportunity.referringParty2Person.linkedUserCommission;

        			onKeyUpCommissionSplit2();
        		}
        	}
        }

        function onKeyUpCommissionSplit2(){
        	if(vm.opportunity.propertyCommission != null && vm.opportunity.propertyCommission != ''
        			&& vm.opportunity.commissionSplit2 != null && vm.opportunity.commissionSplit2 != ''){

        		var tempValue = vm.opportunity.propertyCommission * vm.opportunity.commissionSplit2 / 100;

        		vm.opportunity.commissionOnPropertyValue2 = roundTo(tempValue);
        	}
        }

        function onKeyUpcommissionOnPropertyValue2(){
        	if(vm.opportunity.propertyCommission != null && vm.opportunity.propertyCommission != ''
        			&& vm.opportunity.commissionOnPropertyValue2 != null && vm.opportunity.commissionOnPropertyValue2 != ''){

        		var tempValue = vm.opportunity.commissionOnPropertyValue2 / vm.opportunity.propertyCommission * 100;

        		vm.opportunity.commissionSplit2 = roundTo(tempValue);
        	}
        }

        function isDuplicateReferringPartyContact(){
        	if(vm.referringParty1 == null){
        		return false;
        	}
        	if(vm.referringParty2 == null){
        		return false;
        	}
        	if(vm.referringParty1.id == null){
        		return false;
        	}
        	if(vm.referringParty1.isCompany){
        		return false;
        	}
        	if(vm.referringParty1.id != vm.referringParty2.id){
        		return false;
        	}
        	return true;
        }

        function isDuplicateReferringPartyPerson(){
        	if(vm.referringParty1 == null || vm.referringParty1.id == null || vm.opportunity.referringParty1Person == null){
        		return false;
        	}
        	if(vm.referringParty2 == null || vm.referringParty2.id == null || vm.opportunity.referringParty2Person == null){
        		return false;
        	}
        	if(!vm.referringParty1.isCompany || !vm.referringParty2.isCompany){
        		return false;
        	}
        	if(vm.opportunity.referringParty1Person.id != vm.opportunity.referringParty2Person.id){
        		return false;
        	}
        	return true;
        }

        function isLargerTotalCommission(){
        	if(vm.opportunity.commissionOnPropertyValue1 != null && vm.opportunity.commissionOnPropertyValue2 != null){
        		return parseFloat(vm.opportunity.commissionOnPropertyValue1) + parseFloat(vm.opportunity.commissionOnPropertyValue2) > parseFloat(vm.opportunity.propertyCommission);
        	}
        	else{
        		return false;
        	}
        }

        function roundTo(num){
        	return Math.round(parseFloat(num) * 100) / 100;
        }

//        function roundTo(n, digits) {
//     		var negative = false;
//	        if (digits === undefined) {
//	            digits = 0;
//	        }
//	    	if( n < 0) {
//	        	negative = true;
//	        	n = n * -1;
//	        }
//	        var multiplicator = Math.pow(10, digits);
//	        n = parseFloat((n * multiplicator).toFixed(11));
//	        n = (Math.round(n) / multiplicator).toFixed(digits);
//	        if( negative ) {
//	        	n = (n * -1).toFixed(digits);
//	        }
//	        return n;
//	    }

        /*$rootScope.$on('app:sesstionIsTimeOut', function(event, result) {
        	$scope.editForm.$dirty = false;
        });*/

        function onStateChangeStart(event, toState, toParams, fromState, fromParams) {
            if($scope.editForm.$dirty && !$rootScope.isSessionTimeOut){
            	event.preventDefault();

                $uibModal.open({
                    templateUrl: 'app/components/confirmation-dialog/leave/confirm-dialog.html',
                    controller: 'LeaveConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                        entity: function(){
                        	return null;
                        },
                        translateKeys: function(){
                        	return {
                        		keys: 'global.messages.confirmLeavePage'
                        	};
                        }
                    }
                }).result.then(function() {
                	$scope.editForm.$dirty = false;
                	$state.go(toState, toParams, { reload: false });
                }, function() {
//                    $state.go('^');
                });
            }
        }
    }
})();
