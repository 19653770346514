(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('CoAgentCompanyMasterDataDetailController', CoAgentCompanyMasterDataDetailController);

    CoAgentCompanyMasterDataDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CoAgentCompanyMasterData'];

    function CoAgentCompanyMasterDataDetailController($scope, $rootScope, $stateParams, previousState, entity, CoAgentCompanyMasterData) {
        var vm = this;

        vm.coAgentCompanyMasterData = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('indochinaApp:coAgentCompanyMasterDataUpdate', function(event, result) {
            vm.coAgentCompanyMasterData = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
