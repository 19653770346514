(function() {
	'use strict';

	angular.module('indochinaApp')
	.directive('awDatepickerPattern', awDatepickerPattern);
	
	awDatepickerPattern.$inject = ['$filter', '$browser', '$timeout'];
			
	function awDatepickerPattern($filter, $browser, $timeout) {
		return {
			restrict : 'A',
			require : 'ngModel',
			link : function($scope, $element, $attrs, ngModelCtrl) {
				var dRegex = new RegExp("(((0[13578]|1[02])\\/(0[1-9]|[12]\\d|3[01])\\/((19|[2-9]\\d)\\d{2}))|((0[13456789]|1[012])\\/(0[1-9]|[12]\\d|30)\\/((19|[2-9]\\d)\\d{2}))|((02)\\/(0[1-9]|1\\d|2[0-8])\\/((19|[2-9]\\d)\\d{2}))|((02)\\/(29)\\/((1[6-9]|[2-9]\\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$");
				var awDatepickerMindate = $attrs.awDatepickerMindate || null;
				
				ngModelCtrl.$parsers.unshift(function(value) {
					
					ngModelCtrl.$setValidity('date', true);
					ngModelCtrl.$setValidity('datemin', true);

					if (typeof value === 'string' && value) {
						value = value.replace(/[^0-9\\/]/g, '').replace(/\/+/g,"/");
						
						if(value == null || value == ''){
							ngModelCtrl.$setViewValue(null);
							ngModelCtrl.$render();
							return null;
						}

						if(!dRegex.test(value)) {
							ngModelCtrl.$setValidity('date', false);
							$element.val(value);
							return null;
						}
						
						if(typeof awDatepickerMindate === 'string' && awDatepickerMindate){
							var enterDate = new Date($filter('date')(value, 'MM/dd/yyyy'));
							var minDate = new Date($filter('date')(awDatepickerMindate, 'MM/dd/yyyy'));
							
							if(enterDate < minDate){
								ngModelCtrl.$setValidity('datemin', false);
							}
						}

						$element.val(value);
					}

					return value;
				});
			}
		};
	}
})();
