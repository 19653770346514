(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .constant('ActivityConstant', (function() {
            return {
            	type: {
                    EMAIL: 'EM',
                    FOLLOW_UP: 'FU',
                    TELEPHONE_CALL: 'TC',
                    PROPERTY_INFORMATION: 'PI',
                    VIEWING: 'VW',
                    VIEWING_REPORT: 'VR',
                    SELLER_REPORT: 'SR',
                    DEPOSIT: 'DP',
                    OFFER: 'OF',
                    CLOSING: 'CL',
                    MEETING: 'MTG',
                    INTRODUCTION: 'INT',
                    SELLER_FOLLOW_UP: 'SFU',
                    SELLER_OFFER: 'SOF',
                    SELLER_FEEDBACK: 'SFE',
                    SELLER_COUNTER_OFFER: 'SCO',
                    MARKETING_CAMPAIGN: 'MKT'
                },
                from: {
            	    DASHBOARD: 0,
                    DEVELOPMENT: 1,
                    CONTACT: 2,
                    PROPERTY: 3
                },
                status: {
            	    OPEN: 'OPEN',
                    COMPLETE: 'COMPLETE'
                },
                statusText: {
                    OPEN: 'Active',
                    COMPLETE: 'Historical'
                }
            }
        })());
})();
