(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('DashboardController', Controller);

    Controller.$inject = ['$scope', 'Principal', 'AuthorityConstant'];

    function Controller ($scope, Principal, AuthorityConstant) {
        var vm = this;
        
        vm.isShowTeamActivitySummnary = Principal.hasAnyAuthority([AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_MANAGER]);
        
    }
})();
