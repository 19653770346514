	/*
	 * Automatic postal code formatting in input field
	*/
(function() {
    'use strict';
    angular
    	.module('indochinaApp')
    	.directive('postalcodeinput', postalCodeInput)
    	.filter('postalCode', function () {
    		return function (postalCode) {
    	        if (!postalCode) { return ''; }
    	
    	        var value = postalCode.toString().trim().replace(/^\+/, '');
    	        
    	        if((value.match(/\-/g) || []).length > 1) {
    	        	value = value.replace(/-+/g,"-");
    	        	postalCode = value;
    	        }
    	        
    	        if (value.match(/^([0-9]+)(-){1}([0-9]*)$/)) {
    	            return postalCode;
    	        } else {
    	        	if(value.indexOf("-") == 0) {
        	        	value = value.slice(1);
        	        }
    	        }
    	        return value;
    	    };
    	});
    
    postalCodeInput.$inject = ['$filter', '$browser', '$timeout'];
    
    function postalCodeInput($filter, $browser, $timeout) {
    	var directive = {
            restrict: 'A',
            scope: false,
            require: 'ngModel',
            link: linkFunc
        };
    	return directive;
    	
    	function linkFunc($scope, $element, $attrs, ngModelCtrl) {
    		var listener = function() {
                var value = $element.val().replace(/[^0-9\-]/g, '').replace(/-+/g,"-");
                $element.val($filter('postalCode')(value, false));
            };

            // This runs when we update the text field
            ngModelCtrl.$parsers.push(function(viewValue) {
            	var val = viewValue.replace(/[^0-9\-]/g, '').replace(/-+/g,"-").slice(0,10);
            	ngModelCtrl.$setViewValue(val);	            	
                return val;
            });

            // This runs when the model gets updated on the scope directly and keeps our view in sync
            ngModelCtrl.$render = function() {
                $element.val($filter('postalCode')(ngModelCtrl.$viewValue, false));
            };

            $element.bind('change', listener);
            $element.bind('keydown', function(event) {
                var key = event.keyCode;
                // If the keys include the CTRL, SHIFT, ALT, or META keys, or the arrow keys, do nothing.
                // This lets us support copy and paste too
                if (key == 91 || (15 < key && key < 19) || (37 <= key && key <= 40)){
                    return;
                }
                $browser.defer(listener); // Have to do this or changes don't get picked up properly
            });

            $element.bind('paste cut', function() {
                $browser.defer(listener);
            });
            
            $element.bind('blur', function() {
            	var viewValue = $element.val();
            	if(viewValue != null && viewValue.lastIndexOf("-") == viewValue.length - 1) {
            		viewValue = viewValue.slice(0, viewValue.length - 1);
            		$element.val(viewValue);
            		ngModelCtrl.$setViewValue(viewValue);	
            	}
            });
    	}
    }
})();