(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['$state', '$uibModal', 'Principal', 'Auth', 'JhiLanguageService', '$translate', 'CurrencyConstant', 'AuthorityConstant', 'ManagerService'];

    function SettingsController ($state, $uibModal, Principal, Auth, JhiLanguageService, $translate, CurrencyConstant, AuthorityConstant, ManagerService) {
        var vm = this;

        vm.error = null;
        vm.save = save;
        vm.settingsAccount = null;
        vm.success = null;
        vm.preferredUnits = ['IMPERIAL', 'METRIC'];
        vm.currencies = CurrencyConstant.LIST;

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
            return {
            	id: account.id,
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login,
                currencyCode: account.currencyCode,
                preferredUnit: account.preferredUnit,
                authorities: account.authorities,
                managers: account.managers
            };
        };

        Principal.identity().then(function(account) {
            vm.settingsAccount = copyAccount(account);
            
            if(vm.settingsAccount.authorities[0] == AuthorityConstant.ROLE_AGENT){
            	vm.isShowManagerField = true;
            	
            	ManagerService.getManagersById({id: vm.settingsAccount.id}, function(result) {
        			vm.managers = result;
                });
            }
        });

        function save () {
            Auth.updateAccount(vm.settingsAccount).then(function() {
                vm.error = null;
                vm.success = 'OK';
                Principal.identity(true).then(function(account) {
                	if(account == null || account == ''){
                		Auth.logout();
                		$uibModal.open({
                            templateUrl: 'app/components/confirmation-dialog/inform/confirm-dialog.html',
                            controller: 'InformConfirmDialogController',
                            controllerAs: 'vm',
                            size: 'sm',
                            windowClass : 'absolute-center',
                            resolve: {
                                entity: null,
                                translateKeys: function(){
                                	return {
                                		keys: 'settings.confirmAfterChangeEmail'
                                	};
                                }
                            }
                        }).result.then(function() {
                        	$state.go('logout');
                        }, function() {
                        	$state.go('logout');
                        });
                	}
                	else{
                		vm.settingsAccount = copyAccount(account);                		
                	}
                	
                });
                JhiLanguageService.getCurrent().then(function(current) {
                    if (vm.settingsAccount.langKey !== current) {
                        $translate.use(vm.settingsAccount.langKey);
                    }
                });
            }).catch(function() {
                vm.success = null;
                vm.error = 'ERROR';
            });
        }
    }
})();
