(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('ContactPreferTabController', ContactPreferTabController);

    ContactPreferTabController.$inject = ['$q', '$scope', '$state', '$rootScope', '$stateParams', 'previousState', 'entity', 'Contact', 'propertyLocationsResource', 'internalRoomsResource', 'outsideAreasResource', 'facilitiesOffersResource', 'parkingFacilitiesResource',
                                          'nearbyFacilitiesResource', 'propertyTransactionsResource', 'propertyTypesResource', '$uibModal', 'CurrencyConstant', 'AuthorityConstant', 'Principal', 'Opportunity','AlertService',
                                          '$translate', 'countryResource', 'provinceResource', 'cityResource', 'districtHCMResource', 'districtHANResource', 'districtDNGResource', 'constructionStatusResource', 'PropertyConstant', 'reasonForPurchaseResource',
                                          'accessRoadResource', 'MeasurementConversionService','materialsResource'];

    function ContactPreferTabController($q, $scope, $state, $rootScope, $stateParams, previousState, entity, Contact, propertyLocationsResource, internalRoomsResource, outsideAreasResource, facilitiesOffersResource, parkingFacilitiesResource,
                                        nearbyFacilitiesResource, propertyTransactionsResource, propertyTypesResource, $uibModal, CurrencyConstant, AuthorityConstant, Principal, Opportunity, AlertService, $translate, countryResource, provinceResource, cityResource, districtHCMResource,
                                        districtHANResource, districtDNGResource, constructionStatusResource, PropertyConstant, reasonForPurchaseResource,
                                        accessRoadResource, MCService, materialsResource) {

        var vm = this;
        vm.contact = entity;

        // avoid wrong state when refresh page
        if(!vm.contact.id && !vm.contact.parentSource) {
            $state.go('contact.new.detail-tab');
            return;
        } else if(vm.contact.id && !vm.contact.parentSource) {
            $state.go('contact-detail.detail-tab', {id: vm.contact.id});
        }

        if($state.isEdit) {
        	vm.isEdit = $state.isEdit;
        }

        // detect is create new contact, so mode must is edit
        if (vm.contact.id === null) {
            vm.isEdit = true;
        }

        // this variable handle detail tab edit or not
        vm.isDirty = $state.isDirty;

        // broadcast block
        $rootScope.$broadcast('contact:activeTab', 'PREFERENCE');

        $scope.$on('$stateChangeStart', onStateChangeStart);

        $rootScope.$on('contact:changeMode', function(event, result) {
            vm.isEdit = result;
        });

        $rootScope.$on('contact:activated', function(event, result) {
            vm.contact.activated = result;
        });

        $scope.$on('$includeContentLoaded', function () {
            if (vm.isNoLocationPreference) {
                $scope.editForm.$dirty = true;
            }
        });
        // end broadcast block

        // scroll to top
        $rootScope.scrollTop();

        vm.save = save;
        vm.clear = clear;
        vm.duplicate = duplicate;

        vm.isOneRow = isOneRow;
        vm.removeLocation = removeLocation;
        vm.addLocation =  addLocation;
        vm.onSelectLocationCity = onSelectLocationCity;
        vm.onKeyUpLocationCity = onKeyUpLocationCity;
        vm.onSelectLocationCountry = onSelectLocationCountry;
        vm.onKeyUpLocationCountry = onKeyUpLocationCountry;
        vm.propertyLocationClick = propertyLocationClick;
        vm.propertyTransactionClick = propertyTransactionClick;
        vm.propertyTypeClick = propertyTypeClick;
        vm.internalRoomsClick = internalRoomsClick;
        vm.nearbyFacilitiesClick = nearbyFacilitiesClick;
        vm.outsideAreasClick = outsideAreasClick;
        vm.parkingFacilitiesClick = parkingFacilitiesClick;
        vm.facilitiesOfferedsClick = facilitiesOfferedsClick;
        vm.reasonForPurchasesClick = reasonForPurchasesClick;
        vm.materialsClick = materialsClick;

        vm.hideAmenity = hideAmenity;

        vm.priceRangeValidate = priceRangeValidate;
        vm.bedRoomNumberValidate = bedRoomNumberValidate;
        vm.bathRoomNumberValidate = bathRoomNumberValidate;
        vm.sizeRangeValidate = sizeRangeValidate;


        init();

        function init() {

            // currency des list
            vm.currenciesDes = CurrencyConstant.DES;

            // init property location
            vm.propertyLocations = propertyLocationsResource;

            // init internal rooms
            vm.internalRooms = internalRoomsResource;

            // init outside area
            vm.outsideAreas = outsideAreasResource;

            // init facilities offers
            vm.facilitiesOffereds = facilitiesOffersResource;

            // init parking facilities
            vm.parkingFacilities = parkingFacilitiesResource;

            // init nearby facilities
            vm.nearbyFacilities = nearbyFacilitiesResource;

            // init property types
            vm.propertyTypes = propertyTypesResource;

            // init property transactions
            vm.propertyTransactions = propertyTransactionsResource;

            // init construction status
            vm.constructionStatuses = constructionStatusResource;

            // init reason for purchase
            vm.reasonForPurchases = reasonForPurchaseResource;

            vm.materials = materialsResource;

            // init access road
            vm.accessRoads = accessRoadResource;

            // init size units
            vm.unitTypes = PropertyConstant.propertyUnitType.LIST;

            // init term list
            vm.feeTerms = PropertyConstant.feeTerm.LIST;

            // init road unit type
            vm.roadUnitTypes = PropertyConstant.roadUnitType.LIST;

            vm.currencyUnits = CurrencyConstant.LIST;

            // init contact preferences
            if(vm.contact.contactPreferences) {
                // if is create new contact add at least one contact preference
                if(vm.contact.contactPreferences.length === 0) {
                    addLocation();
                } else {

                    /*
                    * add list variable to content value checkbox value
                    * 'property transaction' - 'property location' - 'property type'
                    * 'internal room' - 'outside area' - 'nearby facility'
                    * 'facility offer' - 'parking facility'
                    * */
                    angular.forEach(vm.contact.contactPreferences, function(contactPreference, key) {
                        contactPreference.transactionsSelect = [];
                        contactPreference.locationsSelect = [];
                        contactPreference.propertyTypesSelect = [];
                        contactPreference.internalRoomsSelect = [];
                        contactPreference.outsideAreasSelect = [];
                        contactPreference.nearbyFacilitiesSelect = [];
                        contactPreference.facilitiesOfferedsSelect = [];
                        contactPreference.parkingFacilitiesSelect = [];
                        contactPreference.reasonForPurchasesSelect = [];
                        contactPreference.materialsSelect = [];

                        // for old data
                        if(contactPreference.materials == null){
                        	contactPreference.materials = [];
                        }
                    });


                }

            }

            //load data contact preference
            angular.forEach(vm.contact.contactPreferences, function(contactPreference, key) {

                // load data property transaction
                if(contactPreference.propertyTransactions.length > 0) {
                    angular.forEach(contactPreference.propertyTransactions, function(value, key) {
                        contactPreference.transactionsSelect[value.id] = true;
                    });
                }

                // load data property location
                if(contactPreference.propertyLocations.length > 0) {
                    angular.forEach(contactPreference.propertyLocations, function(value, key) {
                        contactPreference.locationsSelect[value.id] = true;
                    });
                }

                // load data property type
                if(contactPreference.propertyTypes.length > 0) {
                    angular.forEach(contactPreference.propertyTypes, function(value, key) {
                        contactPreference.propertyTypesSelect[value.id] = true;
                    });
                }

                // load data internal room
                if(contactPreference.internalRooms.length > 0) {
                    angular.forEach(contactPreference.internalRooms, function(value, key) {
                        contactPreference.internalRoomsSelect[value.id] = true;
                    });
                }

                // load data outside area
                if(contactPreference.outsideAreas.length > 0) {
                    angular.forEach(contactPreference.outsideAreas, function(value, key) {
                        contactPreference.outsideAreasSelect[value.id] = true;
                    });
                }

                // load data nearby facility
                if(contactPreference.nearbyFacilities.length > 0) {
                    angular.forEach(contactPreference.nearbyFacilities, function(value, key) {
                        contactPreference.nearbyFacilitiesSelect[value.id] = true;
                    });
                }

                // load data facility offer
                if(contactPreference.facilitiesOffereds.length > 0) {
                    angular.forEach(contactPreference.facilitiesOffereds, function(value, key) {
                        contactPreference.facilitiesOfferedsSelect[value.id] = true;
                    });
                }

                // load data parking facility
                if(contactPreference.parkingFacilities.length > 0) {
                    angular.forEach(contactPreference.parkingFacilities, function(value, key) {
                        contactPreference.parkingFacilitiesSelect[value.id] = true;
                    });
                }

                // load reason for purchase
                if(contactPreference.reasonForPurchases.length > 0) {
                    angular.forEach(contactPreference.reasonForPurchases, function(value, key) {
                        contactPreference.reasonForPurchasesSelect[value.id] = true;
                    });
                }

                if(contactPreference.materials != null && contactPreference.materials.length > 0) {
                    angular.forEach(contactPreference.materials, function(value, key) {
                        contactPreference.materialsSelect[value.id] = true;
                    });
                }

                // init show hide amenity
                contactPreference.showAmenity = false;


                if(contactPreference.fromBedRoomNumber === 0) {
                    contactPreference.fromBedRoomNumberDisplay = 'STU';
                } else {
                    contactPreference.fromBedRoomNumberDisplay = contactPreference.fromBedRoomNumber;
                }
                if(contactPreference.toBedRoomNumber === 0) {
                    contactPreference.toBedRoomNumberDisplay = 'STU';
                } else {
                    contactPreference.toBedRoomNumberDisplay = contactPreference.toBedRoomNumber;
                }

                contactPreference.sizeConverter = MCService.init(contactPreference.sizeUnit);


                // dectect property transaction to show/hide fees parking and gym
                detectPropertyTransaction(contactPreference);


            });

            // init district
            initDistrict ();

            // init city
            initCity ();

            // init province
            initProvince ();

            // get country list
            vm.countries = countryResource;

        }

        function initDistrict () {
            //init HCM district
            vm.districtHCMMasterData = districtHCMResource;

            //init HA_NOI district
            vm.districtHANMasterData = districtHANResource;

            //init DA_NANG district
            vm.districtDNGMasterData = districtDNGResource;

            /*
        	* if city is HCM or HANOI or DANANG, district will list following city corresponding and user can't input value
        	* else list district is empty and user can input value
        	* */
            angular.forEach(vm.contact.contactPreferences, function(contactPreference, key) {
                contactPreference.location.districtTypeaheadEditable = false;
                if(contactPreference.location.city == 'Ho Chi Minh City'){
                    contactPreference.location.districtList = vm.districtHCMMasterData;
                } else if(contactPreference.location.city == 'Ha Noi'){
                    contactPreference.location.districtList = vm.districtHANMasterData;
                } else if(contactPreference.location.city == 'Da Nang'){
                    contactPreference.location.districtList = vm.districtDNGMasterData;
                } else {
                    contactPreference.location.districtTypeaheadEditable = true;
                }
            });
        }

        function initCity () {
            vm.cityMasterData = cityResource;

            angular.forEach(vm.contact.contactPreferences, function(contactPreference, key) {
                if(contactPreference.location.country == null || contactPreference.location.country == '' || contactPreference.location.country.toUpperCase() == 'vietnam'.toUpperCase()){
                    contactPreference.location.cityList = vm.cityMasterData;
                } else {
                    contactPreference.location.cityList = [];
                }
            });
        }

        function initProvince () {
            vm.provinceMasterData = provinceResource;

            angular.forEach(vm.contact.contactPreferences, function(contactPreference, key) {
                if(contactPreference.location.country == null || contactPreference.location.country == '' || contactPreference.location.country.toUpperCase() == 'vietnam'.toUpperCase()){
                    contactPreference.location.provinceList = vm.provinceMasterData;
                } else {
                    contactPreference.location.provinceList = [];
                }
            });
        }

        //push location object into list locations
        function addLocation() {
            var location = addLocationInfo();
            vm.contact.contactPreferences.push(location);
        }

        // remove location from list location
        function removeLocation(index) {
        	if(vm.contact.contactPreferences[index].id){
        		$uibModal.open({
        			templateUrl: 'app/components/confirmation-dialog/delete/confirm-dialog.html',
        			controller: 'DeleteConfirmDialogController',
        			controllerAs: 'vm',
        			size: 'sm',
        			windowClass : 'absolute-center',
        			resolve: {
        				entity: function(){
        					return null;
        				},
        				translateKeys: function(){
        					return {
        						keys: 'indochinaApp.property.confirmDeleteSales'
        					};
        				}
        			}
        		}).result.then(function() {
        			$scope.editForm.$dirty = true;
                    vm.contact.contactPreferences.splice(index, 1);
        		}, function() {
        		});
        	}
        	else{        		
        		$scope.editForm.$dirty = true;
        		vm.contact.contactPreferences.splice(index, 1);
        	}
        }

        // create location detail information
        function addLocationInfo() {
            return {
                location: {
                    districtList: [],
                    cityList: vm.cityMasterData,
                    provinceList: vm.provinceMasterData,
                    country: 'Vietnam',
                    province: null,
                    city: null,
                    district: null,
                    id: null
                },
                feeManagementCurrency: vm.currencyUnits[0],
                feeManagementTerm: vm.feeTerms[0],
                feeParkingCurrency: vm.currencyUnits[0],
                feeParkingTerm: vm.feeTerms[0],
                feeGymCurrency: vm.currencyUnits[0],
                feeGymTerm: vm.feeTerms[0],
                currency: null,
                minimumPrice: null,
                maximumPrice: null,
                fromBedRoomNumber: null,
                toBedRoomNumber: null,
                fromBathRoomNumber: null,
                toBathRoomNumber: null,
                sizeFrom: null,
                sizeTo: null,
                sizeUnit: 'SQ_METER',
                sizeConverter: MCService.init('SQ_METER'),
                constructionStatuses: [],
                propertyTransactions: [],
                countries: [],
                propertyTypes: [],
                propertyLocations: [],
                internalRooms: [],
                outsideAreas: [],
                facilitiesOffereds: [],
                nearbyFacilities: [],
                parkingFacilities: [],
                reasonForPurchases: [],
                materials: [],
                accessRoads: []
            };
        }

        // create duplicate contact preference
        function duplicateLocationInfo(contactPreference) {
            // set id null to create new object for location in contact preference
            contactPreference.location.id = null;

            return {
                location: contactPreference.location,
                currency: contactPreference.currency,
                minimumPrice: contactPreference.minimumPrice,
                maximumPrice: contactPreference.maximumPrice,
                fromBedRoomNumber: contactPreference.fromBedRoomNumber,
                fromBedRoomNumberDisplay: contactPreference.fromBedRoomNumberDisplay,
                toBedRoomNumber: contactPreference.toBedRoomNumber,
                toBedRoomNumberDisplay: contactPreference.toBedRoomNumberDisplay,
                fromBathRoomNumber: contactPreference.fromBathRoomNumber,
                toBathRoomNumber: contactPreference.toBathRoomNumber,
                sizeFrom: contactPreference.sizeFrom,
                sizeTo: contactPreference.sizeTo,
                sizeUnit: contactPreference.sizeUnit,
                constructionStatuses: contactPreference.constructionStatuses,
                propertyTransactions: contactPreference.propertyTransactions,
                countries: contactPreference.countries,
                propertyTypes: contactPreference.propertyTypes,
                propertyLocations: contactPreference.propertyLocations,
                internalRooms: contactPreference.internalRooms,
                outsideAreas: contactPreference.outsideAreas,
                facilitiesOffereds: contactPreference.facilitiesOffereds,
                nearbyFacilities: contactPreference.nearbyFacilities,
                parkingFacilities: contactPreference.parkingFacilities,
                reasonForPurchases: contactPreference.reasonForPurchases,
                materials: contactPreference.materials,

                // all variable below contact value checkbox value in UI
                transactionsSelect: contactPreference.transactionsSelect,
                locationsSelect: contactPreference.locationsSelect,
                propertyTypesSelect: contactPreference.propertyTypesSelect,
                internalRoomsSelect: contactPreference.internalRoomsSelect,
                outsideAreasSelect: contactPreference.outsideAreasSelect,
                nearbyFacilitiesSelect: contactPreference.nearbyFacilitiesSelect,
                facilitiesOfferedsSelect: contactPreference.facilitiesOfferedsSelect,
                parkingFacilitiesSelect: contactPreference.parkingFacilitiesSelect,
                reasonForPurchasesSelect: contactPreference.reasonForPurchasesSelect,
                materialsSelect: contactPreference.materialsSelect,
                feeManagementNumber: contactPreference.feeManagementNumber,
                feeManagementCurrency: contactPreference.feeManagementCurrency,
                feeManagementTerm: contactPreference.feeManagementTerm,
                feeParkingNumber: contactPreference.feeParkingNumber,
                feeParkingCurrency: contactPreference.feeParkingCurrency,
                feeParkingTerm: contactPreference.feeParkingTerm,
                feeGymNumber: contactPreference.feeGymNumber,
                feeGymCurrency: contactPreference.feeGymCurrency,
                feeGymTerm: contactPreference.feeGymTerm,
                sizeConverter: contactPreference.sizeConverter,

                // property transaction detect
                isForLease: contactPreference.isForLease,
                isForSale: contactPreference.isForSale,

                // show hide variable
                showAmenity: contactPreference.showAmenity
            };
        }


        // function duplicate a contact preference
        function duplicate (index) {
            var contactPreferenceForDuplicate = angular.copy(vm.contact.contactPreferences[index]);
            var contactPreference = duplicateLocationInfo(contactPreferenceForDuplicate);
            vm.contact.contactPreferences.push(contactPreference);
            $scope.editForm.$dirty = true;
        }

        function validate (contact) {
            // avoid user click save many time
            vm.isSaving = true;
            
            Contact.validate(contact, onValidateSuccess, onValidateError);
        }

        function onValidateSuccess (contact) {
            saveContact (contact);
        }

        function onValidateError (error) {
            if(error.status == 400) {
                if(error.data === '') { //
                    AlertService.error($translate.instant('error.duplicate', {}));
                } else {
                    var contactHaveBankAccountDuplicate = error.data.name;
                    AlertService.error($translate.instant('error.duplicateBankAccount', { param: contactHaveBankAccountDuplicate }));
                }
                vm.isSaving = false;
                $scope.editForm.$dirty = true;
            }
        }

        function save () {

            // reset form edit
            $scope.editForm.$dirty = false;
            
            // avoid user click save many time
            vm.isSaving = true;

            var copy = angular.copy (vm.contact);
            
            cleanContact (copy) ;

            validate (copy);
        }

        function cleanContact (contact) {

            // detect isCompany or individual
            if(contact.isCompany === true) {
                contact.person = null;
            } else {
                contact.company = null;
            }

            // change value BedRoom to save into database
            angular.forEach(contact.contactPreferences, function(contactPreference, key) {
                if(contactPreference.fromBedRoomNumber == 'STU'){
                    contactPreference.fromBedRoomNumber = 0;
                }
                if(contactPreference.toBedRoomNumber == 'STU'){
                    contactPreference.toBedRoomNumber = 0;
                }
                // convert price if currency # USD
                if(contactPreference.minimumPrice && contactPreference.currency !== 'USD') {
                    contactPreference.minimumPrice = Math.round(contactPreference.minimumPrice);
                }

                if(contactPreference.maximumPrice && contactPreference.currency !== 'USD') {
                    contactPreference.maximumPrice = Math.round(contactPreference.maximumPrice);
                }

                // convert fees price if currency # USD
                if(contactPreference.feeManagementCurrency !== 'USD') {
                    contactPreference.feeManagementNumber = Math.round(contactPreference.feeManagementNumber);
                }
                if(!contactPreference.isForLease) {
                    contactPreference.feeParkingNumber = null;
                    contactPreference.feeGymNumber = null;
                    contactPreference.feeParkingCurrency = vm.currencyUnits[0];
                    contactPreference.feeGymCurrency = vm.currencyUnits[0];
                    contactPreference.feeParkingTerm = vm.feeTerms[0];
                    contactPreference.feeGymTerm = vm.feeTerms[0]
                } else {
                    if(contactPreference.feeParkingCurrency !== 'USD') {
                        contactPreference.feeParkingNumber = Math.round(contactPreference.feeParkingNumber);
                    }
                    if(contactPreference.feeGymCurrency !== 'USD') {
                        contactPreference.feeGymNumber =  Math.round(contactPreference.feeGymNumber);
                    }
                }


                if(contactPreference.fromBedRoomNumberDisplay === 'STU') {
                    contactPreference.fromBedRoomNumber = 0;
                } else {
                    contactPreference.fromBedRoomNumber = contactPreference.fromBedRoomNumberDisplay;
                }
                if(contactPreference.toBedRoomNumberDisplay === 'STU') {
                    contactPreference.toBedRoomNumber = 0
                } else {
                    contactPreference.toBedRoomNumber = contactPreference.toBedRoomNumberDisplay;
                }

            });


            // update full name for contact
            if(contact.isCompany) {
                contact.name = contact.company.tradeName.name;
            } else {
                contact.name = contact.person.firstName + ' ' + contact.person.lastName;
            }

            /*
            * action change tab didn't clear all bank accounts
            * so we clear before save
            * */
            contact.bankAccounts = null;
        }

        function saveContact (contact) {
            if (contact.id !== null) {
                vm.isNew = false;
                Contact.update(contact, onSaveSuccess, onSaveError);
                if(vm.isCompanyStatus !== contact.isCompany) {
                    Opportunity.deletePersonWhenCompanyChange({id: contact.id});
                }
            } else {
                vm.isNew = true;
                Contact.save(contact, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {



            vm.isSaving = false;

            // update isDirty variable state
            $state.isDirty = false;
            vm.isDirty = false;

            // update entity
            $scope.$emit('indochinaApp:contactUpdate', result);

            $scope.editForm.$setPristine();

            hasEditingPermisson(result);
        }

        function onSaveError (error) {
            /*
            * detect for error code 400
            * if data have name so this is error notify which contact have bank account duplicate
            * else duplicate contact
            * */
            if(error.status == 400) {
                if(error.data === '') { //
                    AlertService.error($translate.instant('error.duplicate', {}));
                } else {
                    var contactHaveBankAccountDuplicate = error.data.name;
                    AlertService.error($translate.instant('error.duplicateBankAccount', { param: contactHaveBankAccountDuplicate }));
                }
                vm.isSaving = false;
                $scope.parentForm.$dirty = true;
            }

        }

        // this function to detect agent assign have permission to access contact or not when save complete (Dev: Chau Cao)
        function hasEditingPermisson (result) {
            var hasEditingPermissonDefer = $q.defer();

            if(vm.isBuyer){
                if(Principal.hasAnyAuthority([AuthorityConstant.ROLE_AGENT])){
                    if(result.agent != null && result.agent.id == login.id){
                        hasEditingPermissonDefer.resolve(true);
                    }
                    else if(result.transferAgent != null && result.transferAgent.id == login.id){
                        hasEditingPermissonDefer.resolve(true);
                    }
                    else{
                        hasEditingPermissonDefer.resolve(false);
                    }
                }
                else if(Principal.hasAnyAuthority([AuthorityConstant.ROLE_MANAGER])){
                    // check with manager
                    if(result.agent != null && result.agent.id == login.id){
                        hasEditingPermissonDefer.resolve(true);
                    }
                    else if(result.transferAgent != null && result.transferAgent.id == login.id){
                        hasEditingPermissonDefer.resolve(true);
                    }
                    else{
                        User.getAgentListByManagerId({id: login.id}, function(listAgent) {
                            // check with manager
                            var hasAgent = listAgent.some(function(agent){
                                if(agent.id == result.agent.id){
                                    return true;
                                }
                                if(result.transferAgent != null && agent.id == result.transferAgent.id){
                                    return true;
                                }
                                return false;
                            });

                            hasEditingPermissonDefer.resolve(hasAgent);
                        });
                    }
                }
                else{
                    hasEditingPermissonDefer.resolve(true);
                }
            }
            else{
                hasEditingPermissonDefer.resolve(true);
            }

            hasEditingPermissonDefer.promise.then(function(hasEditingPermisson){

                // go to detail state if create contact complete and have permission
                if(hasEditingPermisson){
                    if(vm.isNew === true) {
                        $state.isEdit = false;
                        $state.go('contact-detail.detail-tab', {id: result.id});
                    } else {
                        // change variable isSaving
                        vm.isSaving = false;

                        // change mode edit in state
                        $state.isEdit = false;

                        // change mode on current state
                        vm.isEdit = false;

                        // broadcast event to appear button edit
                        $rootScope.$broadcast('contact:changeMode', false);

                        $state.reload('contact-detail');
                    }
                }
                else{
                    $uibModal.open({
                        templateUrl: 'app/components/confirmation-dialog/inform/confirm-dialog.html',
                        controller: 'InformConfirmDialogController',
                        controllerAs: 'vm',
                        size: 'sm',
                        windowClass : 'absolute-center',
                        resolve: {
                            entity: null,
                            translateKeys: function(){
                                return {
                                    keys: 'indochinaApp.contact.informDoNotHasPermission'
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('contact');
                    }, function() {
                        $state.go('contact');
                    });
                }
            });
        }

        function clear () {

            // if is create new contact will back to contact list
            if(vm.contact.id === null) {
                $state.go('contact', {reload: false});
                return;
            }

            if($state.isDirty || $scope.editForm.$dirty) {

                // if have any change we must reload page to reset entity
                $state.reload('contact-detail.contact-prefer-tab');

                return;
            }

            // change mode edit in state
            $state.isEdit = false;

            // update isDirty variable state
            $state.isDirty = false;

            // change mode on current state
            vm.isEdit = false;

            // broadcast event to appear button edit
            $rootScope.$broadcast('contact:changeMode', false);

            //scroll to top page
            $rootScope.scrollTop();
        }

        function isOneRow(){
        	if(vm.contact.contactPreferences.length === 1) {
        		return true;
        	}
        	return false;
        }



        function onSelectLocationCountry(item, model, label, index) {

            /*
            * if country is 'vietnam', province and city will auto complete and user can't input value
            * else if country is blank, province and city will auto complete and user can input value
            * else if country is not blank and not is vietnam, user input value
            * */
            if(item.name.toUpperCase() === 'vietnam'.toUpperCase()){
            	vm.contact.contactPreferences[index].location.provinceList = vm.provinceMasterData;
            	vm.contact.contactPreferences[index].location.cityList = vm.cityMasterData;
            } else {
            	vm.contact.contactPreferences[index].location.provinceList = [];

            	vm.contact.contactPreferences[index].location.cityList = [];
            }

            // set null city province and district when choice a country
            vm.contact.contactPreferences[index].location.city = null;
            vm.contact.contactPreferences[index].location.province = null;
            vm.contact.contactPreferences[index].location.district = null;

            // reset List district to empty and user can input value
            vm.contact.contactPreferences[index].location.districtList = [];
    		vm.contact.contactPreferences[index].location.districtTypeaheadEditable = true;
        }

        function onKeyUpLocationCountry(index){

            /*
            * if country is blank set province and city List to master
            * */
            if(!vm.contact.contactPreferences[index].location.country || vm.contact.contactPreferences[index].location.country === ''){
                vm.contact.contactPreferences[index].location.provinceList = vm.provinceMasterData;
                vm.contact.contactPreferences[index].location.cityList = vm.cityMasterData;
            }
        }

        function onSelectLocationCity($item, $model, $label, index) {

            // reset value of district
        	vm.contact.contactPreferences[index].location.district = null;

        	/*
        	* if city is HCM or HANOI or DANANG, district will list following city corresponding and user can't input value
        	* else list district is empty and user can input value
        	* */
            vm.contact.contactPreferences[index].location.districtTypeaheadEditable = false;
        	if($item.code === 'HCM'){
        		vm.contact.contactPreferences[index].location.districtList = vm.districtHCMMasterData;
        	} else if($item.code === 'HAN'){
        		vm.contact.contactPreferences[index].location.districtList = vm.districtHANMasterData;
        	} else if($item.code === 'DNG'){
        		vm.contact.contactPreferences[index].location.districtList = vm.districtDNGMasterData;
        	} else{
        		vm.contact.contactPreferences[index].location.districtList = [];
        		vm.contact.contactPreferences[index].location.districtTypeaheadEditable = true;
        	}
        };

        function onKeyUpLocationCity(index){

            /*
            * if city is null or blank, reset district list and user can input value
            * */
        	if(vm.contact.contactPreferences[index].location.city === null || vm.contact.contactPreferences[index].location.city === ''){
        		vm.contact.contactPreferences[index].location.districtList = [];
        		vm.contact.contactPreferences[index].location.districtTypeaheadEditable = true;
        	}
        }

        function onStateChangeStart(event, toState, toParams, fromState, fromParams) {

            // this code help detect detail tab or contact preference tab edit or not
            if($scope.editForm.$dirty){
                $state.isDirty = true;
            }

            if(($state.isDirty || $scope.editForm.$dirty) && toState.name !== 'contact-detail.detail-tab' && toState.name !== 'contact.new.detail-tab' && vm.isEdit && !$rootScope.isSessionTimeOut){

            	event.preventDefault();

                $uibModal.open({
                    templateUrl: 'app/components/confirmation-dialog/leave/confirm-dialog.html',
                    controller: 'LeaveConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                        entity: function(){
                        	return null;
                        },
                        translateKeys: function(){
                        	return {
                        		keys: 'indochinaApp.property.confirmLeavePage'
                        	};
                        }
                    }
                }).result.then(function() {

                    // avoid loop onStateChange function
                	$scope.editForm.$dirty = false;

                    // change mode edit in state
                    $state.isEdit = false;

                    // update isDirty variable state
                    $state.isDirty = false;

                	if(toState.name === 'contact-detail.contact-prefer-tab'){

                        // reload parent to reset entity
                        $state.reload('contact-detail');

                        // broadcast event to appear button edit
                        $rootScope.$broadcast('contact:changeMode', false);
                	}
                	else{
                		$state.go(toState, toParams, { reload: true });
                	}
                }, function() {
//                    $state.go('^');
                });
            }
        }

        function priceRangeValidate () {

            var result = null;
            var i = 0;

            // check all locations to detect which location have price range invalid to display in UI
            angular.forEach(vm.contact.contactPreferences, function(contactPreference, key) {
                result = false;
                if(contactPreference.minimumPrice && contactPreference.maximumPrice && (Number(contactPreference.minimumPrice) > Number(contactPreference.maximumPrice))) {
                    result = true;
                } else {
                    i++;
                }
                contactPreference.priceRangeInValid = result;
            });

            // if i == length of contact, all location don't have any price range invalid
            if(i !== vm.contact.contactPreferences.length) {
                result = true;
            }

            return result;

        }

        function bedRoomNumberValidate () {

            var result = null;
            var i = 0;

            // check all bedrooms to detect which location have bedroom range invalid to display in UI
            angular.forEach(vm.contact.contactPreferences, function(contactPreference, key) {
                result = false;
                if(contactPreference.fromBedRoomNumberDisplay && contactPreference.toBedRoomNumberDisplay) {
                    if((Number(contactPreference.fromBedRoomNumberDisplay) > Number(contactPreference.toBedRoomNumberDisplay))) {
                        result = true;
                    } else if (contactPreference.toBedRoomNumberDisplay === 'STU' && Number(contactPreference.fromBedRoomNumberDisplay) > 0) {
                        result = true;
                    } else {
                        i++;
                    }

                } else {
                    i++;
                }

                contactPreference.bedRoomRangeInValid = result;
            });
            // if i == length of contact, all location don't have any bedroom range invalid
            if(i !== vm.contact.contactPreferences.length) {
                result = true;
            }

            return result;

        }

        function bathRoomNumberValidate () {

            var result = null;
            var i = 0;

            // check all bathrooms to detect which location have price bathroom invalid to display in UI
            angular.forEach(vm.contact.contactPreferences, function(contactPreference, key) {
                result = false;
                if(contactPreference.fromBathRoomNumber && contactPreference.toBathRoomNumber && (Number(contactPreference.fromBathRoomNumber) > Number(contactPreference.toBathRoomNumber))) {
                    result = true;
                } else {
                    i++;
                }
                contactPreference.bathRommRangeInValid = result;
            });

            // if i == length of contact, all location don't have any bathroom range invalid
            if(i !== vm.contact.contactPreferences.length) {
                result = true;
            }

            return result;
        }

        function sizeRangeValidate () {

            var result = null;
            var i = 0;

            // check all locations to detect which location have size invalid to display in UI
            angular.forEach(vm.contact.contactPreferences, function(contactPreference, key) {
                result = false;
                if(contactPreference.sizeFrom && contactPreference.sizeTo && (Number(contactPreference.sizeFrom) > Number(contactPreference.sizeTo))) {
                    result = true;
                } else {
                    i++;
                }
                contactPreference.sizeRangeInValid = result;
            });


            // if i == length of contact, all location don't have any size range invalid
            if(i !== vm.contact.contactPreferences.length) {
                result = true;
            }

            return result;
        }

        // property transaction click function
        function propertyTransactionClick (index, isCheck, propertyTransaction) {

            if(isCheck === true) {
                vm.contact.contactPreferences[index].propertyTransactions.push(propertyTransaction);
            } else {
                var i = 0;
                angular.forEach(vm.contact.contactPreferences[index].propertyTransactions, function(value, key) {
                    if(propertyTransaction.id === value.id) {
                        vm.contact.contactPreferences[index].propertyTransactions.splice(i, 1);
                    }
                    i++;
                });
            }

            // dectect property transaction to show/hide fees parking and gym
            detectPropertyTransaction(vm.contact.contactPreferences[index]);
        }

        // property type click function
        function propertyTypeClick (index, isCheck, propertyType) {
            if(isCheck === true) {
                vm.contact.contactPreferences[index].propertyTypes.push(propertyType);
            } else {
                var i = 0;
                angular.forEach(vm.contact.contactPreferences[index].propertyTypes, function(value, key) {
                    if(propertyType.id === value.id) {
                        vm.contact.contactPreferences[index].propertyTypes.splice(i, 1);
                    }
                    i++;
                });
            }
        }

        // property location click function
        function propertyLocationClick (index, isCheck, propertyLocation) {
        	if(isCheck === true) {
        		vm.contact.contactPreferences[index].propertyLocations.push(propertyLocation);
        	} else {
        		var i = 0;
        		angular.forEach(vm.contact.contactPreferences[index].propertyLocations, function(value, key) {
        			if(propertyLocation.id === value.id) {
        				vm.contact.contactPreferences[index].propertyLocations.splice(i, 1);
        			}
                    i++;
                });
        	}
        }

        // internal room click function
        function internalRoomsClick (index, isCheck, internalRoom) {
        	if(isCheck === true) {
        		vm.contact.contactPreferences[index].internalRooms.push(internalRoom);
        	} else {
        		var i = 0;
        		angular.forEach(vm.contact.contactPreferences[index].internalRooms, function(value, key) {
        			if(internalRoom.id === value.id) {
        				vm.contact.contactPreferences[index].internalRooms.splice(i, 1);
        			}
                    i++;
                });
        	}
        }

        // nearby facility click function
        function nearbyFacilitiesClick (index, isCheck, nearbyFacility) {
        	if(isCheck === true) {
        		vm.contact.contactPreferences[index].nearbyFacilities.push(nearbyFacility);
        	} else {
        		var i = 0;
        		angular.forEach(vm.contact.contactPreferences[index].nearbyFacilities, function(value, key) {
        			if(nearbyFacility.id === value.id) {
        				vm.contact.contactPreferences[index].nearbyFacilities.splice(i, 1);
        			}
                    i++;
                });
        	}
        }

        // outside area click function
        function outsideAreasClick (index, isCheck, outsideArea) {
        	if(isCheck === true) {
        		vm.contact.contactPreferences[index].outsideAreas.push(outsideArea);
        	} else {
        		var i = 0;
        		angular.forEach(vm.contact.contactPreferences[index].outsideAreas, function(value, key) {
        			if(outsideArea.id === value.id) {
        				vm.contact.contactPreferences[index].outsideAreas.splice(i, 1);
        			}
                    i++;
                });
        	}
        }

        // parking facility click function
        function parkingFacilitiesClick (index, isCheck, parkingFacility) {
        	if(isCheck === true) {
        		vm.contact.contactPreferences[index].parkingFacilities.push(parkingFacility);
        	} else {
        		var i = 0;
        		angular.forEach(vm.contact.contactPreferences[index].parkingFacilities, function(value, key) {
        			if(parkingFacility.id === value.id) {
        				vm.contact.contactPreferences[index].parkingFacilities.splice(i, 1);
        			}
                    i++;
                });
        	}
        }

        // facility offer click function
        function facilitiesOfferedsClick (index, isCheck, facilitiesOffered) {
        	if(isCheck === true) {
        		vm.contact.contactPreferences[index].facilitiesOffereds.push(facilitiesOffered);
        	} else {
        		var i = 0;
        		angular.forEach(vm.contact.contactPreferences[index].facilitiesOffereds, function(value, key) {
        			if(facilitiesOffered.id === value.id) {
        				vm.contact.contactPreferences[index].facilitiesOffereds.splice(i, 1);
        			}
                    i++;
                });
        	}
        }

        // reason for purchase click function
        function reasonForPurchasesClick (index, isCheck, reasonForPurchase) {
            if(isCheck === true) {
                vm.contact.contactPreferences[index].reasonForPurchases.push(reasonForPurchase);
            } else {
                var i = 0;
                angular.forEach(vm.contact.contactPreferences[index].reasonForPurchases, function(value, key) {
                    if(reasonForPurchase.id === value.id) {
                        vm.contact.contactPreferences[index].reasonForPurchases.splice(i, 1);
                    }
                    i++;
                });
            }
        }

        function materialsClick(index, isCheck, material) {
            if(isCheck === true) {
                vm.contact.contactPreferences[index].materials.push(material);
            } else {
                var i = 0;
                angular.forEach(vm.contact.contactPreferences[index].materials, function(value, key) {
                    if(material.id === value.id) {
                        vm.contact.contactPreferences[index].materials.splice(i, 1);
                    }
                    i++;
                });
            }
        }

        //function show hide all amenity
        function hideAmenity (index) {
            if(!vm.contact.contactPreferences[index].showAmenity) {
                vm.contact.contactPreferences[index].showAmenity = true;
            } else {
                vm.contact.contactPreferences[index].showAmenity = false;
            }
        }


        function detectPropertyTransaction (contactPreference) {
            if(contactPreference.propertyTransactions.length === 2) {
                contactPreference.isForSale = true;
                contactPreference.isForLease = true;
            } else if (contactPreference.propertyTransactions.length === 0) {
                contactPreference.isForSale = false;
                contactPreference.isForLease = false;
            } else {
                angular.forEach(contactPreference.propertyTransactions, function(propertyTransaction, key) {
                    if (propertyTransaction.id === 1) {
                        contactPreference.isForSale = true;
                    } else {
                        contactPreference.isForSale = false;
                    }

                    if (propertyTransaction.id === 2) {
                        contactPreference.isForLease = true;
                    } else {
                        contactPreference.isForLease = false;
                    }
                });
            }
        }
    }
})();
