(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('PropertyDetailControllerRe', PropertyDetailControllerRe);

    PropertyDetailControllerRe.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'previousState', 'entity' , 'User', '$uibModal',
                                        'Principal','propertySearch','PropertyConstant','PaginationUtil', 'pagingParamsForActivity', 'pagingParamsForActivityHistorical',
                                        'pagingParamsForOpportunity'];

    function PropertyDetailControllerRe($scope, $state, $rootScope, $stateParams, previousState, entity, User, $uibModal,
    									Principal, propertySearch, PropertyConstant, PaginationUtil, pagingParamsForActivity, pagingParamsForActivityHistorical,
    									pagingParamsForOpportunity) {

        var vm = this;

        if($state.isEdit === true) {
        	$state.isEdit = false;
        }

        if($state.isDuplication === true) {
        	$state.isDuplication = false;
        }

        if($state.current.name == 'property-detail.expose') {
            vm.isExposeState = true;
        }

        /*if($state.isDuplication) {
        	vm.isDuplication = true;
        }*/

        $rootScope.$on('property:changeMode', function(event, result) {
        	vm.isEdit = result;
        });
        $rootScope.$on('property:activeTab', function(event, result) {
            vm.isExposeState = false;
        	switch(result){
        	case 'DETAIL':
        		vm.isShowExposeButton = true;
        		vm.isShowDuplicateButton = true;
        		vm.isActOrOpp = false;
        		break;
        	case 'PHOTO':
        		vm.isShowExposeButton = true;
        		vm.isShowDuplicateButton = false;
        		vm.isActOrOpp = false;
        		break;
        	case 'PUBLISH':
        	case 'LEGAL':
        	case 'SALES':
        		vm.isShowExposeButton = false;
        		vm.isShowDuplicateButton = false;
        		vm.isActOrOpp = false;
        		break;
        	case 'ACTIVITY':
        	case 'OPPORTUNITY':
        		vm.isShowExposeButton = false;
        		vm.isShowDuplicateButton = false;
        		vm.isActOrOpp = true;
        		break;
        	}
        });

        $rootScope.$on('property:backFromExpose', function(event, result) {
            vm.isExposeState = false;
        });

        $rootScope.$on('property:isDuplication', function(event, result) {
        	vm.isDuplication = result;
        });
        
        $rootScope.$on('property:propertyDetailUpdated', function(event, result) {
        	vm.property.title = result.title;
        });
        
        $state.pagingParams = {
        		page: PaginationUtil.parsePage(pagingParamsForActivity.page),
                sort: pagingParamsForActivity.sort,
                predicate: PaginationUtil.parsePredicate(pagingParamsForActivity.sort),
                ascending: PaginationUtil.parseAscending(pagingParamsForActivity.sort)
        }
        $state.pagingParamsHistorical = {
        		page: PaginationUtil.parsePage(pagingParamsForActivityHistorical.page),
                sort: pagingParamsForActivityHistorical.sort,
                predicate: PaginationUtil.parsePredicate(pagingParamsForActivityHistorical.sort),
                ascending: PaginationUtil.parseAscending(pagingParamsForActivityHistorical.sort)
        }

        $state.pagingParamsForOpportunity = pagingParamsForOpportunity;

        vm.property = entity;
        vm.previousState = previousState.name;
        vm.previousStateParams = previousState.params;

        vm.edit = edit;
        vm.duplicate = duplicate;
        vm.back = back;
        vm.goExpose = goExpose;

        function edit (){
        	vm.isEdit = true;
        	$state.isEdit = true;
        	$rootScope.$broadcast('property:changeMode', true);
        }

        function duplicate() {
        	$uibModal.open({
                templateUrl: 'app/entities/property/property-detail/property-detail-tab/duplicate-dialog.html',
                controller: 'PropertyDupliateDialogControllerRe',
                controllerAs: 'vm',
                size: 'sm',
                windowClass : 'absolute-center',
                resolve: {
                	entity: ['$stateParams', 'PropertyService', function($stateParams, PropertyService) {
                        return PropertyService.get({id : $stateParams.id}).$promise;
                    }]
                }
            }).result.then(function() {
            	vm.isDuplication = true;
            	$state.isDuplication = true;
            	$state.go('property.duplicate.detail-tab', {id: $stateParams.id});
            }, function() {
                //$state.go('^');
            });
        }

        function back () {
        	$state.go('property', propertySearch);
        }
        // for checking permission for edit
        var login = null;
        vm.isCheckPermission = isCheckPermission;
        Principal.identity().then(function(account) {

        	login = account;

        	if(account.authorities[0] === 'ROLE_MANAGER') {
        		User.getAgentListByManagerId({id: account.id}, function(result) {
        			vm.allAgentOfManager = result;
                });
        	}
        });

        function isCheckPermission() {

        	if(login.authorities[0] === 'ROLE_ADMIN' || login.authorities[0] === 'ROLE_SUPER_USER') {
        		return false;
        	}

        	if(vm.allAgentOfManager) {
        		for(var i = 0; i < vm.allAgentOfManager.length; i++) {
        			if(vm.property.userDTO && vm.allAgentOfManager[i].id === vm.property.userDTO.id) {
        				return false;
        			}
        		}
        	}

        	if(vm.property.userDTO && login.id === vm.property.userDTO.id) {
        		return false;
        	}

        	return true;
        }

        $scope.$on("curreny:change", function(event, item){
        	var price = null;
        	if(propertySearch.transactionType === PropertyConstant.transactionType.RENT) {
        		price = Math.ceil(item.valueOfUsdExchangeRate * PropertyConstant.price.MAX_SEARCH_LEASE_PRICE);
        	} else {
        		price = Math.ceil(item.valueOfUsdExchangeRate * PropertyConstant.price.MAX_SEARCH_PRICE);
        	}

        	propertySearch.valueOfUsdExchangeRate = item.valueOfUsdExchangeRate;

        	propertySearch.maxPrice = price;

        	propertySearch.stepRange = item.valueOfUsdExchangeRate * 100;

        	propertySearch.destinationCode = item.destinationCode;
        });

        function goExpose () {
            $state.go("property-detail.expose");
            vm.isExposeState =  true;
        }
    }
})();
