(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'AuthorityConstant'];

    function stateConfig($stateProvider, AuthorityConstant) {
        $stateProvider
        .state('property-detail.opportunity-tab', {
        	parent: 'property-detail',
        	url: '/opportunity-tab',
        	data: {
            	authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_ADMIN, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT]
            },
            templateUrl: 'app/entities/opportunity/opportunity-tab.html',
            controller: 'OpportunityTabController',
            controllerAs: 'vm',
            resolve: {
            	translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opportunity');
                    $translatePartialLoader.addPart('unitType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PropertyService', function($stateParams, PropertyService) {
                    return PropertyService.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        ;
    }

})();
