(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('ContactPreferencesDetailController', ContactPreferencesDetailController);

    ContactPreferencesDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ContactPreferences'];

    function ContactPreferencesDetailController($scope, $rootScope, $stateParams, previousState, entity, ContactPreferences) {
        var vm = this;

        vm.contactPreferences = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('indochinaApp:contactPreferencesUpdate', function(event, result) {
            vm.contactPreferences = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
