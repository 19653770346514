(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('ActivitiesTabDevelopmentController', ActivitiesTabDevelopmentController);

    ActivitiesTabDevelopmentController.$inject = ['$scope', '$state', '$rootScope', 'entity', 'agentResource', 'searchActivity', 'Activity', '$uibModal', 'login'];

    function ActivitiesTabDevelopmentController($scope, $state, $rootScope, entity, agentResource, searchActivity, Activity, $uibModal, login) {

        var vm = this;

        // get entity
        vm.development = entity;

        // define action
        vm.search = search;
        vm.clear = clear;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.activityClick = activityClick;
        vm.onAgentSelect = onAgentSelect;
        vm.onRemoveAgentSelect = onRemoveAgentSelect;

        // paging variable
        var pagingParams = $state.pagingParams;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = 5;

        init ();

        function init () {

            // init agent Resource
            vm.listAgents = agentResource;

            // load search condition
            vm.searchObj = searchActivity;
            vm.searchObj.propertyIds = vm.development.properties;
        }



        function search () {
            loadAll();
        }

        function clear () {

            //reset all search condition
            vm.searchObj.searchQuery =  null;
            vm.searchObj.status = 'OPEN';
            vm.searchObj.agentIds = [];
            vm.searchObj.agentObjectList = [];

            // search again
            search ();
        }


        if($state.isEdit) {
            vm.isEdit = $state.isEdit;
        }
        loadAll();

        $rootScope.$on('indochinaApp:activityUpdate', function(event, result) {
            transition();
        });

        /*$rootScope.$on('property:changeMode', function(event, result) {
            vm.isEdit = result;
        });*/

        function loadAll () {
            var apiParam = angular.copy(vm.searchObj);
            apiParam.page = pagingParams.page - 1;
            apiParam.size = vm.itemsPerPage;
            apiParam.sort = sort();
            Activity.getActivityByDevelopment(apiParam, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if(vm.predicate === 'person') {
                    result = [];
                    result.push('primanyPerson.firstName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                    result.push('primanyPerson.lastName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                }
                if(vm.predicate === 'owner') {
                    result = [];
                    result.push('owner.firstName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                    result.push('owner.lastName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                }
                if (vm.predicate !== 'id') {
                    result.push('id' + ',' + (vm.reverse ? 'asc' : 'desc'));
                }
                return result;
            }
            function onSuccess(data, headers) {
                //vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.activities = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                //AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.pagingParams.page = vm.page;
            $state.pagingParams.predicate = vm.predicate;
            $state.pagingParams.ascending = vm.reverse;
            $state.pagingParams.sort = vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc');
            loadAll();
        }

        function activityClick (activity) {
            $uibModal.open({
                templateUrl: 'app/entities/activity/activity-dialog.html',
                controller: 'ActivityDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    login: login,
                    isFollowUpActivity: function () {
                        return false;
                    },
                    contact: function () {
                        return vm.contact;
                    },
                    entity: function () {
                        return Activity.get({id : activity.id}).$promise;
                    }
                }
            }).result.then(function() {
                $state.go('activity', null, { reload: 'activity' });
            }, function() {

            });
        }


        function onAgentSelect (item) {

            // check contact type to init contact types list display in UI
            vm.searchObj.agentIds.push(item.id);
        }

        function onRemoveAgentSelect (item) {
            vm.searchObj.agentIds = [];
            angular.forEach(vm.searchObj.agentObjectList, function(value, key) {
                vm.searchObj.agentIds.push(value.id);
            });
        }
    }
})();
