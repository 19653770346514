(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('MaterialsDeleteController',MaterialsDeleteController);

    MaterialsDeleteController.$inject = ['$uibModalInstance', 'entity', 'Materials'];

    function MaterialsDeleteController($uibModalInstance, entity, Materials) {
        var vm = this;

        vm.materials = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Materials.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
