(function() {
    'use strict';
    angular
        .module('indochinaApp')
        .factory('PropertyService', PropertyService);

    PropertyService.$inject = ['$resource', 'DateUtils', '$http', '$q'];

    function PropertyService ($resource, DateUtils, $http, $q) {
        var resourceUrl =  'api/properties/:id';

        var service = $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.created = DateUtils.convertDateTimeFromServer(data.created);
                        data.modified = DateUtils.convertDateTimeFromServer(data.modified);
                        data.availabilityPeriod = DateUtils.convertDateTimeFromServer(data.availabilityPeriod);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'importProperties': {
                method:'POST',
                url:'api/properties/import',
                transformRequest: function(data) {
                    var formData = new FormData();
                    formData.append('file', data.file);
                    return formData;
                },
                headers: { 'Content-Type': undefined },
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
             },
             'exportCSVProperties': {
                 method:'GET',
                 url:'api/properties/export-csv',
            	 headers : {accept : 'text/csv'},
                 cache : false,
                 transformResponse : function(data) {
                     return {
                         response : new Blob([data], {type : 'text/csv;charset=utf-8;'})
                     };
                 }
              },
             'getPropertyForOpportunity' : {
  				method : 'GET',
  				url : 'api/properties/property-opportunity-get-by-id'
  			 },
             'getAllPropertiesForOpportunity' : {
 				method : 'GET',
 				url : 'api/properties/property-opportunity-search-query',
 				isArray : true
 			 },
 			'getAllByOwnerIdForContact' : {
 				method : 'GET',
 				url : 'api/properties/get-all-by-owner-id-for-contact',
 				isArray : true
 			 },
 			'getAllPropertiesByNameAndWithoutListIdsForAutoComplete' : {
 				method : 'GET',
 				url : 'api/properties/get-all-by-name-and-without-list-ids-for-autocomplete',
 				isArray : true
 			 },
 			'getAllPropertiesByManager' : {
 				method : 'GET',
 				url : 'api/properties/search-properties-by-userId',
 				isArray : true
 			 },
            getLanguages: {
                method : 'GET',
                url : 'api/properties/property-language-enums',
                isArray : true
            },
            'getAllForReportSelector' : {
 				method : 'GET',
 				url : 'api/properties/getAllForReportSelector',
 				isArray : true
 			 }
        });

        service.getAllLanguage = getAllLanguage;

        return service;

        function getAllLanguage(){
        	return $http.get('api/properties/property-language-enums');
        }
    }
})();
