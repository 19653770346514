(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'AuthorityConstant'];

    function stateConfig($stateProvider, AuthorityConstant) {
        $stateProvider
        .state('contact-detail.opportunity-tab', {
        	parent: 'contact-detail',
        	url: '/opportunity-tab',
            templateUrl: 'app/entities/opportunity/opportunity-tab.html',
            controller: 'OpportunityTabController',
            controllerAs: 'vm',
            resolve: {
            	translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opportunity');
                    $translatePartialLoader.addPart('unitType');
                    return $translate.refresh();
                }]
            }
        })
        ;
    }

})();
