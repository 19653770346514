(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'AuthorityConstant'];

    function stateConfig($stateProvider, AuthorityConstant) {
        $stateProvider
        .state('property-detail.photo-video-tab', {
        	parent: 'property-detail',
        	url: '/photo-video-tab',
        	templateUrl: 'app/entities/property/property-detail/photo-video-tab/photo-video-tab.html',
            controller: 'PhotoVideoTabController',
            controllerAs: 'vm',
            resolve: {
                entity: ['$stateParams', 'PropertyService', function($stateParams, PropertyService) {
                    return PropertyService.get({id : $stateParams.id}).$promise;
                }]
            }
        });
    }

})();
