(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('contact-detail.detail-tab', {
        	parent: 'contact-detail',
        	url: '/detail-tab',
        	templateUrl: 'app/entities/contact/contact-detail/detail-tab/detail-tab.html',
            controller: 'DetailTabController',
            controllerAs: 'vm',
            resolve: {
                pagingParams: ['PaginationUtil', function (PaginationUtil) {
                    var sort = 'id';
                    return {
                        page: PaginationUtil.parsePage(1),
                        sort: sort,
                        predicate: PaginationUtil.parsePredicate(sort),
                        ascending: PaginationUtil.parseAscending(sort),
                        search: null
                    };
                }]
            }
        })
        .state('contact.new.detail-tab', {
        	parent: 'contact.new',
        	url: '/new-detail-tab',
            templateUrl: 'app/entities/contact/contact-detail/detail-tab/detail-tab.html',
            controller: 'DetailTabController',
            controllerAs: 'vm',
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }]
            }
        });
    }

})();
