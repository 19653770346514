(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('ContactDetailController', ContactDetailController);

    ContactDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$uibModal', 'previousState', 'entity', 'Contact',
                                       'pagingParamsForActivity', 'pagingParamsForActivityHistorical', 'pagingParamsForOpportunity', 'PaginationUtil', '$localStorage'];

    function ContactDetailController($scope, $rootScope, $state, $stateParams, $uibModal, previousState, entity, Contact,
    		pagingParamsForActivity, pagingParamsForActivityHistorical, pagingParamsForOpportunity, PaginationUtil, $localStorage) {
        var vm = this;
        /*if($state.isEdit === true) {
        	$state.isEdit = false;
        }*/
        $state.pagingParams = {
        		page: PaginationUtil.parsePage(pagingParamsForActivity.page),
                sort: pagingParamsForActivity.sort,
                predicate: PaginationUtil.parsePredicate(pagingParamsForActivity.sort),
                ascending: PaginationUtil.parseAscending(pagingParamsForActivity.sort)
        }
        $state.pagingParamsHistorical = {
        		page: PaginationUtil.parsePage(pagingParamsForActivityHistorical.page),
                sort: pagingParamsForActivityHistorical.sort,
                predicate: PaginationUtil.parsePredicate(pagingParamsForActivityHistorical.sort),
                ascending: PaginationUtil.parseAscending(pagingParamsForActivityHistorical.sort)
        }
        
        $state.pagingParamsForOpportunity = pagingParamsForOpportunity;

        vm.contact = entity;

        vm.edit = edit;
        vm.backToList = backToList;
        vm.previousState = previousState.name;

        vm.activate = activate;
        vm.deactivate = deactivate;
        vm.checkWorkflow = checkWorkflow;

        if(vm.contact.id === null) {
        	vm.isBuyer = false;
        	vm.isEdit = true;
        } else {
        	angular.forEach(vm.contact.contactTypes, function(value, key) {
    			// is buyer
    			if(value.id === 2) {
    				vm.isBuyer =  true;
    			}
    		});
        }

        var unsubscribe = $rootScope.$on('indochinaApp:contactUpdate', function(event, result) {
//            vm.contact = result;
            angular.copy(result,vm.contact);
        });

        $scope.$on('$destroy', unsubscribe);

        $rootScope.$on('contact:isCompanyChange', function(event, result) {
            vm.contact.isCompany = result;
        });

        $rootScope.$on('contact:activeTab', function(event, result) {
        	switch(result){
        	case 'DETAIL':
        		vm.isShowDeactivateButton = true;
        		vm.isActOrOpp = false;
        		break;
        	case 'PREFERENCE':
        	case 'PERSONAL':
        		vm.isShowDeactivateButton = false;
        		vm.isActOrOpp = false;
        		break;
        	case 'ACTIVITY':
        	case 'OPPORTUNITY':
        		vm.isShowDeactivateButton = false;
        		vm.isActOrOpp = true;
        		break;
        	case 'LEGAL':
        		vm.isShowDeactivateButton = false;
        		vm.isActOrOpp = false;
        	}
        	
        });

        $rootScope.$on('contact:changeMode', function(event, result) {
        	vm.isEdit = result;
        });

        $rootScope.$on('contact:isBuyer', function(event, result) {
        	vm.isBuyerTemp = true;
            vm.isBuyer = result;

            if(result === false ) {
            	vm.isBuyerTemp =  false;
            } else {
            	angular.forEach(vm.contact.contactTypes, function(value, key) {
        			// is buyer
        			if(value.id === 2) {
        				vm.isBuyerTemp =  false;
        			}
        		});
            }
        });

        function edit (){
        	vm.isEdit = true;
        	$state.isEdit = true;
        	$rootScope.$broadcast('contact:changeMode', true);
        }

        function backToList() {
        	$state.isEdit = false;
        	if(vm.previousState === 'opportunity-detail') {
        		$state.go(vm.previousState, previousState.params);
        	} else {
        		$state.go('contact');
        	}

        }

        function activate(){
        	$uibModal.open({
                templateUrl: 'app/entities/contact/contact-detail/contact-confirm-active-dialog.html',
                controller: 'ContactConfirmActiveDialogController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass : 'absolute-center',
                resolve: {
                    entity: function(){
                    	return {
                    		title: vm.contact.name
                    	};
                    },
                    translateKeys: function(){
                    	return {
                    		keys: 'indochinaApp.contact.confirmActive'
                    	};
                    }
                }
            }).result.then(function() {
            	Contact.activeContact({id: vm.contact.id}, onSuccess, onError);
            }, function() {
            });

        	function onSuccess(data){
        		vm.contact.activated = 1;
        		$rootScope.$broadcast('contact:activated', true);
        	}

        	function onError(error){
            	AlertService.error(error.data.message);
            };
        }

		function deactivate(){
        	$uibModal.open({
                templateUrl: 'app/components/confirmation-dialog/delete/confirm-dialog.html',
                controller: 'DeleteConfirmDialogController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass : 'absolute-center',
                resolve: {
                    entity: function(){
                    	return {
                    		title: vm.contact.name
                    	};
                    },
                    translateKeys: function(){
                    	return {
                    		keys: 'indochinaApp.contact.confirmDeactive'
                    	};
                    }
                }
            }).result.then(function() {
            	Contact.deactiveContact({id: vm.contact.id}, onSuccess, onError);
            }, function() {
            });

        	function onSuccess(data){
        		vm.contact.activated = 0;
        		$rootScope.$broadcast('contact:activated', false);
        	}

        	function onError(error){
            	AlertService.error(error.data.message);
            };
        }

        // check work-flow validation to enable or disable contact preference tab
        function checkWorkflow (){
		    var result = false;
		    if($state.current.name === 'contact-detail.contact-prefer-tab' || $state.current.name === 'contact.new.contact-prefer-tab') {
                result = true;
            }
		    return result;
        }
    }
})();
