(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('JhiCurrencyController', JhiCurrencyController);

    JhiCurrencyController.$inject = ['JhiCurrencyService', 'CurrencyConstant'];

    function JhiCurrencyController (JhiCurrencyService, CurrencyConstant) {
        var vm = this;
        
        vm.preferredCurrency = JhiCurrencyService.currencyCode;
        vm.currencies = [];
        vm.changeCurrency = changeCurrency;

        var group1 = [];
        var group2 = [];
        var group3 = [];
        angular.forEach(CurrencyConstant.LIST, function(value, key) {
            if(key < 6){
                group1.push(value);
            } else if(6 <= key && key < 12){
                group2.push(value);
            } else if (key >=12){
                group3.push(value);
            }
          });
        vm.currencies.push(group1);
        vm.currencies.push(group2);
        vm.currencies.push(group3);
        
        function changeCurrency(currencyKey) {
        	JhiCurrencyService.changeCurrency(currencyKey);
        }
    }
})();
