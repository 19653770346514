(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('DevelopmentController', DevelopmentController);

    DevelopmentController.$inject = ['$rootScope', '$state', 'DevelopmentService', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'DevelopmentConstant',
         'MeasurementConversionService', 'JhiCurrencyService', '$scope', 'CurrencyConstant',
         'countryResource', 'provinceResource', 'cityResource', 'districtHCMResource','districtHANResource',
         'districtDNGResource', 'propertyTypesResource'];

    function DevelopmentController($rootScope, $state, DevelopmentService, ParseLinks, AlertService, paginationConstants, pagingParams, DevelopmentConstant,
			MCService, JhiCurrencyService, $scope, CurrencyConstant,
			countryResource, provinceResource, cityResource, districtHCMResource, districtHANResource,
			districtDNGResource, propertyTypesResource) {

        var vm = this;
        var ceilOfSlideBar = 10000000;
        var currentCurrencyDes = null;
        vm.searchObject = {};
        var valueOfUsdExchangeRate = 1;
        vm.isSearched = false;

        // function on grid
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 10;

        // search functions
        vm.search = search;
        vm.clearSearchingValue = clearSearchingValue;
        vm.onSelectCountry = onSelectCountry;
        vm.onSelectCity = onSelectCity;
        vm.onKeyUpCountry = onKeyUpCountry;
        vm.onKeyUpCity = onKeyUpCity;

        vm.currencyCode = JhiCurrencyService.currencyCode;
        vm.currencyRate = JhiCurrencyService.rate;
        vm.currenciesDes = CurrencyConstant.DES;
        vm.currencyUSDRate = JhiCurrencyService.USDRate;

        //TODO : init value for currency with currency code
        JhiCurrencyService.initialize(vm.currencyCode.value);
        // init property size unit
        vm.propertyUnitTypes = DevelopmentConstant.propertyUnitType.LIST;

        vm.changeMinBedRoom = changeMinBedRoom;
        vm.changeMaxBedRoom = changeMaxBedRoom;
        vm.changeMinBathRoom = changeMinBathRoom;
        vm.changeMaxBathRoom = changeMaxBathRoom;

        vm.selectFromToValidateBedRoom = selectFromToValidateBedRoom;
        vm.selectFromToValidateBathRoom = selectFromToValidateBathRoom;
        vm.changeFromToValidateSizeUnit = changeFromToValidateSizeUnit;
        vm.changeFromToValidateCommission = changeFromToValidateCommission;

        // default value for search
       // vm.searchObject = {};
        vm.getBathRoom = getBathRoom;
        vm.maxRoom = 5;
        vm.slider = {
        	    options: {
        	        floor: 0,
        	        ceil: ceilOfSlideBar,
        	        step: 10,
        	        translate: function(value, sliderId, label) {
    					switch (label) {
    						case 'high':
    							var viewValue = currentCurrencyDes + numberWithCommas(value);
    							if(value == vm.slider.options.ceil){
    								viewValue += '+';
    							}
    							return viewValue;
    						case 'ceil':
    							return currentCurrencyDes + numberWithCommas(value) + '+';
    						default:
    							return currentCurrencyDes + numberWithCommas(value);
    					}
    				}
        	    }
        	};
        init();

        function init(){
           	//init model
        	vm.countryList = countryResource;
            vm.propertyType = propertyTypesResource;

        	if(pagingParams.search && pagingParams.search.length>0){
        		//get parameter from url
        		vm.searchObject = decryptSearchingObj(pagingParams.search);
        		updateSelectCountry(vm.searchObject.country);
        		updateSelectCity(vm.searchObject.cityCode);
        	}else {
	        	//init data Search
	        	vm.searchObject.country = 'Vietnam';
	        	vm.provinceTypeaheadEditable = false;
	        	vm.cityTypeaheadEditable = false;
	        	vm.districtTypeaheadEditable = false;
	        	vm.searchObject.sizeUnitFrom = null;
	        	vm.searchObject.sizeUnitTo = null
	        	vm.searchObject.commissionFrom = null;
	        	vm.searchObject.commissionTo = null;
	        	vm.searchObject.valueOfUsdExchangeRate = valueOfUsdExchangeRate;
	        	vm.searchObject.sizeUnitType= 'SQ_METER';
	        	onSelectCountry(null, vm.searchObject.country, null);
        	}
        	//init slider
        	if(vm.searchObject.minPrice == null){
            	vm.searchObject.minPrice = 0;
            }
            if(vm.searchObject.maxPrice == null){
            	vm.searchObject.maxPrice = ceilOfSlideBar;
            }
        	if(vm.searchObject.currency == null || vm.searchObject.currency == ''){
            	vm.searchObject.currency = 'USD';
            }
    		updateSliderValue();


        	updatePrice();
        	loadAll();
        }

        function loadAll() {
            var apiParam = angular.copy(vm.searchObject);
            apiParam.page = pagingParams.page - 1;
            apiParam.size = vm.itemsPerPage;
            apiParam.sort = sort();
            //update get id for property type
            if(apiParam.propertyType != null && apiParam.propertyType.length > 0){
        		var listPropertyTypeId = [];
        		apiParam.propertyType.forEach(function(i){
        			listPropertyTypeId.push(i.id);
        		});
        		apiParam.propertyType = listPropertyTypeId;
            }
        	DevelopmentService.query(apiParam, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.developments = data;
                vm.page = pagingParams.page;
             // for slider
                addConverter();
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }

        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
        	$state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: encryptSearchingObj(vm.searchObject)
            });
        }
        function addConverter(){
        	vm.developments.forEach(function(p){
        		if(p.size != null){
        			p.sizeConverter = MCService.init(p.sizeUnit);
        		}
        	});
        }

        function search(){
        	vm.page = 1 ;
        	vm.isSearched = true;
        	updatePrice();
        	vm.transition();
        }

        function clearSearchingValue(){
        	vm.page = 1 ;
            $state.transitionTo($state.$current, {
	            page: vm.page,
	            sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
	            search: null
	        },
	        {
	        	reload: true
	        });
        }

        function getBethRoom(){
        	return new Array(vm.maxRoom);
        }

        function getBathRoom (){
        	return [0.5,1,1.5,2,2.5,3,3.5,4,4.5,5];
        }

        function onSelectCountry($item, $model, $label) {
        	updateSelectCountry($model);
            vm.searchObject.city = '';
            vm.searchObject.province = '';
            vm.searchObject.district = '';
        };

        function updateSelectCountry(value){
        	if(!value || value.toUpperCase() == 'vietnam'.toUpperCase()){
                vm.cityList = cityResource;
                vm.provinceList = provinceResource;
                vm.cityTypeaheadEditable = false;
                vm.provinceTypeaheadEditable = false;
            }
            else{
            	vm.cityList = [];
            	vm.provinceList = [];
            	vm.cityTypeaheadEditable = true;
            	vm.provinceTypeaheadEditable = true;
            }
        }

        function onSelectCity($item, $model, $label) {
        	updateSelectCity($item.code);
        	vm.searchObject.cityCode = $item.code;
        	vm.searchObject.district = '';
        }

        function updateSelectCity(code){
        	if(code == 'HCM'){
        		vm.districtList = districtHCMResource;
    			vm.districtTypeaheadEditable = false;
        	}
        	else if(code == 'HAN'){
        		vm.districtList = districtHANResource;
    			vm.districtTypeaheadEditable = false;
        	}
        	else if(code == 'DNG'){
        		vm.districtList = districtDNGResource;
    			vm.districtTypeaheadEditable = false;
        	}
        	else{
        		vm.districtList = [];
    			vm.districtTypeaheadEditable = true;
        	}
        }

        function onKeyUpCountry(){
        	if(vm.searchObject.country == null || vm.searchObject.country == '' ||
        			vm.searchObject.country.toUpperCase() == 'vietnam'.toUpperCase()){
        		onSelectCountry(null,'vietnam',null);
        	}else{
        		vm.cityTypeaheadEditable = false;
        		vm.provinceTypeaheadEditable = false;
        	}
        }

        function onKeyUpCity(){
        	if(vm.searchObject.city == null || vm.searchObject.city == ''){
        		vm.districtList = [];
        		vm.districtTypeaheadEditable = true;
        	}
        	vm.searchObject.cityCode = '';
        }

        // receive broadcast change currency
        $scope.$on("curreny:change", function(event, item){
        	updateSliderValue();
        });

        function updateSliderValue(){
            if(vm.currencyCode.value == 'USD'){
            	vm.slider.options.ceil = ceilOfSlideBar;
            }
            else{
            	vm.slider.options.ceil = convertCurrencyFromUSD(ceilOfSlideBar, vm.currencyCode.value);
            }

            if(vm.searchObject.currency != vm.currencyCode.value){
            	vm.searchObject.minPrice = 0;
                vm.searchObject.maxPrice = vm.slider.options.ceil;
            }

            vm.searchObject.currency = vm.currencyCode.value;
        	currentCurrencyDes = vm.currenciesDes[vm.currencyCode.value];
		}

		function convertCurrencyFromUSD(value, code){
			var exchangeRate = {value: 1};
			vm.currencyUSDRate.some(function(r){
				if(r.destinationCode == code){
					exchangeRate = r;
					return true;
				}
				return false;
			});

			return Math.ceil(value * exchangeRate.value);
        }

        function numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        function changeMinBedRoom(value) {
        	vm.searchObject.minBedRoom = value;
        }

        function changeMaxBedRoom(value) {
        	vm.searchObject.maxBedRoom = value;
        }

        function changeMinBathRoom(value) {
        	vm.searchObject.minBathRoom = value;
        }

        function changeMaxBathRoom(value) {
        	vm.searchObject.maxBathRoom = value;
        }

        function selectFromToValidateBedRoom(){
        	if(vm.searchObject.maxBedRoom == null) return false;
        	return vm.searchObject.minBedRoom > vm.searchObject.maxBedRoom;
        }

        function selectFromToValidateBathRoom(){
        	if(vm.searchObject.maxBathRoom === '') return false;
        	return vm.searchObject.minBathRoom > vm.searchObject.maxBathRoom;
        }

        function changeFromToValidateSizeUnit(){
        	var toU = vm.searchObject.sizeUnitTo;
        	var fromU = vm.searchObject.sizeUnitFrom;
        	if(!toU || !fromU) return false;
        	return parseInt(fromU) > parseInt(toU);
        }

        function changeFromToValidateCommission(){
        	var toU = vm.searchObject.commissionTo;
        	var fromU = vm.searchObject.commissionFrom;
        	if(!toU || !fromU) return false;
        	return parseInt(fromU) > parseInt(toU);
        }

        function updatePrice(){
			vm.searchObject.minPriceSearch =  Math.floor(vm.searchObject.minPrice / vm.searchObject.valueOfUsdExchangeRate);
			vm.searchObject.maxPriceSearch = Math.floor(vm.searchObject.maxPrice / vm.searchObject.valueOfUsdExchangeRate);
        }

        function encryptSearchingObj(obj){
			if($.isEmptyObject(obj)){
				return null;
        	}

			return encryptText(JSON.stringify(obj));
		}

		function decryptSearchingObj(encryptMsg){
			if($.isEmptyObject(encryptMsg)){
				return {};
			}

			try{
				return JSON.parse(decryptText(encryptMsg));
            }
            catch(e){
            	return {};
            }
		}

		function encryptText(msg){
			return CryptoJS.TripleDES.encrypt(msg, "U2FsdGVkX1~2FnVu36U2P7~2FJ5ye~2FJhvhZQScHXSCUl1cc%3D");
		}

		function decryptText(msg){
			return CryptoJS.TripleDES.decrypt(msg, "U2FsdGVkX1~2FnVu36U2P7~2FJ5ye~2FJhvhZQScHXSCUl1cc%3D").toString(CryptoJS.enc.Utf8);
		}
    }
})();
