(function() {
    'use strict';
    angular
        .module('indochinaApp')
        .factory('Contact', Contact);

    Contact.$inject = ['$resource', 'DateUtils'];

    function Contact ($resource, DateUtils) {
        var resourceUrl =  'api/contacts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'update': {
                method:'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    if(copy.person != null && copy.person.personalDetails !== null) {
                        copy.person.personalDetails.birthday = DateUtils.convertLocalDateToServer(copy.person.personalDetails.birthday);
                        copy.person.personalDetails.partnerBirthday = DateUtils.convertLocalDateToServer(copy.person.personalDetails.partnerBirthday);
                        if(copy.person.personalDetails.childs !== null && copy.person.personalDetails.childs.length > 0) {
                            angular.forEach(copy.person.personalDetails.childs, function(value, key) {
                                value.birthday = DateUtils.convertLocalDateToServer(value.birthday)
                            });
                        }

                    }
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    if(copy.person != null && copy.person.personalDetails !== null) {
                        copy.person.personalDetails.birthday = DateUtils.convertLocalDateToServer(copy.person.personalDetails.birthday);
                        copy.person.personalDetails.partnerBirthday = DateUtils.convertLocalDateToServer(copy.person.personalDetails.partnerBirthday);
                        if(copy.person.personalDetails.childs !== null && copy.person.personalDetails.childs.length > 0) {
                            angular.forEach(copy.person.personalDetails.childs, function(value, key) {
                                value.birthday = DateUtils.convertLocalDateToServer(value.birthday)
                            });
                        }

                    }
                    return angular.toJson(copy);
                }
            },
            'exportCSVContacts': {
                method:'GET',
                url:'api/contacts/export-csv',
           	 	headers : {accept : 'text/csv'},
                cache : false,
                transformResponse : function(data) {
                    return {
                        response : new Blob([data], {type : 'text/csv;charset=utf-8;'})
                    };
                }
             },
            'getByName': {
            	url: 'api/contact-by-name',
            	method: 'GET',
            	isArray: true
            },
            /*get contact for contact detail page*/
            'getContactByEditingRole': {
            	url: 'api/contacts/contact-by-editing-role',
            	method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                        if(data.person != null && data.person.personalDetails !== null) {
                            data.person.personalDetails.birthday = DateUtils.convertDateTimeFromServer(data.person.personalDetails.birthday);
                            data.person.personalDetails.partnerBirthday = DateUtils.convertDateTimeFromServer(data.person.personalDetails.partnerBirthday);
                            if(data.person.personalDetails.childs !== null && data.person.personalDetails.childs.length > 0) {
                                angular.forEach(data.person.personalDetails.childs, function(value, key) {
                                    value.birthday = DateUtils.convertDateTimeFromServer(value.birthday)
                                });
                            }

                        }
                    }
                    return data;
                }
            },
            /*get all activated contact by name. It is use for auto completed field*/
            'getAllContactForCompanyNameOnDevelopment' : {
 				method : 'GET',
 				url : 'api/contacts/contacts-development-get-all-for-company-name',
 				isArray : true
 			},
            'getAllContactByName': {
            	url: 'api/contacts/contact-by-name',
            	method: 'GET',
            	isArray: true
            },
            'getAllContactForReferringPartyOnOpportunity' : {
 				method : 'GET',
 				url : 'api/contacts/contacts-opportunity-get-all-for-referring-party',
 				isArray : true
 			},
            'getAllContactsForOpportunity' : {
 				method : 'GET',
 				url : 'api/contacts/contacts-opportunity-search-query',
 				isArray : true
 			},
            'getContactForOpportunity' : {
            	method : 'GET',
  				url : 'api/contacts/contacts-opportunity-get-by-id'
  			},
  			'getCoBrokerContactForAutoComplete' : {
  				// parameter is name
            	method : 'GET',
  				url : 'api/contacts/co-broker-contact-for-auto-complete',
  				isArray : true
  			},
  			'getIndochinaPropertyContact' : {
            	method : 'GET',
  				url : 'api/contacts/indochina-property-contact'
  			},
  			'activeContact' : {
            	method : 'GET',
  				url : 'api/contacts/activate-contact'
  			},
  			'deactiveContact' : {
            	method : 'GET',
  				url : 'api/contacts/deactivate-contact'
  			},
  			'getAllByPermisson': {
            	url: 'api/contacts/contact-by-permission',
            	method: 'GET',
            	isArray: true
            },
            'getAllDeleteByPermisson': {
            	url: 'api/contacts/contact-delete-by-permission',
            	method: 'GET',
            	isArray: true
            },
            'getAllContactTypeBrokerByName': {
                url: 'api/contacts/contact-type-co-broker',
                method: 'GET',
                isArray: true
            },
            'validate': {
                url: 'api/contacts/validation',
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    if(copy.person != null && copy.person.personalDetails !== null) {
                        copy.person.personalDetails.birthday = DateUtils.convertLocalDateToServer(copy.person.personalDetails.birthday);
                        copy.person.personalDetails.partnerBirthday = DateUtils.convertLocalDateToServer(copy.person.personalDetails.partnerBirthday);
                        if(copy.person.personalDetails.childs !== null && copy.person.personalDetails.childs.length > 0) {
                            angular.forEach(copy.person.personalDetails.childs, function(value, key) {
                                value.birthday = DateUtils.convertLocalDateToServer(value.birthday)
                            });
                        }

                    }
                    return angular.toJson(copy);
                }
            }
        });
    }
})();
