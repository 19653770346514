(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('LeadSourceManagementDialogController',LeadSourceManagementDialogController);

    LeadSourceManagementDialogController.$inject = ['$stateParams', '$state', '$uibModalInstance', 'entity', 'JhiLanguageService', 'ManagerService', 'AuthorityConstant', 'JhiCurrencyService',
                                              'Principal'];

    function LeadSourceManagementDialogController ($stateParams, $state, $uibModalInstance, entity, JhiLanguageService, ManagerService, AuthorityConstant, JhiCurrencyService,
    										Principal) {
        var vm = this;
        
        vm.clear = clear;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
