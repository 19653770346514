(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('UserManagementDeleteController', UserManagementDeleteController);

    UserManagementDeleteController.$inject = ['$uibModalInstance', 'entity', 'User', '$rootScope'];

    function UserManagementDeleteController ($uibModalInstance, entity, User, $rootScope) {
        var vm = this;

        vm.user = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        // session is time out so we must close all modals
        $rootScope.$on('modal:close', function(event, result) {
            clear();
        });

        function clear () {
            $rootScope.$broadcast('app:resetTimeSession', true);
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (login) {
            User.delete({login: login},
                function () {
                    $rootScope.$broadcast('app:resetTimeSession', true);
                    $uibModalInstance.close(true);
                });
        }
    }
})();
