(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('PhotoDeleteControllerRe',PhotoDeleteControllerRe);

    PhotoDeleteControllerRe.$inject = ['$uibModalInstance', 'entity', 'PhotoService', '$rootScope'];

    function PhotoDeleteControllerRe($uibModalInstance, entity, PhotoService, $rootScope) {
        var vm = this;

        vm.photo = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        // session is time out so we must close all modals
        $rootScope.$on('modal:close', function(event, result) {
            clear();
        });

        function clear () {
            $rootScope.$broadcast('app:resetTimeSession', true);
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PhotoService.delete({id: id},
                function () {
                    $rootScope.$broadcast('app:resetTimeSession', true);
                    $uibModalInstance.close(true);
                });
        }
    }
})();
