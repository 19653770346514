(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'AuthorityConstant'];

    function stateConfig($stateProvider, AuthorityConstant) {
        $stateProvider.state('property-management', {
            abstract: true,
            parent: 'entity',
            resolve: {
                propertySearch: ['$q', 'PropertyConstant', 'JhiCurrencyService', function($q, PropertyConstant, JhiCurrencyService) {
                	var deferred = $q.defer();
                	var o =  {
                        searchQuery:null,
                        city:null,
                        district:null,
                        country: 'Vietnam',
                        minPrice:0,
                        maxPrice:PropertyConstant.price.MAX_SEARCH_PRICE,
                        transactionType:'',
                        availability:'',
                        unit: 'SQ_METER',
                        minBedRoom: null,
                        maxBedRoom: null,
                        minBathRoom:'',
                        maxBathRoom:'',
                        minPriceSearch:null,
                        maxPriceSearch:null,
                        valueOfUsdExchangeRate:1,
                        currencyCode:JhiCurrencyService.currencyCode.value,
                        destinationCode:JhiCurrencyService.currencyCode.value,
                        codeDescription:JhiCurrencyService.currencyCode.decCode,
                        stepRange: 100
                    };
                	return JhiCurrencyService.getExchangeRates(JhiCurrencyService.currencyCode.value).then(function(data) {
               	 		data.forEach(function(e){
               	 			if(e.sourceCode === JhiCurrencyService.currencyCode.value) {
               	 				o.valueOfUsdExchangeRate = e.valueOfUsdExchangeRate;
               	 				o.stepRange = e.valueOfUsdExchangeRate * 100;
               	 			}
               	 		});
                		o.maxPrice = Math.ceil(o.maxPrice*o.valueOfUsdExchangeRate);
                		deferred.resolve(o);
                		return deferred.promise;
                	});

                }]
            }
        }).state('property', {
            parent: 'property-management',
            url: '/property?page&sort&search',
            data: {
            	authorities: AuthorityConstant.ROLE_LIST,
                pageTitle: 'indochinaApp.property.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/property/properties.html',
                    controller: 'PropertyController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'title,asc',
                    squash: true
                },
                propertySearch:{}
            },
            resolve: {
            	login: ['Principal' , function (Principal) {
            		var result = Principal.identity();
            		return result;
                }],
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        //search: $stateParams.search,
                       // propertySearchObjPaging: $stateParams.propertySearchObjPaging
                        propertySearch: $stateParams.propertySearch
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('property');
                    $translatePartialLoader.addPart('transactionType');
                    $translatePartialLoader.addPart('propertyType');
                    $translatePartialLoader.addPart('availabilityStatus');
                    $translatePartialLoader.addPart('constructionStatus');
                    $translatePartialLoader.addPart('paymentCycleEnum');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('unitType');
                    $translatePartialLoader.addPart('photo');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'property',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }],
                developmentResources: ["DevelopmentService", function (DevelopmentService) {
                    return DevelopmentService.getAllByDevelopmentNameForAutoComplete().$promise;
                }]
            }
        }).state('property.new', {
            parent: 'property',
            url: '/new',
            data: {
            	authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_ADMIN, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT],
                pageTitle: 'indochinaApp.property.detail.title'
            },
            params: {
            	propertySearchObj: {},
            	pageForActivity: {
                    value: '1',
                    squash: true
                },
                sortForActivity: {
                    value: 'dueDate,desc',
                    squash: true
                },
                pageForActivityHistorical: {
                    value: '1',
                    squash: true
                },
                sortForActivityHistorical: {
                    value: 'dueDate,desc',
                    squash: true
                },
                searchForOpportunity: {
                    value: {agent: -1, status: 'active'},
                    squash: true
                },
                pageForOpportunity: {
                    value: '1',
                    squash: true
                },
                sortForOpportunity: {
                    value: 'name,asc',
                    squash: true
                }
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/property/property-detail/property-detail.html',
                    controller: 'PropertyDetailControllerRe',
                    controllerAs: 'vm'
                }
            },
            resolve: {
        	  pagingParamsForActivity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                  return {
                      page: PaginationUtil.parsePage($stateParams.pageForActivity),
                      sort: $stateParams.sortForActivity,
                      predicate: PaginationUtil.parsePredicate($stateParams.sortForActivity),
                      ascending: PaginationUtil.parseAscending($stateParams.sortForActivity),
                      search: $stateParams.search
                  };
              }],
              pagingParamsForActivityHistorical: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                  return {
                      page: PaginationUtil.parsePage($stateParams.pageForActivityHistorical),
                      sort: $stateParams.sortForActivityHistorical,
                      predicate: PaginationUtil.parsePredicate($stateParams.sortForActivityHistorical),
                      ascending: PaginationUtil.parseAscending($stateParams.sortForActivityHistorical)
                  };
              }],
              pagingParamsForOpportunity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                  return {
                      page: PaginationUtil.parsePage($stateParams.pageForOpportunity),
                      sort: $stateParams.sortForOpportunity,
                      predicate: PaginationUtil.parsePredicate($stateParams.sortForOpportunity),
                      ascending: PaginationUtil.parseAscending($stateParams.sortForOpportunity),
                      search: $stateParams.searchForOpportunity
                  };
              }],
              entity: function () {
                  return {
                      cityCode: null,
                      developmentCode: null,
                      unitNumber: null,
                      title: null,
                      developmentName: null,
                      created: null,
                      modified: null,
                      active: false,
                      transactionType: null,
                      streetNumber: null,
                      streetName: null,
                      city: null,
                      province: null,
                      postalCode: null,
                      country: null,
                      unitNo: null,
                      floor: null,
                      latitude: null,
                      longitude: null,
                      type: null,
                      availability: null,
                      size: null,
                      sizeUnit: null,
                      lotSize: null,
                      lotSizeUnit: null,
                      currency: null,
                      listPrice: null,
                      paymentCycle: null,
                      availabilityPeriod: null,
                      leaseTerm: null,
                      yearBuilt: null,
                      titleDeedNo: null,
                      titleDeedLandNo: null,
                      titleDeedOther: null,
                      accessRoadWidth: null,
                      accessRoadWidthUnit: null,
                      propertyOtherDetailLanguages: [],
                      icPropertyLocations: [],
                      icInternalRooms: [],
                      icOutsideAreas: [],
                      icFacilitiesOffereds: [],
                      icNearbyFacilities: [],
                      icParkingFacilities: [],
                      icReasonForPurchases: [],
                      icMaterials: [],
                      icAccessRoads: [],
                      propertyLocations: [],
                      internalRooms: [],
                      outsideAreas: [],
                      facilitiesOffereds: [],
                      nearbyFacilities: [],
                      parkingFacilities: [],
                      accessRoads: [],
                      reasonForPurchases: [],
                      id: null
                  };
              },
              previousState: ["$state", function ($state) {
                  var currentStateData = {
                      name: $state.current.name || 'property',
                      params: $state.params,
                      url: $state.href($state.current.name, $state.params)
                  };
                  return currentStateData;
              }]
          }
        }).state('property-detail', {
            parent: 'property',
            url: '/{id}',
            data: {
            	authorities: AuthorityConstant.ROLE_LIST,
                pageTitle: 'indochinaApp.property.detail.title'
            },
            params: {
            	propertySearchObj: {},
            	pageForActivity: {
                    value: '1',
                    squash: true
                },
                sortForActivity: {
                    value: 'dueDate,desc',
                    squash: true
                },
                pageForActivityHistorical: {
                    value: '1',
                    squash: true
                },
                sortForActivityHistorical: {
                    value: 'dueDate,desc',
                    squash: true
                },
                searchForOpportunity: {
                    value: {agent: -1, status: 'active'},
                    squash: true
                },
                pageForOpportunity: {
                    value: '1',
                    squash: true
                },
                sortForOpportunity: {
                    value: 'name,asc',
                    squash: true
                }
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/property/property-detail/property-detail.html',
                    controller: 'PropertyDetailControllerRe',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                searchActivity: [function (){
                    return {
                        searchQuery: null,
                        status: 'OPEN',
                        propertyIds: [],
                        agentIds: [],
                        agentObjectList: [],
                        contactId: null
                    }
                }],
            	pagingParamsForActivity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.pageForActivity),
                        sort: $stateParams.sortForActivity,
                        predicate: PaginationUtil.parsePredicate($stateParams.sortForActivity),
                        ascending: PaginationUtil.parseAscending($stateParams.sortForActivity),
                        search: $stateParams.search
                    };
                }],
                pagingParamsForActivityHistorical: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.pageForActivityHistorical),
                        sort: $stateParams.sortForActivityHistorical,
                        predicate: PaginationUtil.parsePredicate($stateParams.sortForActivityHistorical),
                        ascending: PaginationUtil.parseAscending($stateParams.sortForActivityHistorical)
                    };
                }],
                pagingParamsForOpportunity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.pageForOpportunity),
                        sort: $stateParams.sortForOpportunity,
                        predicate: PaginationUtil.parsePredicate($stateParams.sortForOpportunity),
                        ascending: PaginationUtil.parseAscending($stateParams.sortForOpportunity),
                        search: $stateParams.searchForOpportunity
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('property');
                    $translatePartialLoader.addPart('transactionType');
                    $translatePartialLoader.addPart('propertyType');
                    $translatePartialLoader.addPart('availabilityStatus');
                    $translatePartialLoader.addPart('paymentCycleEnum');
                    $translatePartialLoader.addPart('unitType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', '$state', 'PropertyService', function($stateParams, $state, PropertyService) {
                    return PropertyService.get({id : $stateParams.id}, function (result) {
                  	  	if(result == null || result.id == null || result.active == false){
                  	  		$state.go('accessdenied');
                  	  	}
                  	  	return result;
                    }).$promise;
                }],
                previousState: ["$state", '$stateParams', function ($state, $stateParams) {
                    var currentStateData = {
                        name: $state.current.name || 'property',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params),
                        propertySearchObj: $stateParams.propertySearchObj
                    };
                    return currentStateData;
                }]
            }
        }).state('property.duplicate', {
            parent: 'property',
            url: '/{id}/duplicate',
            data: {
            	authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_ADMIN, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT],
                pageTitle: 'indochinaApp.property.detail.title'
            },
            params: {
            	propertySearchObj: {},
            	pageForActivity: {
                    value: '1',
                    squash: true
                },
                sortForActivity: {
                    value: 'title,asc',
                    squash: true
                },
                pageForActivityHistorical: {
                    value: '1',
                    squash: true
                },
                sortForActivityHistorical: {
                    value: 'closeDate,desc',
                    squash: true
                },
                searchForOpportunity: {
                    value: {agent: -1, status: 'active'},
                    squash: true
                },
                pageForOpportunity: {
                    value: '1',
                    squash: true
                },
                sortForOpportunity: {
                    value: 'name,asc',
                    squash: true
                }
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/property/property-detail/property-detail.html',
                    controller: 'PropertyDetailControllerRe',
                    controllerAs: 'vm'
                }
            },
            resolve: {
        	  pagingParamsForActivity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                  return {
                      page: PaginationUtil.parsePage($stateParams.pageForActivity),
                      sort: $stateParams.sortForActivity,
                      predicate: PaginationUtil.parsePredicate($stateParams.sortForActivity),
                      ascending: PaginationUtil.parseAscending($stateParams.sortForActivity),
                      search: $stateParams.search
                  };
              }],
              pagingParamsForActivityHistorical: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                  return {
                      page: PaginationUtil.parsePage($stateParams.pageForActivityHistorical),
                      sort: $stateParams.sortForActivityHistorical,
                      predicate: PaginationUtil.parsePredicate($stateParams.sortForActivityHistorical),
                      ascending: PaginationUtil.parseAscending($stateParams.sortForActivityHistorical)
                  };
              }],
              pagingParamsForOpportunity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                  return {
                      page: PaginationUtil.parsePage($stateParams.pageForOpportunity),
                      sort: $stateParams.sortForOpportunity,
                      predicate: PaginationUtil.parsePredicate($stateParams.sortForOpportunity),
                      ascending: PaginationUtil.parseAscending($stateParams.sortForOpportunity),
                      search: $stateParams.searchForOpportunity
                  };
              }],
              entity: ['$stateParams', '$state', 'PropertyService', 'PropertyConstant', function($stateParams, $state, PropertyService, PropertyConstant) {
                  return PropertyService.get({id : $stateParams.id}, function (result) {
                	  	if(result == null || result.id == null || result.active == false){
                	  		$state.go('accessdenied');
                	  	}
                	  
						result.duplicatedId = result.id;
						result.id = null;
						result.title =  result.title + ' (Copy)';
						result.sites = [];
						
						if(result.availability == PropertyConstant.availability.CLOSED_WON){							
							result.availability = PropertyConstant.availability.AVAILABLE;
						}
                  }).$promise;
              }],
              previousState: ["$state", function ($state) {
                  var currentStateData = {
                      name: $state.current.name || 'property',
                      params: $state.params,
                      url: $state.href($state.current.name, $state.params)
                  };
                  return currentStateData;
              }]
          }
        });
    }

})();
