(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('property-detail.sales-tab', {
        	parent: 'property-detail',
        	url: '/sales-tab',
        	templateUrl: 'app/entities/property/property-detail/sales-tab/sales-tab.html',
            controller: 'PropertySalesTabController',
            controllerAs: 'vm',
            resolve: {
                entity: ['$stateParams', 'PropertyService', function($stateParams, PropertyService) {
                    return PropertyService.get({id : $stateParams.id}).$promise;
                }]
            }
        });
    }

})();
