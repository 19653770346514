/**
 * monitorlength.directive.js file
 */
(function(){
	'use strict';
	
	angular
		.module('indochinaApp')
		.directive('monitorlength', ['$filter', function($filter) {
			return {
				require: '?ngModel',
				link : function(scope, elem, attrs, ctrl) {
					var lengthMax = parseInt(attrs.monitorlengthmax) || 255;
					var required = attrs.monitorlengthrequired || false;
					
					if(!ctrl) return;
					
					elem.on('keydown', function(e) {
						var plainValue = elem[0].value;

						//Backspace: 8, Delete: 46, Left arrow: 37, Right arrow: 39
						if("8,46,37,38,39,40,110,16,35,36,13".indexOf(e.keyCode.toString()) < 0) {
							if(plainValue.length >= lengthMax) {
								e.preventDefault();
								return false;
							}
						}
					});
					
		        	elem.on("cut copy paste", function(e) {
		        		setTimeout(function() {
		        			if (elem.val().length > lengthMax) {
		        				ctrl.$setViewValue(elem.val().substring(0, lengthMax));
		        				ctrl.$render();
		                    };
		        		}, 50);
		            });
					
	               ctrl.$formatters.unshift(function (modelValue) {
                		return modelValue;
	                });

					ctrl.$parsers.unshift(function (viewValue) {
	                	var plainValue = null;
	                	if(viewValue != null){
	                		plainValue = viewValue;
	                	}
	                	
	                	ctrl.$setValidity('required', true);
	                	ctrl.$setValidity('monitorLengthMax', true);
	                	
	                	if(plainValue == null || plainValue == ''){
	                		elem.val(plainValue);
	                		
	                		if(required){
	                    		ctrl.$setValidity('required', false);
	                    	}
	                	} else {
	                		ctrl.$setViewValue(plainValue);
	                		ctrl.$render();
	                		
	                		var lastLength = plainValue.toString().length;

	                    	if(lastLength > lengthMax){
	                    		ctrl.$setValidity('monitorLengthMax', false);
	                    	}
	                	}
	                	return plainValue;	
	                });
				}
			};
		}]);
})();