(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('QuotaManagementDetailController', QuotaManagementDetailController);

    QuotaManagementDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'QuotaManagement', 'User'];

    function QuotaManagementDetailController($scope, $rootScope, $stateParams, previousState, entity, QuotaManagement, User) {
        var vm = this;

        vm.quotaManagement = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('indochinaApp:quotaManagementUpdate', function(event, result) {
            vm.quotaManagement = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
