(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('OpportunityController', OpportunityController);

    OpportunityController.$inject = ['$state', '$scope', 'Opportunity', 'ParseLinks', 
    	'paginationConstants', 'pagingParams', 'AuthorityConstant', 'CurrencyConstant', 'OpportunityConstant', 'listSearchAgent', 'countryResource',
    	'AlertService', 'JhiCurrencyService', 'DateUtils', 'FileSaver', 'Principal'];

    function OpportunityController($state, $scope, Opportunity, ParseLinks, 
    		paginationConstants, pagingParams, AuthorityConstant, CurrencyConstant, OpportunityConstant, listSearchAgent, countryResource,
    		AlertService, JhiCurrencyService, DateUtils, FileSaver, Principal) {

        var vm = this;
        
        /******************************************************************************************
         * define all variables
         */
        var ceilOfSlideBar = 10000000;
        var currentCurrencyDes = null;

        vm.searchQuery = pagingParams.search;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.search = search;
        vm.clearSearch = clearSearch;
        // export csv
        vm.exportCSV = exportCSV;
        
        vm.validateDatepicker = validateDatepicker;
        
        vm.countries = [];
        
        vm.stages = OpportunityConstant.STAGE.LIST;
        vm.currenciesDes = CurrencyConstant.DES;
        vm.currencyCode = JhiCurrencyService.currencyCode;
        vm.currencyRate = JhiCurrencyService.rate;
        vm.currencyUSDRate = JhiCurrencyService.USDRate;
        
        vm.fromDatePickerOption = {
    		showWeeks: false,
    		closeDate: false,
    		dateFormat: 'MM/dd/yyyy',
    		isOpen: false
        }
        vm.toDatePickerOption = {
    		showWeeks: false,
    		closeDate: false,
    		dateFormat: 'MM/dd/yyyy',
    		isOpen: false
        }
        
        vm.sliderPropertyValue = {
//    	    minValue: 0,
//    	    maxValue: ceilOfSlideBar,
    	    options: {
    	        floor: 0,
    	        ceil: ceilOfSlideBar,
    	        step: 10,
    	        translate: function(value, sliderId, label) {
					switch (label) {
//						case 'model':
//							return numberWithCommas(value);
						case 'high':
							var viewValue = currentCurrencyDes + numberWithCommas(value);
							if(value == vm.sliderPropertyValue.options.ceil){
								viewValue += '+';
							}
							return viewValue;
						default:
							return currentCurrencyDes + numberWithCommas(value);
					}
				}
    	    }
        };
        
        vm.sliderCommission = {
    	    options: {
    	        floor: 0,
    	        ceil: 100,
    	        step: 1,
    	        translate: function(value, sliderId, label) {
					return value + '%';
				}
    	    }
        };
        
        initData();
        
        
        /******************************************************************************************
         * define all listener action
         */
        
        $scope.$on("curreny:change", function(event, item){
			updateSliderValue();
        });
        
        /******************************************************************************************
         * load data from server
         */

        /******************************************************************************************
         * define functions
         */
        
        function initData(){
        	vm.searchObj = decryptSearchingObj(pagingParams.search);
        	
        	if(vm.searchObj.currency == null || vm.searchObj.currency == ''){
            	vm.searchObj.currency = 'USD';
            }
            if(vm.searchObj.fromPropertyValue == null){
            	vm.searchObj.fromPropertyValue = 0;
            }
            if(vm.searchObj.toPropertyValue == null){
            	vm.searchObj.toPropertyValue = ceilOfSlideBar;
            }
            
            if(vm.searchObj.fromCommission == null){
            	vm.searchObj.fromCommission = 0;
            }
            if(vm.searchObj.toCommission == null){
            	vm.searchObj.toCommission = 100;
            }
        	
        	if(vm.searchObj.fromDate != null){
            	vm.searchObj.fromDate = new Date(vm.searchObj.fromDate);
            }
            if(vm.searchObj.toDate != null){
            	vm.searchObj.toDate = new Date(vm.searchObj.toDate);
            }
        	
        	// init agents
            Principal.identity().then(function(account) {
            	// disable "Agent" field when user login by agent account
            	if(account.authorities[0] == AuthorityConstant.ROLE_AGENT){
            		vm.isDisabledAgentField = true;
            	}
            	
            	vm.agents = listSearchAgent;
            	
            	// set default value for agent
        		vm.agents.some(function(u){
        			if(u.id == account.id){
        				initAgent(u);
        				return true;
        			}
        			return false;
        		});
        	});
            
            countryResource.forEach(function(c){
                vm.countries.push(c.name);
            });
            
            updateSliderValue();
        }
        
        function initAgent(currentUser){
        	if(vm.searchObj.agent != null && vm.searchObj.agent == -1){
        		vm.searchObj.agent = [currentUser];
            }
        	
            loadAll();
        }

        function loadAll () {
            Opportunity.query(getApiParams(), onSuccess, onError);
            
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.opportunities = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        
        function getApiParams(){
        	var apiParams = angular.copy(vm.searchObj);
        	apiParams.page = pagingParams.page - 1;
        	apiParams.size = vm.itemsPerPage;
        	apiParams.sort = sort();
        	
        	if(apiParams.fromPropertyValue == 0){
            	apiParams.fromPropertyValue = null;
            }

            if(apiParams.toPropertyValue == vm.sliderPropertyValue.options.ceil){
            	apiParams.toPropertyValue = null;
            }
        	
        	if(apiParams.fromDate != null){
        		apiParams.fromDate = DateUtils.convertLocalDateToServer(apiParams.fromDate);
        	}

        	if(apiParams.toDate != null){
        		apiParams.toDate = DateUtils.convertLocalDateToServer(apiParams.toDate);
        	}
        	
        	if(apiParams.agent != null && apiParams.agent.length > 0){
        		var listAgentId = [];
        		apiParams.agent.forEach(function(i){
        			listAgentId.push(i.id);
        		});
        		
        		apiParams.agent = listAgentId;
        	}
        	
        	if(apiParams.status != null){
        		var status = null;
        		if(apiParams.status == 'active'){
        			status = OpportunityConstant.STAGE.ACTIVE_LIST;
        		}
        		else{
        			status = OpportunityConstant.STAGE.HISTORICAL_LIST;
        		}
        		
        		apiParams.status = status;
        	}
        	
        	return apiParams;
        }
        
        function sort() {
        	var result = [];
        	if(vm.predicate == 'agent'){
        		result.push('u.firstName,' + (vm.reverse ? 'asc' : 'desc'));
        		result.push('u.lastName,' + (vm.reverse ? 'asc' : 'desc'));
        	}
        	else if(vm.predicate == 'contact'){
        		result.push('c.name,' + (vm.reverse ? 'asc' : 'desc'));
        	}
        	else if(vm.predicate == 'person'){
        		result.push('c.isCompany,' + (vm.reverse ? 'asc' : 'desc'));
        		result.push('psc.firstName,' + (vm.reverse ? 'asc' : 'desc'));
        		result.push('psc.lastName,' + (vm.reverse ? 'asc' : 'desc'));
        	}
        	else if(vm.predicate == 'propertyId'){
        		result.push('p.propertyId,' + (vm.reverse ? 'asc' : 'desc'));
        	}
        	else if(vm.predicate == 'status'){
        		result.push('o.stage,' + (vm.reverse ? 'asc' : 'desc'));
        	}
        	else{
        		result.push('o.' + vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'));
        	}
            if (vm.predicate !== 'id') {
                result.push('o.id');
            }
            return result;
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: encryptSearchingObj()
            },
            {
	        	reload: true
	        });
        }
        
        function search(){
        	vm.page = 1;
        	vm.transition();
        }
        
        function clearSearch(){
        	vm.page = 1;
        	vm.predicate = 'name';
        	vm.reverse = true;
        	vm.searchObj = {};
        	vm.validateDatepicker();
        	vm.transition();
        }
        
        function validateDatepicker(){
        	vm.invalidDatepicker = false;
        	if(vm.searchObj.fromDate != null && vm.searchObj.toDate != null){
        		if(vm.searchObj.fromDate > vm.searchObj.toDate){
        			vm.invalidDatepicker = true;
        		}
        	}
        }
        
        function updateSliderValue(){
            if(vm.currencyCode.value == 'USD'){
            	vm.sliderPropertyValue.options.ceil = ceilOfSlideBar;
            }
            else{
            	vm.sliderPropertyValue.options.ceil = convertCurrencyFromUSD(ceilOfSlideBar, vm.currencyCode.value);
            }

            if(vm.searchObj.currency != vm.currencyCode.value){
            	vm.searchObj.fromPropertyValue = 0;
            	vm.searchObj.toPropertyValue = vm.sliderPropertyValue.options.ceil;
            }

            vm.searchObj.currency = vm.currencyCode.value;
        	currentCurrencyDes = vm.currenciesDes[vm.currencyCode.value];
		}
        
        function convertCurrencyFromUSD(value, code){
			var exchangeRate = {value: 1};
			vm.currencyUSDRate.some(function(r){
				if(r.destinationCode == code){
					exchangeRate = r;
					return true;
				}
				return false;
			});

			return Math.ceil(value * exchangeRate.value);
        }

        function numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        
        function encryptSearchingObj(){
			if($.isEmptyObject(vm.searchObj)){
				return null;
        	}

			return encryptText(JSON.stringify(vm.searchObj));
		}

		function decryptSearchingObj(encryptMsg){
			if($.isEmptyObject(encryptMsg)){
				return {};
			}

			try{
				return JSON.parse(decryptText(encryptMsg));
            }
            catch(e){
            	return {};
            }
		}

		function encryptText(msg){
			return CryptoJS.TripleDES.encrypt(msg, "U2FsdGVkX1~2FnVu36U2P7~2FJ5ye~2FJhvhZQScHXSCUl1cc%3D");
		}

		function decryptText(msg){
			return CryptoJS.TripleDES.decrypt(msg, "U2FsdGVkX1~2FnVu36U2P7~2FJ5ye~2FJhvhZQScHXSCUl1cc%3D").toString(CryptoJS.enc.Utf8);
		}
        
        function exportCSV(){
        	Opportunity.exportCSVOpportunities(getApiParams(), function (result){
                FileSaver.saveAs(result.response, 'OpportunityPages.csv');
            });
        }
    }
})();
