(function() {
	'use strict';

	angular
		.module('indochinaApp')
		.constant('OpportunityConstant', (function() {
			return {
				STAGE : {					
					LIST: ['NEGOTIATION', 'DEPOSIT', 'CLOSED_WON', 'CLOSED_LOST'],
					ACTIVE_LIST: ['NEGOTIATION', 'DEPOSIT'],
					HISTORICAL_LIST: ['CLOSED_WON', 'CLOSED_LOST'],
					NEGOTIATION : 'NEGOTIATION',
					DEPOSIT : 'DEPOSIT',
					CLOSED_WON : 'CLOSED_WON',
					CLOSED_LOST : 'CLOSED_LOST'
				},
				STAGE_MULTI_SELECTOR: [
					{
						name: 'NEGOTIATION',
						displayName: 'Negotiation'
					},
					{
						name: 'DEPOSIT',
						displayName: 'Deposit'
					},
					{
						name: 'CLOSED_WON',
						displayName: 'Closed Won'
					},
					{
						name: 'CLOSED_LOST',
						displayName: 'Closed Lost'
					}
				]
			}
		})());
})();
