(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('DetailTabController', DetailTabController);

    DetailTabController.$inject = ['$q', '$scope', '$state', '$rootScope', '$timeout', '$stateParams', 'previousState', 'entity', 'Contact', 'login', 'pagingParams', 'User',
                                   'contactByResource', 'ContactConstant', 'Company', 'LeadSourceManagementService', 'PublishService', 'PropertyService', '$uibModal', 'Opportunity', 'Person', 'AlertService', '$translate',
                                    'contactMethodResource', 'otherContactResource', 'LeadSourceConstant', 'countryResource', 'provinceResource', 'cityResource', 'districtHCMResource',
                                    'districtHANResource', 'districtDNGResource', 'leadSourceResource', 'coAgentResource', 'agentResource', 'contactTypeResource', 'languagePreferenceResource', 'publishSiteResource',
                                    'CompanyNameMasterData', 'indochinaPropertyContact'];

    function DetailTabController($q, $scope, $state, $rootScope, $timeout, $stateParams, previousState, entity, Contact, login, pagingParams, User,
                                 contactByResource, ContactConstant, Company, LeadSourceManagementService, PublishService, PropertyService, $uibModal, Opportunity, Person, AlertService, $translate,
    		                    contactMethodResource, otherContactResource, LeadSourceConstant, countryResource, provinceResource, cityResource, districtHCMResource,
                                districtHANResource, districtDNGResource, leadSourceResource, coAgentResource, agentResource, contactTypeResource, languagePreferenceResource, publishSiteResource,
                                CompanyNameMasterData, indochinaPropertyContact) {

        var vm = this;
        var login = login;
        vm.limit = 2;

        if($state.isEdit) {
        	vm.isEdit = $state.isEdit;
        }

        $rootScope.scrollTop();
        $rootScope.$broadcast('contact:activeTab', 'DETAIL');
        $scope.$on('$stateChangeStart', onStateChangeStart);

        vm.contact = entity;

        if (vm.contact.id === null) {
        	vm.isEdit = true;
        }

        vm.previousState = previousState.name;
        vm.save = save;
        vm.clear = clear;
        vm.changeStatus = changeStatus;
        vm.changeMailingCountry = changeMailingCountry;
        vm.changeContactBy = changeContactBy;
        vm.changePersonPosition = changePersonPosition;
        vm.changeCompanyPersonPosition = changeCompanyPersonPosition;
        vm.changeSalutation = changeSalutation;
        vm.changeSalutationForIndividual = changeSalutationForIndividual;
        vm.onSelectAgent = onSelectAgent;
        vm.onSelectTransferAgent = onSelectTransferAgent;
        vm.isCompany = isCompany;
        vm.removeCompanyPerson = removeCompanyPerson;
        vm.isOneRow = isOneRow;
        vm.addCompanyPerson = addCompanyPerson;
        vm.parentCompanies = parentCompanies;
        vm.parentCompanySelect = parentCompanySelect;
        vm.contacts = contacts;
        vm.coAgents = coAgents;
        vm.sites = sites;
        //vm.contactTypeClick = contactTypeClick;
        vm.isContactTypeRequire = isContactTypeRequire;
        //vm.checkType = checkType;
        vm.changeParentsSource = changeParentsSource;
        vm.changeContact = changeContact;
        vm.checkingLeadSource = checkingLeadSource;

        vm.onContactTypeSelect = onContactTypeSelect;
        vm.onRemoveContactTypeSelect = onRemoveContactTypeSelect;

        vm.listForAgentField = listForAgentField;
        vm.listForTransferAgentField = listForTransferAgentField;

        vm.mobilePersonBlur = mobilePersonBlur;
        vm.mobileCompanyPersonBlur = mobileCompanyPersonBlur;

        vm.checkIfMailing = checkIfMailing;

        vm.loadMasterDataForCompanyName = loadMasterDataForCompanyName;
        vm.onSelectCompanyName = onSelectCompanyName;

        vm.addressChange = addressChange;

        vm.tradeNameChange = tradeNameChange;

        vm.selectedConcactBy = [];
        vm.onContactTypesSelect = [];

        // country for company
        vm.countryList = [];
        vm.provinceList = [];
        vm.provinceMasterData = [];
        vm.cityList = [];
        vm.cityMasterData = [];
        vm.companyDistrictList = [];
        vm.districtHCMMasterData = [];
        vm.districtHANMasterData = [];
        vm.districtDNGMasterData = [];
        vm.onSelectCompanyCountry = onSelectCompanyCountry;
        vm.onKeyUpCompanyCountry = onKeyUpCompanyCountry;
        vm.onSelectCompanyCity = onSelectCompanyCity;
        vm.onKeyUpCompanyCity = onKeyUpCompanyCity;

        // country for bank
        vm.onSelectBankCountry = onSelectBankCountry;
        vm.onKeyUpBankCountry = onKeyUpBankCountry;
        vm.onSelectBankCity = onSelectBankCity;
        vm.onKeyupBankCity = onKeyupBankCity;

        // country for mailing address
        vm.onSelectMailingAddressCity = onSelectMailingAddressCity;
        vm.onSelectMailingAddressCountry = onSelectMailingAddressCountry;
        vm.onKeyUpMailingAddressCountry = onKeyUpMailingAddressCountry;
        vm.onKeyupMailingAddressCity = onKeyupMailingAddressCity;
        vm.mailingAddressProvinceList = [];
        vm.mailingAddressCityList = [];
        vm.mailingAddressDistrictList = [];

        vm.isLeadSourceInActivated = false;
        processDisableLeadOrChildrenSource();

        // define these constant to use in file html template
        vm.coBrokerConstant = LeadSourceConstant.types.CoBROKER;
        vm.introductionConstant = LeadSourceConstant.types.INTRODUCTION;
        vm.portalConstant = LeadSourceConstant.types.PORTAL;
        vm.indochinaWebsiteConstant = LeadSourceConstant.types.INDOCHINA_WEBSITE;
        vm.iccStaffConstant = LeadSourceConstant.types.ICC_STAFF;
        vm.iccDivisionConstant = LeadSourceConstant.types.ICC_DIVISION;
        vm.existingContactConstant = LeadSourceConstant.types.EXISTING_CONTACT;
        vm.personalNetworkConstant = LeadSourceConstant.types.PERSONAL_NETWORK;
        vm.databaseConstant = LeadSourceConstant.types.DATABASE;
        
        vm.indochinaPropertyContact = indochinaPropertyContact;

        $rootScope.$on('contact:changeMode', function(event, result) {
            vm.isEdit = result;

            if(vm.isEdit){
        		$timeout(function (){
            		resetInputWith('jsUISelect', 2);
            	}, 1);
        	}
        });

        $rootScope.$on('contact:activated', function(event, result) {
        	vm.contact.activated = result;
        });


        // variable paging, sorting for persons of company list mode view
        vm.transition = transition;
        vm.itemsPerPage = 10;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        //emails
        vm.addPersonEmail = addPersonEmail;
        vm.confirmDeletePersonEmail = confirmDeletePersonEmail;
        vm.isPersonEmailOneRow = isPersonEmailOneRow;
        // mobiles
        vm.addPersonMobile = addPersonMobile;
        vm.confirmDeletePersonMobile = confirmDeletePersonMobile;
        vm.isPersonMobileOneRow = isPersonMobileOneRow;

        init();

        function init() {
            // get full information user login
            User.get({login: login.login}, function (result) {
                if(login.authorities[0] !== 'ROLE_ADMIN'){
                	login = angular.copy(result);
                	
                	if(vm.contact.id == null){
                		vm.contact.agent = angular.copy(result);
                	}
                }
            });

            // this for when change tab and contact
        	if(vm.contact.id === null && vm.contact.contactTypes && vm.contact.contactTypes.length === 0) {
        		// create empty person for company
        		vm.contact.company.persons = [];
        		var person = {
        				salutation: null,
                        firstName: null,
                        lastName: null,
                        company: null,
                        position: 'none-select',
                        emails: [PersonEmailInit()],
                        mobiles: [PersonPhone(true)],
                        officePhone: PersonPhone(false),
                        nationality: null,
                        vietKieu: null,
                        id: null,
                        indexDefault: 0,
                        indexEmail: 1
        		}
        		vm.contact.company.persons.push(person);
        	}

        	// set isCompany = false as default value
        	if(vm.contact.isCompany === null) {
        		vm.contact.isCompany = false;
        	}
        	vm.isCompanyStatus = angular.copy(vm.contact.isCompany);

        	// init contact by
        	if(vm.contact.contactBies != null) {
        		angular.forEach(vm.contact.contactBies, function(value, key) {
        			vm.selectedConcactBy[value.id] = true;

        		});
        	}

        	// init status list
        	vm.statusList = ContactConstant.status.LIST;
        	if (vm.contact.status === null) vm.contact.status = 'none-select';

        	// init prefer contact method list
            vm.preferredContactMethodList = contactMethodResource;

            // init other contact type
            vm.otherContactTypes = otherContactResource;

        	// init contact by
        	if(vm.contact.contactBy === null) vm.contact.contactBy = 'none-select';

        	// init salutations
        	vm.salutations = ['Mr.','Ms.','Mrs.'];

        	//init positions
        	vm.positions = ['C LEVEL','DIRECTOR','MANAGER','OTHER'];

        	//load user agent list
            vm.listAgents = agentResource;

        	//load co-agent list
            vm.listCoAgents = coAgentResource;

        	// get contact type list
            initContactType ();
            
        	// get contact by list
            vm.contactBies = contactByResource;

        	// init district
            initDistrict ();

            // init city
            initCity ();

        	// init province
            initProvince ();

        	// get country list
            vm.countryList = countryResource;

        	//get lead sources list
            vm.parentsSource = leadSourceResource;
            if(vm.contact.parentSource !== null) {
                angular.forEach(vm.parentsSource, function(value, key) {
                    if(vm.contact.parentSource.id === value.id) {
                        vm.contact.parentSource.childs = value.childs;
                    }

                });
            }

        	// get sites
            vm.sites = publishSiteResource;

        	// init bank account for contact
            if(!vm.contact.bankAccounts) {
        	    vm.contact.bankAccounts = [];
                addBankAccount();
            } else if (vm.contact.bankAccounts.length === 0 ) {
                addBankAccount();
            }

            // get all language preference
            vm.languagePreferences = languagePreferenceResource;


            // load list person mode view
            if(vm.contact.id && vm.contact.isCompany) {
                loadAll();
            }

            //init data for Email
            initEmail(true);
            //init data for Mobile
            initMobile();
            //initOffice Phone
            initOfficePhone();
        }

        function initEmail(isInit){
        	if(!vm.contact.isCompany) {
        		vm.contact.person.indexEmail = 0;
        		if(!vm.contact.person.emails || vm.contact.person.emails.length === 0){
	        		vm.contact.person.emails = [];
	        		addPersonEmail();
	        		vm.contact.person.indexDefault = 0;
        		}else if(isInit){
        			 angular.forEach(vm.contact.person.emails, function(value, key) {
        				 if(value.default){
        					 vm.contact.person.indexDefault = key;
    					 }
        				 value.indexEmail = vm.contact.person.indexEmail++;
        			 });
        		}
        	}else {
        		 angular.forEach(vm.contact.company.persons, function(value, key) {
        			 value.indexEmail = 0;
        			 if(!value.emails || value.emails.length ===0){
        				 value.emails = [];
        				 addPersonEmail(key);
    	        		 value.indexDefault = 0;

        			 }else if(isInit){
        				 angular.forEach(value.emails, function(valueEmail, keyEmail) {
        					 if(valueEmail.default){
        						 value.indexDefault = keyEmail;
        					 }
        					 valueEmail.indexEmail = value.indexEmail++;
        				 });
        			 }
        		 });
        	}
        }

        function initMobile(){
        	if(!vm.contact.isCompany && (!vm.contact.person.mobiles || vm.contact.person.mobiles.length === 0)) {
        		vm.contact.person.mobiles = [];
        		addPersonMobile();
        	}else if(vm.contact.isCompany){
        		 angular.forEach(vm.contact.company.persons, function(value, key) {
        			 if(!value.mobiles || value.mobiles.length ===0){
        				 value.mobiles = [];
        				 addPersonMobile(key);
        			 }
        		 });
        	}
        }

        function initOfficePhone(){
        	if(!vm.contact.isCompany && !vm.contact.person.officePhone) {
        		vm.contact.person.officePhone = PersonPhone(false);
        	}else if(vm.contact.isCompany){
        		 angular.forEach(vm.contact.company.persons, function(value, key) {
        			 if(!value.officePhone){
        				 value.officePhone = PersonPhone(false);
        			 }
        		 });
        	}
        }

        function initProvince () {
            vm.provinceMasterData = provinceResource;

            // init company province list
            if(vm.contact == null || vm.contact.company == null || vm.contact.company.country == null){
            	vm.provinceList = vm.provinceMasterData;
                vm.provinceTypeaheadEditable = true;
            }
            else if(vm.contact.company.country.toUpperCase() == 'vietnam'.toUpperCase()){
            	vm.provinceList = vm.provinceMasterData;
                vm.provinceTypeaheadEditable = false;
            }
            else {
                vm.provinceList = [];
                vm.provinceTypeaheadEditable = true;
            }

            // init mailing province list
            if(vm.contact == null || vm.contact.mailingCountry == null){
            	vm.mailingAddressProvinceList = vm.provinceMasterData;
                vm.mailingAddressProvinceTypeaheadEditable = true;
            }
            else if(vm.contact.mailingCountry.toUpperCase() == 'vietnam'.toUpperCase()){
                vm.mailingAddressProvinceList = vm.provinceMasterData;
                vm.mailingAddressProvinceTypeaheadEditable = false;
            }
            else {
                vm.mailingAddressProvinceList = [];
                vm.mailingAddressProvinceTypeaheadEditable = true;
            }

            // init bank account province list
            angular.forEach(vm.contact.bankAccounts, function(value, key) {
                if (!value.country || value.country === '' || value.country.toUpperCase() == 'vietnam'.toUpperCase()) {
                    value.provinceList = vm.provinceMasterData;
                } else {
                    value.provinceList = [];
                }
            });
        }

        function initCity () {
            vm.cityMasterData = cityResource;

            // init company city list
            if(vm.contact.company == null || vm.contact.company.country == null && vm.contact.company.country == ''){
            	vm.cityList = vm.cityMasterData;
                vm.cityTypeaheadEditable = true;
            }
            else if(vm.contact.company != null && vm.contact.company.country != null && vm.contact.company.country.toUpperCase() == 'vietnam'.toUpperCase()){
                vm.cityList = vm.cityMasterData;
                vm.cityTypeaheadEditable = false;
            } else {
                vm.cityList = [];
                vm.cityTypeaheadEditable = true;
            }

            // init mailing city list
            if(vm.contact == null || vm.contact.mailingCountry == null || vm.contact.mailingCountry == '') {
            	vm.mailingAddressCityList = vm.cityMasterData;
                vm.mailingAddressCityTypeaheadEditable = true;
            }
            else if(vm.contact.mailingCountry.toUpperCase() == 'vietnam'.toUpperCase()) {
                vm.mailingAddressCityList = vm.cityMasterData;
                vm.mailingAddressCityTypeaheadEditable = false;
            } else {
                vm.mailingAddressCityList = [];
                vm.mailingAddressCityTypeaheadEditable = true;
            }

            // init bank account city list
            angular.forEach(vm.contact.bankAccounts, function(value, key) {
                if(value.country == null || value.country == '' || value.country.toUpperCase() == 'vietnam'.toUpperCase()){
                    value.cityList = vm.cityMasterData;
                } else {
                    value.cityList = [];
                }
            });
        }

        function initDistrict () {
            //init HCM district
            vm.districtHCMMasterData = districtHCMResource;

            //init HA_NOI district
            vm.districtHANMasterData = districtHANResource;

            //init DA_NANG district
            vm.districtDNGMasterData = districtDNGResource;

            // init company district list
            if(vm.contact.company){
                // this field can editable or not
                vm.companyDistrictTypeaheadEditable = false;
                if(vm.contact.company.city == 'Ho Chi Minh City'){
                    vm.companyDistrictList = vm.districtHCMMasterData;
                } else if(vm.contact.company.city == 'Ha Noi'){
                    vm.companyDistrictList = vm.districtHANMasterData;
                } else if(vm.contact.company.city == 'Da Nang'){
                    vm.companyDistrictList = vm.districtDNGMasterData;
                } else {
                    vm.companyDistrictTypeaheadEditable = true;
                }
            }

            // init mailing city list
            vm.mailingAddressDistrictTypeaheadEditable = false;
            if(vm.contact.mailingCity == 'Ho Chi Minh City'){
                vm.mailingAddressDistrictList = vm.districtHCMMasterData;
            } else if(vm.contact.mailingCity == 'Ha Noi'){
                vm.mailingAddressDistrictList = vm.districtHANMasterData;
            } else if(vm.contact.mailingCity == 'Da Nang'){
                vm.mailingAddressDistrictList = vm.districtDNGMasterData;
            } else {
                vm.mailingAddressDistrictTypeaheadEditable = true;
            }

            // init bank account city list
            angular.forEach(vm.contact.bankAccounts, function(value, key) {
                if(value != null){
                    value.districtTypeaheadEditable = true;
                    if(value.city == 'Ho Chi Minh City'){
                        value.districtList = vm.districtHCMMasterData;
                    }
                    if(value.city == 'Ha Noi'){
                        value.districtList = vm.districtHANMasterData;
                    }
                    if(value.city == 'Da Nang'){
                        value.districtList = vm.districtDNGMasterData;
                    } else {
                        value.districtTypeaheadEditable = true;
                    }
                }

            });
        }

        /*
        * contact type:
        * id = 1 is Seller, id = 2 is Buyer, id = 3 is Other
        * and the rest is create from user management
        * */
        function initContactType () {
            // init master contact type list
            vm.masterContactType = angular.copy(contactTypeResource);

            // init contact type list user can choice in UI
            vm.canChoiseConactType = [];

            // init list item type if user is seller or other
            vm.sellerOtherContactType = [];

            //init list item if contact is create when create a user in user management
            vm.userCreateContactType = [];

            angular.forEach(vm.masterContactType, function(value, key) {
                if(value.id === 1 || value.id === 2 || value.id === 3) {
                    vm.canChoiseConactType.push(angular.copy(value));
                }
                if(value.id === 1 || value.id === 3) {
                    vm.sellerOtherContactType.push(angular.copy(value));
                }
                if(value.id !== 1 && value.id !== 2 && value.id !== 3) {
                    vm.userCreateContactType.push(angular.copy(value));
                }
            });

            // detect contact is buyer, seller, other, or create from user management
            if(vm.contact.contactTypes !== null && vm.contact.contactTypes.length > 0) {
                angular.forEach(vm.contact.contactTypes, function(value, key) {
                    onContactTypeSelect(value);
                });
            } else {
                vm.contactTypes = angular.copy(vm.canChoiseConactType);
            }
        }

        function onContactTypeSelect (item) {

            // check contact type to init contact types list display in UI
            if(item.id === 2) {
                vm.contactTypes = [];
            } else if (item.id === 1 || item.id === 3) {
                $state.isDirty = false;
                vm.contactTypes = angular.copy(vm.sellerOtherContactType);
            } else {
                vm.contactTypes = angular.copy(vm.userCreateContactType);
            }
            contactTypeChecking(item, true);
            
            if(item.id === 1){            	
            	loadPropertyOwned();
            }
        }

        function onRemoveContactTypeSelect (item) {
            if(vm.contact.contactTypes.length === 0) {
                vm.contactTypes = angular.copy(vm.canChoiseConactType);
            }
            contactTypeChecking(item, false);
            
            var isHidePropertyOwned = true;
            if(vm.contact.contactTypes != null && vm.contact.contactTypes.length > 0){
            	vm.contact.contactTypes.forEach(function(contactType){
            		if(contactType.id === 1){
            			isHidePropertyOwned = false;
            		}
            	});
            }
            
            if(isHidePropertyOwned){
            	vm.isShowPropertyOwned = false;
            	vm.listBelongProperties = [];
            }
        }

        function contactTypeChecking (item, value) {
            if(item.id === 2) {
                vm.isBuyer = value;
                if(value) {
                    if(vm.contact.agent === null && login.authorities[0] !== 'ROLE_ADMIN') {
                        vm.contact.agent = login;
                    }
                }
                $rootScope.$broadcast('contact:isBuyer', vm.isBuyer);

                if(!vm.contact.contactPreferences) {
                    vm.contact.contactPreferences = [];
                }

            } else if(item.id === 1) {
                vm.isSeller = value;
            } else if(item.id === 3) {
                vm.isOther = value;
            } else {
                vm.isCreateFromUserManagement = ovalidatevalue;
            }
        }
        
        function loadPropertyOwned(){
        	vm.isShowPropertyOwned = false;
        	vm.listBelongProperties = [];
        	
        	if(vm.contact.id != null && vm.contact.contactTypes != null){
        		console.log(vm.contact.contactTypes);
        		vm.contact.contactTypes.forEach(function(contactType){
        			if(contactType.id == 1){ // seller
        				vm.isShowPropertyOwned = true;
        	        	PropertyService.getAllByOwnerIdForContact({contactId:vm.contact.id},function(data){
        	    			vm.listBelongProperties = data;
        	    		});
        			}
        		});
        	}
        }

        function validate (contact) {
            // avoid user click save many time
            vm.isSaving = true;
            
            Contact.validate(contact, onValidateSuccess, onValidateError);
        }

        function onValidateSuccess (contact) {
            saveContact (contact);
        }

        function onValidateError (error) {
            if(error.status == 400) {
                if(error.data === '') { //
                    AlertService.error($translate.instant('error.duplicate', {}));
                } else {
                    var contactHaveBankAccountDuplicate = error.data.name;
                    AlertService.error($translate.instant('error.duplicateBankAccount', { param: contactHaveBankAccountDuplicate }));
                }
                vm.isSaving = false;
                $scope.editForm.$dirty = true;
            }
        }

        function save () {
            var copy = angular.copy(vm.contact);
            cleanContact (copy);
            validate (copy);
        }

        function cleanContact (contact) {
            // set null variable if contact is not a buyer
        	if(typeof vm.isBuyer === 'undefined' || vm.isBuyer === false) {
        		contact.parentSource = null;
        		contact.childSource = null;
        		contact.coAgent = null;
        		contact.contactPreferences = null;
        		contact.status = 'none-select';
            }

        	// if Other type didn't chosen remove other contact type
        	if(typeof vm.isOther === 'undefined' || !vm.isOther) {
        	    contact.otherContactType = null;
            }

        	// get contact by list for contact
        	contact.contactBies = [];
        	angular.forEach(vm.selectedConcactBy, function(value, key) {
                if(value === true) {
                	contact.contactBies.push(vm.contactBies[key - 1]);
                }
            });

            // update full name for contact
            if(contact.isCompany) {
                contact.name = contact.company.tradeName.name;
            } else {
                contact.name = contact.person.firstName + ' ' + contact.person.lastName;
            }

        	// agent
        	if(contact.agent != null && contact.agent.fullName === null) contact.agent = null;
        	if(contact.transferAgent == null || contact.transferAgent.fullName === null){
        		contact.transferAgent = null;
        		contact.transferAgentCommission = null;
        	}

        	// bank account info
            if(vm.isBuyer || (vm.isOther && !vm.isSeller)) {
                contact.bankAccounts = null;
            } else {
                angular.forEach(contact.bankAccounts, function(value, key) {
                    if(!value.postalCode) value.postalCode = '';
                    if(value.postalCode.trim() === '' && value.accountNumber.trim() === '' && value.beneficiaryName.trim() === ''
                        && value.bankName.trim() === '' && value.branchName.trim() === ''
                        && value.swift.trim() === '' && value.streetNumber.trim() === ''
                        && value.streetName.trim() === '' && (!value.country || value.country.trim() === '')
                        && (!value.city || value.city.trim() === '') && (!value.province || value.province.trim() === '')) {
                        contact.bankAccounts.splice(key, 1);

                    }
                });
            }

			//set is default email and remove value blank for email, phone
			var isDefaultEmail = false;
			if(contact.isCompany) {
				angular.forEach(contact.company.persons, function(value, key) {
					isDefaultEmail = false;
					//email
					// set default email
					angular.forEach(value.emails, function(valueEmail, keyEmail, obj) {
						if(value.indexDefault == valueEmail.indexEmail){
							valueEmail.default = true;
							isDefaultEmail = true;
							value.email = valueEmail.email;
						}else{
							valueEmail.default = false;
						}
					});
					
					// remove item blank
					var listEmailTemporary = angular.copy(value.emails);
					value.emails = [];
					angular.forEach(listEmailTemporary, function(emailObj){
						if (emailObj.email != null && emailObj.email.trim() != '') {
							value.emails.push(emailObj);
						}
					});
					
					if(value.emails.length>0 && !isDefaultEmail){
						value.emails[0].default = true;
					}
					
					// mobile
					// remove item blank
					var listMobileTemporary = angular.copy(value.mobiles);
					value.mobiles = [];
					angular.forEach(listMobileTemporary, function(mobileObj){
						if(mobileObj.phone != null && mobileObj.phone.trim() != ''){
							value.mobiles.push(mobileObj);
						}
					});
				});
			}
			else {
				isDefaultEmail = false;
				angular.forEach(contact.person.emails, function(valueEmail, keyEmail, obj) {
					if(contact.person.indexDefault == valueEmail.indexEmail){
						valueEmail.default = true;
						isDefaultEmail = true;
						contact.person.email = valueEmail.email;
					}
					else{
						valueEmail.default = false;
					}
				});
				
				// remove item blank
				var listEmailTemporary = angular.copy(contact.person.emails);
				contact.person.emails = [];
				angular.forEach(listEmailTemporary, function(emailObj){
					if(emailObj.email != null && emailObj.email.trim() != ''){
						contact.person.emails.push(emailObj);
					}
				});
				
				if(contact.person.emails.length>0 && !isDefaultEmail){
					contact.person.emails[0].default = true;
				}
				// mobile
				// remove item blank
				var listMobileTemporary = angular.copy(contact.person.mobiles);
				contact.person.mobiles = [];
				angular.forEach(listMobileTemporary, function(mobileObj){
					if(mobileObj.phone != null && mobileObj.phone.trim() != ''){
						contact.person.mobiles.push(mobileObj);
					}
				});
			}
            return contact;
        }


        function saveContact (contact) {

            // avoid user click save many time
        	// vm.isSaving = true;

        	


        	/*if contact is buyer, so update entity (contact) in parent state and will next to 'Search Criteria' not save
        	* else save and change to mode view
        	* */
        	if(vm.isBuyer) {
        	    // if have this tab edit, update isDirty for next tab
        	    if($scope.editForm.$dirty) $state.isDirty = true;

        	    // update entity
                $scope.$emit('indochinaApp:contactUpdate', contact);

                // check contact id to detect create new contact or edit contact
                if(contact.id) {
                    $state.go('contact-detail.contact-prefer-tab');
                } else {
                    $state.go('contact.new.contact-prefer-tab');
                }
            } else {

                // detect isCompany or individual
                if(contact.isCompany === true) {
                    contact.person = null;
                } else {
                    contact.company = null;
                }

                // create new or update contact
                if (contact.id !== null) {
                    Contact.update(contact, onSaveSuccess, onSaveError);
                    if(vm.isCompanyStatus !== contact.isCompany) {
                        Opportunity.deletePersonWhenCompanyChange({id: contact.id});
                    }
                } else {
                    vm.isNew = true;
                    Contact.save(contact, onSaveSuccess, onSaveError);
                }
            }

            $scope.editForm.$dirty = false;

        }

        function onSaveSuccess (result) {

            // go to detail state if create contact complete
            if(vm.isNew) {
                $state.go('contact-detail.detail-tab', {id: result.id});
                return;
            } else {

                // reset scope editForm dirty
                $scope.editForm.$dirty = false;

                // change variable isSaving
                vm.isSaving = false;

                // change mode edit in state
                $state.isEdit = false;

                // update isDirty variable state
                $state.isDirty = false;

                // change mode on current state
                vm.isEdit = false;

                // broadcast event to appear button edit
                $rootScope.$broadcast('contact:changeMode', false);

                $state.reload('contact-detail');
            }

            /*// broadcast event to appear button edit
            $rootScope.$broadcast('contact:changeMode', false);*/
        }

        function onSaveError (error) {
            /*
            * detect for error code 400
            * if data have name so this is error notify which contact have bank account duplicate
            * else duplicate contact
            * */
            // if(error.status == 400) {
            //     if(error.data === '') { //
            //         AlertService.error($translate.instant('error.duplicate', {}));
            //     } else {
            //         var contactHaveBankAccountDuplicate = error.data.name;
            //         AlertService.error($translate.instant('error.duplicateBankAccount', { param: contactHaveBankAccountDuplicate }));
            //     }
            //     vm.isSaving = false;
            //     $scope.editForm.$dirty = true;
            // }

            // // init Bank Account 
            // if(_.isEmpty(vm.contact.bankAccounts)) {
            //     addBankAccount ();
            // }

            // //init data for Email
            // initEmail(true);
            // //init data for Mobile
            // initMobile();
            // //initOffice Phone
            // initOfficePhone();

        }

        function clear () {

            // if is create new contact will back to contact list
            if(vm.contact.id === null) {
                $state.go('contact', {reload: false});
                return;
            }

            if($state.isDirty || $scope.editForm.$dirty) {

                // if have any change we must reload page to reset entity
                $state.reload($state.current.name);

                return;
            }

            // change mode edit in state
            $state.isEdit = false;

            // update isDirty variable state
            $state.isDirty = false;

            // change mode on current state
            vm.isEdit = false;

            // broadcast event to appear button edit
            $rootScope.$broadcast('contact:changeMode', false);

            //scroll to top page
            $rootScope.scrollTop();

        }



        function changeStatus (status) {
        	vm.contact.status = status;
        	if($scope.editForm) {
        		$scope.editForm.$dirty = true;
        	}
        }

        function changeMailingCountry (country) {
        	vm.contact.mailingCountry = country.name;
        }

        function changeContactBy (contactBy) {
        	vm.contact.contactBy = contactBy.name;
        	if($scope.editForm) {
        		$scope.editForm.$dirty = true;
        	}
        }

        function changeSalutation (salutation, person) {
        	person.salutation = salutation;
        	if($scope.editForm) {
        		$scope.editForm.$dirty = true;
        	}
        }

        function changeSalutationForIndividual (salutation) {
        	vm.contact.person.salutation = salutation;
        	if($scope.editForm) {
        		$scope.editForm.$dirty = true;
        	}
        }

        function changePersonPosition (position) {
        	if(vm.contact.person.position !== 'OTHER') {
        		vm.contact.person.otherPosition = null;
        	}
        }

        function changeCompanyPersonPosition (person) {
        	if(person.position !== 'OTHER') {
        		person.otherPosition = null;
        	}
        }

        function onSelectAgent (agent) {
        	var copy = angular.copy(agent);
        	vm.contact.agent = copy;
        }

        function onSelectTransferAgent (agent) {
        	var copy = angular.copy(agent);
        	vm.contact.transferAgent = copy;
        }

        function isCompany () {
        	// will be broadcast a event
        	$rootScope.$broadcast('contact:isCompanyChange', vm.contact.isCompany);

        	if(vm.contact.isCompany === false && vm.contact.person === null) {
        		var person = addPerson();
        		vm.contact.person = person;
        	}

        	if(vm.contact.isCompany === true && vm.contact.company === null) {
        		vm.contact.company = {
        				bankAccount: null,
                        persons: [],
                        name: null,
                        website: null,
                        phone: null,
                        streetNumber: null,
                        streetName: null,
                        city: null,
                        province: null,
                        country: null,
                        postalCode: null,
                        activated: null,
                        createDate: null,
                        modifiedDate: null,
                        taxCode: null,
                        registryNumber: null,
                        id: null,
                        indexDefault: 0,
                        indexEmail: 0
        		};
        		var personCompany = addPerson();
        		vm.contact.company.persons.push(personCompany);
        	}
        }

        function removeCompanyPerson(index) {
        	if(vm.contact.company.persons[index].id === null) {
        		removePerson(index);
        	} else {
        		Opportunity.getByPersonId({id:vm.contact.company.persons[index].id}, function (result){
            		if(result[0] > 0) {
            			confirmDeletePersonHasChosen(index);
            		} else {
            			removePerson(index);
            		}
            	}, function error (error){
            		console.log(error);
            	});
        	}
        }

        function confirmDeletePersonHasChosen(index){
        	$uibModal.open({
                templateUrl: 'app/components/confirmation-dialog/delete/confirm-dialog.html',
                controller: 'DeleteConfirmDialogController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass : 'absolute-center',
                resolve: {
                    entity: function(){
                    	return null;
                    },
                    translateKeys: function(){
                    	return {
                    		keys: 'indochinaApp.contact.confirmDeletePerson'
                    	};
                    }
                }
            }).result.then(function() {
            	removePerson(index);
            }, function() {
            });
        }

        function removePerson(index) {
        	$scope.editForm.$dirty = true;
        	vm.contact.company.persons.splice(index, 1);
        }

        function isOneRow(){
        	if(vm.contact.company.persons.length === 1) {
        		return true;
        	}
        	return false;
        }

        function addCompanyPerson() {
        	var person = addPerson();
    		vm.contact.company.persons.push(person);
        }

        function addPerson() {
        	return {
        			personalDetails: null,
    				salutation: null,
                    firstName: null,
                    lastName: null,
                    company: null,
                    otherPosition: null,
                    position: 'none-select',
                    emails: [PersonEmailInit()],
                    mobiles: [PersonPhone(true)],
                    officePhone: PersonPhone(false),
                    nationality: null,
                    vietKieu: null,
                    id: null,
                    indexDefault: 0,
                    indexEmail: 1
    		};

        }

        function parentCompanies(name) {
        	if (name) {
        		return Company.getByName({name:name, id: vm.contact.company.id}).$promise;
        	}
        }

        function parentCompanySelect(item, model, label) {
        	vm.contact.company.parent = item;
        }

        function contacts(name) {
            if(vm.contact.parentSource.name.toLowerCase() === vm.coBrokerConstant.toLowerCase() ) {
                return Contact.getAllContactTypeBrokerByName({name:name, id: vm.contact.id}).$promise;
            } else {
                return Contact.getAllContactByName({name:name, id: vm.contact.id, contactType: vm.contact.contactTypes[0].id}).$promise;
            }
        }

        function coAgents(name) {
        	if (name) {
        		return User.getCoAgentByName({name:name}).$promise;
        	}
        }

        function sites() {

        		return PublishService.query(function (data){
        		}).$promise;
        }

        function onSelectCompanyCountry($item, $model, $label) {
            if($model.toUpperCase() == 'vietnam'.toUpperCase()){
            	vm.provinceList = vm.provinceMasterData;
                vm.provinceTypeaheadEditable = false;

                vm.cityList = vm.cityMasterData;
                vm.cityTypeaheadEditable = false;
            }
            else{
            	vm.provinceList = [];
            	vm.provinceTypeaheadEditable = true;

            	vm.cityList = [];
            	vm.cityTypeaheadEditable = true;
            	//vm.contact.company.city = 'INTL';
            }
            vm.contact.company.city = '';
            vm.contact.company.province = '';
            vm.contact.company.district = '';

            vm.companyDistrictList = [];
    		vm.companyDistrictTypeaheadEditable = true;
        };

        function onKeyUpCompanyCountry(){
        	if(vm.contact == null || vm.contact.company == null || vm.contact.company.country == null || vm.contact.company.country == ''){
        		vm.provinceList = [];
                vm.provinceTypeaheadEditable = true;

        		vm.cityList = [];
                vm.cityTypeaheadEditable = true;
        	}
        }

        function onSelectCompanyCity($item, $model, $label) {
        	vm.contact.company.district = '';
        	if($item.code == 'HCM'){
        		vm.companyDistrictList = vm.districtHCMMasterData;
        		vm.companyDistrictTypeaheadEditable = false;
        	}
        	else if($item.code == 'HAN'){
        		vm.companyDistrictList = vm.districtHANMasterData;
        		vm.companyDistrictTypeaheadEditable = false;
        	}
        	else if($item.code == 'DNG'){
        		vm.companyDistrictList = vm.districtDNGMasterData;
        		vm.companyDistrictTypeaheadEditable = false;
        	}
        	else{
        		vm.companyDistrictList = [];
        		vm.companyDistrictTypeaheadEditable = true;
        	}
        };

        function onKeyUpCompanyCity(){
        	if(vm.contact.company.city == null || vm.contact.company.city == ''){
        		vm.companyDistrictList = [];
        		vm.companyDistrictTypeaheadEditable = true;
        	}
        }

        function onSelectMailingAddressCountry($item, $model, $label) {
            if($model && $model.toUpperCase() == 'vietnam'.toUpperCase()){
            	vm.mailingAddressProvinceList = vm.provinceMasterData;
                vm.mailingAddressProvinceTypeaheadEditable = false;

                vm.mailingAddressCityList = vm.cityMasterData;
                vm.mailingAddressCityTypeaheadEditable = false;
            } else {
            	vm.mailingAddressProvinceList = [];
            	vm.mailingAddressProvinceTypeaheadEditable = true;

            	vm.mailingAddressCityList = [];
            	vm.mailingAddressCityTypeaheadEditable = true;
            	//vm.contact.company.city = 'INTL';
            }
            vm.contact.mailingCity = '';
            vm.contact.mailingProvince = '';
            vm.contact.mailingDistrict = '';

            vm.mailingAddressDistrictList = [];
    		vm.mailingAddressDistrictTypeaheadEditable = true;
        };

        function onKeyUpMailingAddressCountry(){
        	if(vm.contact == null || vm.contact.mailingCountry == null || vm.contact.mailingCountry == ''){
        		vm.mailingAddressProvinceList = vm.provinceMasterData;
                vm.mailingAddressProvinceTypeaheadEditable = true;

        		vm.mailingAddressCityList = vm.cityMasterData;
                vm.mailingAddressCityTypeaheadEditable = true;
        	}
        }

        function onSelectMailingAddressCity($item, $model, $label) {
        	vm.contact.mailingDistrict = '';
        	if($item.code == 'HCM'){
        		vm.mailingAddressDistrictList = vm.districtHCMMasterData;
        		vm.mailingAddressDistrictTypeaheadEditable = false;
        	}
        	else if($item.code == 'HAN'){
        		vm.mailingAddressDistrictList = vm.districtHANMasterData;
        		vm.mailingAddressDistrictTypeaheadEditable = false;
        	}
        	else if($item.code == 'DNG'){
        		vm.mailingAddressDistrictList = vm.districtDNGMasterData;
        		vm.mailingAddressDistrictTypeaheadEditable = false;
        	}
        	else{
        		vm.mailingAddressDistrictList = [];
        		vm.mailingAddressDistrictTypeaheadEditable = true;
        	}
        };

        function onKeyupMailingAddressCity(){
        	if(vm.contact.mailingCity == null || vm.contact.mailingCity == ''){
        		vm.mailingAddressDistrictList = [];
        		vm.mailingAddressDistrictTypeaheadEditable = true;
        	}
        }

        function isContactTypeRequire () {
        	var result = true;
        	angular.forEach(vm.onContactTypesSelect, function(value, key) {
                if(result === true && value === true && key !== 0) {
                	result = false;
                }
            });
        	return result;
        }

        function onStateChangeStart(event, toState, toParams, fromState, fromParams) {


            // this code help detect detail tab or contact preference tab edit or not
            if($scope.editForm.$dirty){
                $state.isDirty = true;
            }

            if(($state.isDirty || $scope.editForm.$dirty) && toState.name !== 'contact-detail.contact-prefer-tab' && toState.name !== 'contact.new.contact-prefer-tab' && !$rootScope.isSessionTimeOut){
            	event.preventDefault();
                $uibModal.open({
                    templateUrl: 'app/components/confirmation-dialog/leave/confirm-dialog.html',
                    controller: 'LeaveConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                        entity: function(){
                        	return null;
                        },
                        translateKeys: function(){
                        	return {
                        		keys: 'indochinaApp.property.confirmLeavePage'
                        	};
                        }
                    }
                }).result.then(function() {

                    // change mode edit in state
                    $state.isEdit = false;

                    // update isDirty variable state
                    $state.isDirty = false;

                    // avoid loop onStateChange function
                    if($scope.editForm) {
                        $scope.editForm.$dirty = false;
                    }

                	if(toState.name == 'contact-detail.detail-tab'){

                        // reload parent to reset entity
                		$state.reload('contact-detail');

                        // broadcast event to appear button edit
                        $rootScope.$broadcast('contact:changeMode', false);
                	}
                	else{
                        //$state.isEdit = true;
                		$state.go(toState, toParams, { reload: true });
                	}
                }, function() {
//                    $state.go('^');
                });
            }
        }

        function resetInputWith(className, minInputWith){
        	var thisDiv = $('.' + className).children('div:first');
    		var parentWidth = thisDiv.width();
    		var spanWidth = thisDiv.children('span').width();
    		var inputWidth = parentWidth - spanWidth;

//    		console.log(className + ':' + parentWidth + "-" + spanWidth);
    		if(inputWidth <= minInputWith){
    			inputWidth = minInputWith;
    		}

    		$('.' + className).find('input').attr('style','width:'+inputWidth+'px;');
        }

        /**
         * Support process disable / enable Lead/Children Source field in case the Contact reference.
         */
        function processDisableLeadOrChildrenSource() {
            if(vm.contact.parentSource !== null) {
            	vm.isLeadSourceInActivated = !vm.contact.parentSource.activated;
            	if(vm.contact.childSource) {
            		vm.isLeadSourceInActivated = !vm.contact.childSource.activated;
            	}
            	if(!vm.isLeadSourceInActivated) {
    	        	if(!vm.isBuyer) {
    	            	if(vm.contact.parentSource.name === LeadSourceConstant.types.PORTAL && vm.contact.portalSite !== null) {
    	            		vm.isLeadSourceInActivated = !vm.contact.portalSite.activated;
    	            	} else if(vm.contact.parentSource.name === LeadSourceConstant.types.INTRODUCTION && vm.contact.contact !== null) {
    	            		vm.isLeadSourceInActivated = !vm.contact.contact.activated;
    	            	} else if(vm.contact.parentSource.name === LeadSourceConstant.types.CoBROKER && vm.contact.coAgent !== null) {
    	            		vm.isLeadSourceInActivated = !vm.contact.coAgent.activated;
    	            	}// else if(vm.contact.parentSource.name == 'Indochina Website') {} this case with no Children Source support
    	        	} else if(vm.contact.childSource !== null && !vm.contact.childSource.activated) {
    	        		vm.isLeadSourceInActivated = !vm.contact.childSource.activated;
    	        	}
            	}
            }
            if(vm.isLeadSourceInActivated) {
            	LeadSourceManagementService.getAllDelete(function(result) {
                	vm.parentsSourceDelete = result;

                });
            	if(vm.contact.parentSource.name == LeadSourceConstant.types.INTRODUCTION) {
            		Contact.getAllDeleteByPermisson(function (result){
            			vm.contactsDetete = result;
            		});
            	} else if (vm.contact.parentSource.name == LeadSourceConstant.types.CoBROKER) {
                	User.getAllCoAgentsDelete(function(data){
                		vm.listCoAgentsDelete = data;
                	});
            	}
            }
        }

        function listForAgentField(){

        	var result = [];

        	if(vm.contact.transferAgent == null || vm.contact.transferAgent.fullName == null){
        		result = vm.listAgents;
        	}
        	else{
        		vm.listAgents.forEach(function(agent){
        			if(agent.id != vm.contact.transferAgent.id){
        				result.push(agent);
        			}
        		});
        	}

        	return result;
        }

        function listForTransferAgentField(){

        	var result = [];

        	if(vm.contact.agent == null || vm.contact.agent.fullName == null){
        		result = vm.listAgents;
        	}
        	else{
        		vm.listAgents.forEach(function(agent){
        			if(agent.id != vm.contact.agent.id){
        				result.push(agent);
        			}
        		});
        	}

        	return result;
        }

        function changeParentsSource(item){
        	// clear child source
        	clearChildSource();
        }

        function changeContact (contact) {
            if(vm.contact.contact) {
                Contact.get({id : contact.id}, function (data){
                    vm.contact.contact = data;
                });
            }
        }

        function clearChildSource () {
        	vm.contact.childSource = null;
        	vm.contact.portalSite = null;
        	vm.contact.contact = null;
        	vm.contact.coAgent = null;
        	vm.contact.personContact = null;
        }

        function checkingLeadSource () {
        	if(vm.isBuyer) {
        		if(!vm.contact.parentSource) {
        			return true;
        		} else {
        			if(vm.contact.parentSource.name === LeadSourceConstant.types.INDOCHINA_WEBSITE) {
        				return false;
        			}
    				if(vm.contact.parentSource.name === LeadSourceConstant.types.PORTAL) {
    					if(vm.contact.portalSite) {
    						return false;
    					} else {
    						return true;
    					}
    				}
    				if(vm.contact.parentSource.name === LeadSourceConstant.types.CoBROKER) {
    					if(vm.contact.contact) {
    						return false;
    					} else {
    						return true;
    					}
    				}
    				/*
    				* if lead source is introduction, secondary lead source is require
    				*   if secondary lead source is 'Database' or 'Personal Network' contact is not require
    				*   else contact is not require
    				* */
    				if(vm.contact.parentSource.name === LeadSourceConstant.types.INTRODUCTION) {
        			    if(vm.contact.childSource) {
                            if(vm.contact.childSource.name === LeadSourceConstant.types.DATABASE || vm.contact.childSource.name === LeadSourceConstant.types.PERSONAL_NETWORK) {
                                return false;
                            } else {
                                if(vm.contact.contact) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        } else {
        			        return true;
                        }
    				}
        			if(vm.contact.childSource) {
        				return false;
        			} else {
        				return true;
        			}
        		}

        	}

        	return false;
        }

        function mobilePersonBlur () {
            if(vm.contact.chatUsernameLifechat == null || vm.contact.chatUsernameLifechat == "") {
                vm.contact.chatUsernameLifechat = angular.copy(vm.contact.person.mobile);
            }
            if(vm.contact.chatUsernameWechat == null || vm.contact.chatUsernameWechat == "") {
                vm.contact.chatUsernameWechat = angular.copy(vm.contact.person.mobile);
            }
            if(vm.contact.chatUsernameWhatapp == null || vm.contact.chatUsernameWhatapp == "") {
                vm.contact.chatUsernameWhatapp = angular.copy(vm.contact.person.mobile);
            }
            if(vm.contact.chatUsernameViber == null || vm.contact.chatUsernameViber == "") {
                vm.contact.chatUsernameViber = angular.copy(vm.contact.person.mobile);
            }
        }

        function mobileCompanyPersonBlur (index, indexMobile) {
            if(index === 0) {
            	var mbPhone = angular.copy(vm.contact.company.persons[index].mobiles[indexMobile].phone);
                if(vm.contact.chatUsernameLifechat == null || vm.contact.chatUsernameLifechat == "") {
                    vm.contact.chatUsernameLifechat = mbPhone;
                }
                if(vm.contact.chatUsernameWechat == null || vm.contact.chatUsernameWechat == "") {
                    vm.contact.chatUsernameWechat = mbPhone;
                }
                if(vm.contact.chatUsernameWhatapp == null || vm.contact.chatUsernameWhatapp == "") {
                    vm.contact.chatUsernameWhatapp = mbPhone;
                }
                if(vm.contact.chatUsernameViber == null || vm.contact.chatUsernameViber == "") {
                    vm.contact.chatUsernameViber = mbPhone;
                }
            }
        }

        function isOneBank(){
            if(vm.contact.bankAccounts.length === 1) {
                return true;
            }
            return false;
        }

        vm.addBankAccount = addBankAccount;
        vm.confirmDeleteBankAccount = confirmDeleteBankAccount;
        vm.isBankAccountDuplicate = isBankAccountDuplicate;
        vm.isOneBank = isOneBank;

        function isBankAccountDuplicate (bankAccount, index) {

            var result = false;
            var bankInfoStr = getStringBankAccountInfo(bankAccount);
            if( bankInfoStr === '' || vm.contact.bankAccounts.length === 1) return false;

            // index never equal 0
            angular.forEach(vm.contact.bankAccounts, function(value, key) {
                if(key !== index) {
                    var bankStringCompare = getStringBankAccountInfo(value);
                    if(bankInfoStr === bankStringCompare) {
                        result =  true;
                    }
                }
            });
            vm.isBankDuplicate = result;
            return result;
        }

        function getStringBankAccountInfo( bankAccount ) {
             var str = bankAccount.accountNumber.replace(/ /g, '').toLowerCase() + bankAccount.bankName.replace(/ /g, '').toLowerCase() + bankAccount.branchName.replace(/ /g, '').toLowerCase();
            return str;
        }

        function addBankAccount() {
            var bankAccountInfo = createBankAccount();
            vm.contact.bankAccounts.push(bankAccountInfo);
        }
        
        function confirmDeleteBankAccount(index){
        	if(vm.contact.bankAccounts[index].id){
        		$uibModal.open({
        			templateUrl: 'app/components/confirmation-dialog/delete/confirm-dialog.html',
        			controller: 'DeleteConfirmDialogController',
        			controllerAs: 'vm',
        			size: 'sm',
        			windowClass : 'absolute-center',
        			resolve: {
        				entity: function(){
        					return null;
        				},
        				translateKeys: function(){
        					return {
        						keys: 'indochinaApp.property.confirmDeleteSales'
        					};
        				}
        			}
        		}).result.then(function() {
        			removeBankAccount(index);
        		}, function() {
        		});
        	}
        	else{
        		removeBankAccount(index);
        	}
        	
        	function removeBankAccount(index){
        		$scope.editForm.$dirty = true;
    			vm.contact.bankAccounts.splice(index, 1);
    			if(vm.contact.bankAccounts.length === 1) {
    				vm.isBankDuplicate = false;
    			}
        	}
        }


        function createBankAccount() {
            return {
            	districtList: [],
                cityList: vm.cityMasterData,
                provinceList: vm.provinceMasterData,
                bankName: '',
                branchName: '',
                beneficiaryName: '',
                accountNumber: '',
                swift: '',
                streetNumber: '',
                streetName: '',
                district: '',
                city: '',
                province: '',
                country: '',
                postalCode: '',
                id: null
            };
        }

        function onSelectBankCountry(item, model, label, index) {

            if(model.toUpperCase() == 'vietnam'.toUpperCase()){
                vm.contact.bankAccounts[index].provinceList = vm.provinceMasterData;
                vm.contact.bankAccounts[index].cityList = vm.cityMasterData;
            } else {
                vm.contact.bankAccounts[index].provinceList = [];

                vm.contact.bankAccounts[index].cityList = [];
            }
            vm.contact.bankAccounts[index].city = '';
            vm.contact.bankAccounts[index].province = '';
            vm.contact.bankAccounts[index].district = '';

            vm.contact.bankAccounts[index].districtList = [];
    		vm.contact.bankAccounts[index].districtTypeaheadEditable = true;
        };

        function onKeyUpBankCountry(index){
        	if(vm.contact == null || vm.contact.bankAccounts[index] == null || vm.contact.bankAccounts[index].country == null || vm.contact.bankAccounts[index].country == ''){
        		vm.contact.bankAccounts[index].provinceList = vm.provinceMasterData;
        		vm.contact.bankAccounts[index].cityList = vm.cityMasterData;
        	}

        }

        function onSelectBankCity($item, $model, $label, index) {
        	vm.contact.bankAccounts[index].district = '';

        	if($item.code == 'HCM'){
        		vm.contact.bankAccounts[index].districtList = vm.districtHCMMasterData;
        		vm.contact.bankAccounts[index].districtTypeaheadEditable = false;
        	}
        	else if($item.code == 'HAN'){
        		vm.contact.bankAccounts[index].districtList = vm.districtHANMasterData;
        		vm.contact.bankAccounts[index].districtTypeaheadEditable = false;
        	}
        	else if($item.code == 'DNG'){
        		vm.contact.bankAccounts[index].districtList = vm.districtDNGMasterData;
        		vm.contact.bankAccounts[index].districtTypeaheadEditable = false;
        	}
        	else{
        		vm.contact.bankAccounts[index].districtList = [];
        		vm.contact.bankAccounts[index].districtTypeaheadEditable = true;
        	}
        };

        function onKeyupBankCity(index){
        	if(vm.contact.bankAccounts[index].city == null || vm.contact.bankAccounts[index].city == ''){
        		vm.contact.bankAccounts[index].districtList = [];
        		vm.contact.bankAccounts[index].districtTypeaheadEditable = true;
        	}
        }

        function checkIfMailing() {

            var country = null;
            var streetNumber = null;
            var streetName = null;
            var city = null;
            var district = null;
            var province = null;
            var postalCode = null;

            if(vm.contact.checkIfMailing) {
                country = vm.contact.company.country;
                streetNumber = vm.contact.company.streetNumber;
                streetName = vm.contact.company.streetName;
                city = vm.contact.company.city;
                district = vm.contact.company.district;
                province = vm.contact.company.province;
                postalCode = vm.contact.company.postalCode;
            }

            copyCompanyAddressToMailingAddress(country, streetNumber, streetName, city, district, province, postalCode);

            // checking City to get District list
            if(city === 'Ho Chi Minh City'){
                vm.mailingAddressDistrictList = vm.districtHCMMasterData;
                vm.mailingAddressDistrictTypeaheadEditable = false;
            }
            else if(city === 'Ha Noi'){
                vm.mailingAddressDistrictList = vm.districtHANMasterData;
                vm.mailingAddressDistrictTypeaheadEditable = false;
            }
            else if(city === 'Da Nang'){
                vm.mailingAddressDistrictList = vm.districtDNGMasterData;
                vm.mailingAddressDistrictTypeaheadEditable = false;
            }
            else{
                vm.mailingAddressDistrictList = [];
                vm.mailingAddressDistrictTypeaheadEditable = true;
            }
        }

        function copyCompanyAddressToMailingAddress(country, streetNumber, streetName, city, district, province, postalCode) {
            vm.contact.mailingCountry = country;

            // init data master for city, province if country is Vietnam
            onSelectMailingAddressCountry(null, vm.contact.mailingCountry, null);

            vm.contact.mailingStreetNumber = streetNumber;
            vm.contact.mailingStreetName = streetName;
            vm.contact.mailingCity = city;
            vm.contact.mailingDistrict = district;
            vm.contact.mailingProvince = province;
            vm.contact.mailingPostalCode = postalCode;
        }



        function addressChange() {
            vm.contact.checkIfMailing = false;
        }

        function tradeNameChange (){
            $scope.editForm.$dirty = true;
        }

        function loadMasterDataForCompanyName(name){
        	if(vm.contact.company.tradeName == null || vm.contact.company.tradeName == ''){
        		vm.contact.company.tradeName = {};
        	}

        	vm.contact.company.tradeName.id = null;

        	return CompanyNameMasterData.searchByNameForAutoComplete({name:name}).$promise;
        }

        function onSelectCompanyName(item, model, label){
        	vm.contact.company.tradeName = item;
        }

        function loadAll (companyId) {
            var id;
            if(companyId) {
                id = companyId;
            } else {
                id = vm.contact.company.id;
            }
            Person.getByCompanyId({
                companyId: id,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if(vm.predicate === 'fullName') {
                    result = [];
                    result.push('firstName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                    result.push('lastName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                }
                if (vm.predicate !== 'id') {
                    result.push('id' + ',' + (vm.reverse ? 'asc' : 'desc'));
                }
                /*if (vm.predicate !== 'id') {
                    result.push('id');
                }*/
                return result;
            }
            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.people = data;
                vm.page = pagingParams.page;

                // set null position if choice default vaule
                angular.forEach(vm.people, function(person, key) {
                    if(person.position === 'none-select') {
                        person.position =  null;
                    }
                });
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transition() {
            pagingParams.page = vm.page;
            pagingParams.predicate = vm.predicate;
            pagingParams.ascending = vm.reverse;
            pagingParams.sort = vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc');
            loadAll();
        }

        function removePersonEmail(index, indexPerson) {
        	$scope.editForm.$dirty = true;
        	var isChangeEmailPrimary = false;
        	if(!angular.isUndefined(indexPerson)){
        		if(vm.contact.company.persons[indexPerson].indexDefault == vm.contact.company.persons[indexPerson].emails[index].indexEmail){
        			isChangeEmailPrimary = true
        		}
        		vm.contact.company.persons[indexPerson].emails.splice(index, 1);
        		if(isChangeEmailPrimary){
        			vm.contact.company.persons[indexPerson].indexDefault = vm.contact.company.persons[indexPerson].emails[0].indexEmail;
        		}
        	}else{
        		if(vm.contact.person.indexDefault == vm.contact.person.emails[index].indexEmail){
        			isChangeEmailPrimary = true
        		}
        		vm.contact.person.emails.splice(index, 1);
        		if(isChangeEmailPrimary){
        			vm.contact.person.indexDefault = vm.contact.person.emails[0].indexEmail;
        		}
        	}
        }

        function confirmDeletePersonEmail(index, indexPerson){
        	var personEmailDelete = null;
        	if(!angular.isUndefined(indexPerson)){
        		personEmailDelete = vm.contact.company.persons[indexPerson].emails[index];
        	}else{
        		personEmailDelete = vm.contact.person.emails[index];
        	}
        	if(personEmailDelete.id){
        		$uibModal.open({
                    templateUrl: 'app/components/confirmation-dialog/delete/confirm-dialog.html',
                    controller: 'DeleteConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                        entity: function(){
                        	return null;
                        },
                        translateKeys: function(){
                        	return {
                        		keys: 'indochinaApp.contact.confirmDeletePersonEmail'
                        	};
                        }
                    }
                }).result.then(function() {
                	removePersonEmail(index, indexPerson);
                }, function() {
                });
        	}else{
        		removePersonEmail(index, indexPerson);
        	}
        }

        function isPersonEmailOneRow(indexPerson){
        	if(angular.isUndefined(indexPerson) && vm.contact.person.emails.length === 1){
        		return true;
        	}else if(!angular.isUndefined(indexPerson) && vm.contact.company.persons[indexPerson].emails.length === 1){
        		return true;
        	}
        	return false;
        }

        function addPersonEmail(indexPerson) {
        	var personEmail = PersonEmail();
        	if(!angular.isUndefined(indexPerson)){
        		personEmail.indexEmail = vm.contact.company.persons[indexPerson].indexEmail++;
        		vm.contact.company.persons[indexPerson].emails.push(personEmail);
        	}else{
        		personEmail.indexEmail = vm.contact.person.indexEmail++;
        		vm.contact.person.emails.push(personEmail);
        	}
        }

        function confirmDeletePersonMobile(index, indexPerson){
        	var personMobileDelete = null;
        	if(!angular.isUndefined(indexPerson)){
        		personMobileDelete = vm.contact.company.persons[indexPerson].mobiles[index];
        	}else{
        		personMobileDelete = vm.contact.person.mobiles[index];
        	}
        	if(personMobileDelete.id){
        		$uibModal.open({
                    templateUrl: 'app/components/confirmation-dialog/delete/confirm-dialog.html',
                    controller: 'DeleteConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                        entity: function(){
                        	return null;
                        },
                        translateKeys: function(){
                        	return {
                        		keys: 'indochinaApp.contact.confirmDeletePersonMobile'
                        	};
                        }
                    }
                }).result.then(function() {
                	removePersonMobile(index, indexPerson);
                }, function() {
                });
        	}else{
        		removePersonMobile(index, indexPerson);
        	}
        }

        function removePersonMobile(index, indexPerson) {
        	$scope.editForm.$dirty = true;
        	if(!angular.isUndefined(indexPerson)){
        		vm.contact.company.persons[indexPerson].mobiles.splice(index, 1);
        	}else{
        		vm.contact.person.mobiles.splice(index, 1);
        	}
        }

        function isPersonMobileOneRow(indexPerson){
        	if(angular.isUndefined(indexPerson) && vm.contact.person.mobiles.length === 1){
        		return true;
        	}else if(!angular.isUndefined(indexPerson) && vm.contact.company.persons[indexPerson].mobiles.length === 1){
        		return true;
        	}
        	return false;
        }

        function addPersonMobile(indexPerson) {
        	var personMobile = PersonPhone(true);
        	if(!angular.isUndefined(indexPerson)){
        		vm.contact.company.persons[indexPerson].mobiles.push(personMobile);
        	}else{
        		vm.contact.person.mobiles.push(personMobile);
        	}
        }

        function PersonEmail() {
        	return {
        			id: null,
        			email: null,
        			isDefault: null,
        			active: true,
    		};
        }

        function PersonEmailInit() {
        	var person = PersonEmail();
        	person.indexEmail = 0;
        	return person;
        }

        function PersonPhone(isMobile) {
        	return {
        			id: null,
        			phone: null,
        			isMobile: isMobile,
        			active: true,
    		};
        }
    }
})();
