(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('contact-type', {
            parent: 'entity',
            url: '/contact-type?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'indochinaApp.contactType.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contact-type/contact-types.html',
                    controller: 'ContactTypeController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('contactType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('contact-type-detail', {
            parent: 'contact-type',
            url: '/contact-type/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'indochinaApp.contactType.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contact-type/contact-type-detail.html',
                    controller: 'ContactTypeDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('contactType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ContactType', function($stateParams, ContactType) {
                    return ContactType.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'contact-type',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('contact-type-detail.edit', {
            parent: 'contact-type-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contact-type/contact-type-dialog.html',
                    controller: 'ContactTypeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ContactType', function(ContactType) {
                            return ContactType.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('contact-type.new', {
            parent: 'contact-type',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contact-type/contact-type-dialog.html',
                    controller: 'ContactTypeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('contact-type', null, { reload: 'contact-type' });
                }, function() {
                    $state.go('contact-type');
                });
            }]
        })
        .state('contact-type.edit', {
            parent: 'contact-type',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contact-type/contact-type-dialog.html',
                    controller: 'ContactTypeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ContactType', function(ContactType) {
                            return ContactType.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('contact-type', null, { reload: 'contact-type' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('contact-type.delete', {
            parent: 'contact-type',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contact-type/contact-type-delete-dialog.html',
                    controller: 'ContactTypeDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ContactType', function(ContactType) {
                            return ContactType.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('contact-type', null, { reload: 'contact-type' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
