(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('DeleteConfirmDialogController',DeleteConfirmDialogController);

    DeleteConfirmDialogController.$inject = ['$uibModalInstance', 'entity', 'translateKeys', '$rootScope'];

    function DeleteConfirmDialogController($uibModalInstance, entity, translateKeys, $rootScope) {
        var vm = this;

        vm.entity = entity;
        vm.translateKeys = translateKeys.keys;

        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        $rootScope.$on('modal:close', function(event, result) {
            clear();
        });

        function clear () {
            $rootScope.$broadcast('app:resetTimeSession', true);
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
            $rootScope.$broadcast('app:resetTimeSession', true);
        	$uibModalInstance.close(true);
        }
    }
})();
