(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('PhotoVideoTabController', PhotoVideoTabController);

    PhotoVideoTabController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', '$timeout', 'entity', 'PhotoService', 'Upload', 'AlertService',
                                       '$uibModal', '$translate', 'PropertyService'];

    function PhotoVideoTabController($scope, $state, $rootScope, $stateParams, $timeout, entity, PhotoService, Upload, AlertService,
    								$uibModal, $translate, PropertyService) {
        var vm = this;
        vm.property = entity

        if($state.isEdit) {
        	vm.isEdit = $state.isEdit;
        }

        $rootScope.scrollTop();
        $rootScope.$broadcast('property:activeTab', 'PHOTO');
//        $rootScope.$broadcast('property:isActivityOrOpportunity', false);

        $rootScope.$on('property:changeMode', function(event, result) {
        	vm.isEdit = result;
        });

        $rootScope.$on('property:editImage', function(event, result) {
            vm.photos = null; // this line force data clear cache
            loadAll();
        });

        vm.cancel = cancel;
        vm.save = save;
        vm.viewPhoto = viewPhoto;
        vm.editPhoto = editPhoto;
        vm.deletePhoto = deletePhoto;

        loadAll();

        function loadAll() {
            PhotoService.query({propertyId: vm.property.id}, function(result) {
                vm.photos = result;
                //vm.searchQuery = null;
            });
        }

        function cancel () {
        	$state.isEdit = false;
    		$rootScope.$broadcast('property:changeMode', false);
    		$state.reload($state.current.name);
        }

        function save() {
            PropertyService.update(vm.property, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            //$scope.editForm.$dirty = false;
            cancel();
        }

        function onSaveError (response) {
            console.log(response);
        }

        function viewPhoto(photo) {
        	if (photo) {
        		$uibModal.open({
                    templateUrl: 'app/entities/photo/partial/photo-view-dialog.html',
                    controller: 'PhotoViewControllerRe',
                    controllerAs: 'vm',
                    windowClass : 'absolute-center',
                    resolve: {
                    	photoView: function() {
                            return photo;
                        }
                    }
                }).result.then(function() {
                	// to do something
                }, function() {
                	// to do something
                });
        	}
        }

        function editPhoto (photo) {
        	$uibModal.open({
                templateUrl: 'app/entities/photo/photo-dialog.html',
                controller: 'PhotoDialogControllerRe',
                controllerAs: 'vm',
                backdrop: 'static',
                size: '',
                resolve: {
                    photoEntity: function() {
                        if(photo) {
                            return PhotoService.get({id : photo.id}).$promise;
                        }
                    },
                    photoDescriptionLanguages: function() {
                        if(photo) {
                            return PhotoService.getPhotoDescriptionLanguages({id :photo.id}).$promise;
                        }
                    },
                    photoProperties: ['ApplicationPropertiesService', function(ApplicationPropertiesService) {
                        return ApplicationPropertiesService.getPhoto().$promise;
                    }]
                }
            }).result.then(function() {
                //$state.go('property-detail.edit.photos', null, { reload: 'property-detail.edit.photos' });
            }, function() {
                //$state.go('^');
            });
        }

        function deletePhoto(photo) {
        	$uibModal.open({
                templateUrl: 'app/entities/photo/photo-delete-dialog.html',
                controller: 'PhotoDeleteControllerRe',
                controllerAs: 'vm',
                size: 'sm',
                windowClass : 'absolute-center',
                resolve: {
                    entity: function() {
                        return PhotoService.get({id: photo.id}).$promise;
                    }
                }
            }).result.then(function() {
//            	AlertService.success($translate.instant('indochinaApp.property.deletedDocumentSuccessfullyMsg'));
            	loadAll();
            }, function() {
            	// to do something
            });
        }

        vm.uploadFiles = function(files, errFiles) {
            vm.files = files;
            vm.errFiles = errFiles;

            var isShowedErrorMsg = false;
            //set priority for photo
            var priority = vm.photos.length ;
            angular.forEach(vm.photos, function(item) {  
                if (item.priority > priority) priority = item.priority;    
            });
            priority++;
            
            angular.forEach(files, function(file) {

            	if(!(/^image\/.*$/.test(file.type))){
            		return;
            	}

            	// 10485760bytes = 1024*1024*10 = 10MB
            	if(file.size > 10485760){

            		// we will show when the first image have size larger 10MB.
            		// if we wait system uploaded all images, It is very slow.
            		if(!isShowedErrorMsg){
            			isShowedErrorMsg = true;
            			AlertService.error("File must be less than 10MB");
            		}
            	}
            	else{
            		file.upload = Upload.upload({
            			url: 'api/photos/upload',
            			data: {propertyId:$stateParams.id, file: file, priority: priority++}
            		});

            		file.upload.then(function (response) {
            			$timeout(function () {
            				file.result = response.data;
            				vm.photos.push(response.data);
            			});
            		}, function (response) {
            			if (response.status > 0)
            				$scope.errorMsg = response.status + ': ' + response.data;
            		}, function (evt) {
            			file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            		});
            	}
            });
        }
        
        /**
         * Sort able for table photo
         */
        $scope.sortableOptions = {
		    update: function(e, ui) {
		    	//update photo order
		    	PhotoService.updateOrder(vm.photos, onOrderSuccess, onOrderError);
		    },
		    stop: function(e, ui) {
		     
		    }
        }
        
        function onOrderSuccess (results) {
            vm.photos = results;
        }

        function onOrderError (response) {
            //console.log(response);
        }
    }
})();
