(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .constant('PropertyConstant', (function() {
            return {
                availability: {
                    LIST: ['AVAILABLE','PENDING','WITHDRAWN','CLOSEDWON','SOLDBYOTHER','LEASED'],
                    AVAILABLE: 'AVAILABLE',
                    PENDING: 'PENDING',
                    WITHDRAWN: 'WITHDRAWN',
                    CLOSED_WON: 'CLOSEDWON',
                    SOLD_BY_OTHER: 'SOLDBYOTHER',
                    LEASED: 'LEASED',
                },
                transactionType: {
                    LIST: ['SELL','RENT'],
                    SELL: 'SELL',
                    RENT: 'RENT'
                },
                propertyType: {
                    LIST: ['APARTMENT','TOWNHOUSE','VILLA','PENTHOUSE','DUPLEX','SHOPHOUSE','OFFICETEL','CONDOTEL','LAND'],
                    APARTMENT: 'APARTMENT',
                    TOWNHOUSE: 'TOWNHOUSE',
                    VILLA: 'VILLA',
                    PENTHOUSE: 'PENTHOUSE',
                    DUPLEX: 'DUPLEX',
                    SHOPHOUSE: 'SHOPHOUSE',
                    OFFICETEL: 'OFFICETEL',
                    CONDOTEL: 'CONDOTEL',
                    LAND: 'LAND'
                },
                paymentCycle: {
                    LIST: ['ANNUALLY', 'MONTHLY', 'WEEKLY', 'DAILY'],
                    ANNUALLY: 'ANNUALLY',
                    MONTHLY: 'MONTHLY',
                    WEEKLY: 'WEEKLY',
                    DAILY: 'DAILY'
                },
                propertyUnitType: {
                	LIST: ['SQ_METER', 'SQ_FEET'],
                	SQ_METER: 'SQ_METER',
                	SQ_FEET: 'SQ_FEET'
                },
                lotUnitType: {
                	LIST: ['SQ_METER', 'SQ_KILOMETER', 'HECTARE', 'SQ_FEET', 'SQ_MILE', 'ACRE'],
                	SQ_METER: 'SQ_METER',
                	SQ_KILOMETER: 'SQ_KILOMETER',
                	HECTARE: 'HECTARE',
                	SQ_FEET: 'SQ_FEET',
                	SQ_MILE: 'SQ_MILE',
                	ACRE: 'ACRE'
                },
                roadUnitType: {
                	LIST: ['METER', 'FEET'],
                	METER: 'METER',
                	FEET: 'FEET'
                },
                constructionStatus: {
                	LIST: ['OFF_PLAN', 'UNDER_CONSTRUCTION', 'COMPLETED', 'RESALE'],
                	OFF_PLAN: 'OFF_PLAN',
                	UNDER_CONSTRUCTION: 'UNDER_CONSTRUCTION',
                	COMPLETED: 'COMPLETED',
                	RESALE: 'RESALE'
                },
                feeTerm: {
                    LIST: ['MONTHLY', 'QUARTERLY', 'BIANNUAL', 'ANNUAL'],
                    MONTHLY: 'MONTHLY',
                    QUARTERLY: 'QUARTERLY',
                    BIANNUAL: 'BIANNUAL',
                    ANNUAL: 'ANNUAL'
                },
                price: {
                	MAX_SEARCH_PRICE : 10000000,
                	MAX_SEARCH_LEASE_PRICE : 15000
                }
            }
        })());
})();
