(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('DetailTabControllerRe', DetailTabControllerRe);

    DetailTabControllerRe.$inject = ['$scope', '$state', '$rootScope', '$timeout', '$stateParams', 'previousState', 'entity', 'PropertyService', 'CurrencyConstant', 'JhiCurrencyService', 'PropertyConstant',
                                     'AlertService', 'District', '$translate','$uibModal', 'User', 'Principal', 'Contact', 'MeasurementConversionService', 'propertySearch',
                                     'LeaseTerm', 'AuthorityConstant', 'developmentResources', 'countryResource', 'provinceResource', 'cityResource', 'districtHCMResource','districtHANResource',
                                    'districtDNGResource', 'propertyLocationsResource', 'internalRoomsResource', 'outsideAreasResource', 'facilitiesOffersResource', 'parkingFacilitiesResource', 'nearbyFacilitiesResource',
                                    'reasonForPurchaseResource', 'accessRoadResource', 'languagesResource', 'agentResource', 'materialsResource', 'indochinaPropertyContact'];

    function DetailTabControllerRe($scope, $state, $rootScope, $timeout, $stateParams, previousState, entity, PropertyService, CurrencyConstant, JhiCurrencyService, PropertyConstant,
            AlertService, District, $translate, $uibModal, User, Principal, Contact, MCService, propertySearch,
            LeaseTerm, AuthorityConstant, developmentResources, countryResource, provinceResource, cityResource, districtHCMResource, districtHANResource,
            districtDNGResource,  propertyLocationsResource, internalRoomsResource, outsideAreasResource, facilitiesOffersResource, parkingFacilitiesResource, nearbyFacilitiesResource,
            reasonForPurchaseResource, accessRoadResource, languagesResource, agentResource, materialsResource, indochinaPropertyContact) {
        var vm = this;
        var languages = [];
    	var login = null;
    	vm.property = entity;

        if($state.isEdit) {
        	vm.isEdit = $state.isEdit;
        }
        
        if(!vm.property.id) {
        	vm.isEdit = true;
        }
        
        $rootScope.scrollTop();
        $rootScope.$broadcast('property:activeTab', 'DETAIL');
        if($state.isDuplication) {
        	vm.isDuplication = $state.isDuplication;
        }

        if($state.current.name === 'property.duplicate.detail-tab') {
        	vm.isDuplication =  true;
        	$state.isDuplication = true;
        	$rootScope.$broadcast('property:isDuplication', true);
        }

        if($state.current.name === 'property.new.detail-tab') {
        	vm.isCreateNew = true;
        }

        $rootScope.$on('property:changeMode', function(event, result) {
        	vm.isEdit = result;
        });

    	$scope.$on('$stateChangeStart', onStateChangeStart);
    	
        $timeout(function (){
        	angular.element('#field_developmentCode').focus();
    	});
        
        $scope.$watch('vm.property.availability', function(newValue, oldValue) {
        	if(vm.isEdit && PropertyConstant.availability.CLOSED_WON != oldValue && PropertyConstant.availability.CLOSED_WON == newValue){        		
        		$uibModal.open({
                    templateUrl: 'app/components/confirmation-dialog/inform/confirm-dialog.html',
                    controller: 'InformConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                        entity: null,
                        translateKeys: function(){
                        	return {
                        		keys: 'indochinaApp.property.informWhenUserChooseClosedWon'
                        	};
                        }
                    }
                }).result.then(function() {
                	vm.property.availability = oldValue;
                }, function() {
                	vm.property.availability = oldValue;
                });
        	}
        });

        vm.save = save;
        vm.cancel = cancel;
        vm.editInternalRoomOther = editInternalRoomOther;
        vm.editAccessRoadWidth = editAccessRoadWidth;
        vm.editFeeManagement = editFeeManagement;
        vm.editFeeParking = editFeeParking;
        vm.editFeeGym = editFeeGym;
        vm.getLanguages = getLanguages;
        vm.addPropertyOtherDetailLanguage = addPropertyOtherDetailLanguage;
        vm.removePropertyOtherDetailLanguage = removePropertyOtherDetailLanguage;
        vm.isDefaultRow = isDefaultRow;
        vm.hasRowEmpty = hasRowEmpty;
        vm.onSelectCountry = onSelectCountry;
        vm.onSelectCity = onSelectCity;
        vm.onKeyupCity = onKeyupCity;
        vm.onSelectUserAgent = onSelectUserAgent;
        vm.onPropertyTypeChange = onPropertyTypeChange;
        vm.isDisableLotSize = isDisableLotSize;
        vm.isDisablePOA = isDisablePOA;
        vm.isDisableListPrice = isDisableListPrice;
        vm.loadOwner = loadOwner;
        vm.onSelectOwner = onSelectOwner;
        vm.onSelectDevelopment = onSelectDevelopment;
        vm.onKeyUpDevelopment = onKeyUpDevelopment;
        vm.validateGrossAndNetSize = validateGrossAndNetSize;
        
        vm.developmentResources = developmentResources;
        vm.indochinaPropertyContact = indochinaPropertyContact;
        
        vm.netSizeLessThanGrossSize = true;
        
        vm.currencyRate = JhiCurrencyService.rate;
        vm.currencyCode = JhiCurrencyService.currencyCode;
        vm.currencyUnits = CurrencyConstant.LIST;
        vm.currenciesDes = CurrencyConstant.DES;

        vm.propertyOtherDetailLanguageEmpty = {id:null, languageCode:null, otherDetail:null, propertyId:entity.id, created: null, modified: null};

        vm.propertyLocations = [];
        vm.internalRooms = [];
        vm.outsideAreas = [];
        vm.facilitiesOffereds = [];
        vm.accessRoads = [];
        vm.parkingFacilities = [];
        vm.nearbyFacilities = [];        

        vm.selectedPropertyLocations = [];
        vm.selectedInternalRooms = [];
        vm.selectedOutsideAreas = [];
        vm.selectedFacilitiesOffereds = [];
        vm.selectedAccessRoads = [];
        vm.selectedParkingFacilities = [];
        vm.selectedNearbyFacilities = [];
        vm.selectedReasonForPurchases = [];
        vm.selectedMaterials = [];
        vm.propertyOtherDetailLanguages = vm.property.propertyOtherDetailLanguages;

        vm.transactionTypes = PropertyConstant.transactionType.LIST;
        vm.availabilities = PropertyConstant.availability.LIST;
        vm.constructionStatuses = PropertyConstant.constructionStatus.LIST;
        vm.propertyTypes = PropertyConstant.propertyType.LIST;
        vm.paymentCycles = PropertyConstant.paymentCycle.LIST;
        vm.leaseTerms = [];
        vm.propertyUnitTypes = PropertyConstant.propertyUnitType.LIST;
        vm.lotUnitTypes = PropertyConstant.lotUnitType.LIST;
        vm.roadUnitTypes = PropertyConstant.roadUnitType.LIST;
        vm.feeTerms = PropertyConstant.feeTerm.LIST;

        vm.countryList = [];
        vm.provinceList = [];
        vm.provinceMasterData = [];
        vm.cityList = [];
        vm.cityMasterData = [];

        vm.propertyLocationClick = propertyLocationClick;
        vm.accessRoadClick = accessRoadClick;
        vm.internalRoomClick = internalRoomClick;
        vm.nearbyFacilityClick = nearbyFacilityClick;
        vm.outsideAreaClick = outsideAreaClick;
        vm.parkingFacilityClick = parkingFacilityClick;
        vm.facilitiesOfferedClick = facilitiesOfferedClick;
        vm.reasonForPurchaseClick = reasonForPurchaseClick;
        vm.materialsClick = materialsClick;

        // load user
        Principal.identity().then(function(account) {

        	// for check login is agent property
        	login = account;

        	if(account.fullName === null) {
        		var fullName = account.firstName + ' ' + account.lastName;
        		account.fullName = fullName;
        	}

        	// current state is create property
            if((account.authorities[0] === AuthorityConstant.ROLE_AGENT || account.authorities[0] === AuthorityConstant.ROLE_MANAGER || account.authorities[0] === AuthorityConstant.ROLE_SUPER_USER)
            		&& (vm.isCreateNew || vm.isDuplication)) {
            	vm.property.userDTO = angular.copy(account);
            }

            // if user is co-agent, don't show unit_no and owner/seller
            vm.isCoAgent = false;
            if(account.authorities[0] === AuthorityConstant.ROLE_CO_AGENT){
            	vm.isCoAgent = true;
            }
        });

        loadAll();

        // default language
        vm.defaultLanguage = ['EN','VN'];

        vm.openDatePicker = openDatePicker;
        vm.datePickerTo = {
        		isOpen: false,
        		invalid: false,
        		close: closeDatePickerTo,
        		keyUp: keyUpPickerTo
        }
        vm.datePickerFrom = {
        		isOpen: false,
        		invalid: false,
        		close: closeDatePickerFrom,
        		keyUp: keyUpPickerFrom
        }
        vm.datePickerOption = {
    		showWeeks: false,
            startingDay: 1,
            readonlyInput: true,
            dateformat: 'MM/dd/yyyy'
        }

        if(!vm.propertyOtherDetailLanguages.length){
            addPropertyOtherDetailLanguage();
        }

        if(vm.property.feeManagementCurrency == null){
        	vm.property.feeManagementCurrency = 'USD';
        }
        if(vm.property.feeManagementTerm == null){
        	vm.property.feeManagementTerm = PropertyConstant.feeTerm.MONTHLY;
        }
        if(vm.property.feeParkingCurrency == null){
        	vm.property.feeParkingCurrency = 'USD';
        }
        if(vm.property.feeParkingTerm == null){
        	vm.property.feeParkingTerm = PropertyConstant.feeTerm.MONTHLY;
        }
        if(vm.property.feeGymCurrency == null){
        	vm.property.feeGymCurrency = 'USD';
        }
        if(vm.property.feeGymTerm == null){
        	vm.property.feeGymTerm = PropertyConstant.feeTerm.MONTHLY;
        }

        function loadAll(){
        	if(vm.property.id){
        		User.getUserById(vm.property.modifiedBy).then(function(result){
            		vm.modifiedUser = result.data;
            	});
        	}

            if(vm.property.bedRoomNumber == 0){
            	vm.property.bedRoomNumber = 'STU';
            }

//            if(vm.property != null && ['HCM', 'HAN', 'DNG'].indexOf(vm.property.cityCode) != -1){
//                vm.districtTypeaheadEditable = false;
//                vm.districtList = getDistrictByCityCode(vm.property.cityCode);
//            }
            if(['Ho Chi Minh City', 'Ha Noi', 'Da Nang'].indexOf(vm.property.city) != -1){
                vm.districtTypeaheadEditable = false;
                vm.districtList = getDistrictByCity(vm.property.city);
            }
            else{
                vm.districtTypeaheadEditable = true;
                vm.districtList = [];
            }


            // get country list
            vm.countryList = countryResource;


            vm.cityMasterData = cityResource;
            if(vm.property != null && vm.property.country != null && vm.property.country.toUpperCase() == 'vietnam'.toUpperCase()){
                vm.cityList = vm.cityMasterData;
                vm.cityTypeaheadEditable = false;
            }
            else{
                vm.cityList = [];
                vm.cityTypeaheadEditable = true;
            }


            vm.provinceMasterData = provinceResource;
            if(vm.property != null && vm.property.country != null && vm.property.country.toUpperCase() == 'vietnam'.toUpperCase()){
                vm.provinceList = vm.provinceMasterData;
                vm.provinceTypeaheadEditable = false;
            }
            else{
                vm.provinceList = [];
                vm.provinceTypeaheadEditable = true;
            }

        	LeaseTerm.query(function(result) {
        		vm.leaseTerms = result;
            });

            // Mr Dung refactor
            languages = languagesResource;

            vm.propertyLocations = propertyLocationsResource

            vm.internalRooms = internalRoomsResource;

            vm.outsideAreas = outsideAreasResource;

            vm.facilitiesOffereds = facilitiesOffersResource;

            vm.accessRoads = accessRoadResource;

            vm.parkingFacilities = parkingFacilitiesResource;

            vm.nearbyFacilities = nearbyFacilitiesResource;

            vm.reasonForPurchases = reasonForPurchaseResource;
            
            vm.materials = materialsResource;

            //load user agent list
            vm.listAgents = agentResource;

            if(vm.property.propertyLocations.length > 0) {
        		angular.forEach(vm.property.propertyLocations, function(value, key) {
        			vm.selectedPropertyLocations[value.id] = true;
        		});
        	}

            if(vm.property.accessRoads.length > 0) {
        		angular.forEach(vm.property.accessRoads, function(value, key) {
        			vm.selectedAccessRoads[value.id] = true;
        		});
        	}

            if(vm.property.internalRooms.length > 0) {
        		angular.forEach(vm.property.internalRooms, function(value, key) {
        			vm.selectedInternalRooms[value.id] = true;
        		});
        	}

            if(vm.property.nearbyFacilities.length > 0) {
        		angular.forEach(vm.property.nearbyFacilities, function(value, key) {
        			vm.selectedNearbyFacilities[value.id] = true;
        		});
        	}

            if(vm.property.outsideAreas.length > 0) {
        		angular.forEach(vm.property.outsideAreas, function(value, key) {
        			vm.selectedOutsideAreas[value.id] = true;
        		});
        	}

            if(vm.property.parkingFacilities.length > 0) {
        		angular.forEach(vm.property.parkingFacilities, function(value, key) {
        			vm.selectedParkingFacilities[value.id] = true;
        		});
        	}

            if(vm.property.facilitiesOffereds.length > 0) {
        		angular.forEach(vm.property.facilitiesOffereds, function(value, key) {
        			vm.selectedFacilitiesOffereds[value.id] = true;
        		});
        	}

        	if (!vm.property.reasonForPurchases) vm.property.reasonForPurchases = []; // this row for old data
            if(vm.property.reasonForPurchases.length > 0) {
                angular.forEach(vm.property.reasonForPurchases, function(value, key) {
                    vm.selectedReasonForPurchases[value.id] = true;
                });
            }
            
            if(!vm.property.materials) vm.property.materials = [];
            if(vm.property.materials != null && vm.property.materials.length > 0) {
        		angular.forEach(vm.property.materials, function(value, key) {
        			vm.selectedMaterials[value.id] = true;
        		});
        	}
            // end
            if(!vm.property.icPropertyLocations){            	
            	vm.property.icPropertyLocations = [];
            }
            if(!vm.property.icInternalRooms){            	
            	vm.property.icInternalRooms = [];
            }
            if(!vm.property.icOutsideAreas){            	
            	vm.property.icOutsideAreas = [];
            }
            if(!vm.property.icFacilitiesOffereds){            	
            	vm.property.icFacilitiesOffereds = [];
            }
            if(!vm.property.icNearbyFacilities){            	
            	vm.property.icNearbyFacilities = [];
            }
            if(!vm.property.icParkingFacilities){            	
            	vm.property.icParkingFacilities = [];
            }
            if(!vm.property.icReasonForPurchases){            	
            	vm.property.icReasonForPurchases = [];
            }
            if(!vm.property.icMaterials){            	
            	vm.property.icMaterials = [];
            }
            if(!vm.property.icAccessRoads){            	
            	vm.property.icAccessRoads = [];
            }

            if(vm.property != null){
            	if(vm.property.availabilityPeriodFrom != null){
                	vm.property.availabilityPeriodFrom = new Date(vm.property.availabilityPeriodFrom);
                }

                if(vm.property.availabilityPeriodTo != null){
                	vm.property.availabilityPeriodTo = new Date(vm.property.availabilityPeriodTo);
                }

                if(vm.property.sizeUnit == null || vm.property.sizeUnit == ''){
                	vm.property.sizeUnit = PropertyConstant.propertyUnitType.SQ_METER;
                }

                if(vm.property.netSizeUnit == null || vm.property.netSizeUnit == ''){
                	vm.property.netSizeUnit = PropertyConstant.propertyUnitType.SQ_METER;
                }

                if(vm.property.lotSizeUnit == null || vm.property.lotSizeUnit == ''){
                	vm.property.lotSizeUnit = PropertyConstant.lotUnitType.SQ_METER;
                }

                if(vm.property.accessRoadWidthUnit == null || vm.property.accessRoadWidthUnit == ''){
                	vm.property.accessRoadWidthUnit = PropertyConstant.roadUnitType.METER;
                }

                vm.internalRoomOtherCheckbox = vm.property.internalRoomOther != null;
            }

            if(vm.isDulication){
            	vm.property.title = vm.property.title + ' (Copy)';
            }
        }

        // converter
        if(vm.property.accessRoadWidth != null){
        	vm.roadConverter = MCService.init(vm.property.accessRoadWidthUnit);
        }

        if(vm.property.size != null){
        	vm.sizeConverter = MCService.init(vm.property.sizeUnit);
        }

        if(vm.property.netSize != null){
        	vm.netSizeConverter = MCService.init(vm.property.netSizeUnit);
        }

        if(vm.property.lotSize != null){
        	vm.lotConverter = MCService.init(vm.property.lotSizeUnit);
        }
        
        function editInternalRoomOther(){
        	vm.property.internalRoomOtherIc = true;
        }
        
        function editAccessRoadWidth(){
        	vm.property.accessRoadWidthIc = true;
        }
        
        function editFeeManagement(){
        	vm.property.feeManagementIc = true;
        }
        
        function editFeeParking(){
        	vm.property.feeParkingIc = true;
        }

        function editFeeGym(){
        	vm.property.feeGymIc = true;
        }

        function propertyLocationClick (value, propertyLocation) {
        	if(value === true) {
        		vm.property.propertyLocations.push(propertyLocation);
        		vm.property.icPropertyLocations.push(propertyLocation);
        	} else {
        		var i = 0;
        		angular.forEach(vm.property.propertyLocations, function(value, key) {
        			if(propertyLocation.id === value.id) {
        				vm.property.propertyLocations.splice(i, 1);
        			}
                    i++;
                });
        		
        		i = 0;
        		angular.forEach(vm.property.icPropertyLocations, function(value, key) {
        			if(propertyLocation.id === value.id) {
        				vm.property.icPropertyLocations.splice(i, 1);
        			}
                    i++;
                });
        	}
        }

        function accessRoadClick (value, accessRoad) {
        	if(value === true) {
        		vm.property.accessRoads.push(accessRoad);
        		vm.property.icAccessRoads.push(accessRoad);
        	} else {
        		var i = 0;
        		angular.forEach(vm.property.accessRoads, function(value, key) {
        			if(accessRoad.id === value.id) {
        				vm.property.accessRoads.splice(i, 1);
        			}
                    i++;
                });
        		
        		i = 0;
        		angular.forEach(vm.property.icAccessRoads, function(value, key) {
        			if(accessRoad.id === value.id) {
        				vm.property.icAccessRoads.splice(i, 1);
        			}
                    i++;
                });
        	}
        }

        function internalRoomClick (value, internalRoom) {
        	if(value === true) {
        		vm.property.internalRooms.push(internalRoom);
        		vm.property.icInternalRooms.push(internalRoom);
        	} else {
        		var i = 0;
        		angular.forEach(vm.property.internalRooms, function(value, key) {
        			if(internalRoom.id === value.id) {
        				vm.property.internalRooms.splice(i, 1);
        			}
                    i++;
                });
        		
        		i = 0;
        		angular.forEach(vm.property.icInternalRooms, function(value, key) {
        			if(internalRoom.id === value.id) {
        				vm.property.icInternalRooms.splice(i, 1);
        			}
                    i++;
                });
        	}
        }

        function nearbyFacilityClick (value, nearbyFacility) {
        	if(value === true) {
        		vm.property.nearbyFacilities.push(nearbyFacility);
        		vm.property.icNearbyFacilities.push(nearbyFacility);
        	} else {
        		var i = 0;
        		angular.forEach(vm.property.nearbyFacilities, function(value, key) {
        			if(nearbyFacility.id === value.id) {
        				vm.property.nearbyFacilities.splice(i, 1);
        			}
                    i++;
                });
        		
        		i = 0;
        		angular.forEach(vm.property.icNearbyFacilities, function(value, key) {
        			if(nearbyFacility.id === value.id) {
        				vm.property.icNearbyFacilities.splice(i, 1);
        			}
                    i++;
                });
        	}
        }

        function outsideAreaClick (value, outsideArea) {
        	if(value === true) {
        		vm.property.outsideAreas.push(outsideArea);
        		vm.property.icOutsideAreas.push(outsideArea);
        	} else {
        		var i = 0;
        		angular.forEach(vm.property.outsideAreas, function(value, key) {
        			if(outsideArea.id === value.id) {
        				vm.property.outsideAreas.splice(i, 1);
        			}
                    i++;
                });
        		
        		i = 0;
        		angular.forEach(vm.property.icOutsideAreas, function(value, key) {
        			if(outsideArea.id === value.id) {
        				vm.property.icOutsideAreas.splice(i, 1);
        			}
                    i++;
                });
        	}
        }

        function parkingFacilityClick (value, parkingFacility) {
        	if(value === true) {
        		vm.property.parkingFacilities.push(parkingFacility);
        		vm.property.icParkingFacilities.push(parkingFacility);
        	} else {
        		var i = 0;
        		angular.forEach(vm.property.parkingFacilities, function(value, key) {
        			if(parkingFacility.id === value.id) {
        				vm.property.parkingFacilities.splice(i, 1);
        			}
                    i++;
                });
        		
        		i = 0;
        		angular.forEach(vm.property.icParkingFacilities, function(value, key) {
        			if(parkingFacility.id === value.id) {
        				vm.property.icParkingFacilities.splice(i, 1);
        			}
                    i++;
                });
        	}
        }

        function facilitiesOfferedClick (value, facilitiesOffered) {
        	if(value === true) {
        		vm.property.facilitiesOffereds.push(facilitiesOffered);
        		vm.property.icFacilitiesOffereds.push(facilitiesOffered);
        	} else {
        		var i = 0;
        		angular.forEach(vm.property.facilitiesOffereds, function(value, key) {
        			if(facilitiesOffered.id === value.id) {
        				vm.property.facilitiesOffereds.splice(i, 1);
        			}
                    i++;
                });
        		
        		i = 0;
        		angular.forEach(vm.property.icFacilitiesOffereds, function(value, key) {
        			if(facilitiesOffered.id === value.id) {
        				vm.property.icFacilitiesOffereds.splice(i, 1);
        			}
                    i++;
                });
        	}
        }

        function reasonForPurchaseClick (value, reasonForPurchase) {
            if(value === true) {
                vm.property.reasonForPurchases.push(reasonForPurchase);
                vm.property.icReasonForPurchases.push(reasonForPurchase);
            } else {
                var i = 0;
                angular.forEach(vm.property.reasonForPurchases, function(value, key) {
                    if(reasonForPurchase.id === value.id) {
                        vm.property.reasonForPurchases.splice(i, 1);
                    }
                    i++;
                });
                
                i = 0;
                angular.forEach(vm.property.icReasonForPurchases, function(value, key) {
                    if(reasonForPurchase.id === value.id) {
                        vm.property.icReasonForPurchases.splice(i, 1);
                    }
                    i++;
                });
            }
        }
        
        function materialsClick (value, material) {
            if(value === true) {
                vm.property.materials.push(material);
                vm.property.icMaterials.push(material);
            } else {
                var i = 0;
                angular.forEach(vm.property.materials, function(value, key) {
                    if(material.id === value.id) {
                        vm.property.materials.splice(i, 1);
                    }
                    i++;
                });
                
                i = 0;
                angular.forEach(vm.property.icMaterials, function(value, key) {
                    if(material.id === value.id) {
                        vm.property.icMaterials.splice(i, 1);
                    }
                    i++;
                });
            }
        }

        function save () {
            vm.isSaving = true;

            if(vm.property.internalRoomOther == '' || !vm.internalRoomOtherCheckbox) vm.property.internalRoomOther = null;

            if(vm.property.bedRoomNumber == 'STU'){
            	vm.property.bedRoomNumber = 0;
            }

            if (vm.property.id !== null) {
                PropertyService.update(vm.property, onSaveSuccess, onSaveError);
            } else {
                PropertyService.save(vm.property, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
        	vm.isSaving = false;
        	$scope.editForm.$dirty = false;

        	if(vm.isCreateNew || vm.isDulication) {
				AlertService.success($translate.instant('indochinaApp.property.created', { param: result.title }));
            }
        	else {
        		AlertService.success($translate.instant('indochinaApp.property.updated', { param: result.title }));
        	}

        	// refresh propertyId
        	vm.property.propertyId = result.propertyId;

        	$state.isEdit = false;
        	$scope.$emit('property:propertyDetailUpdated', result);
        	$rootScope.$broadcast('property:changeMode', false);
        	$state.go('property-detail.photo-video-tab', {id: result.id});
        }

        function onSaveError (response) {
        	if(response.data != null){
        		var errorKey = response.data.key;
        		if(errorKey === 'idexists'){
        			AlertService.error("A new property cannot already have an ID");
        		}
        		else if(errorKey === 'duplicateId'){
        			$("#property-container").scrollTop();
        			AlertService.error("Duplicated propertyId");
        		}
        		else{
        			AlertService.error("Has an error form server");
        		}
        	}
        	else{
        		AlertService.error("Has an error form server");
    		}

            vm.isSaving = false;
        }

        function cancel(){
        	if (vm.isDuplication) {
        		$state.go('property-detail.detail-tab', {id:vm.property.duplicatedId})
        	} else {
        		if(vm.property.id === null) {
            		$state.isEdit = true;
            		$state.go('property');
            	}else {
            		$state.isEdit = false;
            		if($scope.editForm.$dirty && !$rootScope.isSessionTimeOut){
                        $uibModal.open({
                            templateUrl: 'app/components/confirmation-dialog/leave/confirm-dialog.html',
                            controller: 'LeaveConfirmDialogController',
                            controllerAs: 'vm',
                            size: 'sm',
                            windowClass : 'absolute-center',
                            resolve: {
                                entity: function(){
                                	return null;
                                },
                                translateKeys: function(){
                                	return {
                                		keys: 'indochinaApp.property.confirmLeavePage'
                                	};
                                }
                            }
                        }).result.then(function() {
                        	$scope.editForm.$dirty = false;
                        	$rootScope.scrollTop();
                        	$rootScope.$broadcast('property:changeMode', false);
                    		$state.reload($state.current.name);
                        }, function() {
//                            $state.go('^');
                        });
                    }
            		else{
            			$rootScope.scrollTop();
            			$rootScope.$broadcast('property:changeMode', false);
            		}
            	}
        	}
        }

        function openDatePicker(p) {
            if(p=='to'){
            	vm.datePickerTo.isOpen = true;
            }
            if(p=='from'){
            	vm.datePickerFrom.isOpen = true;
            }
        }

        function closeDatePickerTo(args){
        	validateAvailabilityDates();
        }

        function closeDatePickerFrom(args){
        	validateAvailabilityDates();
        }

        function keyUpPickerTo(){
        	validateAvailabilityDates();
        }

        function keyUpPickerFrom(){
        	validateAvailabilityDates();
        }

        function validateAvailabilityDates(){
        	if(vm.property.availabilityPeriodFrom != null && vm.property.availabilityPeriodTo != null
        			&& vm.property.availabilityPeriodFrom > vm.property.availabilityPeriodTo){
        		vm.datePickerFrom.invalid = true;
        		vm.datePickerTo.invalid = true;
        	}
        	else{
        		vm.datePickerFrom.invalid = false;
        		vm.datePickerTo.invalid = false;
        	}
        }

        /*$rootScope.$on('app:sesstionIsTimeOut', function(event, result) {
        	$scope.editForm.$dirty = false;
        });*/

        function onStateChangeStart(event, toState, toParams, fromState, fromParams) {
            if($scope.editForm.$dirty && !$rootScope.isSessionTimeOut){
            	event.preventDefault();

                $uibModal.open({
                	templateUrl: 'app/components/confirmation-dialog/leave/confirm-dialog.html',
                    controller: 'LeaveConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                        entity: function(){
                        	return null;
                        },
                        translateKeys: function(){
                        	return {
                        		keys: 'indochinaApp.property.confirmLeavePage'
                        	};
                        }
                    }
                }).result.then(function() {
                	$scope.editForm.$dirty = false;
                	if(toState.name == 'property-detail.detail-tab'){
                		$state.go(toState, toParams, { reload: true });
                	}
                	else{
                		$state.go(toState, toParams, { reload: false });
                	}
                }, function() {
//                    $state.go('^');
                });
            }
        }

        function removePropertyOtherDetailLanguage(index) {
        	if(vm.propertyOtherDetailLanguages[index].id){
        		$uibModal.open({
	        		templateUrl: 'app/components/confirmation-dialog/delete/confirm-dialog.html',
	        		controller: 'DeleteConfirmDialogController',
	        		controllerAs: 'vm',
	        		size: 'sm',
	        		windowClass : 'absolute-center',
	        		resolve: {
	        			entity: function(){
	        				return null;
	        			},
	        			translateKeys: function(){
	        				return {
	        					keys: 'indochinaApp.property.confirmDeleteSales'
	        				};
	        			}
	        		}
	        	}).result.then(function() {
	        		$scope.editForm.$dirty = true;
	                vm.propertyOtherDetailLanguages.splice(index, 1);
	                if(!vm.propertyOtherDetailLanguages.length){	                	
	                	addPropertyOtherDetailLanguage();
	                }
	        	}, function() {
	        	});
        	}
        	else{        		
        		$scope.editForm.$dirty = true;
        		vm.propertyOtherDetailLanguages.splice(index, 1);
        		if(!vm.propertyOtherDetailLanguages.length){        			
        			addPropertyOtherDetailLanguage();
        		}
        	}
        }

        function addPropertyOtherDetailLanguage() {
        	if (!vm.propertyOtherDetailLanguages.length) {
        		//default language EN && VN
                var propertyOtherDetailsEN = {id:null, languageCode:'EN', otherDetail:null, propertyId:entity.id, created: null, modified: null};
//                var propertyOtherDetailsVN = {id:null, languageCode:'VN', otherDetail:null, propertyId:entity.id, created: null, modified: null};
                vm.propertyOtherDetailLanguages.push(propertyOtherDetailsEN);
//                vm.propertyOtherDetailLanguages.push(propertyOtherDetailsVN);
        	} else {
        		vm.propertyOtherDetailLanguages.push(angular.copy(vm.propertyOtherDetailLanguageEmpty));
                vm.propertyOtherDetailLanguageEmpty.languageCode = null;
                vm.propertyOtherDetailLanguageEmpty.otherDetail = null;
        	}

        }

        function getLanguages(me) {
            if(!vm.propertyOtherDetailLanguages.length)
                return languages;

            var langs = [];
            for(var i = 0; i < languages.length; i++) {
                if(languages[i] === me || !isExist(languages[i]))
                    langs.push(languages[i]);
            }
            return langs;
        }

        function isExist(code) {
            for(var i = 0; i < vm.propertyOtherDetailLanguages.length; i++) {
                if(vm.propertyOtherDetailLanguages[i].languageCode === code)
                    return true;
            }
            return false;
        }

        function isDefaultRow(index) {
//        	if(index == 0 || index == 1) {
        	if(index == 0) {
        		return true;
        	}
        	return false;
        }

        function hasRowEmpty() {
            if(vm.propertyOtherDetailLanguages.length < languages.length) {
                return true;
            }
            return false;
        }

        function onSelectCountry($item, $model, $label) {
            if($model.toUpperCase() == 'vietnam'.toUpperCase()){
            	vm.provinceList = vm.provinceMasterData;
                vm.provinceTypeaheadEditable = false;

                vm.cityList = vm.cityMasterData;
                vm.cityTypeaheadEditable = false;
            }
            else{
            	vm.provinceList = [];
            	vm.provinceTypeaheadEditable = true;

            	vm.cityList = [];
            	vm.cityTypeaheadEditable = true;
//            	vm.property.cityCode = 'INTL';
            }
            vm.property.city = '';
            vm.property.province = '';
            vm.property.district = '';

            vm.districtTypeaheadEditable = true;
        	vm.districtList = [];
        };

//        function onSelectCity($item, $model, $label) {
//            vm.property.cityCode = $item.code;
//
//            if(['HCM', 'HAN', 'DNG'].indexOf(vm.property.cityCode) != -1){
//                vm.districtTypeaheadEditable = false;
//                vm.districtList = getDistrictByCityCode(vm.property.cityCode);
//            }
//            else{
//            	vm.districtTypeaheadEditable = true;
//            	vm.districtList = [];
//            }
//
//            vm.property.district = '';
//        };
//
//        function getDistrictByCityCode (cityCode) {
//            if(cityCode === 'HCM') return districtHCMResource;
//            if(cityCode === 'HAN') return districtHANResource;
//            if(cityCode === 'DNG') return districtDNGResource;
//        }

        function onSelectCity($item, $model, $label) {
//            vm.property.cityCode = $item.code;

            if(['Ho Chi Minh City', 'Ha Noi', 'Da Nang'].indexOf(vm.property.city) != -1){
                vm.districtTypeaheadEditable = false;
                vm.districtList = getDistrictByCity(vm.property.city);
            }
            else{
            	vm.districtTypeaheadEditable = true;
            	vm.districtList = [];
            }

            vm.property.district = '';
        };

        function getDistrictByCity (city) {
            if(city === 'Ho Chi Minh City') return districtHCMResource;
            if(city === 'Ha Noi') return districtHANResource;
            if(city === 'Da Nang') return districtDNGResource;
        }

        function onKeyupCity(){
        	if(vm.property.city == null || vm.property.city == ''){
        		vm.districtTypeaheadEditable = true;
            	vm.districtList = [];
        	}
        }

        function onSelectUserAgent($item) {
        	var login = angular.copy($item);
        	vm.property.userDTO = login;
        }

        function onPropertyTypeChange(){
        	if(['APARTMENT','PENTHOUSE','DUPLEX','OFFICETEL','CONDOTEL'].indexOf(vm.property.type) != -1){
        		vm.property.lotSize = null;
        	}
        }

        function isDisableLotSize(){
        	if(['APARTMENT','PENTHOUSE','DUPLEX','OFFICETEL','CONDOTEL'].indexOf(vm.property.type) != -1){
        		return true;
        	}
        	return false;
        }

        function isDisablePOA(){
        	if(vm.isCreateNew || vm.isDulication) {
        		return false;
        	}

        	//check user login is property agent or supper user
            if(login.authorities[0] === 'ROLE_SUPER_USER' || (vm.property.userDTO && vm.property.userDTO.id === login.id)) {
            	return false;
            }

            return true;
        }
        
        function isDisableListPrice(){
        	if(vm.isCreateNew || vm.isDulication) {
        		return false;
        	}

        	//check user login is property agent or supper user
            if(login.authorities[0] === 'ROLE_SUPER_USER' || (vm.property.userDTO && vm.property.userDTO.id === login.id)) {
            	return false;
            }

            return vm.property.poa;
        }

        function loadOwner(searchQuery){
        	return Contact.getAllContactByName({name: searchQuery}).$promise;
        }

        function onSelectOwner(item, model, label){
        	vm.property.ownerId = item.id;
        }

        function onSelectDevelopment($item, $model, $label){
        	vm.property.developmentId = $item.id;
        	vm.property.developmentName = $item.developmentName;
        	vm.property.developmentCode = $item.developmentCode;
        	vm.property.cityCode = $item.cityCode;

        	// set property country, province, city and cityCode like development had choice
            vm.property.city = $item.city;
            vm.property.province = $item.province;
            vm.property.country = $item.country;
            // set district for autocomplete
            onSelectCity();

            vm.property.district = $item.district;
            vm.property.streetNumber = $item.streetNumber;
            vm.property.streetName = $item.streetName;
            vm.property.postalCode = $item.postalCode;
            vm.property.latitude = $item.latitude;
            vm.property.longitude = $item.longitude;
            
            vm.property.ownerId = $item.ownerId;
            vm.property.ownerName = $item.ownerName;
            
            // set all Amenity
            initAmenity();
            
            function removeOldAmenity(){
            	if(!vm.property.internalRoomOtherIc){
            		vm.property.internalRoomOther == null
            	}
            	
            	if(!vm.property.accessRoadWidthIc){
            		vm.property.accessRoadWidth == null
            	}
            	
            	if(!vm.property.feeManagementIc){
            		vm.property.feeManagementNumber == null
            	}
            	
            	if(!vm.property.feeParkingIc){
            		vm.property.feeParkingNumber == null
            	}
            	
            	if(!vm.property.feeGymIc){
            		vm.property.feeGymNumber == null
            	}
            	
            	vm.selectedPropertyLocations = [];
            	vm.property.propertyLocations = [];
            	if(vm.property.icPropertyLocations){
            		angular.forEach(vm.property.icPropertyLocations, function(i){
            			vm.selectedPropertyLocations[i.id] = true;
                		vm.property.propertyLocations.push(i);
            		});
            	}
            	
            	vm.selectedInternalRooms = [];
            	vm.property.internalRooms = [];
            	if(vm.property.icInternalRooms){
            		angular.forEach(vm.property.icInternalRooms, function(i){
            			vm.selectedInternalRooms[i.id] = true;
                		vm.property.internalRooms.push(i);
            		});
            	}
            	
            	vm.selectedOutsideAreas = [];
            	vm.property.outsideAreas = [];
            	if(vm.property.icOutsideAreas){
            		angular.forEach(vm.property.icOutsideAreas, function(i){
            			vm.selectedOutsideAreas[i.id] = true;
                		vm.property.outsideAreas.push(i);
            		});
            	}
            	
            	vm.selectedFacilitiesOffereds = [];
            	vm.property.facilitiesOffereds = [];
            	if(vm.property.icFacilitiesOffereds){
            		angular.forEach(vm.property.icFacilitiesOffereds, function(i){
            			vm.selectedFacilitiesOffereds[i.id] = true;
                		vm.property.facilitiesOffereds.push(i);
            		});
            	}
            	
            	vm.selectedNearbyFacilities = [];
            	vm.property.nearbyFacilities = [];
            	if(vm.property.icNearbyFacilities){
            		angular.forEach(vm.property.icNearbyFacilities, function(i){
            			vm.selectedNearbyFacilities[i.id] = true;
                		vm.property.nearbyFacilities.push(i);
            		});
            	}
            	
            	vm.selectedParkingFacilities = [];
            	vm.property.parkingFacilities = [];
            	if(vm.property.icParkingFacilities){
            		angular.forEach(vm.property.icParkingFacilities, function(i){
            			vm.selectedParkingFacilities[i.id] = true;
                		vm.property.parkingFacilities.push(i);
            		});
            	}
            	
            	vm.selectedReasonForPurchases = [];
            	vm.property.reasonForPurchases = [];
            	if(vm.property.icReasonForPurchases){
            		angular.forEach(vm.property.icReasonForPurchases, function(i){
            			vm.selectedReasonForPurchases[i.id] = true;
                		vm.property.reasonForPurchases.push(i);
            		});
            	}
            	
            	vm.selectedMaterials = [];
            	vm.property.materials = [];
            	if(vm.property.icMaterials){
            		angular.forEach(vm.property.icMaterials, function(i){
            			vm.selectedMaterials[i.id] = true;
                		vm.property.materials.push(i);
            		});
            	}
            	
            	vm.selectedAccessRoads = [];
            	vm.property.accessRoads = [];
            	if(vm.property.icAccessRoads){
            		angular.forEach(vm.property.icAccessRoads, function(i){
            			vm.selectedAccessRoads[i.id] = true;
                		vm.property.accessRoads.push(i);
            		});
            	}
            }
            
            function initAmenity(){
            	removeOldAmenity();
            	
            	if((vm.property.internalRoomOther == null || vm.property.internalRoomOther == '')
            			&& ($item.internalRoomOther != null && $item.internalRoomOther != '')){
            		vm.internalRoomOtherCheckbox = true;
            		vm.property.internalRoomOtherIc = false;
            		vm.property.internalRoomOther = $item.internalRoomOther;
            	}
            	
            	if((vm.property.accessRoadWidth == null || vm.property.accessRoadWidth == '')
            			&& ($item.accessRoadWidth != null && $item.accessRoadWidth != '')){
            		vm.property.accessRoadWidth = $item.accessRoadWidth;
            		vm.property.accessRoadWidthUnit = $item.accessRoadWidthUnit;
            		vm.property.accessRoadWidthIc = false;
            	}
            	
            	if((vm.property.feeManagementNumber == null || vm.property.feeManagementNumber == '')
            			&& ($item.feeManagementNumber != null && $item.feeManagementNumber != '')){
            		vm.property.feeManagementNumber = $item.feeManagementNumber;
            		vm.property.feeManagementCurrency = $item.feeManagementCurrency;
            		vm.property.feeManagementTerm = $item.feeManagementTerm;
            		vm.property.feeManagementIc = false;
            	}
            	
            	if((vm.property.feeParkingNumber == null || vm.property.feeParkingNumber == '')
            			&& ($item.feeParkingNumber != null && $item.feeParkingNumber != '')){
            		vm.property.feeParkingNumber = $item.feeParkingNumber;
            		vm.property.feeParkingCurrency = $item.feeParkingCurrency;
            		vm.property.feeParkingTerm = $item.feeParkingTerm;
            		vm.property.feeParkingIc = false;
            	}
            	
            	if((vm.property.feeGymNumber == null || vm.property.feeGymNumber == '')
            			&& ($item.feeGymNumber != null && $item.feeGymNumber != '')){
            		vm.property.feeGymNumber = $item.feeGymNumber;
            		vm.property.feeGymCurrency = $item.feeGymCurrency;
            		vm.property.feeGymTerm = $item.feeGymTerm;
            		vm.property.feeGymIc = false;
            	}
            	
            	if($item.propertyLocations){
                	angular.forEach($item.propertyLocations,function(i){
                		
                		var isNotExists = true;
                		angular.forEach(vm.property.propertyLocations, function(j){
                			if(i.id == j.id){
                				isNotExists = false;
                			}
                		});
                		
                		if(isNotExists){
                			vm.selectedPropertyLocations[i.id] = true;
                			vm.property.propertyLocations.push(i);
                		}
                	});
                }
            	
            	if($item.internalRooms){
                	angular.forEach($item.internalRooms,function(i){
                		
                		var isNotExists = true;
                		angular.forEach(vm.property.internalRooms, function(j){
                			if(i.id == j.id){
                				isNotExists = false;
                			}
                		});
                		if(isNotExists){
                			vm.selectedInternalRooms[i.id] = true;
                			vm.property.internalRooms.push(i);
                		}
                	});
                }
            	
            	if($item.outsideAreas){
                	angular.forEach($item.outsideAreas,function(i){
                		
                		var isNotExists = true;
                		angular.forEach(vm.property.outsideAreas, function(j){
                			if(i.id == j.id){
                				isNotExists = false;
                			}
                		});
                		if(isNotExists){
                			vm.selectedOutsideAreas[i.id] = true;
                			vm.property.outsideAreas.push(i);
                		}
                	});
                }
            	
            	if($item.facilitiesOffereds){
                	angular.forEach($item.facilitiesOffereds,function(i){
                		
                		var isNotExists = true;
                		angular.forEach(vm.property.facilitiesOffereds, function(j){
                			if(i.id == j.id){
                				isNotExists = false;
                			}
                		});
                		if(isNotExists){
                			vm.selectedFacilitiesOffereds[i.id] = true;
                			vm.property.facilitiesOffereds.push(i);
                		}
                	});
                }
            	
            	if($item.nearbyFacilities){
                	angular.forEach($item.nearbyFacilities,function(i){
                		
                		var isNotExists = true;
                		angular.forEach(vm.property.nearbyFacilities, function(j){
                			if(i.id == j.id){
                				isNotExists = false;
                			}
                		});
                		if(isNotExists){
                			vm.selectedNearbyFacilities[i.id] = true;
                			vm.property.nearbyFacilities.push(i);
                		}
                	});
                }
            	
            	if($item.parkingFacilities){
                	angular.forEach($item.parkingFacilities,function(i){
                		
                		var isNotExists = true;
                		angular.forEach(vm.property.parkingFacilities, function(j){
                			if(i.id == j.id){
                				isNotExists = false;
                			}
                		});
                		if(isNotExists){
                			vm.selectedParkingFacilities[i.id] = true;
                			vm.property.parkingFacilities.push(i);
                		}
                	});
                }
            	
            	if($item.reasonForPurchases){
                	angular.forEach($item.reasonForPurchases,function(i){
                		
                		var isNotExists = true;
                		angular.forEach(vm.property.reasonForPurchases, function(j){
                			if(i.id == j.id){
                				isNotExists = false;
                			}
                		});
                		if(isNotExists){
                			vm.selectedReasonForPurchases[i.id] = true;
                			vm.property.reasonForPurchases.push(i);
                		}
                	});
                }
            	
            	if($item.materials){
                	angular.forEach($item.materials,function(i){
                		
                		var isNotExists = true;
                		angular.forEach(vm.property.materials, function(j){
                			if(i.id == j.id){
                				isNotExists = false;
                			}
                		});
                		if(isNotExists){
                			vm.selectedMaterials[i.id] = true;
                			vm.property.materials.push(i);
                		}
                	});
                }
            	
            	if($item.accessRoads){
                	angular.forEach($item.accessRoads,function(i){
                		
                		var isNotExists = true;
                		angular.forEach(vm.property.accessRoads, function(j){
                			if(i.id == j.id){
                				isNotExists = false;
                			}
                		});
                		if(isNotExists){
                			vm.selectedAccessRoads[i.id] = true;
                			vm.property.accessRoads.push(i);
                		}
                	});
                }
            }
        }

        function onKeyUpDevelopment(){
        	if(vm.property.developmentName == null || vm.property.developmentName == ''){
        		vm.property.developmentId = null;
        		vm.property.developmentCode = null;
        		vm.property.cityCode = null;
        	}
        }
        
        function validateGrossAndNetSize(){
        	vm.netSizeLessThanGrossSize = true;
        	if(vm.property.netSize != null && vm.property.netSize != ''){
        		if(vm.property.size == null || vm.property.size == ''){
        			vm.netSizeLessThanGrossSize = false;
        		}
        		else if(vm.property.netSizeUnit == vm.property.sizeUnit && parseFloat(vm.property.netSize) > parseFloat(vm.property.size)){
        			vm.netSizeLessThanGrossSize = false;
        		}
        		else if(vm.property.netSizeUnit != vm.property.sizeUnit){
        			var feetToMeter = 0.3048;
        			var netSizeValue = 0;
        			var grossSizeValue = 0;
        			
        			if(vm.property.netSizeUnit == 'SQ_METER'){
        				netSizeValue = parseFloat(vm.property.netSize);
        			}
        			else{
        				netSizeValue = parseFloat(vm.property.netSize) * feetToMeter;
        			}
        			
        			if(vm.property.sizeUnit == 'SQ_METER'){
        				grossSizeValue = parseFloat(vm.property.size);
        			}
        			else{
        				grossSizeValue = parseFloat(vm.property.size) * feetToMeter;
        			}
        			
        			if(netSizeValue > grossSizeValue){
        				vm.netSizeLessThanGrossSize = false;
        			}
        		}
        	}
        }
    }
})();
