(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('PropertyLegalTabController', Controller);

    Controller.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'previousState', 'entity', 'Upload', 'PropertyLegal', 'ParseLinks', 'AlertService',
                                  '$timeout', '$translate', '$uibModal'];

    function Controller($scope, $state, $rootScope, $stateParams, previousState, entity, Upload, PropertyLegal, ParseLinks, AlertService,
    							$timeout, $translate, $uibModal) {
        var vm = this;
        vm.property = entity;

        if($state.isEdit) {
        	vm.isEdit = $state.isEdit;
        }

        $rootScope.scrollTop();
        $rootScope.$broadcast('property:activeTab', 'LEGAL');
//        $rootScope.$broadcast('property:isActivityOrOpportunity', false);
        $rootScope.$on('property:changeMode', function(event, result) {
        	vm.isEdit = result;
        });
        vm.predicate = 'createdDate';
        vm.reverse = 0;
        vm.page = 1;
        vm.itemsPerPage = 20;
        
        vm.cancel = cancel;
       
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.uploadFiles = uploadFiles;
        vm.deleteEntity = deleteEntity;
        vm.downloadFile = $rootScope.downloadFile;
        
        loadAll();
        
        function loadAll () {
            PropertyLegal.query({
            	propertyId: vm.property.id,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [];
                
                if(vm.predicate == 'createdBy'){
                	result.push('u.firstName,' + (vm.reverse ? 'asc' : 'desc'));
                	result.push('u.lastName,' + (vm.reverse ? 'asc' : 'desc'));
                }
                else if(vm.predicate == 'fileName'){
                	result.push('f.filename,' + (vm.reverse ? 'asc' : 'desc'));
                }
                else if(vm.predicate == 'createdDate'){
                	result.push('f.created,' + (vm.reverse ? 'asc' : 'desc'));
                }
                
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.propertyLegals = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        
        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }
        
        function transition(){
        	loadAll();
        }
        
        function uploadFiles(files, errFiles) {
            vm.files = files;
            vm.errFiles = errFiles;
            
            var isShowedErrorMsg = false;
            var uploadedSuccessFiles = [];
            angular.forEach(files, function(file) {
            	
            	// 2097152bytes = 2MB
            	if(file.size > 2097152){
            		
            		// we will show when the first image have size larger 2MB.
            		// if we wait system uploaded all images, It is very slow. 
            		if(!isShowedErrorMsg){
            			isShowedErrorMsg = true;
            			AlertService.error("File must be less than 2MB");
            		}
            		
            		// reload page when all files is uploaded successfully
            		uploadedSuccessFiles.push(file);
    				if(uploadedSuccessFiles.length == vm.files.length){            					
    					transition();
    				}
            	}
            	else{ 		
            		file.upload = Upload.upload({
            			url: 'api/property-legals/upload',
            			data: {propertyId:vm.property.id, file: file}
            		});
            		
            		file.upload.then(function (response) {
            			$timeout(function () {
            				file.result = response.data;
            				
                    		// reload page when all files is uploaded successfully
                    		uploadedSuccessFiles.push(file);
            				if(uploadedSuccessFiles.length == vm.files.length){            					
            					transition();
            				}
            			});
            		}, function (response) {
            			if (response.status > 0)
            				AlertService.error(response.status + ': ' + response.data);
            		}, function (evt) {
            			file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            		});
            	}
            });
        }
        
        function deleteEntity(propertyLegalId){
        	$uibModal.open({
                templateUrl: 'app/components/confirmation-dialog/delete/confirm-dialog.html',
                controller: 'DeleteConfirmDialogController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass : 'absolute-center',
                resolve: {
                    entity: function(){
                    	return null;
                    },
                    translateKeys: function(){
                    	return {
                    		keys: 'indochinaApp.property.confirmDeleteLegal'
                    	};
                    }
                }
            }).result.then(function() {
            	PropertyLegal.delete({id: propertyLegalId},function () {
            		AlertService.success($translate.instant('indochinaApp.property.deletedDocumentSuccessfullyMsg'));
            		loadAll();
            	});
            }, function() {
//                $state.go('^');
            });
        }
        
        function cancel() {
        	$state.isEdit = false;
    		$rootScope.$broadcast('property:changeMode', false);
    		$state.reload($state.current.name);
        }
    }
})();
