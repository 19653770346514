(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('ActivitiesTabContactController', Controller);

    Controller.$inject = ['$scope', '$state', '$parse', '$rootScope', '$stateParams', 'previousState', 'entity', 'DateUtils', 'activityTypeResource', '$uibModal', 'login'];

    function Controller($scope, $state, $parse, $rootScope, $stateParams, previousState, entity, DateUtils, activityTypeResource, $uibModal, login) {

        var vm = this;
        vm.contact = entity;
        vm.createNewActivity = createNewActivity;
        vm.cancel = cancel;
        vm.search = search;


        $rootScope.scrollTop();
        $rootScope.$broadcast('contact:activeTab', 'ACTIVITY');
        vm.isEdit = true;

        // data picker
        vm.openFromCalendar = openFromCalendar;
        vm.openToCalendar = openToCalendar;
        vm.validateDatepicker = validateDatepicker;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.fromDate = false;
        vm.datePickerOpenStatus.toDate = false

        vm.datePickerOption = {
            showWeeks: false,
            startingDay: 1,
            readonlyInput: true,
            dateformat: 'MM/dd/yyyy'
        }

        $rootScope.$on('contact:activated', function(event, result) {
        	vm.contact.activated = result;
        });

        function search() {

            // convert date
            var fromDate = DateUtils.convertLocalDateToServer(vm.fromDate);
            var toDate = DateUtils.convertLocalDateToServer(vm.toDate);

            // broadcast to child state
            $rootScope.$broadcast('activity-contact:search', fromDate, toDate);
        }




        function createNewActivity () {
        	$uibModal.open({
                templateUrl: 'app/entities/activity/activity-dialog.html',
                controller: 'ActivityDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    development: function () {
                        return null;
                    },
                	login: function () {
                	    return login;
                    },
                	isFollowUpActivity: function () {
                		return false;
                	},
                	contact: ['Contact', function (Contact) {
                		return Contact.get({id : vm.contact.id}).$promise;
                	}],
                    property: function () {
                        return null;
                    },
                    entity: function () {
                        return {
                            title: null,
                            type: null,
                            status: 'OPEN',
                            notes: null,
                            activated: null,
                            createdDate: null,
                            modifiedDate: null,
                            dueDate: new Date(),
                            id: null,
                            opportunity: null,
                            developmentId: null,
                            developmentName: null,
                            from: 2,
                            contactActivities: [],
                            propertyActivities: []
                        };
                    },
                    propertyList: function () {
                        return null;
                    },
                    propertyTemplate: function () {
                        return null;
                    },
                    activityTypeResource: function () {
                        return activityTypeResource;
                    }
                }
            }).result.then(function() {
                $state.go('activity', null, { reload: 'activity' });
            }, function() {

            });
        }

        function cancel () {
        	/*$state.isEdit = false;
        	$state.reload();*/
        	$state.isEdit = false;
    		$rootScope.$broadcast('contact:changeMode', false);
    		$state.reload($state.current.name);
        }

        function openFromCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function openToCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function validateDatepicker(){
            vm.invalidDatepicker = false;
            if(vm.fromDate != null && vm.toDate != null){
                if(vm.fromDate > vm.toDate){
                    vm.invalidDatepicker = true;
                }
            }
        }
    }
})();
