(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app', {
            abstract: true,
            views: {
                'navbar@': {
                    templateUrl: 'app/layouts/navbar/navbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                }],
                // common Resource
                constructionStatusResource: ['ConstructionStatus', function(ConstructionStatus) {
                    return ConstructionStatus.query().$promise;
                }],
                leadSourceResource: ['LeadSourceManagementService', function(LeadSourceManagementService) {
                    return LeadSourceManagementService.query().$promise;
                }],
                marketingCampaignResource: ['MarketingCampaign', function(MarketingCampaign) {
                    return MarketingCampaign.query().$promise;
                }],
                countryResource: ['Country', function(Country) {
                    return Country.query().$promise;
                }],
                provinceResource: ['Province', function(Province) {
                    return Province.query().$promise;
                }],
                cityResource: ['City', function(City) {
                    return City.query().$promise;
                }],
                districtHCMResource: ['District', function(District) {
                    return District.getAllByCityCode({cityCode: 'HCM'}).$promise;
                }],
                districtHANResource: ['District', function(District) {
                    return District.getAllByCityCode({cityCode: 'HAN'}).$promise;
                }],
                districtDNGResource: ['District', function(District) {
                    return District.getAllByCityCode({cityCode: 'DNG'}).$promise;
                }],
                propertyLocationsResource: ['PropertyLocationService', function(PropertyLocationService) {
                    return PropertyLocationService.query().$promise;
                }],
                internalRoomsResource: ['InternalRoomsService', function(InternalRoomsService) {
                    return InternalRoomsService.query().$promise;
                }],
                outsideAreasResource: ['OutsideAreaService', function(OutsideAreaService) {
                    return OutsideAreaService.query().$promise;
                }],
                facilitiesOffersResource: ['FacilitiesOfferedService', function(FacilitiesOfferedService) {
                    return FacilitiesOfferedService.query().$promise;
                }],
                parkingFacilitiesResource: ['ParkingFacilitiesService', function(ParkingFacilitiesService) {
                    return ParkingFacilitiesService.query().$promise;
                }],
                nearbyFacilitiesResource: ['NearbyFacilitiesService', function(NearbyFacilitiesService) {
                    return NearbyFacilitiesService.query().$promise;
                }],
                propertyTypesResource: ['PropertyType', function(PropertyType) {
                    return PropertyType.query().$promise;
                }],
                propertyTransactionsResource: ['PropertyTransaction', function(PropertyTransaction) {
                    return PropertyTransaction.query().$promise;
                }],
                reasonForPurchaseResource: ['ReasonForPurchase', function(ReasonForPurchase) {
                    return ReasonForPurchase.query().$promise;
                }],
                materialsResource: ['Materials', function(Materials) {
                    return Materials.query().$promise;
                }],
                accessRoadResource: ['AccessRoadService', function(AccessRoadService) {
                    return AccessRoadService.query().$promise;
                }],
                languagesResource: ['PropertyService', function(PropertyService) {
                    return PropertyService.getLanguages().$promise;
                }],
                publishSiteResource: ['PublishService', function(PublishService) {
                    return PublishService.query().$promise;
                }],
                activityTypeResource: ['ActivityType', function(ActivityType) {
                    return ActivityType.query({sort: 'order,asc'}).$promise;
                }]
            }
        });
    }
})();
