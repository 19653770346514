(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('entity', {
            abstract: true,
            parent: 'app',
            resolve: {
                // user login resource
                login: ['Principal' , function (Principal) {
                    var result = Principal.identity();
                    return result;
                }],
                // all resource relative with contact detail
                contactMethodResource: ['ContactMethod', function(ContactMethod) {
                    return ContactMethod.query().$promise;
                }],
                otherContactResource: ['OtherContactType', function(OtherContactType) {
                    return OtherContactType.query({
                        size: 100
                    }).$promise;
                }],
                contactByResource: ['ContactBy', function(ContactBy) {
                    return ContactBy.query().$promise;
                }],
                coAgentResource:['User', function(User) {
                    return User.getAllCoAgents().$promise;
                }],
                agentResource:['User', function(User) {
                    return User.getAgentListReport().$promise;
                }],
                contactTypeResource:['ContactType', function(ContactType) {
                    return ContactType.query().$promise;
                }],
                languagePreferenceResource:['LanguagePreference', function(LanguagePreference) {
                    return LanguagePreference.query().$promise;
                }],
                indochinaPropertyContact:['Contact', function(Contact) {
                    return Contact.getIndochinaPropertyContact().$promise;
                }],
                listSearchAgent:['login', 'User', 'AuthorityConstant', function(login, User, AuthorityConstant) {

                    // set full name for login user
                    login.fullName = login.firstName + ' ' + login.lastName;

                    // return current user if login role is agent
                    if(login.authorities[0] === AuthorityConstant.ROLE_AGENT){
                        var listAgent = [];
                        listAgent.push(login);
                        return listAgent;
                    }
                    // return list agent and current user if login role is MANAGER
                    if(login.authorities[0] === AuthorityConstant.ROLE_MANAGER){
                        return User.getAgentListByManagerId({id: login.id}, function (result){
                            result.push(login);
                        }).$promise;
                    }
                    // return list all users if login role is ADMIN or SUPER USER account
                    if(login.authorities[0] === AuthorityConstant.ROLE_SUPER_USER || login.authorities[0] === AuthorityConstant.ROLE_ADMIN){
                        return User.getAllAgentManagerAdminSuperUserList().$promise;
                    }
                }]
            }
        });
    }
})();
