(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('ContactController', ContactController);

    ContactController.$inject = ['$state', '$scope', '$timeout', '$uibModal', 'Contact', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams',
                                 'JhiCurrencyService', 'AuthorityConstant', 'CurrencyConstant', 'ContactConstant', 'Country', 'Province', 'City', 'User', 'DateUtils',
                                 'Principal', 'countryResource', 'provinceResource', 'cityResource', 'districtHCMResource', 'districtHANResource', 'districtDNGResource',
                                 'otherContactResource', 'listSearchAgent', 'FileSaver'];

    function ContactController($state, $scope, $timeout, $uibModal, Contact, ParseLinks, AlertService, paginationConstants, pagingParams,
    							JhiCurrencyService, AuthorityConstant, CurrencyConstant, ContactConstant, Country, Province, City, User, DateUtils,
    							Principal, countryResource, provinceResource, cityResource, districtHCMResource, districtHANResource, districtDNGResource,
    							otherContactResource, listSearchAgent, FileSaver) {

        var vm = this;
        var ceilOfSlideBar = 10000000;
        var currentCurrencyDes = null;

        if($state.isEdit === true) {
        	$state.isEdit = false;
        }

        vm.loadPage = loadPage;
        vm.transition = transition;

        vm.clear = clear;
        vm.search = search;
        vm.getBedRoom = getBedRoom;
        vm.getBathRoom = getBathRoom;
        vm.loadCoBrokerContact = loadCoBrokerContact;
        vm.validateDatepicker = validateDatepicker;
        vm.validateBedroom = validateBedroom;
        vm.validateBathroom = validateBathroom;
        vm.onRefreshUISelect = onRefreshUISelect;
        vm.onChangeCountryLocation = onChangeCountryLocation;
        vm.onChangeFromBedroom = onChangeFromBedroom;
        vm.onChangeToBedroom = onChangeToBedroom;
        vm.onChangeFromBathroom = onChangeFromBathroom;
        vm.onChangeToBathroom = onChangeToBathroom;
        vm.onClickAdvanceSearch = onClickAdvanceSearch;
        vm.hasAdvancedSearch = hasAdvancedSearch;

        vm.isOpenAdvancedSearch = false;
        vm.isOnlyVietnamInCountryLocationList = false;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.listType = ContactConstant.type.LIST;
        vm.listStatus = ContactConstant.status.LIST;
        vm.countries = [];
        vm.provinces = [];
        vm.cities = [];
        vm.districts = [];
        vm.agents = [];
        vm.currencyUnits = CurrencyConstant.LIST;
        vm.currenciesDes = CurrencyConstant.DES;
        vm.currencyCode = JhiCurrencyService.currencyCode;
        vm.currencyRate = JhiCurrencyService.rate;
        vm.currencyUSDRate = JhiCurrencyService.USDRate;
        // export csv
        vm.exportCSV = exportCSV;
        
        vm.fromDatePickerOption = {
    		showWeeks: false,
    		closeDate: false,
    		dateFormat: 'MM/dd/yyyy',
    		isOpen: false
        }
        vm.toDatePickerOption = {
    		showWeeks: false,
    		closeDate: false,
    		dateFormat: 'MM/dd/yyyy',
    		isOpen: false
        }

        vm.slider = {
//    	    minValue: 0,
//    	    maxValue: ceilOfSlideBar,
    	    options: {
    	        floor: 0,
    	        ceil: ceilOfSlideBar,
    	        step: 10,
    	        translate: function(value, sliderId, label) {
					switch (label) {
//						case 'model':
//							return numberWithCommas(value);
						case 'high':
							var viewValue = currentCurrencyDes + numberWithCommas(value);
							if(value == vm.slider.options.ceil){
								viewValue += '+';
							}
							return viewValue;
						default:
							return currentCurrencyDes + numberWithCommas(value);
					}
				}
    	    }
    	};

        initData();

        function initData(){
        	vm.searchObj = decryptSearchingObj(pagingParams.search);
            if(vm.searchObj.fromDate != null){
            	vm.searchObj.fromDate = new Date(vm.searchObj.fromDate);
            }
            if(vm.searchObj.toDate != null){
            	vm.searchObj.toDate = new Date(vm.searchObj.toDate);
            }
            if(vm.searchObj.currency == null || vm.searchObj.currency == ''){
            	vm.searchObj.currency = 'USD';
            }
            if(vm.searchObj.fromPrice == null){
            	vm.searchObj.fromPrice = 0;
            }
            if(vm.searchObj.toPrice == null){
            	vm.searchObj.toPrice = ceilOfSlideBar;
            }

            updateSliderValue();
            setEventToCloseAdvanceSearchPopup();

            vm.otherContactTypes = otherContactResource;

            // init province
            vm.provinces = provinceResource;

            // init city
            vm.cities = cityResource;

            // init district
            vm.districts = angular.copy(districtHCMResource);
            Array.prototype.push.apply(vm.districts,districtHANResource);
            Array.prototype.push.apply(vm.districts,districtDNGResource);

            // init country
            countryResource.forEach(function(c){
                vm.countries.push(c.name);
            });
            
            // init agents
            Principal.identity().then(function(account) {
            	// disable "Agent" field when user login by agent account
            	if(account.authorities[0] == AuthorityConstant.ROLE_AGENT){
            		vm.isDisabledAgentField = true;
            	}
            	
            	vm.agents = listSearchAgent;
            	
            	// set default value for agent
        		vm.agents.some(function(u){
        			if(u.id == account.id){
        				initAgent(u);
        				return true;
        			}
        			return false;
        		});
        	});
        }
        
        function initAgent(currentUser){
        	if(vm.searchObj.agent != null && vm.searchObj.agent == -1){
        		vm.searchObj.agent = [currentUser];
            }
        	
            loadAll();
        }

        function loadAll () {
            Contact.query(getApiParams(), onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.contacts = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }

//            function parseStringFromDate(date){
//            	var mm = date.getMonth() + 1;
//            	var dd = date.getDate();
//            	var yyyy = date.getFullYear();
//
//            	if(dd < 9){
//            		dd = '0' + dd;
//            	}
//            	if(mm < 9){
//            		mm = '0' + mm;
//            	}
//
//            	return mm + '/' + dd + '/' + yyyy;
//            }
        }
        
        function getApiParams(){
        	var apiParams = angular.copy(vm.searchObj);
        	apiParams.page = pagingParams.page - 1;
        	apiParams.size = vm.itemsPerPage;
        	apiParams.sort = sort();

        	if(apiParams.fromDate != null){
        		apiParams.fromDate = DateUtils.convertLocalDateToServer(apiParams.fromDate);
        	}

        	if(apiParams.toDate != null){
        		apiParams.toDate = DateUtils.convertLocalDateToServer(apiParams.toDate);
        	}
        	
        	if(apiParams.fromPrice == 0){
            	apiParams.fromPrice = null;
            }

            if(apiParams.toPrice == vm.slider.options.ceil){
            	apiParams.toPrice = null;
            }

        	if(apiParams.provinceLocation != null && apiParams.provinceLocation.length > 0){
        		var listProvinceLocation = [];
        		apiParams.provinceLocation.forEach(function(i){
        			listProvinceLocation.push(i.name);
        		});

        		apiParams.provinceLocation = listProvinceLocation;
        	}

        	if(apiParams.cityLocation != null && apiParams.cityLocation.length > 0){
        		var listCityLocation = [];
        		apiParams.cityLocation.forEach(function(i){
        			listCityLocation.push(i.name);
        		});

        		apiParams.cityLocation = listCityLocation;
        	}
        	
        	if(apiParams.districtLocation != null && apiParams.districtLocation.length > 0){
        		var listDistrictLocation = [];
        		apiParams.districtLocation.forEach(function(i){
        			listDistrictLocation.push(i.name);
        		});
        		
        		apiParams.districtLocation = listDistrictLocation;
        	}
        	
        	if(apiParams.agent != null && apiParams.agent.length > 0){
        		var listAgentId = [];
        		apiParams.agent.forEach(function(i){
        			listAgentId.push(i.id);
        		});
        		
        		apiParams.agent = listAgentId;
        	}
        	
        	// remove industry when type don't have OTHER
        	if(apiParams.type == null || apiParams.type == '' || apiParams.type.indexOf('OTHER') == -1){
        		apiParams.industry = null;
        	}
        	
        	if(apiParams.industry != null && apiParams.industry.length > 0 ){
        		var listIndustryId = [];
        		apiParams.industry.forEach(function(i){
        			listIndustryId.push(i.id);
        		});
        		
        		apiParams.industry = listIndustryId;
        	}
        	
        	// remove coBrokerContactName when type don't have OTHER
        	if(apiParams.type == null || apiParams.type == '' || apiParams.type.indexOf('OTHER') == -1){
        		apiParams.coBrokerContactName = null;
        	}
        	
        	return apiParams;
        }
        
        function sort() {
            var result = [];

            if(vm.predicate == 'registeredName'){
            	result.push('cp.registeredName,' + (vm.reverse ? 'asc' : 'desc'));
            }
            else if(vm.predicate == 'type'){
            	result.push('ct.name,' + (vm.reverse ? 'asc' : 'desc'));
            }
            else if(vm.predicate == 'industry'){
            	result.push('oct.name,' + (vm.reverse ? 'asc' : 'desc'));
            }
            else if(vm.predicate == 'person'){
            	result.push('pss.firstName,' + (vm.reverse ? 'asc' : 'desc'));
            	result.push('pss.lastName,' + (vm.reverse ? 'asc' : 'desc'));
            }
            else if(vm.predicate == 'email'){
            	result.push('pss_email.email,' + (vm.reverse ? 'asc' : 'desc'));
            	result.push('ps_email.email,' + (vm.reverse ? 'asc' : 'desc'));
            }
            else if(vm.predicate == 'mobile'){
            	result.push('pss_mobiles.phone,' + (vm.reverse ? 'asc' : 'desc'));
            	result.push('ps_mobiles.phone,' + (vm.reverse ? 'asc' : 'desc'));
            	result.push('ps.officePhone.phone,' + (vm.reverse ? 'asc' : 'desc'));
            	result.push('pss.officePhone.phone,' + (vm.reverse ? 'asc' : 'desc'));
            }
            else if(vm.predicate == 'agent'){
            	result.push('a.firstName' + ',' + (vm.reverse ? 'asc' : 'desc'));
            	result.push('a.lastName,' + (vm.reverse ? 'asc' : 'desc'));
            }
            else if(vm.predicate == 'dueDate'){
            	result.push('ac.dueDate,' + (vm.reverse ? 'asc' : 'desc'));
            }
            else{
            	result.push('c.' + vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'));
            }

            if (vm.predicate !== 'id') {
                result.push('c.id');
            }
            return result;
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: encryptSearchingObj()
            },
            {
	        	reload: true
	        });
        }

        function clear(){
        	vm.page = 1;
        	// reload grid's data
	        $state.transitionTo($state.$current, {
	            page: vm.page,
	            sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
	            search: null
	        },
	        {
	        	reload: true
	        });
        }

        function search(){
        	vm.page = 1;
        	vm.isOpenAdvancedSearch = false;
        	vm.transition();
        }

        function getBedRoom(){
        	return [1,2,3,4,5];
        }

        function getBathRoom(){
        	return [0.5,1,1.5,2,2.5,3,3.5,4,4.5,5];
        }
        
        function loadCoBrokerContact(name){
        	return Contact.getCoBrokerContactForAutoComplete({name:name}).$promise;
        }

        function onClickAdvanceSearch(){
        	vm.isOpenAdvancedSearch = !vm.isOpenAdvancedSearch;

//        	if(vm.isOpenAdvancedSearch){
//        		$timeout(function (){
//            		resetInputWith('jsCountryLocation', 2);
//            		resetInputWith('jsProvinceLocation', 2);
//            		resetInputWith('jsCityLocation', 2);
//
////            		setEventToCloseAdvanceSearchPopup();
//            	}, 1);
//        	}
        }

        function hasAdvancedSearch(){
        	if(vm.searchObj.fromBedroom != null){
        		return true;
        	}
        	if(vm.searchObj.toBedroom != null){
        		return true;
        	}
        	if(vm.searchObj.fromBathroom != null && vm.searchObj.fromBathroom != ''){
        		return true;
        	}
        	if(vm.searchObj.toBathroom != null && vm.searchObj.toBathroom != ''){
        		return true;
        	}
        	if(vm.searchObj.fromPrice != null && vm.searchObj.fromPrice != 0){
        		return true;
        	}
        	if(vm.searchObj.toPrice != null && vm.searchObj.toPrice != vm.slider.options.ceil){
        		return true;
        	}
        	if(vm.searchObj.countryLocation != null && vm.searchObj.countryLocation != ''){
        		return true;
        	}
        	if(vm.searchObj.provinceLocation != null && vm.searchObj.provinceLocation != ''){
        		return true;
        	}
        	if(vm.searchObj.cityLocation != null && vm.searchObj.cityLocation != ''){
        		return true;
        	}
        	if(vm.searchObj.districtLocation != null && vm.searchObj.districtLocation != ''){
        		return true;
        	}
        	return false;
        }

        function onChangeFromBedroom(value){
        	vm.searchObj.fromBedroom = value;
        	validateBedroom();
        }

        function onChangeToBedroom(value){
        	vm.searchObj.toBedroom = value;
        	validateBedroom();
        }

        function onChangeFromBathroom(value){
        	vm.searchObj.fromBathroom = value;
        	validateBathroom();
        }

        function onChangeToBathroom(value){
        	vm.searchObj.toBathroom = value;
        	validateBathroom();
        }

        function onChangeCountryLocation($item, $model){
        	vm.isOnlyVietnamInCountryLocationList = false;

        	if(vm.searchObj.countryLocation != null && vm.searchObj.countryLocation.length == 1){
        		if(vm.searchObj.countryLocation[0].toUpperCase() == 'VIETNAM'){
        			vm.isOnlyVietnamInCountryLocationList = true;
        		}
        	}
        }

        function onRefreshUISelect($select){
        	if(vm.isOnlyVietnamInCountryLocationList){
        		return;
        	}

			var search = $select.search,
				list = angular.copy($select.items),
				FLAG = -1;

			//remove last user input
			list = list.filter(function(item) {
				return item.id !== FLAG;
			});

			if (!search) {
				//use the predefined list
				$select.items = list;
			}
			else {
				//manually add user input and set selection
				var userInputItem = {
					id: FLAG,
					name: search
				};
				list.unshift(userInputItem);
				$select.items = list;
//				$select.selected = userInputItem;
			}
		}

        function validateDatepicker(){
        	vm.invalidDatepicker = false;
        	if(vm.searchObj.fromDate != null && vm.searchObj.toDate != null){
        		if(vm.searchObj.fromDate > vm.searchObj.toDate){
        			vm.invalidDatepicker = true;
        		}
        	}
        }

        function validateBedroom() {
        	vm.invalidBedroom = false;
        	if(vm.searchObj.fromBedroom != null && vm.searchObj.toBedroom != null){
        		if(parseFloat(vm.searchObj.fromBedroom) > parseFloat(vm.searchObj.toBedroom)){
        			vm.invalidBedroom = true;
        		}
        	}
		}

		function validateBathroom() {
			vm.invalidBathroom = false;
			if(vm.searchObj.fromBathroom != null && vm.searchObj.toBathroom != null){
				if(parseFloat(vm.searchObj.fromBathroom) > parseFloat(vm.searchObj.toBathroom)){
					vm.invalidBathroom = true;
				}
			}
		}

//		function resetInputWith(className, minInputWith){
//        	var thisDiv = $('.' + className).children('div:first');
//    		var parentWidth = thisDiv.width();
//    		var spanWidth = thisDiv.children('span').width();
//    		var inputWidth = parentWidth - spanWidth;
//
////    		console.log(className + ':' + parentWidth + "-" + spanWidth);
//    		if(inputWidth <= minInputWith){
//    			inputWidth = minInputWith;
//    		}
//
//    		$('.' + className).find('input').attr('style','width:'+inputWidth+'px;');
//        }

		$scope.$on("curreny:change", function(event, item){
			updateSliderValue();
        });

		function updateSliderValue(){
            if(vm.currencyCode.value == 'USD'){
            	vm.slider.options.ceil = ceilOfSlideBar;
            }
            else{
            	vm.slider.options.ceil = convertCurrencyFromUSD(ceilOfSlideBar, vm.currencyCode.value);
            }

            if(vm.searchObj.currency != vm.currencyCode.value){
            	vm.searchObj.fromPrice = 0;
                vm.searchObj.toPrice = vm.slider.options.ceil;
            }

            vm.searchObj.currency = vm.currencyCode.value;
        	currentCurrencyDes = vm.currenciesDes[vm.currencyCode.value];
		}

		function convertCurrencyFromUSD(value, code){
			var exchangeRate = {value: 1};
			vm.currencyUSDRate.some(function(r){
				if(r.destinationCode == code){
					exchangeRate = r;
					return true;
				}
				return false;
			});

			return Math.ceil(value * exchangeRate.value);
        }

        function numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

		function setEventToCloseAdvanceSearchPopup(){
			var isClickOnJsAdvanceSearch = false;
			$('.jsAdvanceSearch').click(function(event){
//			    event.stopPropagation();
			    isClickOnJsAdvanceSearch = true;
			});

			$('body').click(function(event){
				if(vm.isOpenAdvancedSearch && !isClickOnJsAdvanceSearch && !vm.invalidBedroom && !vm.invalidBathroom){
	        		$timeout(function (){
	        			vm.isOpenAdvancedSearch = false;
	            	}, 1);
	        	}

				isClickOnJsAdvanceSearch = false;
			});
		}

		function encryptSearchingObj(){
			if($.isEmptyObject(vm.searchObj)){
				return null;
        	}

			return encryptText(JSON.stringify(vm.searchObj));
		}

		function decryptSearchingObj(encryptMsg){
			if($.isEmptyObject(encryptMsg)){
				return {};
			}

			try{
				return JSON.parse(decryptText(encryptMsg));
            }
            catch(e){
            	return {};
            }
		}

		function encryptText(msg){
			return CryptoJS.TripleDES.encrypt(msg, "U2FsdGVkX1~2FnVu36U2P7~2FJ5ye~2FJhvhZQScHXSCUl1cc%3D");
		}

		function decryptText(msg){
			return CryptoJS.TripleDES.decrypt(msg, "U2FsdGVkX1~2FnVu36U2P7~2FJ5ye~2FJhvhZQScHXSCUl1cc%3D").toString(CryptoJS.enc.Utf8);
		}
		
		function exportCSV(){
			var params = getApiParams();
			Contact.exportCSVContacts(params,
             function (result){
                FileSaver.saveAs(result.response, 'ContactPages.csv');
             });
        }
    }
})();
