(function () {
    'use strict';

    angular
        .module('indochinaApp')
        .factory('ApplicationPropertiesService', ApplicationPropertiesService);

    ApplicationPropertiesService.$inject = ['$resource'];

    function ApplicationPropertiesService ($resource) {
        var service = $resource('api/application-properties', {}, {
            'getAll': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getPhoto': {
                method: 'GET',
                url:'api/application-properties/photo',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;
    }
})();
