(function() {
    'use strict';
    
    angular
    .module('indochinaApp')
    .directive('uiSelectCustomize', ['$filter', '$timeout', function ($filter, $timeout) {
        return {
            require: '?ngModel',
            link: function (scope, elem, attrs, ctrl) {
            	
                /******************************************************************************************
                 * define all variables
                 */
            	var _input = elem.find('input');
            	
            	
                /******************************************************************************************
                 * all default action
                 */
            	// fix bug that occur when user download a report
				$(window).resize(function() {
					// we have to call it because ui-select auto resize when window is resized.
					function recall(milliseconds){
						if(milliseconds > 1000){
							return;
						}
						
						$timeout(function (){
		            		minimizeInputUISelect();
		            		recall(milliseconds + 10);
		            	}, 10);
					}
					
					recall(0);
				});
            	
            	_input.focus(function(e) {
            		elem.addClass('jsFocus');
            	});
            	
            	_input.blur(function(e) {
            		elem.removeClass('jsFocus');
            		minimizeInputUISelect();
            	});
            	
            	// OnClick on div
            	elem.click(function(e) {
    				if (e.target === this){
    					_input.click();
    				}
        		});
            	elem.children('div').click(function(e) {
    				if (e.target === this){
    					_input.click();
    				}
        		});
            	elem.click(function(e) {
    				if (e.target === this){
    					_input.click();
    				}
        		});
            	
            	// reset input's width to user type
            	_input.keydown(function(e) {
            		resetInputWith(100);
            	});
            	
                ctrl.$formatters.push(function (modelValue) {

                	$timeout(function (){
                		minimizeInputUISelect();
                	}, 1);
                	$timeout(function (){
                		minimizeInputUISelect();
                	}, 5);
                	$timeout(function (){
                		minimizeInputUISelect();
                	}, 10);
                	
                	return modelValue;
                });
                
                
                /******************************************************************************************
                 * define all functions
                 */
                function minimizeInputUISelect(){
                	resetInputWith(2);
            	}
                
                function resetInputWith(minInputWith){
                	var thisDiv = elem.children('div:first');
            		var parentWidth = thisDiv.width();
            		var spanWidth = thisDiv.children('span').width();
            		var inputWidth = Math.floor(parentWidth) - Math.ceil(spanWidth);
            		
            		if(inputWidth <= minInputWith){
            			inputWidth = minInputWith;
            		}
            		
            		_input.attr('style','width:'+inputWidth+'px;');
                }
            }
        };
    }]);
})();
