(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'AuthorityConstant'];

    function stateConfig($stateProvider, AuthorityConstant) {
        $stateProvider
        .state('contact-detail.legal-tab', {
        	parent: 'contact-detail',
        	url: '/legal-tab',
     /*   	data: {
            	authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_ADMIN, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT]
            },*/
        	templateUrl: 'app/entities/contact/contact-detail/legal-tab/legal-tab.html',
            controller: 'ContactLegalTabController',
            controllerAs: 'vm',
            resolve: {
                entity: ['$stateParams', 'Contact', function($stateParams, Contact) {
                    return Contact.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        ;
    }

})();
