(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'AuthorityConstant'];

    function stateConfig($stateProvider, AuthorityConstant) {
        $stateProvider
        .state('opportunity', {
            parent: 'entity',
            url: '/opportunity?page&sort&search',
            data: {
                authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_ADMIN, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT],
                pageTitle: 'indochinaApp.opportunity.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/opportunity/opportunities.html',
                    controller: 'OpportunityController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'name,asc',
                    squash: true
                },
                search: {
                	// {"agent":-1, "status": 'active'}
                    value: 'U2FsdGVkX18UafJXhDLvxTCfwBH2u3nZVEh0g/UV77F8ImDcwHk12/wCycVMu0Z7',
                    squash: true
                },
                contactId: null,
                propertyId: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opportunity');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('unitType');
                    return $translate.refresh();
                }]
            }
        })
        .state('opportunity-detail', {
            parent: 'opportunity',
            url: '/{id}',
            data: {
            	authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_ADMIN, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT],
                pageTitle: 'indochinaApp.opportunity.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/opportunity/opportunity-detail.html',
                    controller: 'OpportunityDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opportunity');
                    $translatePartialLoader.addPart('unitType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', '$state', '$q', 'Opportunity', function($stateParams, $state, $q, Opportunity) {
                	var deferred = $q.defer();
                	
                	Opportunity.findOneByIdAndActivatedTrue({id : $stateParams.id}, function(result){
                		if(result == null || result == {} || result.id == null){
                			$state.go('accessdenied');
                		}
                		else{                			
                			deferred.resolve(result);
                		}
                    }, function(){
                    	$state.go('accessdenied');
                    });
                	
                	return deferred.promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'opportunity',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        /*.state('opportunity-detail.edit', {
            parent: 'opportunity-detail',
            url: '/detail/edit',
            data: {
                authorities: AuthorityConstant.ROLE_LIST
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/opportunity/opportunity-dialog.html',
                    controller: 'OpportunityDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Opportunity', function(Opportunity) {
                            return Opportunity.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })*/
        .state('opportunity.new', {
            parent: 'opportunity',
            url: '/new/',
            data: {
                authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT]
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/opportunity/opportunity-editor.html',
                    controller: 'OpportunityEditorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opportunity');
                    $translatePartialLoader.addPart('unitType');
                    return $translate.refresh();
                }],
                entity: function () {
                    return {
                        name: null,
                        transactionPrice: null,
                        currency: null,
                        stage: null,
                        closeDate: null,
                        coAgent1Commission: null,
                        coAgent2Commission: null,
                        propertyId: null,
                        contactId: null,
                        activityId: null,
                        id: null
                    };
                },
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'opportunity',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('opportunity.new-from-contact', {
            parent: 'opportunity',
            url: '/new-from-contact/{contactId}',
            data: {
                authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT]
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/opportunity/opportunity-editor.html',
                    controller: 'OpportunityEditorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opportunity');
                    $translatePartialLoader.addPart('unitType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', function($stateParams) {
                	return {
                        name: null,
                        transactionPrice: null,
                        currency: null,
                        stage: null,
                        closeDate: null,
                        coAgent1Commission: null,
                        coAgent2Commission: null,
                        propertyId: null,
                        contactId: $stateParams.contactId,
                        activityId: null,
                        id: null
                    };
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'opportunity',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('opportunity.new-from-contact-activity', {
            parent: 'opportunity',
            url: '/new-from-contact-activity/{contactId}/{activityId}/{propertyId}',
            data: {
                authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT]
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/opportunity/opportunity-editor.html',
                    controller: 'OpportunityEditorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opportunity');
                    $translatePartialLoader.addPart('unitType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', '$q', 'Opportunity', 'DateUtils', function($stateParams, $q, Opportunity, DateUtils) {
                	var deferred = $q.defer();
                	var contactId = $stateParams.contactId;
                	var propertyId = $stateParams.propertyId;
                	var entity = {
                			name: null,
                            transactionPrice: null,
                            currency: null,
                            stage: null,
                            closeDate: null,
                            propertyId: propertyId,
                            contactId: contactId,
                            activityId: $stateParams.activityId,
                            id: null	
                	}
                	
                	if(contactId == null || propertyId == null){
                		deferred.resolve(entity);
                		return deferred.promise;
                	}
                	
                	Opportunity.getAllOpenOpportunitiesByContactAndProperty({contactId: contactId,propertyId: propertyId}, function(result){
                		if(result != null && result.length != 0){
                			entity = result[0];
                			entity.closeDate = DateUtils.convertLocalDateFromServer(entity.closeDate);
                			entity.activityId = $stateParams.activityId;
                		}
                		
                		deferred.resolve(entity);
                    });
                	
                	return deferred.promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'opportunity',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('opportunity.new-from-property', {
            parent: 'opportunity',
            url: '/new-from-property/{propertyId}',
            data: {
                authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT]
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/opportunity/opportunity-editor.html',
                    controller: 'OpportunityEditorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opportunity');
                    $translatePartialLoader.addPart('unitType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', function($stateParams) {
                	return {
                        name: null,
                        transactionPrice: null,
                        currency: null,
                        stage: null,
                        closeDate: null,
                        coAgent1Commission: null,
                        coAgent2Commission: null,
                        propertyId: $stateParams.propertyId,
                        contactId: null,
                        activityId: null,
                        id: null
                    };
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'opportunity',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('opportunity.new-from-property-activity', {
            parent: 'opportunity',
            url: '/new-from-property-activity/{propertyId}/{activityId}/{contactId}',
            data: {
                authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT]
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/opportunity/opportunity-editor.html',
                    controller: 'OpportunityEditorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opportunity');
                    $translatePartialLoader.addPart('unitType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', '$q', 'Opportunity', 'DateUtils', function($stateParams, $q, Opportunity, DateUtils) {
                	var deferred = $q.defer();
                	var contactId = $stateParams.contactId;
                	var propertyId = $stateParams.propertyId;
                	var entity = {
                			name: null,
                            transactionPrice: null,
                            currency: null,
                            stage: null,
                            closeDate: null,
                            propertyId: propertyId,
                            contactId: contactId,
                            activityId: $stateParams.activityId,
                            id: null	
                	}
                	
                	if(contactId == null || propertyId == null){
                		deferred.resolve(entity);
                		return deferred.promise;
                	}
                	
                	Opportunity.getAllOpenOpportunitiesByContactAndProperty({contactId: contactId,propertyId: propertyId}, function(result){
                		if(result != null && result.length != 0){
                			entity = result[0];
                			entity.closeDate = DateUtils.convertLocalDateFromServer(entity.closeDate);
                			entity.activityId = $stateParams.activityId;
                		}
                		
                		deferred.resolve(entity);
                    });
                	
                	return deferred.promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'opportunity',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('opportunity.edit', {
            parent: 'opportunity',
            url: '/{id}/edit',
            data: {
            	authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT],
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/opportunity/opportunity-editor.html',
                    controller: 'OpportunityEditorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opportunity');
                    $translatePartialLoader.addPart('unitType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', '$state', '$q', 'Opportunity', function($stateParams, $state, $q, Opportunity) {
                	var deferred = $q.defer();
                	
                	Opportunity.findOneByIdAndActivatedTrue({id : $stateParams.id}, function(result){
                		if(result == null || result == {} || result.id == null){
                			$state.go('accessdenied');
                		}
                		else{                			
                			deferred.resolve(result);
                		}
                    }, function(){
                    	$state.go('accessdenied');
                    });
                	
                	return deferred.promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'opportunity',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
//        .state('opportunity.property-detail', {
//            parent: 'opportunity',
//            url: '/property-detail/{id}',
//            data: {
//            	authorities: AuthorityConstant.ROLE_LIST,
//                pageTitle: 'indochinaApp.property.detail.title'
//            },
//            params: {
//            	propertySearchObj: {}
//            },
//            views: {
//                'content@': {
//                    templateUrl: 'app/entities/property/property-detail.html',
//                    controller: 'PropertyDetailController',
//                    controllerAs: 'vm'
//                }
//            },
//            resolve: {
//            	translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
//                    $translatePartialLoader.addPart('property');
//                    $translatePartialLoader.addPart('transactionType');
//                    $translatePartialLoader.addPart('propertyType');
//                    $translatePartialLoader.addPart('availabilityStatus');
//                    $translatePartialLoader.addPart('paymentCycleEnum');
//                    $translatePartialLoader.addPart('leaseTermEnum');
//                    $translatePartialLoader.addPart('unitType');
//                    $translatePartialLoader.addPart('constructionStatus');
//                    return $translate.refresh();
//                }],
//                entity: ['$stateParams', 'PropertyService', function($stateParams, PropertyService) {
//                    return PropertyService.get({id : $stateParams.id}).$promise;
//                }],
//                previousState: ["$state", function ($state) {
//                    var currentStateData = {
//                        name: $state.current.name || 'opportunity-detail',
//                        params: $state.params,
//                        url: $state.href($state.current.name, $state.params),
//                        propertySearchObj: {page:1}
//                    };
//                    return currentStateData;
//                }],
//                propertySearch: function () {
//                    return {
//                    	searchQuery:null,
//                        city:null,
//                        country:null,
//                        minPrice:0,
//                        maxPrice:1000,
//                        transactionType:'',
//                        availability:'',
//                        minBedRoom:'',
//                        maxBedRoom:'',
//                        minBathRoom:'',
//                        maxBathRoom:'',
//                        minPriceSearch:null,
//                        maxPriceSearch:null,
//                        valueOfUsdExchangeRate:1,
//                        currencyCode: null,
//                        destinationCode: null,
//                        codeDescription:'$',
//                        stepRange: 100
//                    };
//                }
//            }
//        })
        .state('opportunity.delete', {
            parent: 'opportunity-detail',
            url: '/delete',
            data: {
                authorities: [AuthorityConstant.ROLE_SUPER_USER, AuthorityConstant.ROLE_MANAGER, AuthorityConstant.ROLE_AGENT]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', '$rootScope', function($stateParams, $state, $uibModal, $rootScope) {
                $uibModal.open({
                    templateUrl: 'app/entities/opportunity/opportunity-delete-dialog.html',
                    controller: 'OpportunityDeleteController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                        entity: ['Opportunity', function(Opportunity) {
                            return Opportunity.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('opportunity', null, { reload: 'opportunity' });
                }, function() {
                    // if session is time out, go to logout
                    if(!$rootScope.isSessionTimeOut) {
                        $state.go('^');
                    }
                });
            }]
        })
        ;
    }

})();
