(function() {
    'use strict';
    
    angular
    .module('indochinaApp')
    .directive('numbersOnly', numbersOnly);
    
    function numbersOnly() {
    	return {
            require: 'ngModel',
            link: function(scope, element, attrs, modelCtrl) {

                modelCtrl.$parsers.push(function (inputValue) {
                    var transformedInput = inputValue ? inputValue.toString().replace(/[^\d.-]/g,'') : null;

                    if (transformedInput!=inputValue) {
                        modelCtrl.$setViewValue(transformedInput);
                        modelCtrl.$render();
                    }

                    return transformedInput;
                });
            }
    	};
    };
})();
