(function () {
    'use strict';

    angular
        .module('indochinaApp')
        .factory('User', User);

    User.$inject = ['$resource', '$http'];

    function User ($resource, $http) {

    	var resourceUrl =  'api/users/';

        var service = $resource('api/users/:login', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data){                		
                		data = angular.fromJson(data);
                	}
                    return data;
                }
            },
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'delete':{ method:'DELETE'},
            'reactivate' : {
            	method : 'GET',
				url : resourceUrl + 'reactivate'
            },
            'getAgentListByManagerId' : {
				method : 'GET',
				url : resourceUrl + 'get-agent-list-by-managerId',
				isArray : true
			},
			'getAllCoAgents' : {
				method : 'GET',
				url : resourceUrl + 'getallcoagents',
				isArray : true
			},
			'getAllCoAgentsDelete' : {
				method : 'GET',
				url : resourceUrl + 'getAllCoAgentsDelete',
				isArray : true
			},
			'getAllManagerByAgent': {
				url : resourceUrl + 'get-manager-list-by-agent',
				method : 'GET',
				isArray : true
			},
			'getCoAgentByName': {
            	url: 'api/users/get-agent-list-by-name',
            	method: 'GET',
            	isArray: true
            },
            'getAgentListReport': {
                url: 'api/users/get-agent-list',
                method: 'GET',
                isArray: true
            },
            'getAllAgentManagerAdminSuperUserList': {
                url: 'api/users/get-all-agent-manager-admin-super-user-list',
                method: 'GET',
                isArray: true
            }
        });

        service.getUserById = getUserById;
        service.getCurrentUser = getCurrentUser;

        // get list user where role is [ROLE_SUPER_USER, ROLE_AGENT, ROLE_Manager]
        service.getAgentList = getAgentList;

        return service;

        function getUserById(id){
        	return $http.get('api/users/get-user-by-id',{params:{id:id}});
        }

        function getCurrentUser(){
        	return $http.get('api/users/current-login');
        }

        function getAgentList() {
        	return $http.get('api/users/get-agent-list');
        }

    }
})();
