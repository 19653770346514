(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('LeadSourceConfirmDialogController',LeadSourceConfirmDialogController);

    LeadSourceConfirmDialogController.$inject = ['$state', '$uibModalInstance', 'entity', 'translateKeys', '$rootScope'];

    function LeadSourceConfirmDialogController($state, $uibModalInstance, entity, translateKeys, $rootScope) {
        var vm = this;

        vm.property = entity;
        vm.translateKeys = translateKeys.keys;

        vm.clear = clear;
        vm.confirm = confirm;

        // session is time out so we must close all modals
        $rootScope.$on('modal:close', function(event, result) {
            clear();
        });

        function clear () {
            $rootScope.$broadcast('app:resetTimeSession', true);
            $uibModalInstance.dismiss('cancel');
        }

        function confirm () {
            $rootScope.$broadcast('app:resetTimeSession', true);
        	$uibModalInstance.close(true);
        }
    }
})();
