(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', '$state', 'User'];

    function HomeController ($scope, Principal, $state, User) {
        var vm = this;

        redirect();
        
        function redirect(){
        	if(Principal.isAuthenticated()){
        		Principal.identity().then(function(account) {
                    if(account !== null){
                    	switch(account.authorities[0]){
                    		case 'ROLE_SUPER_USER':
                    		case 'ROLE_MANAGER':
                    		case 'ROLE_AGENT':	
                    			$state.go('dashboard.home');
                    			break;
                    		case 'ROLE_CO_AGENT':	
                    			$state.go('property');
                    			break;
                    		case 'ROLE_ADMIN':	
                    			$state.go('user-management');
                    			break;
                			default:
//                				$state.go('home');
                    	}
                    }
                });
    		}
        	else{
        		$state.go('login');
        	}
        }
    }
})();
