(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('DevelopmentLegalTabController', Controller);

    Controller.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'previousState', 'entity', 'DevelopmentLegal', 'ParseLinks', 'AlertService',
                                  '$timeout', '$translate', '$uibModal'];

    function Controller($scope, $state, $rootScope, $stateParams, previousState, entity, DevelopmentLegal, ParseLinks, AlertService,
    							$timeout, $translate, $uibModal) {
        var vm = this;
        vm.transition = transition;
        vm.development = entity;

        if($state.isEdit) {
        	vm.isEdit = $state.isEdit;
        }

        $rootScope.scrollTop();
        $rootScope.$broadcast('development:activeTab', 'LEGAL');
        $rootScope.$on('development:changeMode', function(event, result) {
        	vm.isEdit = result;
        });
        vm.predicate = 'createdDate';
        vm.reverse = 0;
        vm.page = 1;
        vm.itemsPerPage = 20;

        vm.cancel = cancel;

        vm.loadPage = loadPage;
        vm.deleteEntity = deleteEntity;
        vm.downloadFile = $rootScope.downloadFile;
        vm.createNewLegal = createNewLegal;
        vm.editLegal = editLegal;
        vm.viewPhoto = viewPhoto;
        vm.disablePreview = disablePreview;
        loadAll();

        function loadAll () {
            DevelopmentLegal.query({
            	developmentId: vm.development.id,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [];

                if(vm.predicate == 'createdBy'){
                	result.push('u.firstName,' + (vm.reverse ? 'asc' : 'desc'));
                	result.push('u.lastName,' + (vm.reverse ? 'asc' : 'desc'));
                }
                else if(vm.predicate == 'fileName'){
                	result.push('f.filename,' + (vm.reverse ? 'asc' : 'desc'));
                }
                else if(vm.predicate == 'createdDate'){
                	result.push('f.created,' + (vm.reverse ? 'asc' : 'desc'));
                }
                else if(vm.predicate == 'documentType'){
                	result.push('documentType,' + (vm.reverse ? 'asc' : 'desc'));
                }

                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.developmentLegals = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
        }
        
        function transition() {
        	loadAll();
        }


        function deleteEntity(developmentLegalId){
        	$uibModal.open({
                templateUrl: 'app/components/confirmation-dialog/delete/confirm-dialog.html',
                controller: 'DeleteConfirmDialogController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass : 'absolute-center',
                resolve: {
                    entity: function(){
                    	return null;
                    },
                    translateKeys: function(){
                    	return {
                    		keys: 'indochinaApp.development.confirmDeleteLegal'
                    	};
                    }
                }
            }).result.then(function() {
            	DevelopmentLegal.delete({id: developmentLegalId},function () {
            		AlertService.success($translate.instant('indochinaApp.development.deletedDocumentSuccessfullyMsg'));
            		loadAll();
            	});
            }, function() {
//                $state.go('^');
            });
        }

        function cancel() {
        	$state.isEdit = false;
    		$rootScope.$broadcast('development:changeMode', false);
    		$state.reload($state.current.name);
        }
        
        function createNewLegal() {
            if(!vm.isFromDevelopment) {
                $uibModal.open({
                    templateUrl: 'app/entities/development/development-detail/legal-tab/legal-tab-dialog.html',
                    controller: 'DevelopmentLegalDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        development: function () {
                            return vm.development;
                        },
                        entity: createEntityDevelopment ()
                    }
                }).result.then(function() {
                    $state.go('development-detail.legal-tab', null, { reload: 'development-detail.legal-tab' });
                }, function() {

                });
            }
        }
        
        function editLegal(developmentLegal) {
            $uibModal.open({
            	templateUrl: 'app/entities/development/development-detail/legal-tab/legal-tab-dialog.html',
            	controller: 'DevelopmentLegalDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: { 
                	development: function () {
                        return vm.development;
                    },
                    entity: function () {
                    	return DevelopmentLegal.get({id : developmentLegal.id}).$promise;
                    }
                }
            }).result.then(function() {
            	 $state.go('development-detail.legal-tab', null, { reload: 'development-detail.legal-tab' });
            }, function() {

            });
        }

        
        function createEntityDevelopment(){
        	 return {
                 documentType: null,
                 documentName: null,
                 documentNumber: null,
                 issueDate: null,
                 expiryDate: null,
                 fileId: null,
                 id: null
             };
        }
        
        function viewPhoto(photo) {
        	if (photo) {
        		$uibModal.open({
                    templateUrl: 'app/entities/photo/partial/photo-view-dialog.html',
                    controller: 'PhotoViewControllerRe',
                    controllerAs: 'vm',
                    windowClass : 'absolute-center',
                    resolve: {
                    	photoView: function() {
                            return photo;
                        }
                    }
                }).result.then(function() {
                	// to do something
                }, function() {
                	// to do something
                });
        	}
        }
        
        function disablePreview(file){
        	if(!(/\.(jpe?g|png|gif)$/i.test(file.filename))){
        		return true;
        	}
        	return false;
        }
        
    }
})();
