	/*
	 * Automatic tax code formatting in input field
	*/
(function() {
    'use strict';
    angular
    	.module('indochinaApp')
    	.directive('taxcodeinput', taxCodeInput)
    	.filter('taxCode', function () {
    		return function (taxCode) {
    	        if (!taxCode) { return ''; }
    	
    	        var value = taxCode.toString().trim().replace(/^\+/, '');
    	
    	        if (value.match(/[^0-9]/)) {
    	            return taxCode;
    	        }
    	        
    	        var taxCodeP1, number;
    	        
    	        switch (value.length) {
		            case 1:
		            case 2:
		            case 3:
		            	taxCodeP1 = value;
		                break;
		
		            default:
		            	taxCodeP1 = value.slice(0, 3);
		                number = value.slice(3);
    	        }
    	        
    	        if(number) {
    	        	if(number.length > 5) {
    	                number = number.slice(0, 2) + '-' + number.slice(2,6);
    	            } else if(number.length > 2) {
    	                number = number.slice(0, 2) + '-' + number.slice(2);
    	            } else{
    	                number = number;
    	            }
    	
    	            return (taxCodeP1 + "-" + number).trim();
    	        } else {
    	        	return taxCodeP1;
    	        }
    	    };
    	});
    
    taxCodeInput.$inject = ['$filter', '$browser', '$timeout'];
    
    function taxCodeInput($filter, $browser, $timeout) {
    	var directive = {
            restrict: 'A',
            scope: false,
            require: 'ngModel',
            link: linkFunc
        };
    	return directive;
    	
    	function linkFunc($scope, $element, $attrs, ngModelCtrl) {
    		var listener = function() {
                var value = $element.val().replace(/[^0-9]/g, '');	                
                $element.val($filter('taxCode')(value, false));
            };

            // This runs when we update the text field
            ngModelCtrl.$parsers.push(function(viewValue) {	            	
            	var val = viewValue.replace(/[^0-9]/g, '').slice(0,10);
            	ngModelCtrl.$setViewValue(val);	            	
                return val;
            });

            // This runs when the model gets updated on the scope directly and keeps our view in sync
            ngModelCtrl.$render = function() {
                $element.val($filter('taxCode')(ngModelCtrl.$viewValue, false));
            };

            $element.bind('change', listener);
            $element.bind('keydown', function(event) {
                var key = event.keyCode;
                // If the keys include the CTRL, SHIFT, ALT, or META keys, or the arrow keys, do nothing.
                // This lets us support copy and paste too
                if (key == 91 || (15 < key && key < 19) || (37 <= key && key <= 40)){
                    return;
                }
                $browser.defer(listener); // Have to do this or changes don't get picked up properly
            });

            $element.bind('paste cut', function() {
                $browser.defer(listener);
            });
    	}
    }
})();