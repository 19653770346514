(function() {
    'use strict';
    
    angular
    .module('indochinaApp')
    .directive('commission', ['$filter', function ($filter) {
        return {
            require: '?ngModel',
            link: function (scope, elem, attrs, ctrl) {
            	var min = attrs.commissionMin || 0;
            	var max = attrs.commissionMax || 100;
            	var scale = attrs.commissionScale || 2;
            	var required = attrs.commissionRequired || false;
            	
                if (!ctrl) return;

//				elem.on("keyup", function(e) {
//					console.log(e.keyCode);
//					console.log(elem[0].value);
//				});
                
//                elem.on("keypress", function(e) {
//                	console.log(e.keyCode);
//                	console.log(e.which);
//                });
                
                elem.on("keydown", function(e) {
//                	console.log(e.keyCode);
//                	console.log(e.which);
                	
                	// don't accept [a-z]
                	if(e.which >= 65 && e.which <= 90){
                		e.preventDefault();
                	}
                	
                	var caretPos = elem[0].selectionStart;
                	var length = elem[0].value.toString().length;
                	
                	var plainValue = null;
                	if(elem[0].value != null){
                		plainValue = elem[0].value.replace(/[^\d.]/g, '');
                	}
                	
                	var scaleValue = 0;
                	var intValue = plainValue;
                	var dotPosition = plainValue.indexOf('.');
                	
                	if(dotPosition != -1){
                		scaleValue = plainValue.substr(dotPosition + 1);
                		intValue = plainValue.substr(0, dotPosition);
                	}
                	
                	if(isAcceptKey(e.keyCode) && !isSelecting(elem)){
                		if(scaleValue.length >= scale && caretPos > dotPosition){
                    		e.preventDefault();
                    	}
                    	
                    	if(intValue >= max){
                   			e.preventDefault();
                    	}                		
                	}
                	
                	// call backspace when caret position is at the end
                	if(length > 0 && length == caretPos){
                		setCaretPosition(elem[0], caretPos - 1);
                	}
                });

                ctrl.$formatters.unshift(function (modelValue) {
                	ctrl.$setValidity('required', true);
                	ctrl.$setValidity('commissionMax', true);
                	
                	if(modelValue == null || modelValue == ''){
                		if(required){
                    		ctrl.$setValidity('required', false);
                    	}
                		
                		return modelValue;
                	}
                	else{
                		if(modelValue > max){
                    		ctrl.$setValidity('commissionMax', false);
                    	}
                		
                		return modelValue + '%';
                	}
                });

                ctrl.$parsers.unshift(function (viewValue) {
                	var plainValue = null;
                	if(viewValue != null){
                		plainValue = viewValue.replace(/[^\d.]/g, '');
                	}
                	
                	ctrl.$setValidity('required', true);
                	ctrl.$setValidity('commissionMin', true);
                	ctrl.$setValidity('commissionMax', true);
                	ctrl.$setValidity('commissionPattern', true);
                	
                	if(plainValue == null || plainValue == ''){
                		elem.val(plainValue);
                		
                		if(required){
                    		ctrl.$setValidity('required', false);
                    	}
                	}
                	else{
//                		plainValue = parseFloat(plainValue);
                		
                		ctrl.$setViewValue(plainValue + '%');
                		ctrl.$render();
                		
                		var lastCaretPos = elem[0].selectionStart;
                		var lastLength = plainValue.toString().length;
                		
                		if(lastLength == 1){
                			setCaretPosition(elem[0], lastLength);
                		}
                		
                		if(!(/^\d{0,3}(.\d{0,2})?$/.test(plainValue))){
                			ctrl.$setValidity('commissionPattern', false);
                		}
                		
                		if(plainValue < min){
                    		ctrl.$setValidity('commissionMin', false);
                    	}
                    	if(plainValue > max){
                    		ctrl.$setValidity('commissionMax', false);
                    	}
                	}
                	return plainValue;	
                });
                
                function setCaretPosition(elem, caretPos) {
                    if (elem !== null) {
                    	if (elem.createTextRange) {
                            var range = elem.createTextRange();
                            range.move('character', caretPos);
                            range.select();
                        } else {
                            if (elem.setSelectionRange) {
                                elem.focus();
                                elem.setSelectionRange(caretPos, caretPos);
                            } else
                                elem.focus();
                        }
                    }
                }
                
                function isAcceptKey(keyCode){
                	// . key code
                	if(keyCode == 190 || keyCode == 110){
                		return true;
                	}
                	
                	// [0-9] key code
                	if((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)){
                		return true;
                	}
                	
                	return false;
                }
                
                function isSelecting(elem){
                	return elem[0].selectionStart != elem[0].selectionEnd;
                }
            }
        };
    }]);
})();
