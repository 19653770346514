(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('DevelopmentPublishTabController', Controller);

    Controller.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'previousState', 'entity', 'PublishService', 'Principal', '$uibModal', 'DevelopmentService', 'User', 'publishSiteResource'];

    function Controller($scope, $state, $rootScope, $stateParams, previousState, entity, PublishService, Principal, $uibModal, DevelopmentService, User, publishSiteResource) {
        var vm = this;

        // get mode edit or view
        if($state.isEdit) {
            vm.isEdit = $state.isEdit;
        }

        // get development entity
        vm.development = entity;

        // action define
        vm.save = save;
        vm.cancel = cancel;

        // define for checkbox
        vm.selectedSites = [];

        // checkbox function handle
        vm.publishSiteClick = publishSiteClick;
        
        var login = null;
        Principal.identity().then(function(account) {
        	login = account;
        	if(account.authorities[0] === 'ROLE_MANAGER') {
        		User.getAgentListByManagerId({id: account.id}, function(result) {
        			vm.allAgentOfManager = result;
                });
        	}
        });
        vm.isCheckPermission = isCheckPermission;
        
        $scope.$on('$stateChangeStart', onStateChangeStart);

        $rootScope.$broadcast('development:activeTab', 'PUBLISH');

        $rootScope.$on('development:changeMode', function(event, result) {
            vm.isEdit = result;
        });

        init();

        function init () {
            // load site resource
        	vm.sites = [];
            vm.sites.push(publishSiteResource[0]);

            // load value for nearby facility checkbox
            if(vm.development.sites.length > 0) {
                angular.forEach(vm.development.sites, function(value, key) {
                    vm.selectedSites[value.id] = true;
                });
            }
        }

        function save () {

            $scope.editForm.$dirty = false;

            vm.isSaving = true;

            DevelopmentService.update(vm.development, onSaveSuccess, onSaveError);

        }

        function onSaveSuccess (result) {
        	$scope.editForm.$setPristine();
            // change variable isSaving
            vm.isSaving = false;

            // update entity
            $scope.$emit('indochinaApp:developmentUpdate', result);

            // change mode edit in state
            $state.isEdit = false;

            // change mode on current state
            vm.isEdit = false;

            // broadcast event to appear button edit
            $rootScope.$broadcast('development:changeMode', false);
        }

        function onSaveError (response) {
            vm.isSaving = false;
        }

        function cancel () {

            if($scope.editForm.$dirty) {

                // if have any change we must reload page to reset entity
                $state.reload($state.current.name);

                return;
            }

            // change mode edit in state
            $state.isEdit = false;

            // change mode on current state
            vm.isEdit = false;

            // broadcast event to appear button edit
            $rootScope.$broadcast('development:changeMode', false);

            //scroll to top page
            $rootScope.scrollTop();
        }

        // function property type click

        function publishSiteClick (value, site) {
            if(value === true) {
                vm.development.sites.push(site);
            } else {
                var i = 0;
                angular.forEach(vm.development.sites, function(value, key) {
                    if(site.id === value.id) {
                        vm.development.sites.splice(i, 1);
                    }
                    i++;
                });
            }
        }

        function onStateChangeStart(event, toState, toParams, fromState, fromParams) {
            if($scope.editForm.$dirty && !$rootScope.isSessionTimeOut){
                event.preventDefault();

                $uibModal.open({
                    templateUrl: 'app/components/confirmation-dialog/leave/confirm-dialog.html',
                    controller: 'LeaveConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                        entity: function(){
                            return null;
                        },
                        translateKeys: function(){
                            return {
                                keys: 'indochinaApp.property.confirmLeavePage'
                            };
                        }
                    }
                }).result.then(function() {

                    // change mode edit in state
                    $state.isEdit = false;

                    // avoid loop onStateChange function
                    if($scope.editForm) {
                        $scope.editForm.$dirty = false;
                    }

                    if(toState.name == 'development-detail.publish-tab'){

                        // reload parent to reset entity
                        $state.reload('development-detail');

                        // broadcast event to appear button edit
                        $rootScope.$broadcast('development:changeMode', false);
                    }
                    else{
                        //$state.isEdit = true;
                        $state.go(toState, toParams, { reload: true });
                    }

                }, function() {
//                    $state.go('^');
                });
            }
        }
        
        function isCheckPermission() {

        	if(login.authorities[0] === 'ROLE_ADMIN' || login.authorities[0] === 'ROLE_SUPER_USER') {
        		return false;
        	}
        	if(vm.allAgentOfManager) {
        		for(var i = 0; i < vm.allAgentOfManager.length; i++) {
        			if(vm.development.agentId && vm.allAgentOfManager[i].id === vm.development.agentId) {
        				return false;
        			}
        		}
        	}

        	if(vm.development.agentId && login.id === vm.development.agentId) {
        		return false;
        	}

        	return true;
        }
    }
})();
