(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('DevelopmentDetailTabController', Controller);

    Controller.$inject = ['$scope', '$state', '$rootScope', '$translate', '$stateParams', 'entity', 'DevelopmentService', 'propertyTypesResource', 'propertyLocationsResource', 'internalRoomsResource', 'outsideAreasResource', 'facilitiesOffersResource', 'parkingFacilitiesResource',
                        'nearbyFacilitiesResource', 'propertyTransactionsResource', 'constructionStatusResource', 'reasonForPurchaseResource', 'accessRoadResource', 'CurrencyConstant', 'PropertyConstant', 'languagesResource',
                        'Contact', 'agentResource', 'MeasurementConversionService', 'countryResource', 'provinceResource', 'cityResource', 'districtHCMResource', 'districtHANResource', 'districtDNGResource', '$uibModal', 'login',
                        'AlertService', 'AuthorityConstant', 'materialsResource', 'indochinaPropertyContact'];

    function Controller($scope, $state, $rootScope, $translate, $stateParams, entity, DevelopmentService, propertyTypesResource, propertyLocationsResource, internalRoomsResource, outsideAreasResource, facilitiesOffersResource, parkingFacilitiesResource,
        nearbyFacilitiesResource, propertyTransactionsResource, constructionStatusResource, reasonForPurchaseResource, accessRoadResource, CurrencyConstant, PropertyConstant, languagesResource,
        Contact, agentResource, MCService, countryResource, provinceResource, cityResource, districtHCMResource, districtHANResource, districtDNGResource, $uibModal, login,
        AlertService, AuthorityConstant, materialsResource, indochinaPropertyContact) {

        var vm = this;

        // get development entity
        vm.development = entity;

        // get mode edit or view
        if(vm.development.id === null) {
            vm.isEdit = true;
        } else if($state.isEdit) {
            vm.isEdit = $state.isEdit;
        }
        
        vm.indochinaPropertyContact = indochinaPropertyContact;
        vm.netSizeNotLessThanGrossSizeInTotalFields = false;

        // define for checkbox
        vm.propertyTypesSelect = [];
        vm.selectedPropertyLocations = [];
        vm.selectedInternalRooms = [];
        vm.selectedOutsideAreas = [];
        vm.selectedFacilitiesOffereds = [];
        vm.selectedAccessRoads = [];
        vm.selectedParkingFacilities = [];
        vm.selectedNearbyFacilities = [];
        vm.selectReasonForPurchase = [];
        vm.selectedMaterials = [];

        // action define
        vm.save = save;
        vm.cancel = cancel;

        // checkbox function handle
        vm.propertyTypeClick = propertyTypeClick;
        vm.propertyLocationClick = propertyLocationClick;
        vm.accessRoadClick = accessRoadClick;
        vm.internalRoomClick = internalRoomClick;
        vm.nearbyFacilityClick = nearbyFacilityClick;
        vm.outsideAreaClick = outsideAreaClick;
        vm.parkingFacilityClick = parkingFacilityClick;
        vm.facilitiesOfferedClick = facilitiesOfferedClick;
        vm.reasonForPurchaseClick = reasonForPurchaseClick;
        vm.materialsClick = materialsClick;

        // other languages function handle
        vm.hasRowEmpty = hasRowEmpty;
        vm.isDefaultRow = isDefaultRow;
        vm.removeOtherLanguage = removeOtherLanguage;
        vm.addOtherLanguage = addOtherLanguage;

        // contact function handle
        vm.getContactByTradeName = getContactByTradeName;
        vm.getContactByName = getContactByName;
        vm.onSelectCompany = onSelectCompany;
        vm.onSelectDeveloper = onSelectDeveloper;
        vm.onSelectOwner = onSelectOwner;

        // agent function handle
        vm.onSelectAgent = onSelectAgent;

        // other variable
        vm.getLanguages = getLanguages;
        vm.getClassColumn = getClassColumn;
        vm.onSelectCountry = onSelectCountry;
        vm.onSelectCity = onSelectCity;
        vm.onKeyupCity = onKeyupCity;
        
        // validate commission range
        vm.commissionValidate = commissionValidate;
        vm.propertyTypeDetailValidate = propertyTypeDetailValidate;
        vm.bathRoomNumberValidate = bathRoomNumberValidate;
        
        vm.grossAndNetSizeFromValidate = grossAndNetSizeFromValidate;
        vm.grossAndNetSizeToValidate = grossAndNetSizeToValidate;

        // variable for detect all amenities edit
        var originalPropertyLocationsSelected = [];
        var originalInternalRoomsSelected = [];
        var originalOutsideAreasSelected = [];
        var originalFacilitiesOfferedsSelected = [];
        var originalAccessRoadsSelected = [];
        var originalNearbyFacilitiesSelected = [];
        var originalMaterialsSelected = [];
        var originalReasonForPurchasesSelected = [];
        var originalParkingFacilitiesSelected = [];


        $scope.$on('$stateChangeStart', onStateChangeStart);

        $rootScope.$broadcast('development:activeTab', 'DETAIL');

        $rootScope.$on('development:changeMode', function(event, result) {
            vm.isEdit = result;
        });

        init ();

        function init() {

            // init list currency code
            vm.currenciesDes = CurrencyConstant.DES;

            // init property location
            vm.propertyLocations = propertyLocationsResource;

            // init internal rooms
            vm.internalRooms = internalRoomsResource;

            // init outside area
            vm.outsideAreas = outsideAreasResource;

            // init facilities offers
            vm.facilitiesOffereds = facilitiesOffersResource;

            // init parking facilities
            vm.parkingFacilities = parkingFacilitiesResource;

            // init nearby facilities
            vm.nearbyFacilities = nearbyFacilitiesResource;

            // init property types
            vm.propertyTypes = propertyTypesResource;

            // init property transactions
            vm.propertyTransactions = propertyTransactionsResource;

            // init construction status
            vm.constructionStatuses = constructionStatusResource;

            // init reason for purchase
            vm.reasonForPurchases = reasonForPurchaseResource;

            vm.materials = materialsResource;

            // init access road purchase
            vm.accessRoads = accessRoadResource;

            // init currency list
            vm.currencyUnits = CurrencyConstant.LIST;

            // init property size unit
            vm.propertyUnitTypes = PropertyConstant.propertyUnitType.LIST;

            // init lot size unit
            vm.lotUnitTypes = PropertyConstant.lotUnitType.LIST;

            // init term list
            vm.feeTerms = PropertyConstant.feeTerm.LIST;

            // init road unit type
            vm.roadUnitTypes = PropertyConstant.roadUnitType.LIST;

            // init languages
            vm.languages = languagesResource;

            //load user agent list
            vm.listAgents = agentResource;

            // init district
            initDistrict ();

            // init city
            initCity ();

            // init province
            initProvince ();

            // get country list
            vm.countries = countryResource;

            // auto populate if create new development
            if(!vm.development.id
            	&& (login.authorities[0] === AuthorityConstant.ROLE_AGENT || login.authorities[0] === AuthorityConstant.ROLE_MANAGER || login.authorities[0] === AuthorityConstant.ROLE_SUPER_USER)) {

                vm.development.agentId = login.id;
                vm.development.agentName = login.firstName + ' ' + login.lastName;
            }

            // load value for property type checkbox
            if(vm.development.propertyTypes && vm.development.propertyTypes.length > 0) {
                angular.forEach(vm.development.propertyTypes, function(value, key) {
                    vm.propertyTypesSelect[value.propertyType] = true;
                    if(value.bedRoomFrom === 0) {
                        value.bedRoomFromDisplay = 'STU';
                    } else {
                        value.bedRoomFromDisplay = value.bedRoomFrom;
                    }
                    if(value.bedRoomTo === 0) {
                        value.bedRoomToDisplay = 'STU';
                    } else {
                        value.bedRoomToDisplay = value.bedRoomTo;
                    }

                    // set size converter for size of unit
                    if(value.sizeUnitFrom || value.sizeUnitTo){
                        value.sizeConverter = MCService.init(value.sizeUnit);
                    }

                    // for old data
                    if(!value.grossSizeUnit) {
                        value.grossSizeUnit = vm.lotUnitTypes[0];
                    }

                    // set size converter for gross size
                    if(value.grossSizeFrom || value.grossSizeTo){
                        value.grossSizeConverter = MCService.init(value.grossSizeUnit);
                    }

                    // set size converter for lot size
                    if(value.lotSizeFrom || value.lotSizeTo){
                        value.sizeConverterLotSize = MCService.init(value.lotSizeUnit);
                    }

                    // disable POA
                    if(vm.development.id != null && login.authorities[0] !== 'ROLE_SUPER_USER' && vm.development.agentId !== login.id){
                    	value.isDisablePOA = true;
                    	if(value.poa){
                    		value.isDisablePrice = true;
                    	}
                    }
                });
            }

            // this block code for device row for property type
            vm.propertyTypeRow = [];
            var i;
            for(i = 1; i < Math.round(vm.development.propertyTypes.length / 2) + 1; i++) {
                vm.propertyTypeRow.push(i);
            }

            // load value for property location checkbox
            if(vm.development.propertyLocations.length > 0) {

                originalPropertyLocationsSelected =  angular.copy(vm.development.propertyLocations); // set for detect edit or not

                angular.forEach(vm.development.propertyLocations, function(value, key) {
                    vm.selectedPropertyLocations[value.id] = true;
                });
            }

            // load value for internal room checkbox
            if(vm.development.internalRooms.length > 0) {

                originalInternalRoomsSelected = angular.copy(vm.development.internalRooms); // set for detect edit or not

                angular.forEach(vm.development.internalRooms, function(value, key) {
                    vm.selectedInternalRooms[value.id] = true;
                });
            }

            // load value for nearby facility checkbox
            if(vm.development.nearbyFacilities.length > 0) {

                originalNearbyFacilitiesSelected = angular.copy(vm.development.nearbyFacilities); // set for detect edit or not

                angular.forEach(vm.development.nearbyFacilities, function(value, key) {
                    vm.selectedNearbyFacilities[value.id] = true;
                });
            }

            // load value for outside area checkbox
            if(vm.development.outsideAreas.length > 0) {

                originalOutsideAreasSelected =  angular.copy(vm.development.outsideAreas); // set for detect edit or not

                angular.forEach(vm.development.outsideAreas, function(value, key) {
                    vm.selectedOutsideAreas[value.id] = true;
                });
            }

            // load value for parking facility checkbox
            if(vm.development.parkingFacilities.length > 0) {

                originalParkingFacilitiesSelected = angular.copy(vm.development.parkingFacilities); // set for detect edit or not

                angular.forEach(vm.development.parkingFacilities, function(value, key) {
                    vm.selectedParkingFacilities[value.id] = true;
                });
            }

            // load value for facility offer checkbox
            if(vm.development.facilitiesOffereds.length > 0) {

                originalFacilitiesOfferedsSelected = angular.copy(vm.development.facilitiesOffereds); // set for detect edit or not

                angular.forEach(vm.development.facilitiesOffereds, function(value, key) {
                    vm.selectedFacilitiesOffereds[value.id] = true;
                });
            }

            // load value for reason for purchase checkbox
            if(vm.development.reasonForPurchases.length > 0) {

                originalReasonForPurchasesSelected = angular.copy(vm.development.reasonForPurchases); // set for detect edit or not

                angular.forEach(vm.development.reasonForPurchases, function(value, key) {
                    vm.selectReasonForPurchase[value.id] = true;
                });
            }

            // load value for material checkbox
            if(vm.development.materials != null && vm.development.materials.length > 0) {

                originalMaterialsSelected = angular.copy(vm.development.materials); // set for detect edit or not

                angular.forEach(vm.development.materials, function(value, key) {
                	vm.selectedMaterials[value.id] = true;
                });
            }

            // load value for access road checkbox
            if(vm.development.accessRoads.length > 0) {

                originalAccessRoadsSelected = angular.copy(vm.development.accessRoads); // set for detect edit or not

                angular.forEach(vm.development.accessRoads, function(value, key) {
                    vm.selectedAccessRoads[value.id] = true;
                });
            }

            // create default languages
            if(vm.development.otherLanguages && vm.development.otherLanguages.length === 0) {
                addDefaultOtherLanguage ();
            }

            // converter development size
            if(vm.development.developmentSize != null){
                vm.developmentSizeConverter = MCService.init(vm.development.developmentUnit);
            }

            // converter access road
            if(vm.development.accessRoadWidth != null){
                vm.roadConverter = MCService.init(vm.development.accessRoadWidthUnit);
            }

            // other internal room checkbox
            if(vm.development.internalRoomOther) {
                vm.internalRoomOtherCheckbox = true;
            }

            if($state.current.name === 'development.duplicate.detail-tab') {
                vm.isDuplicate = true;
            }

            if(!vm.development.id && $state.current.name !== 'development.duplicate.detail-tab') {
                vm.development.developmentUnit = vm.lotUnitTypes[0];
                vm.development.accessRoadWidthUnit = vm.roadUnitTypes[0];
                vm.development.feeManagementCurrency = vm.currencyUnits[0];
                vm.development.feeParkingCurrency = vm.currencyUnits[0];
                vm.development.feeGymCurrency = vm.currencyUnits[0];
                vm.development.feeManagementTerm = vm.feeTerms[0];
                vm.development.feeParkingTerm = vm.feeTerms[0];
                vm.development.feeGymTerm = vm.feeTerms[0];
            }


        }

        function convertBedroomBeforeSave() {
            // load value for property type checkbox
            if(vm.development.propertyTypes && vm.development.propertyTypes.length > 0) {
                angular.forEach(vm.development.propertyTypes, function(value, key) {
                    if(value.bedRoomFromDisplay === 'STU') {
                        value.bedRoomFrom = 0;
                    } else {
                        value.bedRoomFrom = value.bedRoomFromDisplay;
                    }
                    if(value.bedRoomToDisplay === 'STU') {
                        value.bedRoomTo = 0
                    } else {
                        value.bedRoomTo = value.bedRoomToDisplay;
                    }

                    // convert price if currency # USD
                    if(value.listPriceFrom && value.currency !== 'USD') {
                        value.listPriceFrom = Math.round(value.listPriceFrom);
                    }

                    if(value.listPriceTo && value.currency !== 'USD') {
                        value.listPriceTo = Math.round(value.listPriceTo);
                    }
                });
            }
        }

        function save () {

            // convert fees price if currency # USD
            if(vm.development.feeManagementCurrency !== 'USD') {
                vm.development.feeManagementNumber = Math.round(vm.development.feeManagementNumber);
            }
            if(vm.development.feeParkingCurrency !== 'USD') {
                vm.development.feeParkingNumber = Math.round(vm.development.feeParkingNumber);
            }
            if(vm.development.feeGymCurrency !== 'USD') {
                vm.development.feeGymNumber =  Math.round(vm.development.feeGymNumber);
            }

            // checking value of other check box internal room
            if(!vm.internalRoomOtherCheckbox) {
                vm.development.internalRoomOther = null;
            }

            convertBedroomBeforeSave ();

            vm.isSaving = true;

            // set user modify
            vm.development.modifiedBy = login.id;

            // clean bad data
            vm.development.otherLanguages = _.filter(vm.development.otherLanguages, function (otherLanguage) {
                return otherLanguage.languageCode;
            });

            if (vm.development.id !== null) {

                // detect all eminities to update property belong to this development
                detectAllEminitiesEditOrNot ();


                // set and modify date
                vm.development.modified = new Date();
                DevelopmentService.update(vm.development, onSaveSuccess, onSaveError);
            } else {
                vm.isNew = true;
                // set create date and modify date
                vm.development.created = new Date();
                vm.development.modified = new Date();
                DevelopmentService.save(vm.development, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {

            $scope.editForm.$dirty = false;

            // change variable isSaving
            vm.isSaving = false;

            // update entity
            $scope.$emit('indochinaApp:developmentUpdate', result);

            // change mode edit in state
            $state.isEdit = false;

            // change mode on current state
            vm.isEdit = false;

            // broadcast event to appear button edit
            $rootScope.$broadcast('development:changeMode', false);

            // go to detail state if create contact complete
            $state.go('development-detail.photo-video-tab', {id: result.id},{reload:true});

        }

        function onSaveError (error) {
            vm.isSaving = false;
            if(error.status == 400) {
                if(error.data === '') { //
                    AlertService.error($translate.instant('error.duplicateDevelopment', {}));
                }
            }
        }

        function detectAllEminitiesEditOrNot () {
            vm.development.isEditPropertyLocation = isEditedAmenityItem(originalPropertyLocationsSelected, vm.development.propertyLocations);
            vm.development.isEditInternalRoom = isEditedAmenityItem(originalInternalRoomsSelected, vm.development.internalRooms);
            vm.development.isEditOutsideArea = isEditedAmenityItem(originalOutsideAreasSelected, vm.development.outsideAreas);
            vm.development.isEditFacilitiesOffered = isEditedAmenityItem(originalFacilitiesOfferedsSelected, vm.development.facilitiesOffereds);
            vm.development.isEditAccessRoad = isEditedAmenityItem(originalAccessRoadsSelected, vm.development.accessRoads);
            vm.development.isEditNearbyFacility = isEditedAmenityItem(originalNearbyFacilitiesSelected, vm.development.nearbyFacilities);
            vm.development.isEditMaterial = isEditedAmenityItem(originalMaterialsSelected, vm.development.materials);
            vm.development.isEditReasonForPurchase = isEditedAmenityItem(originalReasonForPurchasesSelected, vm.development.reasonForPurchases);
            vm.development.isEditParkingFacilitiy = isEditedAmenityItem(originalParkingFacilitiesSelected, vm.development.parkingFacilities);
        }

        function cancel () {
            // if is create new development will back to development list
            if(vm.development.id === null) {
                $state.go('development', {reload: false});
                return;
            }

            if($scope.editForm.$dirty) {

                // if have any change we must reload page to reset entity
                $state.reload($state.current.name);

                return;
            }

            // change mode edit in state
            $state.isEdit = false;

            // change mode on current state
            vm.isEdit = false;

            // broadcast event to appear button edit
            $rootScope.$broadcast('development:changeMode', false);

            //scroll to top page
            $rootScope.scrollTop();
        }

        function onStateChangeStart(event, toState, toParams, fromState, fromParams) {


            if($scope.editForm.$dirty && !$rootScope.isSessionTimeOut){
                event.preventDefault();
                $uibModal.open({
                    templateUrl: 'app/components/confirmation-dialog/leave/confirm-dialog.html',
                    controller: 'LeaveConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                        entity: function(){
                            return null;
                        },
                        translateKeys: function(){
                            return {
                                keys: 'indochinaApp.property.confirmLeavePage'
                            };
                        }
                    }
                }).result.then(function() {

                    // change mode edit in state
                    $state.isEdit = false;

                    // avoid loop onStateChange function
                    if($scope.editForm) {
                        $scope.editForm.$dirty = false;
                    }

                    if(toState.name == 'development-detail.detail-tab'){

                        // reload parent to reset entity
                        $state.reload('development-detail');

                        // broadcast event to appear button edit
                        $rootScope.$broadcast('development:changeMode', false);
                    }
                    else{
                        //$state.isEdit = true;
                        $state.go(toState, toParams, { reload: true });
                    }
                }, function() {
                        //$state.go('^');
                });
            }
        }

        function createPropertyTypeDetail (propertyType) {
            return {
                grossSizeFrom: null,
                grossSizeTo: null,
                grossSizeUnit: vm.propertyUnitTypes[0],
                development: null,
                propertyType: propertyType.id,
                propertyTypeName: propertyType.name,
                unitNoFrom: null,
                unitNoTo: null,
                sizeUnitFrom: null,
                sizeUnitTo: null,
                sizeUnit: vm.propertyUnitTypes[0],
                lotSizeFrom: null,
                lotSizeTo: null,
                listPriceFrom: null,
                listPriceTo: null,
                lotSizeUnit: vm.lotUnitTypes[0],
                currency: null,
                bedRoomFrom: null,
                bedRoomTo: null,
                id: null
            };
        }

        // function property type click
        function propertyTypeClick (index, isCheck, propertyType) {
            if(isCheck === true) {
                var propertyTypeDetail = createPropertyTypeDetail(propertyType);
                vm.development.propertyTypes.push(propertyTypeDetail);
            } else {
                var i = 0;
                angular.forEach(vm.development.propertyTypes, function(value, key) {
                    if(propertyType.id === value.propertyType) {
                        vm.development.propertyTypes.splice(i, 1);
                    }
                    i++;
                });
            }
            
            // refresh the validation for "Net Size is less than Gross Size"
            if(isCheck == false){
                isNetSizeLessThanGrossSizeTotal();
            }
            
            // update Unit Mix field
            var newUnitMixValue = '';
            for(var i = 0; i < vm.propertyTypesSelect.length ; i++){            	
            	if(vm.propertyTypesSelect[i]){
            		vm.propertyTypes.forEach(function(propertyType){
            			if(propertyType.id == i){
            				newUnitMixValue += capitalizeFirstLetter(propertyType.name) + ', ';
            			}
            		});
            	}
            }
            // remove end comma
            if(newUnitMixValue != ''){
            	newUnitMixValue = newUnitMixValue.substr(0, newUnitMixValue.length - 2);
            }
            
            vm.development.unitMix = newUnitMixValue;
            
            function capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
            }
        }

        // function property location click
        function propertyLocationClick (isCheck, propertyLocation) {
            if(isCheck === true) {
                vm.development.propertyLocations.push(propertyLocation);
            } else {
                var i = 0;
                angular.forEach(vm.development.propertyLocations, function(value, key) {
                    if(propertyLocation.id === value.id) {
                        vm.development.propertyLocations.splice(i, 1);
                    }
                    i++;
                });
            }
        }

        // function access road click
        function accessRoadClick (value, accessRoad) {
            if(value === true) {
                vm.development.accessRoads.push(accessRoad);
            } else {
                var i = 0;
                angular.forEach(vm.development.accessRoads, function(value, key) {
                    if(accessRoad.id === value.id) {
                        vm.development.accessRoads.splice(i, 1);
                    }
                    i++;
                });
            }
        }

        // function internal room click
        function internalRoomClick (value, internalRoom) {
            if(value === true) {
                vm.development.internalRooms.push(internalRoom);
            } else {
                var i = 0;
                angular.forEach(vm.development.internalRooms, function(value, key) {
                    if(internalRoom.id === value.id) {
                        vm.development.internalRooms.splice(i, 1);
                    }
                    i++;
                });
            }

        }

        // function nearby facility click
        function nearbyFacilityClick (value, nearbyFacility) {
            if(value === true) {
                vm.development.nearbyFacilities.push(nearbyFacility);
            } else {
                var i = 0;
                angular.forEach(vm.development.nearbyFacilities, function(value, key) {
                    if(nearbyFacility.id === value.id) {
                        vm.development.nearbyFacilities.splice(i, 1);
                    }
                    i++;
                });
            }

        }

        // function outside area click
        function outsideAreaClick (value, outsideArea) {
            if(value === true) {
                vm.development.outsideAreas.push(outsideArea);
            } else {
                var i = 0;
                angular.forEach(vm.development.outsideAreas, function(value, key) {
                    if(outsideArea.id === value.id) {
                        vm.development.outsideAreas.splice(i, 1);
                    }
                    i++;
                });
            }
        }

        // function parking facility click
        function parkingFacilityClick (value, parkingFacility) {
            if(value === true) {
                vm.development.parkingFacilities.push(parkingFacility);
            } else {
                var i = 0;
                angular.forEach(vm.development.parkingFacilities, function(value, key) {
                    if(parkingFacility.id === value.id) {
                        vm.development.parkingFacilities.splice(i, 1);
                    }
                    i++;
                });
            }
        }

        // function facility offer click
        function facilitiesOfferedClick (value, facilitiesOffered) {
            if(value === true) {
                vm.development.facilitiesOffereds.push(facilitiesOffered);
            } else {
                var i = 0;
                angular.forEach(vm.development.facilitiesOffereds, function(value, key) {
                    if(facilitiesOffered.id === value.id) {
                        vm.development.facilitiesOffereds.splice(i, 1);
                    }
                    i++;
                });
            }
        }

        // function reason for purchase click
        function reasonForPurchaseClick (value, reasonForPurchase) {
            if(value === true) {
                vm.development.reasonForPurchases.push(reasonForPurchase);
            } else {
                var i = 0;
                angular.forEach(vm.development.reasonForPurchases, function(value, key) {
                    if(reasonForPurchase.id === value.id) {
                        vm.development.reasonForPurchases.splice(i, 1);
                    }
                    i++;
                });
            }
        }

        function materialsClick(value, material) {
            if(value === true) {
                vm.development.materials.push(material);
            } else {
                var i = 0;
                angular.forEach(vm.development.materials, function(value, key) {
                    if(material.id === value.id) {
                    	vm.development.materials.splice(i, 1);
                    }
                    i++;
                });
            }
        }

        // function detect checkbox amenity element edit or not
        function isEditedAmenityItem (source, editSource) {
            if (source.length !== editSource.length) return true;
            return _.differenceBy(source, editSource, 'id').length !== 0;
        }

        // function get contact by trade name
        function getContactByTradeName(name) {
        	return Contact.getAllContactForCompanyNameOnDevelopment({tradeName:name}).$promise;
        }



        // function get contact by name
        function getContactByName(name) {
            if (name) {
                return Contact.getAllContactByName({name:name}, function (data){
                }).$promise;
            }
        }



        // function choice a company name
        function onSelectCompany (contact) {
            vm.development.contactCompanyId = contact.id;
        }

        // function choice a developer name
        function onSelectDeveloper (contact) {
            vm.development.contactDeveloperId = contact.id;
        }

        // function choice a owner name
        function onSelectOwner (contact) {
            vm.development.contactOwnerId = contact.id;
        }

        // function choice a agent name
        function onSelectAgent(agent) {
            vm.development.agentId = agent.id;
        }


        // function create 2 default languages for development [EN, VN]
        function addDefaultOtherLanguage() {
            var englishLanguage = {
                id:null,
                languageCode:'EN',
                headline: null,
                otherDetail: null,
                active: true
                };
            vm.development.otherLanguages.push(englishLanguage);

//            var vietnameseLanguage = {
//                id:null,
//                languageCode:'VN',
//                headLine: null,
//                otherDetail: null,
//                active: true
//            };
//            vm.development.otherLanguages.push(vietnameseLanguage);

        }

        // block code for language checking
        function getLanguages(me) {

            var langs = [];
            for(var i = 0; i < vm.languages.length; i++) {
                if(vm.languages[i] === me || !isExist(vm.languages[i]))
                    langs.push(vm.languages[i]);
            }
            return langs;
        }

        function isExist(code) {
            for(var i = 0; i < vm.development.otherLanguages.length; i++) {
                if(vm.development.otherLanguages[i].languageCode === code)
                    return true;
            }
            return false;
        }

        function hasRowEmpty() {
            if(vm.development.otherLanguages.length < vm.languages.length) {
                return true;
            }
            return false;
        }

        function isDefaultRow(index) {
            if(index == 0) {
                return true;
            }
            return false;
        }

        function addOtherLanguage() {
            var otherLanguage = {
                id:null,
                languageCode: null,
                headLine: null,
                otherDetail: null,
                shortDescription: null,
                active: true
            };
            vm.development.otherLanguages.push(otherLanguage);
        }

        function removeOtherLanguage(index) {
            if(vm.development.otherLanguages[index].id){
            	$uibModal.open({
	        		templateUrl: 'app/components/confirmation-dialog/delete/confirm-dialog.html',
	        		controller: 'DeleteConfirmDialogController',
	        		controllerAs: 'vm',
	        		size: 'sm',
	        		windowClass : 'absolute-center',
	        		resolve: {
	        			entity: function(){
	        				return null;
	        			},
	        			translateKeys: function(){
	        				return {
	        					keys: 'indochinaApp.property.confirmDeleteSales'
	        				};
	        			}
	        		}
	        	}).result.then(function() {
	                $scope.editForm.$dirty = true;
	                vm.development.otherLanguages.splice(index, 1);
	        	}, function() {
	        	});
            }
            else{
                $scope.editForm.$dirty = true;
                vm.development.otherLanguages.splice(index, 1);
            }
        }


        // end block

        function getClassColumn(index) {
        	if(index < 3) return 'column1';
        	if(index === 3) return 'column2 reset';
        	if(index > 3 && index < 6) return 'column2';
        	if(index === 6) return 'column3 reset';
        	if(index > 6 && index < 9) return 'column3';
        	if(index === 9) return 'column4 reset';
        }

        function initDistrict () {
            //init HCM district
            vm.districtHCMMasterData = districtHCMResource;

            //init HA_NOI district
            vm.districtHANMasterData = districtHANResource;

            //init DA_NANG district
            vm.districtDNGMasterData = districtDNGResource;

            /*
        	* if city is HCM or HANOI or DANANG, district will list following city corresponding and user can't input value
        	* else list district is empty and user can input value
        	* */
            vm.districtTypeaheadEditable = false;
            if(vm.development.city == 'Ho Chi Minh City'){
                vm.districtList = vm.districtHCMMasterData;
            } else if(vm.development.city == 'Ha Noi'){
                vm.districtList = vm.districtHANMasterData;
            } else if(vm.development.city == 'Da Nang'){
                vm.districtList = vm.districtDNGMasterData;
            } else {
                vm.districtTypeaheadEditable = true;
            }


        }

        function initCity () {
            vm.cityMasterData = cityResource;

            if(vm.development.country == null || vm.development.country == '' || vm.development.country.toUpperCase() == 'vietnam'.toUpperCase()){
                vm.cityList = vm.cityMasterData;
            } else {
                vm.cityList = [];
            }
        }

        function initProvince () {
            vm.provinceMasterData = provinceResource;

            if(vm.development.country == null || vm.development.country == '' || vm.development.country.toUpperCase() == 'vietnam'.toUpperCase()){
                vm.provinceList = vm.provinceMasterData;
            } else {
                vm.provinceList = [];
            }
        }

        function onSelectCountry($item, $model, $label) {
            if($model.toUpperCase() == 'vietnam'.toUpperCase()){
                vm.provinceList = vm.provinceMasterData;
                vm.provinceTypeaheadEditable = false;

                vm.cityList = vm.cityMasterData;
                vm.cityTypeaheadEditable = false;
            }
            else{
                vm.provinceList = [];
                vm.provinceTypeaheadEditable = true;

                vm.cityList = [];
                vm.cityTypeaheadEditable = true;
                vm.development.cityCode = 'INTL';
            }
            vm.development.city = '';
            vm.development.province = '';
            vm.development.district = '';

            vm.districtTypeaheadEditable = true;
            vm.districtList = [];
        };

        function onSelectCity($item, $model, $label) {

            vm.development.cityCode = $item.code;

            if($item.code == 'HCM'){
                vm.districtList = vm.districtHCMMasterData;
                vm.districtTypeaheadEditable = false;
            }
            else if($item.code == 'HAN'){
                vm.districtList = vm.districtHANMasterData;
                vm.districtTypeaheadEditable = false;
            }
            else if($item.code == 'DNG'){
                vm.districtList = vm.districtDNGMasterData;
                vm.districtTypeaheadEditable = false;
            }
            else{
                vm.districtList = [];
                vm.districtTypeaheadEditable = true;
            }

            vm.development.district = '';

        };

        function onKeyupCity(){
            if(vm.development.city == null || vm.development.city == ''){
                vm.districtTypeaheadEditable = true;
                vm.districtList = [];
            }
        }

        function commissionValidate () {

            var result = null;
            if(vm.development.commissionFrom && vm.development.commissionTo && (Number(vm.development.commissionFrom) > Number(vm.development.commissionTo))) {
                result = true;
            }

            vm.commissionRangeInValid = result;

            return result;
        }

        function bathRoomNumberValidate () {

            var result = null;
            if(vm.development.bathRoomFrom && vm.development.bathRoomTo && (Number(vm.development.bathRoomFrom) > Number(vm.development.bathRoomTo))) {
                result = true;
            }

            vm.bathRommRangeInValid = result;

            return result;

        }


        // validate number of unit range
        function propertyTypeDetailValidate () {

            var result = null;
            var i = 0;

            angular.forEach(vm.development.propertyTypes, function(propertyTypes, key) {

                // validate unit number
                result = false;
                if(propertyTypes.unitNoFrom && propertyTypes.unitNoTo && (Number(propertyTypes.unitNoFrom) > Number(propertyTypes.unitNoTo))) {
                    result = true;
                } else {
                    i++;
                }
                propertyTypes.numberOfUnitRangeInValid = result;

                // validate size unit
                result = false;
                if(propertyTypes.sizeUnitFrom && propertyTypes.sizeUnitTo && (Number(propertyTypes.sizeUnitFrom) > Number(propertyTypes.sizeUnitTo))) {
                    result = true;
                } else {
                    i++;
                }
                propertyTypes.sizeOfUnitRangeInValid = result;



                // validate gross size
                result = false;
                if(propertyTypes.grossSizeFrom && propertyTypes.grossSizeTo && (Number(propertyTypes.grossSizeFrom) > Number(propertyTypes.grossSizeTo))) {
                    result = true;
                } else {
                    i++;
                }
                propertyTypes.grossSizeOfUnitRangeInValid = result;




                // validate lot size
                result = false;
                if(propertyTypes.lotSizeFrom && propertyTypes.lotSizeTo && (Number(propertyTypes.lotSizeFrom) > Number(propertyTypes.lotSizeTo))) {
                    result = true;
                } else {
                    i++;
                }
                propertyTypes.lotSizeRangeInValid = result;

                // validate bedroom
                result = false;
                if(propertyTypes.bedRoomFromDisplay && propertyTypes.bedRoomToDisplay && (Number(propertyTypes.bedRoomFromDisplay) > Number(propertyTypes.bedRoomToDisplay) || (propertyTypes.bedRoomToDisplay === 'STU' && Number(propertyTypes.bedRoomFromDisplay) > 0))) {
                    result = true;
                } else {
                    i++;
                }
                propertyTypes.bedRoomRangeInValid = result;

                // validate list price
                result = false;
                if(propertyTypes.listPriceFrom && propertyTypes.listPriceTo && (Number(propertyTypes.listPriceFrom) > Number(propertyTypes.listPriceTo))) {
                    result = true;
                } else {
                    i++;
                }
                propertyTypes.priceRangeInValid = result;
            });

            // if i == length of contact, all location don't have any bathroom range invalid
            if(i !== vm.development.propertyTypes.length * 6) {
                result = true;
            }


            return result;
        }
        
        function grossAndNetSizeFromValidate($index){
        	vm.development.propertyTypes[$index].netSizeNotLessThanGrossSizeFrom = !isNetSizeLessThanGrossSize(vm.development.propertyTypes[$index].sizeUnitFrom, vm.development.propertyTypes[$index].grossSizeFrom, vm.development.propertyTypes[$index].sizeUnit, vm.development.propertyTypes[$index].grossSizeUnit);
        	
        	isNetSizeLessThanGrossSizeTotal();
        }
        
        function grossAndNetSizeToValidate($index){
        	vm.development.propertyTypes[$index].netSizeNotLessThanGrossSizeTo = !isNetSizeLessThanGrossSize(vm.development.propertyTypes[$index].sizeUnitTo, vm.development.propertyTypes[$index].grossSizeTo, vm.development.propertyTypes[$index].sizeUnit, vm.development.propertyTypes[$index].grossSizeUnit);
        	
        	isNetSizeLessThanGrossSizeTotal();
        }
        
        function isNetSizeLessThanGrossSize(netSize, grossSize, netSizeUnit, grossSizeUnit){
        	var result = true;
        	
        	if(netSize != null && netSize != ''){
        		if(grossSize == null || grossSize == ''){
        			result = false;
        		}
        		else if(netSizeUnit == grossSizeUnit && parseFloat(netSize) > parseFloat(grossSize)){
        			result = false;
        		}
        		else if(netSizeUnit != grossSizeUnit){
        			var feetToMeter = 0.3048;
        			var netSizeValue = 0;
        			var grossSizeValue = 0;
        			
        			if(netSizeUnit == 'SQ_METER'){
        				netSizeValue = parseFloat(netSize);
        			}
        			else{
        				netSizeValue = parseFloat(netSize) * feetToMeter;
        			}
        			
        			if(grossSizeUnit == 'SQ_METER'){
        				grossSizeValue = parseFloat(grossSize);
        			}
        			else{
        				grossSizeValue = parseFloat(grossSize) * feetToMeter;
        			}
        			
        			if(netSizeValue > grossSizeValue){
        				result = false;
        			}
        		}
        	}
        	
        	return result;
        }
        
        function isNetSizeLessThanGrossSizeTotal(){
        	vm.netSizeNotLessThanGrossSizeInTotalFields = vm.development.propertyTypes.some(function(propertyType){
        		return propertyType.netSizeNotLessThanGrossSizeFrom || propertyType.netSizeNotLessThanGrossSizeTo
        	});
        }
    }
})();
