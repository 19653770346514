(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('contact-detail.contact-prefer-tab', {
        	parent: 'contact-detail',
        	url: '/contact-prefer-tab',
        	templateUrl: 'app/entities/contact/contact-detail/contact-preferences-tab/contact-preferences-tab.html',
            controller: 'ContactPreferTabController',
            controllerAs: 'vm'
        })
        .state('contact.new.contact-prefer-tab', {
            parent: 'contact.new',
            url: '/new-prefer-tab',
            templateUrl: 'app/entities/contact/contact-detail/contact-preferences-tab/contact-preferences-tab.html',
            controller: 'ContactPreferTabController',
            controllerAs: 'vm'
        });
    }

})();
