(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('ErrorDialogController',ErrorDialogController);

    ErrorDialogController.$inject = ['$uibModalInstance', 'entity', 'translateKeys', '$rootScope'];

    function ErrorDialogController($uibModalInstance, entity, translateKeys, $rootScope) {
        var vm = this;

        vm.translateKeys = translateKeys.keys;

        vm.clear = clear;

        // session is time out so we must close all modals
        $rootScope.$on('modal:close', function(event, result) {
            clear();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
