(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('LogoutController', LogoutController);

    LogoutController.$inject = ['$state', 'Auth'];

    function LogoutController ($state, Auth) {
        var vm = this;

        Auth.logout();
        $state.go('home');
    }
})();
