(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .directive('limitTo', limitTo);

    function limitTo() {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            scope: false,
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs, controller) {
        	var limit = parseInt(attrs.limitTo),
        		targetEle = angular.element(element);
        	
        	element.on("keypress", function(e) {
                if (this.value.length === limit) e.preventDefault();
            });
        	
        	element.on("cut copy paste", function(e) {
        		setTimeout(function() {
        			if (element.val().length > limit) {
                    	controller.$setViewValue(element.val().substring(0, limit));
                    	controller.$render();
                    };
        		}, 50);
            });
        }
    }
})();
