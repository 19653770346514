(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('ChildDeleteController',ChildDeleteController);

    ChildDeleteController.$inject = ['$uibModalInstance', 'entity', 'Child'];

    function ChildDeleteController($uibModalInstance, entity, Child) {
        var vm = this;

        vm.child = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Child.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
