(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'AuthorityConstant'];

    function stateConfig($stateProvider, AuthorityConstant) {
        $stateProvider
            .state('development', {
                parent: 'entity',
                url: '/development?page&sort&search',
                data: {
                    authorities: AuthorityConstant.ROLE_LIST,
                    pageTitle: 'indochinaApp.development.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/development/developments.html',
                        controller: 'DevelopmentController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'developmentCode,asc',
                        squash: true
                    },
                    search: {
                    	value: null,
//                        value: 'U2FsdGVkX1/HA3wRxoQAslq1pz4E4Cjxwb8CtDc9fTc=',
                        squash: true
                    },
                    pageForActivity: {
                        value: '1',
                        squash: true
                    },
                    sortForActivity: {
                        value: 'dueDate,desc',
                        squash: true
                    },
                    pageForActivityHistorical: {
                        value: '1',
                        squash: true
                    },
                    sortForActivityHistorical: {
                        value: 'dueDate,desc',
                        squash: true
                    },
                    searchForOpportunity: {
                        value: {agent: -1, status: 'active'},
                        squash: true
                    },
                    pageForOpportunity: {
                        value: '1',
                        squash: true
                    },
                    sortForOpportunity: {
                        value: 'name,asc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('development');
                        $translatePartialLoader.addPart('transactionType');
                        $translatePartialLoader.addPart('propertyType');
                        $translatePartialLoader.addPart('availabilityStatus');
                        $translatePartialLoader.addPart('constructionStatus');
                        $translatePartialLoader.addPart('paymentCycleEnum');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('unitType');
                        $translatePartialLoader.addPart('photo');
                        $translatePartialLoader.addPart('contactPreferences');
                        $translatePartialLoader.addPart('property');
                        $translatePartialLoader.addPart('developmentLegal');
                        $translatePartialLoader.addPart('documentType');
                        return $translate.refresh();
                    }]
                }
            })
            .state('development-detail', {
                parent: 'development',
                url: '/{id}',
                data: {
                    authorities: AuthorityConstant.ROLE_LIST,
                    pageTitle: 'indochinaApp.development.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/development/development-detail/development-detail.html',
                        controller: 'DevelopmentDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    searchActivity: [function (){
                        return {
                            searchQuery: null,
                            status: 'OPEN',
                            propertyIds: [],
                            agentIds: [],
                            agentObjectList: [],
                            contactId: null,
                            developmentId: null,
                            fromDate: null,
                            toDate: null
                        }
                    }],
                    entity: ['$stateParams', '$state', 'DevelopmentService', function($stateParams, $state, DevelopmentService) {
                        return DevelopmentService.get({id : $stateParams.id}, function(result){
                        	if(result == null || result.id == null || result.active == false){
                    	  		$state.go('accessdenied');
                    	  	}
                        	return result;
                        }, function(){
                        	$state.go('accessdenied');
                        }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'development',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    pagingParamsForActivity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.pageForActivity),
                            sort: $stateParams.sortForActivity,
                            predicate: PaginationUtil.parsePredicate($stateParams.sortForActivity),
                            ascending: PaginationUtil.parseAscending($stateParams.sortForActivity),
                            search: $stateParams.search
                        };
                    }],
                    pagingParamsForOpportunity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.pageForOpportunity),
                            sort: $stateParams.sortForOpportunity,
                            predicate: PaginationUtil.parsePredicate($stateParams.sortForOpportunity),
                            ascending: PaginationUtil.parseAscending($stateParams.sortForOpportunity),
                            search: $stateParams.searchForOpportunity
                        };
                    }]
                }
            })
            .state('development.new', {
                parent: 'development',
                url: '/new',
                data: {
                    authorities: AuthorityConstant.ROLE_LIST,
                    pageTitle: 'indochinaApp.development.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/development/development-detail/development-detail.html',
                        controller: 'DevelopmentDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pagingParamsForActivity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.pageForActivity),
                            sort: $stateParams.sortForActivity,
                            predicate: PaginationUtil.parsePredicate($stateParams.sortForActivity),
                            ascending: PaginationUtil.parseAscending($stateParams.sortForActivity),
                            search: $stateParams.search
                        };
                    }],
                    pagingParamsForOpportunity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.pageForOpportunity),
                            sort: $stateParams.sortForOpportunity,
                            predicate: PaginationUtil.parsePredicate($stateParams.sortForOpportunity),
                            ascending: PaginationUtil.parseAscending($stateParams.sortForOpportunity),
                            search: $stateParams.searchForOpportunity
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('development');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            agentId: null,
                            agentName: null,
                            contactOwnerId: null,
                            contactOwnerName: null,
                            contactDeveloperId: null,
                            contactDeveloperName: null,
                            contactCompanyRegisterName: null,
                            contactCompanyId: null,
                            feeManagementNumber: null,
                            feeManagementCurrency: null,
                            feeManagementTerm: null,
                            feeParkingNumber: null,
                            feeParkingCurrency: null,
                            feeParkingTerm: null,
                            feeGymNumber: null,
                            feeGymCurrency: null,
                            feeGymTerm: null,
                            bathRoomFrom: null,
                            bathRoomTo: null,
                            internalNotes: null,
                            propertyTypes: [],
                            cityCode: null,
                            developmentCode: null,
                            unitNumber: null,
                            title: null,
                            developmentName: null,
                            companyName: null,
                            owner: null,
                            created: null,
                            modified: null,
                            active: true,
                            transactionType: null,
                            streetNumber: null,
                            streetName: null,
                            city: null,
                            province: null,
                            postalCode: null,
                            country: null,
                            unitNo: null,
                            floor: null,
                            latitude: null,
                            longitude: null,
                            type: null,
                            availability: null,
                            size: null,
                            sizeUnit: null,
                            developmentSize: null,
                            developmentSizeUnit: null,
                            currency: null,
                            listPrice: null,
                            paymentCycle: null,
                            availabilityPeriod: null,
                            leaseTerm: null,
                            yearBuilt: null,
                            titleDeedNo: null,
                            titleDeedLandNo: null,
                            titleDeedOther: null,
                            accessRoadWidth: null,
                            accessRoadWidthUnit: null,
                            properties: [],
                            otherLanguages: [],
                            propertyLocations: [],
                            internalRooms: [],
                            outsideAreas: [],
                            facilitiesOffereds: [],
                            nearbyFacilities: [],
                            parkingFacilities: [],
                            reasonForPurchases: [],
                            materials: [],
                            accessRoads: [],
                            id: null
                        };
                    },
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'development',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]

                }

            })
            .state('development.duplicate', {
                parent: 'development',
                url: '/{id}/duplicate',
                data: {
                    authorities: AuthorityConstant.ROLE_LIST,
                    pageTitle: 'indochinaApp.development.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/development/development-detail/development-detail.html',
                        controller: 'DevelopmentDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pagingParamsForActivity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.pageForActivity),
                            sort: $stateParams.sortForActivity,
                            predicate: PaginationUtil.parsePredicate($stateParams.sortForActivity),
                            ascending: PaginationUtil.parseAscending($stateParams.sortForActivity),
                            search: $stateParams.search
                        };
                    }],
                    pagingParamsForOpportunity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.pageForOpportunity),
                            sort: $stateParams.sortForOpportunity,
                            predicate: PaginationUtil.parsePredicate($stateParams.sortForOpportunity),
                            ascending: PaginationUtil.parseAscending($stateParams.sortForOpportunity),
                            search: $stateParams.searchForOpportunity
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('development');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', '$state', 'DevelopmentService', function($stateParams, $state, DevelopmentService) {
                        return DevelopmentService.get({id : $stateParams.id}, function (result){
                        	if(result == null || result.id == null || result.active == false){
                    	  		$state.go('accessdenied');
                    	  	}

                            // reset some field
                            result.id = null;
                            result.properties = [];
                            result.videoLink = null;
                            result.virtualRealityLink = null;
                            result.agentId = null;
                            result.agentName = null;
                            result.name = result.name + ' (Copy)';

                            // reset id null other languages and property types
                            angular.forEach(result.otherLanguages, function(otherLanguage, key) {
                                otherLanguage.id = null;
                            });

                            angular.forEach(result.propertyTypes, function(propertyType, key) {
                                propertyType.id = null;
                            });

                            result.sites = [];
                        }, function(){
                        	$state.go('accessdenied');
                        }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'development',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]

                }

            });
    }
})();
