(function () {
    'use strict';

    angular
        .module('indochinaApp')
        .factory('JhiCurrencyService', JhiCurrencyService);

    JhiCurrencyService.$inject = ['$q', '$http', '$localStorage', '$rootScope', 'CurrencyConstant', 'Principal', 'User', 'ExchangeRates'];

    function JhiCurrencyService ($q, $http, $localStorage, $rootScope, CurrencyConstant, Principal, User, ExchangeRates) {
    	var baseURL = 'api';
        var service = {
        	initialize: initialize,
        	currencyCode: {
        		decCode: '',
        		value: ''
        	},
        	rate: {},
        	USDRate: [],
            getAll: getAll,
            changeCurrency: changeCurrency,
            getExchangeRates:getExchangeRates
        };
        
        $rootScope.$on('authenticationSuccess', function(event, data){
			User.getCurrentUser().then(function(reponse){
				if(reponse.data != null){
					changeCurrency (reponse.data.currencyCode);
				}
			});
		});
        
        return service;
        
        function initialize(currencyCode){
        	_setCurrencyCode(_getCurrencyCode());
            _updateRate(currencyCode);
            _getUSDRate();
        }

        function getAll () {
            var deferred = $q.defer();
            deferred.resolve(CurrencyConstant.LIST);
            return deferred.promise;
        }

        function changeCurrency (currencyCode) {
        	_setCurrencyCode(currencyCode);
        	_updateRate(currencyCode);
        }
        
        function _getUSDRate(){
			ExchangeRates.getByUSD({},function(data){
				service.USDRate = data;
			}, function(error){
			});
        }
        
        function _updateRate(currencyCode){
        	if(service.currencyCode.value == ''){
        		CurrencyConstant.LIST.forEach(function(e){
        			service.rate[e] = 1;
        		});
        	}
        	else{
        		var url = baseURL + '/change-preferred-currency';
           	 	$http.post(url, service.currencyCode.value).then(function success(result) {
           	 		result.data.forEach(function(e){
           	 			if(e.sourceCode == currencyCode) {
           	 				//TODO: broadcast currency change to change value of slide
           	 				$rootScope.$broadcast('curreny:change', e);
           	 				service.currencyCode.decCode = e.codeDescription.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
           	 			}
           	 			service.rate[e.sourceCode] = e.value;
           	 		});
        		}, function error(e) {
        			
        		});
        	}
        }
        
        function getExchangeRates(currencyCode){
        	var url = baseURL + '/change-preferred-currency-by-usd';
       	 	return $http.post(url).then(function success(result) {
       	 		var rates = [];
       	 		result.data.forEach(function(e){
       	 			rates.push(e);
       	 		});
       	 		return rates;
    		}, function error(e) {
    			return [];
    		});
        }
        
        function _getCurrencyCode(){
        	var currencyCode = '';
    		
        	if($localStorage.currencyCode != null){
        		currencyCode = $localStorage.currencyCode
        	}
        	else{
        		if(Principal.isAuthenticated()){
        			User.getCurrentUser().then(function(reponse){
	        			if(reponse.data != null){
	        				currencyCode = reponse.data.currencyCode;
	        			}
        			});
        		}
        	}
        	
        	return currencyCode;
        }
        
        function _setCurrencyCode(currencyCode){
        	service.currencyCode.value = currencyCode;
        	service.currencyCode.decCode = CurrencyConstant.DES[currencyCode];
        	_storeCurrencyCode();
    	}
        
        function _storeCurrencyCode(){
    		$localStorage.currencyCode = service.currencyCode.value;
    	}
    }
})();
