(function() {
    'use strict';
    angular
        .module('indochinaApp')
        .factory('PublishService', PublishService);

    PublishService.$inject = ['$resource', 'DateUtils'];

    function PublishService ($resource, DateUtils) {
        var resourceUrl =  'api/sites';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                    	console.log(data);
                    }
                    return data;
                }
            },
            'getByName': {
            	url: 'api/sites-by-name',
            	method: 'GET', 
            	isArray: true
            }
        });
    }
})();
