(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('DuplicateDialogControllerRe',Controller);

    Controller.$inject = ['$uibModalInstance', '$rootScope', 'translateKeys'];

    function Controller($uibModalInstance, $rootScope, translateKeys) {
        var vm = this;
        vm.translateKeys = translateKeys.keys;

        vm.clear = clear;
        vm.confirmDuplicate = confirmDuplicate;

        // session is time out so we must close all modals
        $rootScope.$on('modal:close', function(event, result) {
            clear();
        });

        function clear () {
            $rootScope.$broadcast('app:resetTimeSession', true);
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDuplicate (id) {
            $rootScope.$broadcast('app:resetTimeSession', true);
        	$uibModalInstance.close(true);
        }
    }
})();
