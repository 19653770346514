	/*
	Automatic phone number formatting in input field
	
	Credits:
	    - Wade Tandy via
	    http://stackoverflow.com/questions/19094150/using-angularjs-directive-to-format-input-field-while-leaving-scope-variable-unc
	    
	    - kstep via
	    http://stackoverflow.com/questions/12700145/how-to-format-a-telephone-number-in-angularjs
	    
	    - hans via
	    http://codepen.io/hans/details/uDmzf/
	*/
(function() {
    'use strict';
    angular
    	.module('indochinaApp')
    	.directive('phoneinput', phoneInput)
    	.filter('tel', function () {
    		return function (tel) {
    	        if (!tel) { return ''; }
    	
    	        var value = tel.toString().trim().replace(/^\+/, '');
    	
    	        if (value.match(/[^0-9]/)) {
    	            return tel;
    	        }
    	
    	        var country, city, number;
    	
    	        switch (value.length) {
    	            case 1:
    	            case 2:
    	            case 3:
    	                city = value;
    	                break;
    	
    	            default:
    	                city = value.slice(0, 3);
    	                number = value.slice(3);
    	        }
    	
    	        if(number){
    	        	
    	        	if(number.length > 7) {
    	                number = number.slice(0, 4) + '-' + number.slice(4,8);
    	            } else if(number.length > 3) {
    	                number = number.slice(0, 3) + '-' + number.slice(3,7);
    	            } else{
    	                number = number;
    	            }
    	
    	            return ("(" + city + ") " + number).trim();
    	        }
    	        else{
    	        	
    	            return "(" + city;
    	        }
    	
    	    };
    	});
    
    phoneInput.$inject = ['$filter', '$browser', '$timeout'];
    
    function phoneInput($filter, $browser, $timeout) {
    	var directive = {
            restrict: 'A',
            scope: false,
            require: 'ngModel',
            link: linkFunc
        };
    	return directive;
    	
    	function linkFunc($scope, $element, $attrs, ngModelCtrl) {
    		var listener = function() {
                var value = $element.val().replace(/[^0-9]/g, '');	                
                $element.val($filter('tel')(value, false));
            };

            // This runs when we update the text field
            ngModelCtrl.$parsers.push(function(viewValue) {	            	
            	var val = viewValue.replace(/[^0-9]/g, '').slice(0,11);
            	ngModelCtrl.$setViewValue($filter('tel')(val, false));	            	
                return val;
            });

            // This runs when the model gets updated on the scope directly and keeps our view in sync
            ngModelCtrl.$render = function() {
                $element.val($filter('tel')(ngModelCtrl.$viewValue, false));
            };

            $element.bind('change', listener);
            $element.bind('keydown', function(event) {
                var key = event.keyCode;
                // If the keys include the CTRL, SHIFT, ALT, or META keys, or the arrow keys, do nothing.
                // This lets us support copy and paste too
                if (key == 91 || (15 < key && key < 19) || (37 <= key && key <= 40)){
                    return;
                }
                $browser.defer(listener); // Have to do this or changes don't get picked up properly
            });

            $element.bind('paste cut', function() {
                $browser.defer(listener);
            });
    	}
    }
})();