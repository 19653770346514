(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('PersonalDetailsDetailController', PersonalDetailsDetailController);

    PersonalDetailsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PersonalDetails'];

    function PersonalDetailsDetailController($scope, $rootScope, $stateParams, previousState, entity, PersonalDetails) {
        var vm = this;

        vm.personalDetails = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('indochinaApp:personalDetailsUpdate', function(event, result) {
            vm.personalDetails = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
