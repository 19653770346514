(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('ContactLegalDialogController', ContactLegalDialogController);

    ContactLegalDialogController.$inject = ['$rootScope','$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ContactLegal',
    	'ContactConstant','contact', 'Upload','$filter', '$uibModal', 'AlertService'];

    function ContactLegalDialogController ($rootScope, $timeout, $scope, $stateParams, $uibModalInstance, entity, ContactLegal,
    		ContactConstant, contact, Upload, $filter, $uibModal, AlertService) {
        var vm = this;
        vm.contactLegal = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.validateDateIssueExpiry = validateDateIssueExpiry;
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.documentTypes = ContactConstant.documentType.LIST;
        vm.onChangDocumentType = onChangDocumentType;
        vm.companyName = contact.contactCompanyRegisterName;
        vm.contactName = contact.name;
        vm.uploadFiles = uploadFiles;
        vm.contactLegal.contactId = contact.id;
        vm.fileName = null;

        var today = new Date();
        today.setDate(today.getDate() - 2);
        vm.currentDate = $filter('date')(today, 'MM/dd/yyyy');
        vm.datePickerOpenStatus = {};
        vm.datePickerOption = {
        		showWeeks: false,
                readonlyInput: true,
                dateformat: 'MM/dd/yyyy'
                //minDate: vm.currentDate
        }
        vm.isErrorFile= false;
        vm.isEdit = false;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        init();

        function init() {
        	if(vm.contactLegal.id){//edit
        		vm.fileName = vm.contactLegal.fileName;
        		vm.isEdit = true;
        	}
        }

        function clear () {
        	$rootScope.$broadcast('app:resetTimeSession', true);
            onStateChangeStart();
        }

        function save () {
        	$rootScope.$broadcast('app:resetTimeSession', true);
            vm.isSaving = true;
            var data = [];
            data.legal = vm.contactLegal;
            if(vm.file){
            	data.file = vm.file;
            }
            ContactLegal.save(data, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('indochinaApp:contactLegalUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.issueDate = false;
        vm.datePickerOpenStatus.expiryDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function onChangDocumentType(){

        }

        function uploadFiles(file, errFile) {
            vm.file = null;
            vm.errFile = errFile;
            vm.fileName = null;
            vm.isErrorFile= false;
            //20971520 bytes = 1024*1024*20= 20MB
            if(file.size > 20971520){
                vm.isErrorFile= true;
            } else {
                vm.file = file;
                vm.fileName = file.name;
            }
        }

        function onStateChangeStart() {
            if($scope.editForm.$dirty && !$rootScope.isSessionTimeOut){
                $uibModal.open({
                    templateUrl: 'app/components/confirmation-dialog/leave/confirm-dialog.html',
                    controller: 'LeaveConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                    	entity: function(){
                        	return null;
                        },
                        translateKeys: function(){
                        	return {
                        		keys: 'indochinaApp.property.confirmLeavePage'
                        	};
                        }
                    }
                }).result.then(function() {
                	$scope.editForm.$dirty = false;
                	$uibModalInstance.dismiss('cancel');
                }, function() {
//                    $state.go('^');
                });
            } else {
            	$uibModalInstance.dismiss('cancel');
            }
        }
        
        function validateDateIssueExpiry(){
        	vm.invalidDatepicker = false;
        	if(vm.contactLegal.issueDate != null && vm.contactLegal.expiryDate != null){
        		if(vm.contactLegal.issueDate > vm.contactLegal.expiryDate){
        			vm.invalidDatepicker = true;
        		}
        	}
        }
    }
})();
