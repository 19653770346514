(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('LeadSourceManagementController', LeadSourceManagementController);

    LeadSourceManagementController.$inject = ['$rootScope', '$scope', '$uibModal', 'LeadSourceConstant', '$state', 'LeadSourceManagementService', 'leadSourceResource'];

    function LeadSourceManagementController($rootScope, $scope, $uibModal, LeadSourceConstant, $state, LeadSourceManagementService, leadSourceResource ) {
        var vm = this;

        // scope for all event click on this controller
        vm.cancel = cancel;
        vm.edit = edit;
        vm.newSubItem = newSubItem;
        vm.save = save;
        vm.remove = remove;
        vm.newRoot = newRoot;

        var identity = 0;

        vm.isSaving = false;
        vm.isHaveChild = isHaveChild;
        vm.isDisplayButtonPlus = isDisplayButtonPlus;
        vm.isHiddenButtonMinus = isHiddenButtonMinus;

        // define these constant to use in file html template
        vm.coBrokerConstant = LeadSourceConstant.types.CoBROKER;
        vm.introductionConstant = LeadSourceConstant.types.INTRODUCTION;
        vm.portalConstant = LeadSourceConstant.types.PORTAL;
        vm.portalDotPropertyConstant = LeadSourceConstant.types.PORTAL_JUWAI;
        vm.portalJuwaiConstant = LeadSourceConstant.types.PORTAL_DOT_PROPERTY;
        vm.portalSquarefootConstant = LeadSourceConstant.types.PORTAL_SQUARE_FOOT;
        vm.portalTranioConstant = LeadSourceConstant.types.PORTAL_TRANIO;
        vm.indochinaWebsiteConstant = LeadSourceConstant.types.INDOCHINA_WEBSITE;
        vm.iccStaffConstant = LeadSourceConstant.types.ICC_STAFF;
        vm.iccDivisionConstant = LeadSourceConstant.types.ICC_DIVISION;
        vm.existingContactConstant = LeadSourceConstant.types.EXISTING_CONTACT;
        vm.personalNetworkConstant = LeadSourceConstant.types.PERSONAL_NETWORK;
        vm.databaseConstant = LeadSourceConstant.types.DATABASE;

        init ();

        function init () {

            angular.forEach( vm.data, function( item, key ) {

                // identity in layout
                item.identity = identity;
                identity ++;

                angular.forEach( item.childs, function( child, index ) {
                    child.identity = identity;
                    identity ++;
                });
            });
        }


        // scope for another event
        $scope.$on('$stateChangeStart', onStateChangeStart);

        // load list source
        vm.data = leadSourceResource;

        function cancel(){
        	$state.go("lead-source-management", null, { reload: true });
        }

        function edit(node){
            if(isPermanent (node)) {
                return false;
            }
            // update modify date
            node.modifiedDate = new Date();
        	node.edit = true;
        }

        function newRoot(){
            var newRoot = newItemInfo ();
            newRoot.isRoot = true;
            vm.data.unshift(newRoot);
        }

        function newSubItem( node ) {
            node.childs.push(newItemInfo ());
        }

        function newItemInfo () {
            identity ++;
            return {
                id: null,
                name: '',
                activated: true,
                createdDate: null,
                modifiedDate: null,
                childs: [],
                edit: true,
                identity: identity
            }
        }
        function remove(nodeData) {
        	removeData(nodeData);
        }

        function removeData(node, index) {

            node.activated = false;

            // update modify date
            node.modifiedDate = new Date();

            angular.forEach( node.childs, function( child, key ) {
                removeData (child);
            });

            $scope.editForm.$dirty = true;
        }

        function isHaveChild (node) {

            if(node.name === vm.coBrokerConstant || node.name === vm.portalConstant || node.name === vm.indochinaWebsiteConstant) {
                return false;
            }

            if(!node.activated || !node.isRoot) {
                return false;
            }

            if(node.isRoot && node.childs.length === 0) {
                $scope.editForm.$dirty = false;
                return true;
            }

            if(node.isRoot && node.childs.length > 0) {
                var i = 0;
                angular.forEach( node.childs, function( item, key ) {
                    if(item.activated) {
                        i ++;
                    }
                });
                if(i !== 0) {
                    return false;
                }
                $scope.editForm.$dirty = false;
                return true;
            }
        }


        function save (){
            //vm.isSaving = true;

            $scope.editForm.$dirty = false;

            var result = [];
            angular.forEach( vm.data, function( item, key ) {
                if(!item.id && !item.activated) {
                    // do something
                } else {
                    result.push(item);
                    var childrenResult = [];
                    angular.forEach( item.childs, function( child, index ) {
                        if(!child.id && !child.activated) {
                            // do something
                        } else {
                            childrenResult.push(child);
                        }
                    });
                    item.childs = childrenResult;
                }

            });

            vm.data = result;

            LeadSourceManagementService.save(vm.data, onSaveSuccess, onSaveError);
        }


        function onSaveSuccess(){
        	$state.go("lead-source-management", null, { reload: true });
        }

        function onSaveError(){
        }

        function onStateChangeStart(event, toState, toParams, fromState, fromParams) {
            if($scope.editForm.$dirty && !vm.isSaving && !$rootScope.isSessionTimeOut){
            	event.preventDefault();

                $uibModal.open({
                    templateUrl: 'app/admin/lead-source-management/lead-source-management-confirm-dialog.html',
                    controller: 'LeadSourceConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                        entity: function(){
                        	return null;
                        },
                        translateKeys: function(){
                        	return {
                        		keys: 'leadSourceManagement.confirmLeavePage'
                        	};
                        }
                    }
                }).result.then(function() {
                	$scope.editForm.$dirty = false;
                	$state.go(toState, toParams, { reload: true });
                }, function() {
                });
            }
        }


        function isDisplayButtonPlus (node) {

            if(node.isRoot && node.name !== vm.coBrokerConstant && node.name !== vm.introductionConstant
                && node.name !== vm.indochinaWebsiteConstant) {
                return true;
            }

            return false;
        }

        function isHiddenButtonMinus (node) {
            if (node.id) return false;
            return true;
        }
        function isPermanent (node) {
            if(node.name === vm.portalConstant || node.name === vm.introductionConstant || node.name === vm.iccStaffConstant || node.name === vm.iccDivisionConstant
                || node.name === vm.coBrokerConstant || node.name === vm.existingContactConstant || node.name === vm.databaseConstant || node.name === vm.personalNetworkConstant
                || node.name === vm.indochinaWebsiteConstant) return true;
            return false;
        }

    }
})();
