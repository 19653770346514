(function() {
    'use strict';

    angular
        .module('indochinaApp')
        .controller('MarketingCampaignManagementController', Controller);

    Controller.$inject = ['$rootScope', '$scope', '$uibModal', '$state', 'marketingCampaignResource', 'MarketingCampaign', 'MarketingCampaignConstant'];

    function Controller($rootScope, $scope, $uibModal, $state, marketingCampaignResource, MarketingCampaign, MarketingCampaignConstant) {
        var vm = this;

        vm.save = save;
        vm.cancel = cancel;
        vm.edit = edit;
        vm.newSubItem = newSubItem;
        vm.newRoot = newRoot;
        vm.remove = remove;
        vm.isDisplayButtonPlus = isDisplayButtonPlus;
        vm.isDisplayButtonMinus = isDisplayButtonMinus;
        vm.isHaveChild = isHaveChild;

        $scope.$on('$stateChangeStart', onStateChangeStart);

        var identity = 0;

        init ();

        function init () {
            vm.data = marketingCampaignResource;

            angular.forEach( vm.data, function( item, key ) {

                item.isRoot = true;

                // identity in layout
                item.identity = identity;
                identity ++;

                angular.forEach( item.childs, function( child, index ) {
                    child.identity = identity;
                    identity ++;
                });
            });
        }

        function save (){
            vm.isSaving = true;
            $scope.editForm.$dirty = false;

            var result = [];
            angular.forEach( vm.data, function( item, key ) {
                if(!item.id && !item.activated) {
                    // do something
                } else {
                    result.push(item);
                    var childrenResult = [];
                    angular.forEach( item.childs, function( child, index ) {
                        if(!child.id && !child.activated) {
                            // do something
                        } else {
                            childrenResult.push(child);
                        }
                    });
                    item.childs = childrenResult;
                }

            });

            vm.data = result;

            MarketingCampaign.save(vm.data, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(){
            $state.go("marketing-campaign-management", null, { reload: true });
        }

        function onSaveError(){
        }

        function cancel(){
            $state.go("marketing-campaign-management", null, { reload: true });
        }

        function edit ( node ) {
            if(isPermanent (node)) {
                return false;
            }
            node.edit = true;
        }

        function newRoot(){
            var newRoot = newItemInfo ();
            newRoot.isRoot = true;
            vm.data.unshift(newRoot);
        }

        function newSubItem( node ) {
            node.childs.push(newItemInfo ());
        }

        function newItemInfo () {
            identity ++;
            return {
                id: null,
                name: '',
                activated: true,
                createdDate: null,
                modifiedDate: null,
                childs: [],
                isPermanent: false,
                edit: true,
                identity: identity
            }
        }

        function remove(node) {
        	removeData(node);
        }

        function removeData(node, index) {

            node.activated = false;

            angular.forEach( node.childs, function( child, key ) {
                removeData (child);
            });

            $scope.editForm.$dirty = true;
        }

        function isPermanent (node) {
            if(node.isPermanent) return true;
            return false;
        }

        function isDisplayButtonPlus ( node ) {
            if( node.isRoot ) {
                return true;
            }
            return false;
        }

        function isDisplayButtonMinus ( node ) {
            if(node.id) return false;
            return true;
        }

        function isHaveChild (node) {

            if(node.activated === false) {
                return false;
            }

            if(node.isRoot && node.childs && node.childs.length === 0) {
                $scope.editForm.$dirty = false;
                return true;
            }

            return false;
        }

        function onStateChangeStart(event, toState, toParams, fromState, fromParams) {
            if($scope.editForm.$dirty && !vm.isSaving && !$rootScope.isSessionTimeOut){
                event.preventDefault();

                $uibModal.open({
                    templateUrl: 'app/admin/lead-source-management/lead-source-management-confirm-dialog.html',
                    controller: 'LeadSourceConfirmDialogController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass : 'absolute-center',
                    resolve: {
                        entity: function(){
                            return null;
                        },
                        translateKeys: function(){
                            return {
                                keys: 'leadSourceManagement.confirmLeavePage'
                            };
                        }
                    }
                }).result.then(function() {
                    $scope.editForm.$dirty = false;
                    $state.go(toState, toParams, { reload: true });
                }, function() {
                });
            }
        }
    }
})();
